import { LabelCell, IconButton } from "fiorde-fe-components";
import { EditIcon, TrashIcon } from "../icons";
import { t } from "i18next";
import { CHECKLIST_SECTIONCOLUMNS_BUTTON_EDIT, CHECKLIST_SECTIONCOLUMNS_BUTTON_REMOVE } from "@/ids";

export function ChecklistSectionColumns({ edit, remove }) {
  return [
    {
      label: t("components.add-checklist.section-columns.section"),
      key: "section",
      render: ({ name }: any) => <LabelCell>{name}</LabelCell>,
      size: 6,
    },
    {
      label: t("components.add-checklist.condition-columns.actions"),
      key: "actions",
      render: (contact, index) => (
        <div style={{ display: "flex", gap: 8 }}>
          <IconButton
            id={CHECKLIST_SECTIONCOLUMNS_BUTTON_EDIT}
            data-testid="edit-button"
            onClick={() => edit(contact, index)}
            tooltip={t(
              "components.add-checklist.condition-columns.edit-tooltip"
            )}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            id={CHECKLIST_SECTIONCOLUMNS_BUTTON_REMOVE}
            data-testid="remove-button"
            onClick={() => remove(index)}
            tooltip={t(
              "components.add-checklist.condition-columns.remove-tooltip"
            )}
          >
            <TrashIcon />
          </IconButton>
        </div>
      ),
      size: 1,
    },
  ];
}
