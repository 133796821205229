import { RemoteLoadStatesList } from "@/data/usecase";
import { LoadStatesList } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteLoadStatesList = (): LoadStatesList =>
  new RemoteLoadStatesList(
    makeApiUrl(`/sirius-master-data-api/v1/countries`),
    makeAuthorizeHttpClientDecorator()
  );
