import React from 'react'
import { VehicleComposition } from '@/presentation/pages'
import {
  makeRemoteLoadVehicleCompositionPegeable,
  makeRemoteLoadVehicleComposition,
  makeRemoteLoadVehicleTypeList,
  makeRemoteLoadBodyworkList,
  makeRemoteAddVehicleComposition,
  makeRemoteUpdateVehicleComposition,
  
} from '../../usecases'

export const makeVehicleCompositionList: React.FC = (props) => {
  return (
    <VehicleComposition
      {...props}
      addVehicleComposition={makeRemoteAddVehicleComposition()}
      updateVehicleComposition={makeRemoteUpdateVehicleComposition()}
      loadVehicleComposition={makeRemoteLoadVehicleComposition()}
      LoadVehicleCompositionPegeable={makeRemoteLoadVehicleCompositionPegeable()}
      loadVehicleTypeList={makeRemoteLoadVehicleTypeList()}
      loadBodyworkList={makeRemoteLoadBodyworkList()}
    />
  )
}
