import styled from 'styled-components'
import wave from '../../assets/ondas-1.png'

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-image: url(${wave});
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  overflow-y: auto;
  font-family: DMSans-Regular;
  background-color: ${(props: any) => props.theme?.platform?.pages?.home?.mainColor};
  color: ${(props: any) => props.theme?.platform?.fontColor};
  & > div > svg {
    width: 212px;
    height: 64px;
    margin: 107px 0 45px 0;
    @media (max-width: 1366px) {
      margin: 50px 0 30px 0;
    }
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;

  & > p {
    width: 340px;
    text-align: center;
    margin: 0;
    font-size: 14px;
  }
`

const Error = styled.div`
  & > div {
    width: 370px;
    margin-bottom: 0;
    margin-top: 33px;
  }
`

const InputContainer = styled.div`
  & .MuiFormControl-root {
    width: 370px;
    margin-top: 45px;
    display: flex;
    flex-direction: column;

    p {
      color: #CC3D3D;
      font-family: DMSans-Regular;
      align-self: flex-end;
      width: 165px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @media (max-width: 1366px) {
      margin-top: 35px;
    }
  }

  & .MuiInputLabel-formControl {
    left: 7px;
    color: #545454;
    font-size: 14px;
    font-family: DMSans-Regular;
  }

  & .MuiInputBase-input {
    color: #545454;
    padding-top: 6px;
    font-family: DMSans-Regular;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 50px;
  width: 370px;
  justify-content: space-between;
  @media (max-width: 1366px) {
    margin-top: 40px;
  }
`

export { MainContainer, Container, Error, InputContainer, ButtonContainer }
