import React from "react";
import { BusinessPartner } from "@/presentation/pages";
import {
  makeRemoteLoadBusinessPartnerList,
  makeRemoteLoadClassificationList,
  makeRemoteAddBusinessPartner,
  makeRemoteLoadCountriesList,
  makeRemoteLoadAddressTypeList,
  makeRemoteLoadDocumentTypeList,
  makeRemoteLoadCitiesList,
  makeRemoteLoadStatesList,
  makeRemoteLoadBusinessPartner,
  makeRemoteUpdateBusinessPartner
} from "../../usecases";

export const makeBusinessPartnerList: React.FC = (props) => {
  return (
    <BusinessPartner
      {...props}
      loadBusinessPartnerList={makeRemoteLoadBusinessPartnerList()}
      loadClassificationList={makeRemoteLoadClassificationList()}
      addBusinessPartner={makeRemoteAddBusinessPartner()}
      loadCountriesList={makeRemoteLoadCountriesList()}
      loadAddressTypeList={makeRemoteLoadAddressTypeList()}
      loadDocumentTypeList={makeRemoteLoadDocumentTypeList()}
      loadCitiesList={makeRemoteLoadCitiesList()}
      loadStatesList={makeRemoteLoadStatesList()}
      loadBusinessPartner={makeRemoteLoadBusinessPartner()}
      updateBusinessPartner={makeRemoteUpdateBusinessPartner()}
    />
  );
};
