import { HttpClient, HttpStatusCode } from "@/domain/protocols/http";
import { UpdateOrganization } from "@/domain/usecase";
import { RemoteUpdateOrganizationDTO } from "../models";
import { AccessDeniedError, UnexpectedError } from "@/domain/errors";

export class RemoteUpdateOrganization implements UpdateOrganization {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<UpdateOrganization.Model>
  ) {}

  async update(
    id: number,
    params: UpdateOrganization.Model
  ): Promise<void> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${String(id)}`,
      method: "put",
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        break;
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }
}

export namespace RemoteUpdateOrganization {
  export type Model = RemoteUpdateOrganizationDTO;
}
