import React from 'react';
import { ControlledToolTip } from 'fiorde-fe-components';
import { IconDisplay, RedColorSpan } from './ValidityDisplayStyles';
import { I18n } from 'react-redux-i18n';
import WarnIconClicked from '../../../application/icons/WarnClicked';
import AlertClickedIcon from '../../../application/icons/AlertClicked';
import moment from 'moment';
const ValidityDisplay = (props) => {
    const { validity } = props;
    const today = moment().startOf('day');
    const date = moment(validity, 'DD/MM/YYYY');
    const timeDiff = date.diff(today, 'days');
    if (timeDiff < 0) {
        return (React.createElement(IconDisplay, null,
            React.createElement(ControlledToolTip, { placement: "top", title: I18n.t('pages.tariff.tariffTable.overdue'), open: true, disabled: true, getTitle: false },
                React.createElement("div", { className: 'icon' },
                    React.createElement(AlertClickedIcon, null))),
            React.createElement(RedColorSpan, null, validity)));
    }
    else if (timeDiff <= 7) {
        return (React.createElement(IconDisplay, null,
            React.createElement(ControlledToolTip, { placement: "top", title: `${String(I18n.t('pages.tariff.tariffTable.validUntil'))} ${timeDiff} ${String(I18n.t('pages.tariff.tariffTable.days'))}`, open: true, disabled: true, getTitle: false },
                React.createElement("div", { className: 'icon' },
                    React.createElement(WarnIconClicked, null))),
            validity));
    }
    return React.createElement(React.Fragment, null, validity);
};
export default ValidityDisplay;
