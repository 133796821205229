import { Home } from '@/presentation/pages'
import { makeRemoteLoadNcm, makeRemotePostGenerateSheet, makeRemotePostSendSheet } from '../../usecases'
import { makeHomeValidation } from './home-validation-factory'

export const makeHome = () => {
  return (
    <Home
      loadNcm={makeRemoteLoadNcm()}
      postGenerateSheet={makeRemotePostGenerateSheet()}
      postSendSheet={makeRemotePostSendSheet()}
      validation={makeHomeValidation()}
    />
  )
}
