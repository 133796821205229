import React, { useState } from "react";
import { AddStep as DomainAddStep, Load, LoadStepTypeList } from "@/domain/usecase";
import {
  Button,
  Modal,
  Messages,
  ProgressIndicator
} from "fiorde-fe-components";
import { useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import {
  PREFIX_REGISTER_ADDSTEP_BUTTON_OPEN,
  PREFIX_REGISTER_ADDSTEP_BUTTON_CLOSE,
  PREFIX_REGISTER_ADDSTEP_BUTTON_SUBMIT

} from "@/ids";
import { DisplayRules } from "./display-rules";
import { AdditionalFields } from "./additional-fields";
import { GeneralForm } from "./general-form";

type AddStepProps = {
  addStep: DomainAddStep;
  loadStepTypeList: LoadStepTypeList;
  loadConditionType: Load<any>
  loadConditionValue: Load<any>
  loadSectionByCheckListId?: Load<any>
  loadAdditionalFieldType?: Load<any>
  loadChecklistAll?: Load<any>
};

export function AddStep({
  addStep,
  loadStepTypeList,
  loadConditionType,
  loadConditionValue,
  loadSectionByCheckListId,
  loadAdditionalFieldType,
  loadChecklistAll
}: AddStepProps) {
  const queryClient = useQueryClient();
  const [isLoading, setLoading] = useState(false);
  const [permanenceType, setPermanenceType] = useState(null);
  const [values, setValues] = useState({
    name: '',
    stepType: { id: null },
    description: '',
    permanence: '',
    conditions: [],
    stepAdditionalFields: []
  })

  const [open, setOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: "",
  });

  function handleSubmit(): void {
    setLoading(true);
    addStep
      .add({
        name: values.name,
        description: values.description,
        // @ts-expect-error
        permanence: makePermanenceTime(values.permanence),
        stepType: values.stepType,
        conditions: values.conditions,
        stepAdditionalFields: values.stepAdditionalFields,
        isActive: true,
      })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["step-list"] });
        setResponseMessage({
          message: t("components.add-step.response-success-message"),
          severity: "success",
        });
        handleClose();
      })
      .catch((err) => {
        setResponseMessage({
          message: err?.message,
          severity: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setValues({
      name: '',
      stepType: { id: null },
      description: '',
      permanence: '',
      conditions: [],
      stepAdditionalFields: []
    })
    setOpen(false);
   }

  function makePermanenceTime (value) {
    switch (permanenceType) {
      case 'minutos':
        return `00:${value}:00`
      case 'horas':
        return `${value}:00:00`
      default:
        return ''
    }
  }

  return (
    <>
      <ProgressIndicator
        visible={isLoading}
        content={t("components.progress-indicator.content")}
      />
      <Button
        id={PREFIX_REGISTER_ADDSTEP_BUTTON_OPEN}
        icon="add"
        data-testid="open-button"
        onAction={() => handleOpen()}
        text={t("components.add-step.open-button")}
      />
      <Modal
        width={1030}
        title={t("components.add-step.open-button")}
        isOpen={open}
        onClose={handleClose}
        actions={[
          <Button
            id={PREFIX_REGISTER_ADDSTEP_BUTTON_CLOSE}
            data-testid="close-button"
            backgroundGreen={false}
            onAction={() => handleClose()}
            text={t("components.add-step.close-button")}
          />,
          <Button
            id={PREFIX_REGISTER_ADDSTEP_BUTTON_SUBMIT}
            data-testid="submit-button"
            onAction={() => handleSubmit()}
            text={t("components.add-step.submit-button")}
          />,
        ]}
      >
        <GeneralForm
          values={values}
          onChange={(value) => setValues(value)}
          loadStepTypeList={loadStepTypeList}
          addPermanenceType={setPermanenceType}
        />
        <DisplayRules
          loadConditionType={loadConditionType}
          loadConditionValue={loadConditionValue}
          conditions={values.conditions}
          onChange={(conditions) => setValues({ ...values, conditions: conditions })}
        />
        <AdditionalFields
          additionaleFields={values.stepAdditionalFields}
          onChange={(stepAdditionalFields) => setValues({ ...values, stepAdditionalFields: stepAdditionalFields })}
          loadAdditionalFieldType={loadAdditionalFieldType}
          loadChecklistAll={loadChecklistAll}
          loadSectionByCheckListId={loadSectionByCheckListId}
        />
      </Modal>
      {responseMessage.message && (
        <Messages
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={() =>
            setResponseMessage({
              message: "",
              severity: "",
            })
          }
        />
      )}
    </>
  );
}
