import React, { FC, ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ChildrenContainer,
  ExtensionMenuContainer,
  Container,
} from "./route-wrapper-style";
import { t } from "i18next";
import { ExtensionMenu } from "fiorde-fe-components";

export function RouteWrapper({ makeComponent }): JSX.Element {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleChange = (isOpened): void => {
    setOpen(isOpened);
  };

  const menuItems: any = [
    {
      icon: 'checklist',
      label: t("sidebar.checklist"),
      onclick: () => navigate('/'),
      subMenuItems: [],
    },
  ];
  return (
    <Container>
      <ExtensionMenuContainer>
        <ExtensionMenu
          menuItems={menuItems}
          module=""
          onChange={handleChange}
          selectedSubItem={undefined}
          selectedSubMenu={undefined}
        />
      </ExtensionMenuContainer>
      <ChildrenContainer isOpen={open}>{makeComponent()}</ChildrenContainer>
    </Container>
  );
}
