import { Row } from "../row/row";
import { Button, FormControl, Select } from "fiorde-fe-components";
import { makeSelectValues } from "@/presentation/utils/arrays";
import { disableAddForm } from "./checklist-helpers";
import { t } from "i18next";
import { CHECKLIST_CONDITIONFORM_BUTTON_CLOSE, CHECKLIST_CONDITIONFORM_BUTTON_SUBMIT, CHECKLIST_CONDITIONFORM_SELECT_CONDITION, CHECKLIST_CONDITIONFORM_SELECT_CONDITION_LABEL, CHECKLIST_CONDITIONFORM_SELECT_EQUALS, CHECKLIST_CONDITIONFORM_SELECT_EQUALS_LABEL } from "@/ids";

export function ChecklistConditionForm({
  conditions,
  values,
  onChange,
  conditionsList,
  compareList,
  onSubmit,
  onClose,
  disabledCloseButton,
}) {
  return (
    <>
      <Row gridTemplateColumns="1fr">
        <FormControl
          label={t("components.add-checklist.condition")}
          id={CHECKLIST_CONDITIONFORM_SELECT_CONDITION_LABEL}
        >
          <Select
            id={CHECKLIST_CONDITIONFORM_SELECT_CONDITION}
            name="condition"
            options={makeSelectValues({
              label: "name",
              value: "id",
              array: conditionsList,
            })}
            onChange={(value) => 
              onChange({ ...values, conditionType: { id: value } })
            }
            placeholder={t("components.add-checklist.select")}
            value={values?.conditionType?.id}
          />
        </FormControl>
      </Row>
      <Row gridTemplateColumns="1fr">
        <FormControl
          label={t("components.add-checklist.equals-to")}
          id={CHECKLIST_CONDITIONFORM_SELECT_EQUALS_LABEL}
        >
          <Select
            id={CHECKLIST_CONDITIONFORM_SELECT_EQUALS}
            name="value"
            options={makeSelectValues({
              label: "simpleName",
              value: "id",
              array: compareList,
              subLabel: "businessPartner"
            })}
            onChange={(value) => onChange({ ...values, value: { id: value }, operator: compareList?.find((v) => v.businessPartner.id === value)?.businessPartner.simpleName })}
            placeholder={t("components.add-checklist.select")}
            value={values?.value?.id}
          />
        </FormControl>
      </Row>
      <Row gridTemplateColumns="1fr auto" justifyItems="end">
        <Button
          id={CHECKLIST_CONDITIONFORM_BUTTON_CLOSE}
          backgroundGreen={false}
          onAction={onClose}
          text="Fechar"
          disabled={false}
        />
        <Button
          id={CHECKLIST_CONDITIONFORM_BUTTON_SUBMIT}
          onAction={() => onSubmit(values)}
          disabled={disableAddForm(values)}
          text={
            values.index !== null ?  t("components.add-checklist.add-condition") : t("components.add-checklist.edit-condition")
          }
        />
      </Row>
    </>
  );
}
