import { AccessDeniedError, UnexpectedError } from "@/domain/errors";
import {
  BackendPegeableResponse,
  HttpClient,
  HttpStatusCode,
} from "@/domain/protocols/http";
import { LoadOrganizationList } from "@/domain/usecase";
import { RemoteLoadOrganizationListDTO } from "../models";

export class RemoteLoadOrganizationList implements LoadOrganizationList {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<LoadOrganizationList.Model>
  ) {}

  async load(
    filters: Object
  ): Promise<LoadOrganizationList.Model> {
    const queryString = Object.entries(filters)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    const httpResponse = await this.httpClient.request({
      url: `${this.url}?${queryString}`,
      method: "get",
    });
    const LoadOrganizationList = httpResponse.body;
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return LoadOrganizationList;
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }
}

export namespace RemoteLoadOrganizationList {
  export type Model = RemoteLoadOrganizationListDTO;
}
