import { registerApplication, start } from "single-spa";

registerApplication({
  name: "@sirius/platform-core-fe",
  app: () => System.import("@sirius/platform-core-fe"),
  activeWhen: ["/"],
});

registerApplication({
  name: "@sirius/sales-fe",
  app: () => System.import("@sirius/sales-fe"),
  activeWhen: ["/#/comercial"],
});

registerApplication({
  name: "@sirius/yard-control-fe",
  app: () => System.import("@sirius/yard-control-fe"),
  activeWhen: ["/#/yard"],
});

registerApplication({
  name: "@sirius/registrations-fe",
  app: () => System.import("@sirius/registrations-fe"),
  activeWhen: ["/#/registrations"],
});

registerApplication({
  name: "@sirius/checklist-fe",
  app: () => System.import("@sirius/checklist-fe"),
  activeWhen: ["/#/checklist"],
});

registerApplication({
  name: "@sirius/pu-catp-fe",
  app: () => System.import("@sirius/pu-catp-fe"),
  activeWhen: ["/#/pu-catp"],
});

start({
  urlRerouteOnly: true,
});
