import { Modal, Grid, FormLabel, Table, TableBody, TableContainer, TableHead, TableRow, TableCell, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import React, { useState, useContext, useEffect } from 'react';
import CloseIcon from '../../../application/icons/CloseIcon';
import { CloseButtonDiv, ModalDiv, MainDiv, TableBodyCell, NoTariffs } from './TariffImportModalStyles';
import { I18n } from 'react-redux-i18n';
import { HeaderDiv, RowReverseDiv, Title, CloseIconContainer } from '../StyledComponents/modalStyles';
import { Button } from 'fiorde-fe-components';
import { TariffContext } from '../../pages/Tariff/context/TariffContext';
import useTariffsByCountry from '../../hooks/tariff/useTariffsByCountry';
import { TariffItemsTypes, ValidityTypes } from '../../../application/enum/tariffEnum';
import FormatNumber from '../../../application/utils/formatNumber';
import { TARIFF_IMPORT_AIR_MODAL_BUTTON_IMPORT, TARIFF_IMPORT_AIR_MODAL_BUTTON_CANCEL } from '../../../ids';
import { ModalTypes } from '../../../application/enum/enum';
import { useOriginDestination, usePartnerList, useBusinessPartnerByType } from '../../hooks';
const TariffImportAirModal = ({ theme, open, setClose, importFilter, typeModal, calculationData, getPurchase, index, type, cw, cwSale }) => {
    var _a, _b, _c, _d, _f, _g;
    const { filter } = useContext(TariffContext);
    const { content: tariffData, setParams, refetch } = useTariffsByCountry();
    const { partnerList: agentsList } = usePartnerList();
    const { data: originDestinationList = [] } = useOriginDestination();
    const { airPartners = [] } = useBusinessPartnerByType();
    const [value, setValue] = useState('0');
    const [valuePosition, setValuePosition] = useState(0);
    const handleOnClose = () => {
        setClose();
        setValue('');
        setParams();
    };
    useEffect(() => {
        setParams(Object.assign(Object.assign({}, importFilter), { tariffModalType: ModalTypes.Air, validityTariff: ValidityTypes.Valid, tariffType: type }));
        refetch();
    }, [importFilter, filter, open]);
    const calculateValue = () => {
        var _a;
        const tariffValues = (_a = tariffData[0].tariffTypeValues) === null || _a === void 0 ? void 0 : _a.map((item) => {
            return item.value;
        });
        let valueToCheck;
        if (calculationData.weight > calculationData.cubageWeight) {
            valueToCheck = calculationData.weight;
        }
        else {
            valueToCheck = calculationData.cubageWeight;
        }
        const isBetweenMapValue = (value, valueMap) => {
            for (const key in valueMap) {
                // eslint-disable-next-line no-prototype-builtins
                if (valueMap.hasOwnProperty(key)) {
                    const range = valueMap[key];
                    if (value >= range.min && value <= range.max) {
                        return range.position;
                    }
                    if (value >= 1001) {
                        return 5;
                    }
                }
            }
        };
        const rangeMap = {
            range1: { label: TariffItemsTypes.Minimun, position: 0, min: 0, max: 45 },
            range2: { label: TariffItemsTypes.Until45, position: 1, min: 46, max: 100 },
            range3: { label: TariffItemsTypes.Until100, position: 2, min: 101, max: 300 },
            range4: { label: TariffItemsTypes.Until300, position: 3, min: 301, max: 500 },
            range5: { label: TariffItemsTypes.Until500, position: 4, min: 501, max: 1000 }
        };
        const isBetweenValue = isBetweenMapValue(valueToCheck, rangeMap);
        const getCloset = tariffValues[isBetweenValue];
        const getIndexValue = 4 + (isBetweenValue);
        setValuePosition(getIndexValue);
        setValue(FormatNumber.convertNumberToString(getCloset));
    };
    useEffect(() => {
        if (tariffData.length > 0)
            calculateValue();
    }, [calculationData, tariffData]);
    const getTariffValue = (type, tariff) => {
        const tariffValue = tariff === null || tariff === void 0 ? void 0 : tariff.tariffTypeValues.find((each) => each.tariffType.description === type);
        return tariffValue === null || tariffValue === void 0 ? void 0 : tariffValue.value;
    };
    const createTable = () => {
        var _a, _b, _c, _d, _f;
        const tariffs = [];
        tariffs.push({
            id: (_a = tariffData[0]) === null || _a === void 0 ? void 0 : _a.idTariff,
            airCompany: (_b = tariffData[0]) === null || _b === void 0 ? void 0 : _b.dsBusinessPartnerTransporter,
            agent: (_c = tariffData[0]) === null || _c === void 0 ? void 0 : _c.nmAgent,
            dtValidity: new Date((_d = tariffData[0]) === null || _d === void 0 ? void 0 : _d.validityDate).toLocaleDateString('pt-BR'),
            currency: (_f = tariffData[0]) === null || _f === void 0 ? void 0 : _f.currency,
            minValue: getTariffValue(TariffItemsTypes.Minimun, tariffData[0]),
            weight1: getTariffValue(TariffItemsTypes.Until45, tariffData[0]),
            weight2: getTariffValue(TariffItemsTypes.Until100, tariffData[0]),
            weight3: getTariffValue(TariffItemsTypes.Until300, tariffData[0]),
            weight4: getTariffValue(TariffItemsTypes.Until500, tariffData[0]),
            weight5: getTariffValue(TariffItemsTypes.Until1000, tariffData[0])
        });
        return tariffs;
    };
    const checkIsNumber = (value) => {
        if (isNaN(value)) {
            return value;
        }
        else {
            return FormatNumber.convertNumberToString(value !== null ? value : '-');
        }
    };
    return (React.createElement(Modal, { open: open, onClose: handleOnClose },
        React.createElement(ModalDiv, null,
            React.createElement(HeaderDiv, null,
                React.createElement(Title, null, I18n.t('pages.newProposal.step5.tariffImport.title')),
                React.createElement(RowReverseDiv, null,
                    React.createElement(CloseIconContainer, null,
                        React.createElement(CloseIcon, { onClick: handleOnClose })))),
            React.createElement(MainDiv, null,
                React.createElement(Grid, { container: true, spacing: 1 },
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement(FormLabel, { component: "legend" }, I18n.t('pages.tariff.tariffImport.origin')),
                        React.createElement(FormLabel, { component: "legend" },
                            React.createElement("strong", null, (_a = originDestinationList.find((item) => item.id === (importFilter === null || importFilter === void 0 ? void 0 : importFilter.idOrigin))) === null || _a === void 0 ? void 0 :
                                _a.id,
                                ' ',
                                "-", (_b = originDestinationList.find((item) => item.id === (importFilter === null || importFilter === void 0 ? void 0 : importFilter.idOrigin))) === null || _b === void 0 ? void 0 :
                                _b.name))),
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement(FormLabel, { component: "legend" }, I18n.t('pages.tariff.tariffImport.destiny')),
                        React.createElement(FormLabel, { component: "legend" },
                            React.createElement("strong", null, (_c = originDestinationList.find((item) => item.id === (importFilter === null || importFilter === void 0 ? void 0 : importFilter.idDestination))) === null || _c === void 0 ? void 0 :
                                _c.id,
                                ' ',
                                "-", (_d = originDestinationList.find((item) => item.id === (importFilter === null || importFilter === void 0 ? void 0 : importFilter.idDestination))) === null || _d === void 0 ? void 0 :
                                _d.name))),
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement(FormLabel, { component: "legend" }, I18n.t('pages.tariff.tariffImport.agent')),
                        React.createElement(FormLabel, { component: "legend" },
                            React.createElement("strong", null, (_f = agentsList.find((item) => item.id === (importFilter === null || importFilter === void 0 ? void 0 : importFilter.idBusinessPartnerAgent))) === null || _f === void 0 ? void 0 : _f.simpleName))),
                    React.createElement(Grid, { item: true, xs: 6 },
                        React.createElement(FormLabel, { component: "legend" }, typeModal),
                        React.createElement(FormLabel, { component: "legend" },
                            React.createElement("strong", null, (_g = airPartners.find((item) => item.businessPartner.id ===
                                (importFilter === null || importFilter === void 0 ? void 0 : importFilter.idBusinessPartnerTransporter))) === null || _g === void 0 ? void 0 : _g.businessPartner.simpleName)))),
                tariffData.length > 0
                    ? (React.createElement(React.Fragment, null,
                        React.createElement(TableContainer, { style: { marginTop: '20px', borderBottom: '1px solid gray' } },
                            React.createElement(Table, null,
                                React.createElement(TableHead, null,
                                    React.createElement(TableRow, null, Object.values(I18n.t('components.TariffImport.Air')).map((column) => (React.createElement(TableCell, { style: { paddingLeft: 0 }, key: column }, column))))),
                                React.createElement(TableBody, null, createTable().map((tariff) => (React.createElement(TableRow, { key: tariff.id }, Object.values(tariff)
                                    .filter((_e, index) => index !== 0)
                                    .map((each, index) => index < 4
                                    ? (React.createElement(TableBodyCell, { key: `${String(each)}-${String(index)}`, align: "left" }, checkIsNumber(each)))
                                    : (React.createElement(TableBodyCell, { key: `${String(each)}-${String(index)}`, align: "left", style: { marginLeft: '5px' } },
                                        React.createElement(RadioGroup, { row: true, "aria-label": "reason", name: "row-radio-buttons-group", value: value, onChange: () => setValue(event.target.value), style: {
                                                justifyContent: 'left',
                                                marginLeft: '10px'
                                            } },
                                            React.createElement(FormControlLabel, { value: checkIsNumber(each), id: '', control: React.createElement(Radio, { value: checkIsNumber(each), checked: valuePosition === index, disabled: valuePosition !== index }), label: checkIsNumber(each) }))))))))))),
                        React.createElement(Grid, { item: true, xs: 12, container: true, direction: "row", justify: "flex-end", alignItems: "center" },
                            React.createElement(Grid, { item: true },
                                React.createElement(CloseButtonDiv, null,
                                    React.createElement(Button, { id: TARIFF_IMPORT_AIR_MODAL_BUTTON_CANCEL, disabled: false, text: I18n.t('pages.tariff.tariffImport.closeButtonLabel'), tooltip: I18n.t('pages.tariff.tariffImport.closeButtonLabel'), backgroundGreen: false, icon: "", onAction: handleOnClose }))),
                            React.createElement(Grid, { item: true },
                                React.createElement("div", null,
                                    React.createElement(Button, { id: TARIFF_IMPORT_AIR_MODAL_BUTTON_IMPORT, disabled: false, text: I18n.t('pages.newProposal.step5.tariffImport.importButton'), tooltip: I18n.t('pages.newProposal.step5.tariffImport.importButton'), backgroundGreen: true, icon: "", onAction: () => {
                                            getPurchase(value, tariffData[0].currency, index, tariffData[0].idTariff);
                                            handleOnClose();
                                        } }))))))
                    : (React.createElement(React.Fragment, null,
                        React.createElement(NoTariffs, null, I18n.t('pages.newProposal.step5.tariffImport.noTariffs')),
                        React.createElement(Grid, { item: true, xs: 12, container: true, direction: "row", justify: "flex-end", alignItems: "center" },
                            React.createElement(Grid, { item: true },
                                React.createElement(CloseButtonDiv, null,
                                    React.createElement(Button, { id: TARIFF_IMPORT_AIR_MODAL_BUTTON_CANCEL, disabled: false, text: I18n.t('pages.tariff.tariffImport.closeButtonLabel'), tooltip: I18n.t('pages.tariff.tariffImport.closeButtonLabel'), backgroundGreen: false, icon: "", onAction: handleOnClose }))))))))));
};
export default TariffImportAirModal;
