import { AccessDeniedError, UnexpectedError } from '@/domain/errors'
import { HttpClient, HttpStatusCode } from '@/domain/protocols/http'
import { Post } from '@/domain/usecase'

export class RemotePost<T> implements Post<T> {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<T>
  ) {}

  async post (params: T): Promise<T> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params
    })
    const post = httpResponse.body
    switch (httpResponse.statusCode) {
      case HttpStatusCode.created: return post
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      default:
        //@ts-ignore
        const err = httpResponse.body?.response?.error;
        throw new UnexpectedError(err);
    }
  }
}
