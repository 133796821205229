

import { AccessDeniedError, UnexpectedError } from '@/domain/errors'
import { HttpClient, HttpStatusCode } from '@/domain/protocols/http'
import { LoadMaterialFamilyPegeable } from '@/domain/usecase'
import { RemoteLoadMaterialFamilyPegeableDTO } from '../models'

export class RemoteLoadMaterialFamilyPegeable implements LoadMaterialFamilyPegeable {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<RemoteLoadMaterialFamilyPegeable.Model>
  ) {}

  async load (filters: Object): Promise<LoadMaterialFamilyPegeable.Model> {
    const queryString = Object.entries(filters)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&');
    const httpResponse = await this.httpClient.request({
      url: `${this.url}?${queryString}`,
      method: 'get'
    })

    const LoadMaterialFamilyPegeable = httpResponse.body
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return LoadMaterialFamilyPegeable
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      default:
        //@ts-ignore
        const err = httpResponse.body?.response?.error;
        throw new UnexpectedError(err);
    }
  }
}

export namespace RemoteLoadMaterialFamilyPegeable {
  export type Model = RemoteLoadMaterialFamilyPegeableDTO
}
