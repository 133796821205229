import { AccessDeniedError, UnexpectedError } from '@/domain/errors'
import { HttpClient, HttpStatusCode } from '@/domain/protocols/http'
import { LoadBodywork } from '@/domain/usecase'
import { RemoteLoadBodyworkDTO } from '@/data/models'

export class RemoteLoadBodywork implements LoadBodywork {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<LoadBodywork.Model>
  ) {}

  async load (id: number): Promise<LoadBodywork.Model> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${id}`,
      method: 'get'
    })
    const LoadBodywork = httpResponse.body
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return LoadBodywork
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}

export namespace RemoteLoadBodywork {
  export type Model = RemoteLoadBodyworkDTO
}
