import styled from 'styled-components';
const TotalContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: auto;
  border-radius: 8px;
  background: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.totalSurchage) === null || _d === void 0 ? void 0 : _d.backgroundColor; }};
  margin-top: 30px;
  padding: 0 16px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.totalSurchage) === null || _d === void 0 ? void 0 : _d.fontColor; }};
  letter-spacing: 0.02em;
`;
const UpperContainer = styled.div `
  display: flex;
  flex-direction: row;
  margin: 20px 1%;
  width: 98%;
  border-bottom: 1px solid;
  border-color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.totalSurchage) === null || _d === void 0 ? void 0 : _d.borderColor; }};
  padding-bottom: 15px;
  justify-content: space-between;
`;
const LowerContainer = styled.div `
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  margin-left: 1%;
  margin-right: 1%;
  width: 98%;
`;
const CwLabel = styled.span `
  margin-right: 41px;
  width: 200px;
  min-width: 100px;
`;
const ProfitValue = styled.span `
  margin-left: 15px;
  font-weight: 400;
`;
const TotalCargoContainer = styled.div `
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 350px;
`;
export { TotalContainer, UpperContainer, TotalCargoContainer, CwLabel, ProfitValue, LowerContainer };
