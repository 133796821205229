var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Modal, Grid, FormLabel, MenuItem, TableHead, TableBody, Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CloseIcon from '../../../../../application/icons/CloseIcon';
import moment from 'moment';
import { ButtonDiv, ColumnDiv, ModalDiv, MainDiv, SelectSpan, StyledTable, StyledTableCell, SubDiv, TableBodyRow, TableHeadRow, Input } from './AirTariffModalStyles';
import { I18n } from 'react-redux-i18n';
import ControlledInput from '../../../../components/ControlledInput';
import { HeaderDiv, RedColorSpan, RowReverseDiv, Title, CloseIconContainer, RowDiv } from '../../../../components/StyledComponents/modalStyles';
import { Button } from 'fiorde-fe-components';
import { Autocomplete } from '@material-ui/lab';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { NumberInput, StyledPaper } from '../../../NewProposal/steps/StepsStyles';
import FormatNumber from '../../../../../application/utils/formatNumber';
import ControlledSelect from '../../../../components/ControlledSelect';
import API from '../../../../../infrastructure/api';
import { useCurrencies, useFrequency } from '../../../../hooks';
import { TariffItemsTypes } from '../../../../../application/enum/tariffEnum';
import { TARIFF_AIR_TARIFF_MODAL_SPAN_AGENT, TARIFF_AIR_TARIFF_MODAL_SPAN_COMPANY, TARIFF_AIR_TARIFF_MODAL_SELECT_CURRENCY, TARIFF_AIR_TARIFF_MODAL_INPUT_MINVALUE, TARIFF_AIR_TARIFF_MODAL_INPUT_WEIGHT, TARIFF_AIR_TARIFF_MODAL_INPUT_DATA, TARIFF_AIR_TARIFF_MODAL_SELECT_FREQUENCY, TARIFF_AIR_TARIFF_MODAL_INPUT_TXROUTE, TARIFF_AIR_TARIFF_MODAL_INPUT_TRANSITTIME, TARIFF_AIR_TARIFF_MODAL_BUTTON_CANCEL, TARIFF_AIR_TARIFF_MODAL_BUTTON_SAVE } from '../../../../../ids';
export const initialState = {
    minValue: null,
    dtValidity: null,
    frequency: '',
    txRoute: null,
    transitTime: null,
    weight1: null,
    weight2: null,
    weight3: null,
    weight4: null,
    weight5: null,
    currency: null,
    agent: null,
    originDestination: null,
    airCompany: null
};
const AirTariffModal = ({ dataProp, open, setClose }) => {
    var _a, _b, _c, _d, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    const [data, setData] = useState(initialState);
    const { data: frequencyList = [] } = useFrequency();
    const { data: currencyList = [] } = useCurrencies();
    const [invalidInput, setInvalidInput] = useState(false);
    const rgxFloat = /^[0-9]*,?[0-9]*$/;
    const rgxInt = /^[0-9]*$/;
    const MaxLength = {
        maxLength: 13
    };
    useEffect(() => {
        if (dataProp !== initialState) {
            const getTariffValue = (type) => {
                const tariffValue = dataProp.tariffTypeValues.find(each => each.tariffType.description === type);
                if (tariffValue !== undefined) {
                    const { idTariffTypeValues, value } = tariffValue;
                    return { idTariffTypeValues, value: value.toFixed(2) };
                }
                return { idTariffTypeValues: 0, value: 0 };
            };
            const tariff = {
                agent: dataProp.nmAgent,
                airCompany: dataProp.dsBusinessPartnerTransporter,
                transitTime: String(dataProp.transitTime),
                currency: dataProp.currency,
                dtValidity: new Date(dataProp.validityDate).toLocaleDateString('pt-BR'),
                minValue: getTariffValue(TariffItemsTypes.Minimun),
                weight1: getTariffValue(TariffItemsTypes.Until45),
                weight2: getTariffValue(TariffItemsTypes.Until100),
                weight3: getTariffValue(TariffItemsTypes.Until300),
                weight4: getTariffValue(TariffItemsTypes.Until500),
                weight5: getTariffValue(TariffItemsTypes.Until1000),
                txRoute: dataProp.route,
                frequency: dataProp.frequency,
                originDestination: `${String(dataProp.origin)} > ${String(dataProp.destination)}`
            };
            setData(tariff);
        }
    }, [open]);
    const handleOnClose = () => {
        setData(initialState);
        setInvalidInput(false);
        setClose();
    };
    const validateFloatInput = (value) => {
        return value.match(rgxFloat);
    };
    const validateIntInput = (value) => {
        return value.match(rgxInt);
    };
    const validateData = () => {
        var _a, _b, _c, _d, _f, _g, _h, _j, _k, _l, _m;
        return !(!validateDate() ||
            (data.weight1 === null || ((_a = data.weight1.value) === null || _a === void 0 ? void 0 : _a.length) === 0) ||
            (data.weight2 === null || ((_b = data.weight2.value) === null || _b === void 0 ? void 0 : _b.length) === 0) ||
            (data.weight3 === null || ((_c = data.weight3.value) === null || _c === void 0 ? void 0 : _c.length) === 0) ||
            (data.weight4 === null || ((_d = data.weight4.value) === null || _d === void 0 ? void 0 : _d.length) === 0) ||
            (data.weight5 === null || ((_f = data.weight5.value) === null || _f === void 0 ? void 0 : _f.length) === 0) ||
            (data.minValue === null || ((_g = data.minValue.value) === null || _g === void 0 ? void 0 : _g.length) === 0) ||
            (data.dtValidity === null || ((_h = data.dtValidity) === null || _h === void 0 ? void 0 : _h.length) === 0) ||
            (data.frequency === null || ((_j = data.frequency) === null || _j === void 0 ? void 0 : _j.length) === 0) ||
            (data.txRoute === null || ((_k = data.txRoute) === null || _k === void 0 ? void 0 : _k.length) === 0) ||
            (data.transitTime === null || ((_l = data.transitTime) === null || _l === void 0 ? void 0 : _l.length) === 0) ||
            (data.currency === null || ((_m = data.currency) === null || _m === void 0 ? void 0 : _m.length) === 0));
    };
    const handleEdit = () => {
        const { currency, dtValidity, frequency, txRoute, transitTime, minValue, weight1, weight2, weight3, weight4, weight5 } = data;
        const splitedValidityDate = dtValidity !== null ? dtValidity.trim().split('/') : [0, 0, 0];
        const stringToNumber = (item) => {
            let { idTariffTypeValues, value } = item;
            if (typeof value === 'string')
                value = value.replace(',', '.');
            return { idTariffTypeValues, value: Number(value) };
        };
        const values = [minValue, weight1, weight2, weight3, weight4, weight5].map((value) => value !== null && stringToNumber(value));
        const params = {
            currency,
            dtValidity: `${splitedValidityDate[2]}-${splitedValidityDate[1]}-${splitedValidityDate[0]}T00:00-03:00`,
            frequency,
            txRoute,
            transitTime: Number(transitTime),
            values
        };
        void (function () {
            return __awaiter(this, void 0, void 0, function* () {
                yield API.editTariff(dataProp.idTariff, params)
                    .then((_response) => handleOnClose())
                    .catch((err) => console.log(err));
            });
        })();
    };
    const validateDate = () => {
        const validityDate = moment(data.dtValidity, 'DD/MM/YYYY', true);
        const today = moment().startOf('day');
        return validityDate.isValid() && validityDate.isSameOrAfter(today);
    };
    const handleOnAdd = () => {
        if (validateData()) {
            handleEdit();
        }
        else {
            setInvalidInput(true);
        }
    };
    const handleValues = (e, key) => {
        if (data[key] !== null) {
            setData(Object.assign(Object.assign({}, data), { [key]: {
                    idTariffTypeValues: data[key].idTariffTypeValues,
                    value: e.target.value
                } }));
        }
    };
    return (React.createElement(Modal, { open: open, onClose: handleOnClose },
        React.createElement(ModalDiv, null,
            React.createElement(HeaderDiv, null,
                React.createElement(Title, null, I18n.t('pages.tariff.titles.air')),
                React.createElement(RowReverseDiv, null,
                    React.createElement(CloseIconContainer, null,
                        React.createElement(CloseIcon, { onClick: handleOnClose })))),
            React.createElement(MainDiv, null,
                React.createElement(Grid, { container: true, spacing: 2, style: { width: '100%' } },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(SubDiv, null,
                            React.createElement(StyledTable, { size: "small" },
                                React.createElement(TableHead, null,
                                    React.createElement(TableHeadRow, null,
                                        React.createElement(StyledTableCell, { width: "45%" }, I18n.t('components.tariffModal.agentAirCompany')),
                                        React.createElement(StyledTableCell, { width: "40%", align: "left" }, I18n.t('components.tariffModal.originDestination')),
                                        React.createElement(StyledTableCell, { width: "15%", align: "left" }, I18n.t('components.tariffModal.currency')))),
                                React.createElement(TableBody, null,
                                    React.createElement(TableBodyRow, null,
                                        React.createElement(StyledTableCell, { width: "45%", align: "left" },
                                            React.createElement(ColumnDiv, null,
                                                React.createElement("span", { id: TARIFF_AIR_TARIFF_MODAL_SPAN_AGENT }, data.agent),
                                                React.createElement("span", { id: TARIFF_AIR_TARIFF_MODAL_SPAN_COMPANY }, data.airCompany))),
                                        React.createElement(StyledTableCell, { width: "40%", align: "left" }, data.originDestination),
                                        React.createElement(StyledTableCell, { width: "15%", align: "left" },
                                            React.createElement(RowDiv, null,
                                                React.createElement("div", { style: { position: 'relative', marginRight: '14px', marginTop: '-15px' } },
                                                    React.createElement(Autocomplete, { value: data.currency, options: currencyList.map((option) => option.id), disabled: false, onChange: (_e, value) => setData(Object.assign(Object.assign({}, data), { currency: value })), renderInput: (params) => {
                                                            var _a;
                                                            return (React.createElement("div", { ref: params.InputProps.ref },
                                                                React.createElement(Input, Object.assign({}, params.inputProps, { id: TARIFF_AIR_TARIFF_MODAL_SELECT_CURRENCY, width: "84px", placeholder: data.currency, toolTipTitle: I18n.t('components.tariffModal.requiredField'), invalid: invalidInput && ((_a = data.currency) === null || _a === void 0 ? void 0 : _a.length) === 0, disabled: false })),
                                                                React.createElement(Box, { className: "dropdown" },
                                                                    React.createElement(ArrowDropDownIcon, null))));
                                                        }, PaperComponent: (params) => React.createElement(StyledPaper, Object.assign({}, params)) }))))))))),
                    React.createElement(Grid, { item: true, xs: 12, container: true, spacing: 1, direction: "row", justify: "center" },
                        React.createElement(Grid, { item: true, xs: 12, md: true },
                            React.createElement(FormLabel, { component: "legend", error: invalidInput && ((_a = data.weight1) === null || _a === void 0 ? void 0 : _a.value.length) === 0 },
                                I18n.t('components.tariffModal.weight1'),
                                React.createElement(RedColorSpan, null, " *")),
                            React.createElement(NumberInput, { id: `${TARIFF_AIR_TARIFF_MODAL_INPUT_WEIGHT}1`, decimalSeparator: ',', thousandSeparator: '.', decimalScale: 2, format: (value) => FormatNumber.rightToLeftFormatter(value, 2), customInput: ControlledInput, toolTipTitle: I18n.t('components.tariffModal.requiredField'), invalid: invalidInput && ((_b = data.weight1) === null || _b === void 0 ? void 0 : _b.value.length) === 0, value: data.weight1 != null ? data.weight1.value : '', onChange: e => { validateFloatInput(e.target.value) !== null && handleValues(e, 'weight1'); }, variant: "outlined", size: "small", modal: true, inputProps: MaxLength })),
                        React.createElement(Grid, { item: true, xs: 12, md: true },
                            React.createElement(FormLabel, { component: "legend", error: invalidInput && ((_c = data.weight2) === null || _c === void 0 ? void 0 : _c.value.length) === 0 },
                                I18n.t('components.tariffModal.weight2'),
                                React.createElement(RedColorSpan, null, " *")),
                            React.createElement(NumberInput, { id: `${TARIFF_AIR_TARIFF_MODAL_INPUT_WEIGHT}2`, decimalSeparator: ',', thousandSeparator: '.', decimalScale: 2, format: (value) => FormatNumber.rightToLeftFormatter(value, 2), customInput: ControlledInput, toolTipTitle: I18n.t('components.tariffModal.requiredField'), invalid: invalidInput && ((_d = data.weight2) === null || _d === void 0 ? void 0 : _d.value.length) === 0, value: data.weight2 != null ? data.weight2.value : '', onChange: e => { validateFloatInput(e.target.value) !== null && handleValues(e, 'weight2'); }, variant: "outlined", size: "small", modal: true, inputProps: MaxLength })),
                        React.createElement(Grid, { item: true, xs: 12, md: true },
                            React.createElement(FormLabel, { component: "legend", error: invalidInput && ((_f = data.weight3) === null || _f === void 0 ? void 0 : _f.value.length) === 0 },
                                I18n.t('components.tariffModal.weight3'),
                                React.createElement(RedColorSpan, null, " *")),
                            React.createElement(NumberInput, { id: `${TARIFF_AIR_TARIFF_MODAL_INPUT_WEIGHT}3`, decimalSeparator: ',', thousandSeparator: '.', decimalScale: 2, format: (value) => FormatNumber.rightToLeftFormatter(value, 2), customInput: ControlledInput, toolTipTitle: I18n.t('components.tariffModal.requiredField'), invalid: invalidInput && ((_g = data.weight3) === null || _g === void 0 ? void 0 : _g.value.length) === 0, value: data.weight3 != null ? data.weight3.value : '', onChange: e => { validateFloatInput(e.target.value) !== null && handleValues(e, 'weight3'); }, variant: "outlined", size: "small", modal: true, inputProps: MaxLength })),
                        React.createElement(Grid, { item: true, xs: 12, md: true },
                            React.createElement(FormLabel, { component: "legend", error: invalidInput && ((_h = data.weight4) === null || _h === void 0 ? void 0 : _h.value.length) === 0 },
                                I18n.t('components.tariffModal.weight4'),
                                React.createElement(RedColorSpan, null, " *")),
                            React.createElement(NumberInput, { id: `${TARIFF_AIR_TARIFF_MODAL_INPUT_WEIGHT}4`, decimalSeparator: ',', thousandSeparator: '.', decimalScale: 2, format: (value) => FormatNumber.rightToLeftFormatter(value, 2), customInput: ControlledInput, toolTipTitle: I18n.t('components.tariffModal.requiredField'), invalid: invalidInput && ((_j = data.weight4) === null || _j === void 0 ? void 0 : _j.value.length) === 0, value: data.weight4 != null ? data.weight4.value : '', onChange: e => { validateFloatInput(e.target.value) !== null && handleValues(e, 'weight4'); }, variant: "outlined", size: "small", modal: true, inputProps: MaxLength })),
                        React.createElement(Grid, { item: true, xs: 12, md: true },
                            React.createElement(FormLabel, { component: "legend", error: invalidInput && ((_k = data.weight5) === null || _k === void 0 ? void 0 : _k.value.length) === 0 },
                                I18n.t('components.tariffModal.weight5'),
                                React.createElement(RedColorSpan, null, " *")),
                            React.createElement(NumberInput, { id: `${TARIFF_AIR_TARIFF_MODAL_INPUT_WEIGHT}5`, decimalSeparator: ',', thousandSeparator: '.', decimalScale: 2, format: (value) => FormatNumber.rightToLeftFormatter(value, 2), customInput: ControlledInput, toolTipTitle: I18n.t('components.tariffModal.requiredField'), invalid: invalidInput && ((_l = data.weight5) === null || _l === void 0 ? void 0 : _l.value.length) === 0, value: data.weight5 != null ? data.weight5.value : '', onChange: e => { validateFloatInput(e.target.value) !== null && handleValues(e, 'weight5'); }, variant: "outlined", size: "small", modal: true, style: { marginRight: '3px' }, inputProps: MaxLength }))),
                    React.createElement(Grid, { item: true, xs: 12, container: true, spacing: 1, direction: "row", justify: "center" },
                        React.createElement(Grid, { item: true, xs: 12, md: true },
                            React.createElement(FormLabel, { component: "legend", error: invalidInput && ((_m = data.minValue) === null || _m === void 0 ? void 0 : _m.value.length) === 0 },
                                I18n.t('components.tariffModal.minValue'),
                                React.createElement(RedColorSpan, null, " *")),
                            React.createElement(NumberInput, { id: TARIFF_AIR_TARIFF_MODAL_INPUT_MINVALUE, decimalSeparator: ',', thousandSeparator: '.', decimalScale: 2, format: (value) => FormatNumber.rightToLeftFormatter(value, 2), customInput: ControlledInput, toolTipTitle: I18n.t('components.tariffModal.requiredField'), invalid: invalidInput && ((_o = data.minValue) === null || _o === void 0 ? void 0 : _o.value.length) === 0, value: data.minValue != null ? data.minValue.value : '', onChange: e => { validateFloatInput(e.target.value) !== null && handleValues(e, 'minValue'); }, variant: "outlined", size: "small", modal: true, style: { marginRight: '3px' }, inputProps: MaxLength })),
                        React.createElement(Grid, { item: true, xs: 12, md: true },
                            React.createElement(FormLabel, { component: "legend", error: invalidInput && (((_p = data.dtValidity) === null || _p === void 0 ? void 0 : _p.length) === 0 || !validateDate()) },
                                I18n.t('components.tariffModal.validity'),
                                React.createElement(RedColorSpan, null, " *")),
                            React.createElement(NumberInput, { id: TARIFF_AIR_TARIFF_MODAL_INPUT_DATA, format: '##/##/####', mask: ['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y'], placeholder: "DD/MM/YYYY", customInput: ControlledInput, toolTipTitle: I18n.t('components.tariffModal.dateInvalid'), invalid: invalidInput && (((_q = data.dtValidity) === null || _q === void 0 ? void 0 : _q.length) === 0 || !validateDate()), value: data.dtValidity, onChange: (e) => setData(Object.assign(Object.assign({}, data), { dtValidity: e.target.value })), variant: "outlined", size: "small", modal: true, inputProps: MaxLength })),
                        React.createElement(Grid, { item: true, xs: 12, md: true },
                            React.createElement(FormLabel, { component: "legend", error: invalidInput && (data.frequency === null || ((_r = data.frequency) === null || _r === void 0 ? void 0 : _r.length) === 0) },
                                I18n.t('components.tariffModal.frequency'),
                                React.createElement(RedColorSpan, null, " *")),
                            React.createElement(ControlledSelect, { labelId: "frequency-label", id: TARIFF_AIR_TARIFF_MODAL_SELECT_FREQUENCY, value: data.frequency, onChange: (e) => setData(Object.assign(Object.assign({}, data), { frequency: e.target.value })), displayEmpty: true, disableUnderline: true, invalid: invalidInput && (data.frequency === null || ((_s = data.frequency) === null || _s === void 0 ? void 0 : _s.length) === 0), toolTipTitle: I18n.t('components.tariffModal.requiredField') },
                                React.createElement(MenuItem, { disabled: true, value: data.frequency },
                                    React.createElement(SelectSpan, { placeholder: 1 }, I18n.t('components.tariffModal.choose'))),
                                frequencyList.map((item) => (React.createElement(MenuItem, { key: item.id, value: item.id },
                                    React.createElement(SelectSpan, null, item.description)))))),
                        React.createElement(Grid, { item: true, xs: 12, md: true },
                            React.createElement(FormLabel, { component: "legend", error: invalidInput &&
                                    (data.txRoute === null || data.txRoute.length === 0) },
                                I18n.t('components.tariffModal.route'),
                                React.createElement(RedColorSpan, null, " *")),
                            React.createElement(ControlledInput, { id: TARIFF_AIR_TARIFF_MODAL_INPUT_TXROUTE, toolTipTitle: I18n.t('components.tariffModal.requiredField'), invalid: invalidInput &&
                                    (data.txRoute === null || data.txRoute.length === 0), value: data.txRoute, onChange: e => setData(Object.assign(Object.assign({}, data), { txRoute: e.target.value })), variant: "outlined", size: "small", modal: true })),
                        React.createElement(Grid, { item: true, xs: 12, md: true },
                            React.createElement(FormLabel, { component: "legend", error: invalidInput &&
                                    (data.transitTime === null || ((_t = data.transitTime) === null || _t === void 0 ? void 0 : _t.length) === 0) },
                                I18n.t('components.tariffModal.transitTime'),
                                React.createElement(RedColorSpan, null, " *")),
                            React.createElement(ControlledInput, { id: TARIFF_AIR_TARIFF_MODAL_INPUT_TRANSITTIME, toolTipTitle: I18n.t('components.tariffModal.requiredField'), invalid: invalidInput &&
                                    (data.transitTime === null || ((_u = data.transitTime) === null || _u === void 0 ? void 0 : _u.length) === 0), value: data.transitTime, onChange: e => { validateIntInput(e.target.value) !== null && (setData(Object.assign(Object.assign({}, data), { transitTime: e.target.value }))); }, variant: "outlined", size: "small", modal: true })))),
                React.createElement(Grid, { item: true, xs: 12, container: true, direction: "row", justify: "flex-end" },
                    React.createElement(Grid, { item: true, xs: 10 },
                        React.createElement(ButtonDiv, null,
                            React.createElement(Button, { id: TARIFF_AIR_TARIFF_MODAL_BUTTON_CANCEL, disabled: false, text: I18n.t('components.tariffModal.cancel'), tooltip: I18n.t('components.tariffModal.cancel'), backgroundGreen: false, icon: "", onAction: handleOnClose }))),
                    React.createElement(Grid, { item: true, xs: 2 },
                        React.createElement(ButtonDiv, null,
                            React.createElement(Button, { id: TARIFF_AIR_TARIFF_MODAL_BUTTON_SAVE, disabled: false, text: I18n.t('components.tariffModal.save'), tooltip: I18n.t('components.tariffModal.save'), backgroundGreen: true, icon: "", onAction: handleOnAdd }))))))));
};
export default AirTariffModal;
