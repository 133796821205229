import React from "react";
import { Organization } from "@/presentation/pages";
import {
  makeRemoteAddOrganization,
  makeRemoteLoadOrganization,
  makeRemoteLoadOrganizationList,
  makeRemoteUpdateOrganization,
} from "../../usecases";

export const makeOrganizationList: React.FC = (props) => {
  return (
    <Organization
      {...props}
      addOrganization={makeRemoteAddOrganization()}
      loadOrganization={makeRemoteLoadOrganization()}
      loadOrganizationList={makeRemoteLoadOrganizationList()}
      updateOrganization={makeRemoteUpdateOrganization()}
    />
  );
};
