import { CHECKLIST_GENERALFORM_INPUT_NAME, CHECKLIST_GENERALFORM_INPUT_NAME_LABEL, CHECKLIST_GENERALFORM_SELECT_SYSTEM_LABEL, CHECKLIST_GENERALFORM_SELECT_SYSTEM } from '@/ids'
import { FormControl, Input, Select } from 'fiorde-fe-components'
import { t } from 'i18next'
import { makeSelectValues } from '@/presentation/utils'

export function ChecklistGeneralForm({ values, onChange, systemList }) {
  return (
    <>
      <FormControl
        label={t("components.add-checklist.name-label")}
        id={CHECKLIST_GENERALFORM_INPUT_NAME_LABEL}
      >
        <Input
          inputProps={{ "data-testid": "name" }}
          id={CHECKLIST_GENERALFORM_INPUT_NAME}
          onChange={(event: any) => onChange({...values, name: event.target.value})}
          value={values.name}
          placeholder={t("components.add-checklist.name-placeholder")}
        />
      </FormControl>
      <FormControl
        label={t("components.add-checklist.system")}
        id={CHECKLIST_GENERALFORM_SELECT_SYSTEM_LABEL}
      >
        <Select
          id={CHECKLIST_GENERALFORM_SELECT_SYSTEM}
          name="system"
          options={makeSelectValues({
            label: "name",
            value: "id",
            array: systemList,
          })}
          multiple
          onChange={(value) => {
            onChange({ ...values, systems: value })
          }}
          value={values.systems}
          placeholder={t("components.add-checklist.select")}
        />
      </FormControl>
    </>
  )
}