import React from 'react'
import { HashRouter, Routes, Route } from 'react-router-dom'
import { RouteWrapper } from '../route-wrapper/route-wrapper'
import {
  makeHome,
  makeOrganizationList,
  makeVehicleTypeList,
  makeBodyworkList,
  makeBusinessPartnerList
} from '@/main/factories/pages'

export function Router() {
  return (
    <HashRouter basename="/registrations">
      <Routes>
        <Route path="/" element={
          <RouteWrapper makeComponent={makeHome}/>
        } />
        <Route path="/organization/list" element={
          <RouteWrapper makeComponent={makeOrganizationList}/>
        } />
        <Route path="/business-partner/list" element={
          <RouteWrapper makeComponent={makeBusinessPartnerList}/>
        } />
        <Route path="/vehicle-type/list" element={
          <RouteWrapper makeComponent={makeVehicleTypeList}/>
        } />
        <Route path="/vehicle-bodywork/list" element={
          <RouteWrapper makeComponent={makeBodyworkList}/>
        } />
      </Routes>
    </HashRouter>
  );
}
