import { RemoteLoadVehicleCompositionList } from "@/data/usecase";
import { LoadVehicleCompositionList } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteLoadVehicleCompositionList = (): LoadVehicleCompositionList =>
  new RemoteLoadVehicleCompositionList(
    makeApiUrl("/sirius-yard-control-api/v1/vehicle/composition/all"),
    makeAuthorizeHttpClientDecorator()
  );
