var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import styled from 'styled-components';
import React from 'react';
import { Tooltip } from '@material-ui/core';
export const StyledTooltip = styled((_a) => {
    var { className } = _a, props = __rest(_a, ["className"]);
    return (React.createElement(Tooltip, Object.assign({ title: props.title, arrow: true }, props, { classes: { popper: className } }), props.children));
}) `
    z-index: 1300 !important;
    & .MuiTooltip-tooltip {
      background: #000000CC;
      font-family: DM Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 150%;
      letter-spacing: 0.02em;
      color: #F2F3F7;
      bottom:7px;
    }
  
    & .MuiTooltip-arrow {
      color: #000000CC;
    }
  `;
