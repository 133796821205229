import React, { useState } from 'react'
import { Select, Modal, Button } from 'fiorde-fe-components'
import { makeSelectValues } from '@/presentation/utils'
import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { PREFIX_REGISTER_INCLUDESTEP_BUTTON_ADD, PREFIX_REGISTER_INCLUDESTEP_BUTTON_CLOSE, PREFIX_REGISTER_INCLUDESTEP_MODAL_TITLE, PREFIX_REGISTER_INCLUDESTEP_SELECT_STEP } from '@/ids';
import { STEP_TYPE } from '@/main/factories/enums';

export function IncludeStep({ isVisible, onClose, onSubmit, loadStepAll, selectedSteps }) {
  const [selectedStep, setSelectedStep] = useState(null)
  const { data: stepList } = useQuery({
    queryKey: ["step-all"],
    queryFn: async () => loadStepAll.load(),
  });

  function handleClose() {
    setSelectedStep(null)
    onClose()
  }

  function handleSubmit() {
    setSelectedStep(null)
    onSubmit(selectedStep)
  }

  function makeStepList() {
    let newStepList
    let filteredSelectesSteps = selectedSteps.map(item => item.step.id)
    if (filteredSelectesSteps.length === 0) {
      newStepList = stepList?.filter(step => step?.stepType?.id === STEP_TYPE.START) || []
    } else if (selectedSteps.some(item => item?.step.stepType?.id === STEP_TYPE.END)) {
      newStepList = stepList?.filter(step => step?.stepType?.id !== STEP_TYPE.START && step?.stepType?.id !== STEP_TYPE.END) || []
    } else {
      newStepList = stepList?.filter(step => step?.stepType?.id !== STEP_TYPE.START) || []
    }
    return newStepList?.filter(step => !filteredSelectesSteps.includes(step.id))
  }

  return (
    <Modal
      id={PREFIX_REGISTER_INCLUDESTEP_MODAL_TITLE}
      width={600}
      title={t("components.include-step.title")}
      isOpen={isVisible}
      onClose={handleClose}
      actions={[
        <Button
          id={PREFIX_REGISTER_INCLUDESTEP_BUTTON_CLOSE}
          data-testid="close-button"
          backgroundGreen={false}
          onAction={handleClose}
          text={t("components.include-step.close-button")}
        />,
        <Button
          id={PREFIX_REGISTER_INCLUDESTEP_BUTTON_ADD}
          data-testid="submit-button"
          onAction={handleSubmit}
          text={t("components.include-step.add-button")}
        />,
      ]}
    >
      <Select
        id={PREFIX_REGISTER_INCLUDESTEP_SELECT_STEP}
        name="steplist"
        options={makeSelectValues({
          label: "name",
          value: "id",
          array: makeStepList() || [],
        })}
        onChange={(value) => setSelectedStep(value)}
        value={selectedStep}
        placeholder={t("components.include-step.select-placeholder")}
      />
    </Modal>
  )
}