import React from 'react'
import { Router, Switch, Route } from 'react-router-dom'
import { Home, Login, SignUp, Register, SignUpFail } from '../../pages'
import Wrapper from '../Wrapper'
import {
  createBrowserHistory
} from 'history'
import PrivateRoute from '../../../infrastructure/PrivateRoute'

const history = createBrowserHistory({
  basename: '/#'
})

const Routes = (): JSX.Element => (
  <Router history={history}>
    <Switch>
      <PrivateRoute path="/comercial">
        <Wrapper />
      </PrivateRoute>
      <PrivateRoute path="/pu-catp">
        <Wrapper />
      </PrivateRoute>
      <PrivateRoute path="/checklist">
        <Wrapper />
      </PrivateRoute>
      <PrivateRoute path="/registrations">
        <Wrapper />
      </PrivateRoute>
      <PrivateRoute path="/yard">
        <Wrapper />
      </PrivateRoute>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/signup">
        <SignUp />
      </Route>
      <Route exact path="/register">
        <Register />
      </Route>
      <Route exact path="/fail">
        <SignUpFail />
      </Route>
      <PrivateRoute exact path="/">
        <Wrapper>
          <Home />
        </Wrapper>
      </PrivateRoute>
    </Switch>
  </Router>
)

export default Routes
