import React from 'react'
import { MaterialFamily } from '@/presentation/pages'
import {
  makeRemoteAddMaterialFamily,
  makeRemoteLoadMaterialFamilyPegeable,
  makeRemoteLoadMaterialClassificationList,
  makeRemoteLoadMaterialFamily,
  makeRemoteUpdateMaterialFamily
} from '../../usecases'

export const makeMaterialFamilyList: React.FC = (props) => {
  return (
    <MaterialFamily
      {...props}
      addMaterialFamily={makeRemoteAddMaterialFamily()}
      LoadMaterialFamilyPegeable={makeRemoteLoadMaterialFamilyPegeable()}
      loadMaterialClassificationList={makeRemoteLoadMaterialClassificationList()}
      loadMaterialFamily={makeRemoteLoadMaterialFamily()}
      updateMaterialFamily={makeRemoteUpdateMaterialFamily()}
    />
  )
}
