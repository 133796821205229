import { OPERATOR } from "@/main/enums/checklist";
import { initialQuestionForm } from "./add-checklist";

export function handleRemoveCondition(conditions, index, setConditions) {
  setConditions([...conditions.filter((_, i) => i !== index)]);
}

export function handleRemoveSection(sections, index, setSections) {
  setSections([...sections.filter((_, i) => i !== index)]);
}

export function handleAddSectionForm(
  sectionForm,
  sections,
  setSections,
  handleCloseForm
) {
  if (sectionForm.index !== null) {
    const getSections = sections;
    getSections[sectionForm.index] = sectionForm;
    setSections([...getSections]);
  } else {
    const getSections = sections;
    getSections.push({...sectionForm, index: sections.length});
    setSections([...getSections]);
  }
  handleCloseForm();
}

export function handleAddQuestionForm(
  questionForm,
  questions,
  setQuestions,
  handleCloseForm,
  data
) {
  if (data.index !== null) {
    const getSections = questions;
    getSections[data.index] = data;
    setQuestions([...getSections]);
  } else {
    const getSections = questions;
    getSections.push(data);
    setQuestions([...getSections]);
  }
  handleCloseForm();
}

export function handleCloseSectionForm(setSectionForm, setVisibleSectionForm) {
  setSectionForm({
    index: null,
    name: "",
  });
  setVisibleSectionForm(false);
}

export function handleCloseQuestionForm(
  setQuestionForm,
  setVisibleQuestionForm
) {
  setQuestionForm({
    index: null,
    text: "",
    description: "",
    mandatory: "",
    isConditional: "",
  });
  setVisibleQuestionForm(false);
}

export function handleAddForm(
  conditionForm,
  conditions,
  setConditions,
  handleCloseForm
) {
  if (conditionForm.index !== null) {
    const getConditions = conditions;
    getConditions[conditionForm.index] = conditionForm;
    setConditions([...getConditions]);
  } else {
    const getConditions = conditions;
    getConditions.push(conditionForm);
    setConditions([...getConditions]);
  }
  handleCloseForm();
}

export function handleCloseForm(setConditionForm, setVisibleForm) {
  setConditionForm({
    index: null,
    conditionType: { id: null },
    operator: { id: null },
  });
  setVisibleForm(false);
}

export function handleEditCondition(
  condition,
  index,
  setConditionForm,
  setVisibleForm
) {
  setConditionForm({
    ...condition,
    index: index,
  });
  setVisibleForm(true);
}

export function handleEditSection(
  section,
  index,
  setSectionForm,
  setVisibleSectionForm
) {
  setSectionForm({
    ...section,
    index: index,
  });
  setVisibleSectionForm(true);
}

export function disableAddForm(conditionForm) {
  if (!conditionForm.conditionType?.id) return true;
  if (!conditionForm.value?.id) return true;
}

export function disableAddSectionForm(sectionForm) {
  if (!sectionForm.name) return true;
}

export function handleEdit(index: number, questions, setQuestionForm, setVisibleQuestionForm, setSectionEditable) {
  const editQuestion = questions.find((_question, questionIndex) => questionIndex === index)
  setQuestionForm({...editQuestion, index})
  setSectionEditable(editQuestion?.section)
  setVisibleQuestionForm(true)
}

export function handleRemove(index: number, questions, setQuestions) {
  const newQuestions = questions.filter((_question, questionIndex) => questionIndex !== index);
  setQuestions(newQuestions)
}

export function createPayload(questions, conditions, sections, generalForm) {
  const { name, systems } = generalForm;
  const payloadQuestions = (section) => {
    return questions.filter((question) => question.section.name === section.name).map((question) => {
    return {
      description: question.description,
      name: question.name,
      isRequired: question.isRequired,
      questionType: question.questionType,
      alternatives: question?.answers ? question.answers.map((alternative) => {
        return { name: alternative.answer };
      }) : [],
    };
  })};
  return {
    conditions: conditions.map((condition) => {
      return {
        isActive: true,
        conditionType: condition.conditionType,
        operator: { id: OPERATOR.EQUAL },
        value: condition.value.id,
      };
    }),
    name,
    isActive: true,
    sections: sections.map((section) => {
      return { name: section.name, questions: payloadQuestions(section) };
    }),
    systems: systems.map((systemId) => ({id: systemId}))
  };
}

export function parsePayload(payload) {
  const { conditions, sections, name, systems } = payload;

  const questions = sections.flatMap((section) =>
    section.questions.map((question) => {
      return {
        description: question.description,
        name: question.name,
        isRequired: question.isRequired,
        questionType: question.questionType,
        section: { name: section.name },
        answers: question.alternatives.map((alternative) => {
          return { answer: alternative.name };
        }),
      };
    })
  );

  const parsedConditions = conditions.map((condition) => {
    return {
      conditionType: condition.conditionType,
      value: { id: condition.value }, // Adaptar ao formato original esperado
    };
  });

  const parsedSections = sections.map((section) => {
    return {
      name: section.name,
    };
  });

  const parsedSystems = systems.map((system) => {
    return system.id;
  });

  return {
    questions,
    conditions: parsedConditions,
    sections: parsedSections,
    name,
    systems: parsedSystems
  };
}
