import { HttpClient, HttpRequest, HttpResponse } from "@/domain/protocols/http";
import { LocalStorageAdapter } from "@/infrastructure/cache";
import { apiKey } from "../constants";

export class AuthorizeHttpClientDecorator implements HttpClient {
  constructor (
    private readonly storage: LocalStorageAdapter,
    public readonly httpClient: HttpClient
  ) {}

  async request (data: HttpRequest): Promise<HttpResponse> {
    const token: string = this.storage.getToken()
    if (token) {
      Object.assign(data, {
        headers: Object.assign({
          // Authorization: `Bearer ${token}`,
          apiKey: apiKey
        }, data.headers || {})
      })
    }
    const httpResponse = await this.httpClient.request(data)
    return httpResponse
  }
}
