import { HttpClient, HttpStatusCode } from '@/domain/protocols/http'
import { UpdateBodywork } from '@/domain/usecase'
import { RemoteUpdateBodyworkDTO } from '@/data/models'
import { AccessDeniedError, UnexpectedError } from '@/domain/errors'

export class RemoteUpdateBodywork implements UpdateBodywork {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<RemoteUpdateBodywork.Model>
  ) {}

  async update (id: number, body: UpdateBodywork.Params): Promise<void> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${String(id)}`,
      method: 'put',
      body: body
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent: break
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}

export namespace RemoteUpdateBodywork {
  export type Model = RemoteUpdateBodyworkDTO
}
