import { t } from 'i18next';
import React from 'react'
import { ChecklistSectionForm } from './checklist-section-form';
import { handleAddQuestionForm, handleAddSectionForm, handleCloseForm, handleCloseQuestionForm, handleCloseSectionForm, handleEdit, handleEditSection, handleRemove, handleRemoveSection } from './checklist-helpers';
import { Button, QuestionTree, Table } from 'fiorde-fe-components';
import { ChecklistSectionColumns } from './checklist-section-columns';
import { ChecklistQuestionForm } from './checklist-question-form';
import { CHECKLIST_SECTIONSQUESTIONS_BUTTON_ADDQUESTION, CHECKLIST_SECTIONSQUESTIONS_BUTTON_ADDSECTION, CHECKLIST_SECTIONSQUESTIONS_LABEL_TITLE } from '@/ids';

export function ChecklistSectionsQuestionsContainer({
  visibleSectionForm,
  sectionForm,
  setSectionForm,
  sections,
  setSections,
  setVisibleSectionForm,
  visibleQuestionForm,
  sectionEditable,
  typeFieldList,
  questionForm,
  setQuestionForm,
  questions,
  setQuestions,
  setVisibleQuestionForm,
  setSectionEditable
}) {
  return (
    <>
      <div
        style={{marginTop: '20px'}}
        id={CHECKLIST_SECTIONSQUESTIONS_LABEL_TITLE}
      >
        {t("components.add-checklist.sections-and-questions")}
      </div>
      <div style={{ margin: "20px 0px 0px 0px" }}>
        {visibleSectionForm ? (
          <ChecklistSectionForm
            values={sectionForm}
            onChange={(value) => setSectionForm(value)}
            onSubmit={() =>
              handleAddSectionForm(sectionForm, sections, setSections, () =>
                handleCloseForm(setSectionForm, setVisibleSectionForm)
              )
            }
            onClose={() =>
              handleCloseSectionForm(setSectionForm, setVisibleSectionForm)
            }
            disabledCloseButton={false}
          />
        ) : sections.length ? sections.map((section, index) =>
          <div key={index}>
            <Table
              rows={[section]}
              columns={ChecklistSectionColumns({
                edit: (section, index) =>
                  handleEditSection(
                    section,
                    index,
                    setSectionForm,
                    setVisibleSectionForm
                  ),
                remove: (index) =>
                  handleRemoveSection(sections, index, setSections),
              })}
              size="small"
            />
            
            {(visibleQuestionForm && sectionEditable?.name === section?.name) ? (
              <ChecklistQuestionForm
                sectionEditable={sectionEditable}
                typeFieldList={typeFieldList}
                values={questionForm}
                onChange={(value) => setQuestionForm(value)}
                onSubmit={(data) =>
                  handleAddQuestionForm(
                    questionForm,
                    questions,
                    setQuestions,
                    () =>
                      handleCloseQuestionForm(
                        setQuestionForm,
                        setVisibleQuestionForm
                      ),
                      data
                  )
                }
                onClose={() =>
                  handleCloseQuestionForm(
                    setQuestionForm,
                    setVisibleQuestionForm
                  )
                }
                disabledCloseButton={!questions.length}
              />
            ) : (
              questions.map((question, index) => question?.section?.name === section?.name && (
                  <QuestionTree
                    key={index}
                    content={[
                      {
                        question: question.name,
                        answers: question?.answers ? [...question.answers] : [],
                        onEdit: (index) => handleEdit(index, questions, setQuestionForm, setVisibleQuestionForm, setSectionEditable),
                        onRemove: (index) => handleRemove(index, questions, setQuestions),
                        index: index,
                        section: section?.index
                      },
                    ]}
                  />
              ))
            ) }
            <div style={{ marginBottom: "15px" }}>
              <Button
                id={CHECKLIST_SECTIONSQUESTIONS_BUTTON_ADDQUESTION}
                data-testid="new-question-button"
                onAction={() => { 
                  setVisibleQuestionForm(true);
                  setSectionEditable(section);
                }}
                text={t("components.add-checklist.new-question-button")}
                backgroundGreen={false}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        <Button
          id={CHECKLIST_SECTIONSQUESTIONS_BUTTON_ADDSECTION}
          data-testid="new-section-button"
          onAction={() => setVisibleSectionForm(true)}
          text={t("components.add-checklist.new-section-button")}
          backgroundGreen={false}
        />
      </div>
    </>
  )
}