import { AccessDeniedError, UnexpectedError } from "@/domain/errors";
import { HttpClient, HttpStatusCode } from "@/domain/protocols/http";
import { LoadDockStatusList } from "@/domain/usecase";
import { RemoteLoadDockStatusDTO } from "@/data/models";

export class RemoteLoadDockStatusList implements LoadDockStatusList {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<LoadDockStatusList.Model>
  ) {}

  async load(): Promise<LoadDockStatusList.Model> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: "get",
    });
    const LoadDockStatusList = httpResponse.body;
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return LoadDockStatusList;
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        //@ts-ignore
        const err = httpResponse.body?.response?.error;
        throw new UnexpectedError(err);
    }
  }
}

export namespace RemoteLoadDockStatusList {
  export type Model = RemoteLoadDockStatusDTO[];
}
