import styled from 'styled-components';
import { Box, Radio } from '@material-ui/core';
import { primary } from '../../../../application/themes';
styled(Box) `
  position: absolute;
  top: 13px;
  left: 171px;
  background: white;
`;
export const StyledRadio = styled(Radio) `
  color: ${({ color }) => color} !important;
`;
export const ModalDiv = styled.div `
  background-color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.itemModal) === null || _d === void 0 ? void 0 : _d.backgroundColor; }};
  border-radius: 4px;
  width: 537px;
  height: 520px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
`;
export const MainDiv = styled.div `
  padding: 0 20px;

  .MuiGrid-spacing-xs-2 {
    margin: 0;
  }

  .MuiFormLabel-root {
    color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.subtitle; }};
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .MuiFormGroup-root {
    justify-content: center;
  }

  .MuiIconButton-root {
    padding: 0;
  }

  .MuiTypography-root {
    color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.subtitle; }};
    margin-left: 5px;
  }

  & .MuiRadio-root, .MuiCheckbox-root {
    color: #B5B8C2;
  }
  & .MuiRadio-colorSecondary.Mui-checked, .MuiCheckbox-colorSecondary.Mui-checked {
    color: ${primary};
  }

  .MuiSelect-icon {
    color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.font; }};
  }

  .MuiOutlinedInput-adornedEnd {
    padding-right: 6px !important;
  }  

  .MuiTypography-root{
  line-height: 21px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: 2% !important;
  text-align: left !important;
  }

  .MuiTypography-subtitle1 {
    font-size: 14px !important;
    font-weight: 600 !important;
    margin-top: 20px !important;
    color: #000000;
  }
`;
export const CheckBoxArea = styled.div `
  display: flex;
  cursor: pointer;
`;
export const UnorderedList = styled.ul `
  list-style: none;
  padding-inline-start: 10px;
  li {
    color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.subtitle; }};
    margin-bottom: 7px;
    font-weight: 100;
    font-size: 14px !important;
    & span {
      font-weight: bold;
      color: #000000;
    }
  }
`;
export const ListInner = styled.div `
  height: 100px;
  overflow: auto;
  background-color: #f2f2f2;
  .MuiListItemIcon-root {
    color: rgba(0, 0, 0, 0.54);
    display: inline-flex;
    min-width: 0px !important;
    flex-shrink: 0;
  }
`;
