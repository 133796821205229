import { ChildrenContainer, RootContainer, PageContainer, Header, Content, Title, Label, Row, Divider } from "./page.style";
import { Button, DragAndDrop, FormControl, Input, Select, Switch, Messages, ProgressIndicator } from "fiorde-fe-components";
import { makeSelectValues } from "@/presentation/utils";
import { useQuery } from "@tanstack/react-query"
import { useState } from "react";
import { GenerateSheet, Ncm, SendSheet } from "@/domain/models";
import { Load, Post } from "@/domain/usecase";
import { ValidationComposite } from "@/main/composites/validation-composite";
import { mockLoadNcmList } from "@/domain/mocks/remote-load-ncm";

type HomeProps = {
  loadNcm: Load<Ncm[]>
  postGenerateSheet: Post<GenerateSheet>
  postSendSheet: Post<SendSheet>
  validation: ValidationComposite
}

export function Home ({ loadNcm, validation, postGenerateSheet, postSendSheet }: HomeProps) {
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState({
    cnpj: '',
    ncm: [],
    ckSirius: false,
    file: null
  })
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: ""
  })
  const { data } = useQuery({
    queryKey: ['ncm-list'],
    queryFn: async () => loadNcm.load()
  })

  function disableGenerateSheet() {
    if (validation.validate("cnpj", values)) return true
    if (validation.validate("ncm", values)) return true
    return false
  }

  function disableSendSheet() {
    if (values.file === null) return true
    return false
  }

  function submitGenerateSheet() {
    setLoading(true)
    postGenerateSheet.post({
      ncmCode: values.ncm,
      cnpj: values.cnpj,
      ckSirius: values.ckSirius
    }).then(() => {
      setResponseMessage({
        message: "Planilha gerada com sucesso!",
        severity: "success"
      })
    }).catch(err => {
      setResponseMessage({
        message: err?.message,
        severity: "error"
      })
    }).finally(() => {
      setLoading(false)
    })
  }

  function submitSendSheet() {
    setLoading(true)
    postSendSheet.post({
      file: values.file
    }).then(() => {
      setResponseMessage({
        message: "Planilha enviada com sucesso!",
        severity: "success"
      })
    }).catch(err => {
      setResponseMessage({
        message: err?.message,
        severity: "error"
      })
    }).finally(() => {
      setLoading(false)
    })
  }
  return (
    <>
     <ProgressIndicator visible={loading} />
      <RootContainer>
        <ChildrenContainer>
          <PageContainer>
            <Header>Incluir produtos</Header>
            <Content>
              <Title>Gerar planilha para importação de produtos</Title>
              <Label>Preencha os campos abaixo para gerar o template de planilha. Após preencher o template com os produtos, envie-o para o sistema para importar os dados.</Label>
              <Row>
                <FormControl
                  label={'CNPJ raiz do importador'}
                  required
                  validators={[(value) => validation.validate("cnpj", values)]}
                >
                  <Input
                    onChange={(event: any) => setValues({...values, cnpj: event.target.value})}
                    value={values.cnpj}
                    placeholder={"Informe o CNPJ raiz do importador"}
                  />
                </FormControl>
                <FormControl
                  label={'NCM'}
                  required
                  validators={[(value) => validation.validate("ncm", values)]}
                >
                  <Select
                    name='ncm'
                    options={makeSelectValues({
                      label: 'name',
                      value: 'id',
                      array: data || []
                    })}
                    multiple
                    onChange={(value) => setValues({...values, ncm: value})}
                    placeholder={"Selecione"}
                    value={values?.ncm}
                  />
                </FormControl>
              </Row>
              <Switch
                label={"Incluir produtos da base Sirius"}
                checked={values?.ckSirius}
                onChange={() => setValues({...values, ckSirius: !values.ckSirius})}
              />
              <Button
                text="Gerar planilha"
                disabled={disableGenerateSheet()}
                onAction={submitGenerateSheet}
              />
              <Divider />
              <Title>Fazer upload da planilha preenchida</Title>
              <Label>Após preencher a planilha, envie-o aqui para importar os produtos no sistema.</Label>
              <DragAndDrop
                label='Você pode arrastar o arquivo .csv para esta área ou'
                uploadButtonLabel='Selecionar arquivo'
                status='Fazendo upload'
                finishedStatus="Upload completo"
                progress={0}
                types={['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                errorMessage='Formato de arquivo inválido. Escolha um arquivo ".csv"'
                value={values.file}
                onAction={(value) => setValues({...values, file: value})}
              />
              <Button
                text="Enviar Planilha"
                disabled={disableSendSheet()}
                onAction={submitSendSheet}
              />
            </Content>
          </PageContainer>
        </ChildrenContainer>
      </RootContainer>
      {responseMessage.message && (
        <Messages
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={()=> setResponseMessage({
            message: "",
            severity: ""
          })}
        />
      )}
    </>
  );
}
