import { RemoteUpdateOrganization } from "@/data/usecase";
import { UpdateOrganization } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteUpdateOrganization = (): UpdateOrganization =>
  new RemoteUpdateOrganization(
    makeApiUrl("/sirius-business-partner-api/v1/organization"),
    makeAuthorizeHttpClientDecorator()
  );
