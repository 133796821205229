

import { HttpClient, HttpStatusCode } from '@/domain/protocols/http'
import { AddMaterialFamily } from '@/domain/usecase'
import { RemoteAddMaterialFamilyDTO } from '../models'
import { AccessDeniedError, UnexpectedError } from '@/domain/errors'

export class RemoteAddMaterialFamily implements AddMaterialFamily {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<RemoteAddMaterialFamily.Model>
  ) {}

  async add (params: AddMaterialFamily.Params): Promise<void> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.created: break
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      default:
        //@ts-ignore
        const err = httpResponse.body?.response?.error;
        throw new UnexpectedError(err);
    }
  }
}

export namespace RemoteAddMaterialFamily {
  export type Model = RemoteAddMaterialFamilyDTO
}
