import { RemoteLoadStepPegeable } from "@/data/usecase";
import { LoadStepPegeable } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteLoadStepPegeable = (): LoadStepPegeable =>
  new RemoteLoadStepPegeable(
    makeApiUrl("/sirius-yard-control-api/v1/step"),
    makeAuthorizeHttpClientDecorator()
  );
