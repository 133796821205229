import { RemoteLoadVehicleComposition } from "@/data/usecase";
import { LoadVehicleComposition } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteLoadVehicleComposition = (): LoadVehicleComposition =>
  new RemoteLoadVehicleComposition(
    makeApiUrl("/sirius-yard-control-api/v1/vehicle/composition"),
    makeAuthorizeHttpClientDecorator()
  );
