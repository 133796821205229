import { Row } from "../row/row";
import { Button, FormControl, Input } from "fiorde-fe-components";
import { disableAddSectionForm } from "./checklist-helpers";
import { t } from "i18next";
import { CHECKLIST_SECTIONFORM_BUTTON_CANCEL, CHECKLIST_SECTIONFORM_BUTTON_SUBMIT, CHECKLIST_SECTIONFORM_INPUT_NAME, CHECKLIST_SECTIONFORM_INPUT_NAME_LABEL } from "@/ids";

export function ChecklistSectionForm({
  values,
  onChange,
  onSubmit,
  onClose,
  disabledCloseButton,
}) {
  return (
    <>
      <Row gridTemplateColumns="1fr">
        <FormControl
          label={t("components.add-checklist.section-name-label")}
          id={CHECKLIST_SECTIONFORM_INPUT_NAME_LABEL}
        >
          <Input
            inputProps={{ "data-testid": "section-name" }}
            id={CHECKLIST_SECTIONFORM_INPUT_NAME}
            onChange={(event: any) =>
              onChange({ ...values, name: event.target.value })
            }
            value={values.name}
            placeholder={t("components.add-checklist.name-placeholder")}
          />
        </FormControl>
      </Row>
      <Row gridTemplateColumns="1fr auto" justifyItems="end">
        <Button
          id={CHECKLIST_SECTIONFORM_BUTTON_CANCEL}
          backgroundGreen={false}
          onAction={onClose}
          text="Fechar"
          disabled={disabledCloseButton}
        />
        <Button
          id={CHECKLIST_SECTIONFORM_BUTTON_SUBMIT}
          onAction={() => onSubmit(values)}
          disabled={disableAddSectionForm(values)}
          text={values.index !== null ? t("components.add-checklist.edit-section") : t("components.add-checklist.add-section")}
        />
      </Row>
    </>
  );
}
