import React, { useEffect, useState } from "react";
import { AddStep as DomainAddStep, Load, LoadStep, LoadStepTypeList, Put } from "@/domain/usecase";
import {
  Button,
  Modal,
  Messages,
  ProgressIndicator,
  IconButton,
} from "fiorde-fe-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import {
  PREFIX_REGISTER_UPDATESTEP_BUTTON_OPEN,
  PREFIX_REGISTER_UPDATESTEP_BUTTON_CLOSE,
  PREFIX_REGISTER_UPDATESTEP_BUTTON_SUBMIT

} from "@/ids";
import { DisplayRules } from "./display-rules";
import { AdditionalFields } from "./additional-fields";
import { EditIcon } from "../icons";
import { GeneralForm } from "./general-form";

type UpdateStepProps = {
  id: number
  putStep: Put<any>;
  loadStepTypeList: LoadStepTypeList;
  loadConditionType: Load<any>
  loadConditionValue: Load<any>
  loadSectionByCheckListId?: Load<any>
  loadAdditionalFieldType?: Load<any>
  loadChecklistAll?: Load<any>
  loadStep?: LoadStep
  hiddenButton?: boolean
};

export function UpdateStep({
  id,
  putStep,
  loadStep,
  loadStepTypeList,
  loadConditionType,
  loadConditionValue,
  loadSectionByCheckListId,
  loadAdditionalFieldType,
  loadChecklistAll,
  hiddenButton = false
}: UpdateStepProps) {
  const queryClient = useQueryClient();
  const [isLoading, setLoading] = useState(false);
  const [permanenceType, setPermanenceType] = useState(null);
  const [values, setValues] = useState({
    name: '',
    stepType: { id: null },
    description: '',
    permanence: '',
    conditions: [],
    stepAdditionalFields: []
  })

  const [open, setOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: "",
  });

  const { data: step } = useQuery<any>({
    queryKey: [`step-${id}`],
    queryFn: async () => await loadStep.load(id),
    enabled: open && !!id,
    onSuccess: (data) => {
      setValues({
        ...data,
        permanence: formatTimeValue(data?.permanence)
      })
      setPermanenceType(getTimeUnit(data?.permanence))
    }
  });

  const { data: stepTypeList } = useQuery<any>({
    queryKey: ["step-status"],
    queryFn: async () => await loadStepTypeList.load(),
    enabled: open,
  });

  function handleSubmit(): void {
    setLoading(true);
    putStep
      .put(id, {
        name: values.name,
        description: values.description,
        permanence: makePermanenceTime(values.permanence),
        stepType: values.stepType,
        conditions: values.conditions,
        stepAdditionalFields: values.stepAdditionalFields,
        isActive: true,
      })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["step-list"] });
        queryClient.invalidateQueries({ queryKey: [`step-${id}`] });
        setResponseMessage({
          message: t("components.add-step.response-success-message"),
          severity: "success",
        });
        handleClose();
      })
      .catch((err) => {
        setResponseMessage({
          message: err?.message,
          severity: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
   }

  function makePermanenceTime (value) {
    switch (permanenceType) {
      case 'minutos':
        return `00:${value}:00`
      case 'horas':
        return `${value}:00:00`
      default:
        return ''
    }
  }

  function getTimeUnit(timeString) {
    if (timeString) {
      const [hours, minutes] = timeString?.split(':').map(Number);
    
      if (hours > 0) {
        return 'horas';
      }
      if (minutes > 0) {
        return 'minutos';
      }
    }
    return null;
  }

  function formatTimeValue(timeString) {
    if (timeString) {
      const [hours, minutes, seconds] = timeString?.split(':');
      if (hours > 0) {
        return hours.padStart(2, '0');
      } else if (minutes > 0) {
        return minutes.padStart(2, '0');
      } else {
        return seconds.padStart(2, '0');
      }
    }
    return ''
  }

  useEffect(() => {
    if (hiddenButton) {
      handleOpen()
    }
  }, [hiddenButton])

  return (
    <>
      <ProgressIndicator
        visible={isLoading}
        content={t("components.progress-indicator.content")}
      />
      {hiddenButton && (
        <IconButton
          id={PREFIX_REGISTER_UPDATESTEP_BUTTON_OPEN}
          data-testid="open-button"
          onClick={handleOpen}
          tooltip={"Editar"}
        >
          <EditIcon />
        </IconButton>
      )}
      <Modal
        width={1030}
        title={t("components.add-step.open-button")}
        isOpen={open}
        onClose={handleClose}
        actions={[
          <Button
            id={PREFIX_REGISTER_UPDATESTEP_BUTTON_CLOSE}
            data-testid="close-button"
            backgroundGreen={false}
            onAction={() => handleClose()}
            text={t("components.add-step.close-button")}
          />,
          <Button
            id={PREFIX_REGISTER_UPDATESTEP_BUTTON_SUBMIT}
            data-testid="submit-button"
            onAction={() => handleSubmit()}
            text={t("components.add-step.submit-button")}
          />,
        ]}
      >
        <GeneralForm
          values={values}
          onChange={(value) => setValues(value)}
          loadStepTypeList={loadStepTypeList}
          addPermanenceType={setPermanenceType}
        />
        <DisplayRules
          loadConditionType={loadConditionType}
          loadConditionValue={loadConditionValue}
          conditions={values.conditions}
          onChange={(conditions) => setValues({ ...values, conditions: conditions })}
        />
        <AdditionalFields
          additionaleFields={values.stepAdditionalFields}
          onChange={(stepAdditionalFields) => setValues({ ...values, stepAdditionalFields: stepAdditionalFields })}
          loadAdditionalFieldType={loadAdditionalFieldType}
          loadChecklistAll={loadChecklistAll}
          loadSectionByCheckListId={loadSectionByCheckListId}
        />
      </Modal>
      {responseMessage.message && (
        <Messages
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={() =>
            setResponseMessage({
              message: "",
              severity: "",
            })
          }
        />
      )}
    </>
  );
}
