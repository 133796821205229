import React from 'react'
import { Bodywork } from '@/presentation/pages'
import {
  makeRemoteAddBodywork,
  makeRemoteLoadBodywork,
  makeRemoteLoadBodyworkList,
  makeRemoteUpdateBodywork
} from '@/main/factories/usecases'

export const makeBodyworkList: React.FC = (props) => {
  return (
    <Bodywork
      {...props}
      addBodywork={makeRemoteAddBodywork()}
      loadBodyworkList={makeRemoteLoadBodyworkList()}
      updateBodywork={makeRemoteUpdateBodywork()}
      loadBodywork={makeRemoteLoadBodywork()}
    />
  )
}
