import { PREFIX_IDS } from "./main/constants"

// ADD-CHECKLIST
export const CHECKLIST_ADDCHECKLIST_BUTTON_OPEN = `${PREFIX_IDS}_checklist_add-checklist_button_open`
export const CHECKLIST_ADDCHECKLIST_MODAL_TITLE = `${PREFIX_IDS}_checklist_add-checklist_modal_title`
export const CHECKLIST_ADDCHECKLIST_BUTTON_SUBMIT = `${PREFIX_IDS}_checklist_add-checklist_button_submit`
export const CHECKLIST_ADDCHECKLIST_BUTTON_CANCEL = `${PREFIX_IDS}_checklist_add-checklist_button_cancel`
export const CHECKLIST_ADDCHECKLIST_LABEL_MESSAGE = `${PREFIX_IDS}_checklist_add-checklist_label_message`

// UPDATE-CHECKLIST
export const CHECKLIST_UPDATECHECKLIST_BUTTON_OPEN = `${PREFIX_IDS}_checklist_update-checklist_button_open`
export const CHECKLIST_UPDATECHECKLIST_MODAL_TITLE = `${PREFIX_IDS}_checklist_update-checklist_modal_title`
export const CHECKLIST_UPDATECHECKLIST_BUTTON_SUBMIT = `${PREFIX_IDS}_checklist_update-checklist_button_submit`
export const CHECKLIST_UPDATECHECKLIST_BUTTON_CANCEL = `${PREFIX_IDS}_checklist_update-checklist_button_cancel`
export const CHECKLIST_UPDATECHECKLIST_LABEL_MESSAGE = `${PREFIX_IDS}_checklist_update-checklist_label_message`

// CHECKLIST-GENERAL-FORM
export const CHECKLIST_GENERALFORM_INPUT_NAME_LABEL = `${PREFIX_IDS}_checklist_checklist-general-form_label_name`
export const CHECKLIST_GENERALFORM_INPUT_NAME = `${PREFIX_IDS}_checklist_checklist-general-form_input_name`
export const CHECKLIST_GENERALFORM_SELECT_SYSTEM_LABEL = `${PREFIX_IDS}_checklist_checklist-general-form_input_name`
export const CHECKLIST_GENERALFORM_SELECT_SYSTEM = `${PREFIX_IDS}_checklist_checklist-general-form_input_name`

// CHECKLIST-CONDITION-FORM
export const CHECKLIST_CONDITIONFORM_SELECT_CONDITION_LABEL = `${PREFIX_IDS}_checklist_checklist-condition-form_label_condition`
export const CHECKLIST_CONDITIONFORM_SELECT_CONDITION = `${PREFIX_IDS}_checklist_checklist-condition-form_select_condition`
export const CHECKLIST_CONDITIONFORM_SELECT_EQUALS_LABEL = `${PREFIX_IDS}_checklist_checklist-condition-form_label_equals`
export const CHECKLIST_CONDITIONFORM_SELECT_EQUALS = `${PREFIX_IDS}_checklist_checklist-condition-form_select_equals`
export const CHECKLIST_CONDITIONFORM_BUTTON_CLOSE = `${PREFIX_IDS}_checklist_checklist-condition-form_button_close`
export const CHECKLIST_CONDITIONFORM_BUTTON_SUBMIT = `${PREFIX_IDS}_checklist_checklist-condition-form_button_submit`

// CHECKLIST-CONDITION-COLUMNS
export const CHECKLIST_CONDITIONCOLUMNS_BUTTON_EDIT = `${PREFIX_IDS}_checklist_checklist-condition-columns_button_edit`
export const CHECKLIST_CONDITIONCOLUMNS_BUTTON_REMOVE = `${PREFIX_IDS}_checklist_checklist-condition-columns_button_remove`

// CHECKLIST-CONDITION-CONTAINER
export const CHECKLIST_CONDITIONCONTAINER_SWITCH_CONDITION = `${PREFIX_IDS}_checklist_checklist-condition-container_switch_condition`
export const CHECKLIST_CONDITIONCONTAINER_BUTTON_OPEN = `${PREFIX_IDS}_checklist_checklist-condition-container_button_open`

// CHECKLIST-QUESTION-FORM
export const CHECKLIST_QUESTIONFORM_INPUT_NAME_LABEL = `${PREFIX_IDS}_checklist_checklist-question-form_label_name`
export const CHECKLIST_QUESTIONFORM_INPUT_NAME = `${PREFIX_IDS}_checklist_checklist-question-form_input_name`
export const CHECKLIST_QUESTIONFORM_SWITCH_MANDATORY_LABEL = `${PREFIX_IDS}_checklist_checklist-question-form_label_mandatory`
export const CHECKLIST_QUESTIONFORM_SWITCH_MANDATORY = `${PREFIX_IDS}_checklist_checklist-question-form_switch_mandatory`
export const CHECKLIST_QUESTIONFORM_SWITCH_CONDITIONAL_LABEL = `${PREFIX_IDS}_checklist_checklist-question-form_label_conditional`
export const CHECKLIST_QUESTIONFORM_SWITCH_CONDITIONAL = `${PREFIX_IDS}_checklist_checklist-question-form_switch_conditional`
export const CHECKLIST_QUESTIONFORM_SELECT_TYPE_LABEL = `${PREFIX_IDS}_checklist_checklist-question-form_label_type`
export const CHECKLIST_QUESTIONFORM_SELECT_TYPE = `${PREFIX_IDS}_checklist_checklist-question-form_switch_type`
export const CHECKLIST_QUESTIONFORM_INPUT_DESCRIPTION_LABEL = `${PREFIX_IDS}_checklist_checklist-question-form_label_description`
export const CHECKLIST_QUESTIONFORM_INPUT_DESCRIPTION = `${PREFIX_IDS}_checklist_checklist-question-form_switch_description`
export const CHECKLIST_QUESTIONFORM_INPUT_CHOICE_LABEL = `${PREFIX_IDS}_checklist_checklist-question-form_label_choice`
export const CHECKLIST_QUESTIONFORM_INPUT_CHOICE = `${PREFIX_IDS}_checklist_checklist-question-form_input_choice`
export const CHECKLIST_QUESTIONFORM_LINK_ADDCHOICE = `${PREFIX_IDS}_checklist_checklist-question-form_link_add-choice`
export const CHECKLIST_QUESTIONFORM_BUTTON_ADDCHOICE = `${PREFIX_IDS}_checklist_checklist-question-form_button_add-choice`
export const CHECKLIST_QUESTIONFORM_LINK_YES = `${PREFIX_IDS}_checklist_checklist-question-form_link_yes`
export const CHECKLIST_QUESTIONFORM_LINK_NO = `${PREFIX_IDS}_checklist_checklist-question-form_link_no`
export const CHECKLIST_QUESTIONFORM_BUTTON_CLOSE = `${PREFIX_IDS}_checklist_checklist-question-form_button_close`
export const CHECKLIST_QUESTIONFORM_BUTTON_SUBMIT = `${PREFIX_IDS}_checklist_checklist-question-form_button_submit`

// CHECKLIST-SECTION-COLUMNS
export const CHECKLIST_SECTIONCOLUMNS_BUTTON_EDIT = `${PREFIX_IDS}_checklist_checklist-section-columns_button_edit`
export const CHECKLIST_SECTIONCOLUMNS_BUTTON_REMOVE = `${PREFIX_IDS}_checklist_checklist-section-columns_button_remove`

// CHECKLIST-SECTION-FORM
export const CHECKLIST_SECTIONFORM_INPUT_NAME_LABEL = `${PREFIX_IDS}_checklist_checklist-section-form_button_edit`
export const CHECKLIST_SECTIONFORM_INPUT_NAME = `${PREFIX_IDS}_checklist_checklist-section-form_button_edit`
export const CHECKLIST_SECTIONFORM_BUTTON_CANCEL = `${PREFIX_IDS}_checklist_checklist-section-form_button_cancel`
export const CHECKLIST_SECTIONFORM_BUTTON_SUBMIT = `${PREFIX_IDS}_checklist_checklist-section-form_button_submit`

// CHECKLIST-SECTIONS-QUESTIONS-CONTAINER
export const CHECKLIST_SECTIONSQUESTIONS_LABEL_TITLE = `${PREFIX_IDS}_checklist_checklist-section-question-container_label_title`
export const CHECKLIST_SECTIONSQUESTIONS_BUTTON_ADDQUESTION = `${PREFIX_IDS}_checklist_checklist-section-question-container_button_add-question`
export const CHECKLIST_SECTIONSQUESTIONS_BUTTON_ADDSECTION = `${PREFIX_IDS}_checklist_checklist-section-question-container_button_add-section`

// CHECKLIST-REMOVE-MULTIPLE-CHOICE
export const CHECKLIST_REMOVEMULTIPLECHOICE_BUTTON_REMOVE = `${PREFIX_IDS}_checklist_checklist-remove-multiple-choice_button_remove`
export const CHECKLIST_REMOVEMULTIPLECHOICE_MODAL = `${PREFIX_IDS}_checklist_checklist-remove-multiple-choice_modal`
export const CHECKLIST_REMOVEMULTIPLECHOICE_BUTTON_CLOSE = `${PREFIX_IDS}_checklist_checklist-remove-multiple-choice_button_close`
export const CHECKLIST_REMOVEMULTIPLECHOICE_BUTTON_SUBMIT = `${PREFIX_IDS}_checklist_checklist-remove-multiple-choice_button_submit`
export const CHECKLIST_REMOVEMULTIPLECHOICE_SPAN_TEXT = `${PREFIX_IDS}_checklist_checklist-remove-multiple-choice_span_text`
export const CHECKLIST_REMOVEMULTIPLECHOICE_MESSAGES = `${PREFIX_IDS}_checklist_checklist-remove-multiple-choice_messages`
