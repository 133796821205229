var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { Select } from '@material-ui/core';
import { primary } from '../../../application/themes';
export const StyledField = styled(Select) `
    border: 1px solid ${(props) => {
    var _a, _b, _c, _d;
    return props.invalid
        ? '#FF4D4D'
        : props.value != null && props.value.toString().length > 0
            ? `${primary}`
            : (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.itemModal) === null || _d === void 0 ? void 0 : _d.border;
}};
    border-radius: 4px;
    width: 100%;

    & .MuiSelect-root {
      font-size: 14px;
      color: ${(props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return props.placeholder === ''
        ? (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.placeholder
        : (_h = (_g = (_f = (_e = props.theme) === null || _e === void 0 ? void 0 : _e.commercial) === null || _f === void 0 ? void 0 : _f.components) === null || _g === void 0 ? void 0 : _g.itemModal) === null || _h === void 0 ? void 0 : _h.inputFontColor;
}};
    }

    & .MuiSvgIcon-root {
      margin-right: 5px;
    }

    & .MuiInputBase-input.Mui-disabled {
      background: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.disabledBackground; }};
    }
  `;
export const ErrorText = styled.span `
  color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.costTable) === null || _d === void 0 ? void 0 : _d.errorText; }};
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0.02em;
  line-height: 150%;
`;
export const StyledSelect = styled((_a) => {
    var { className, toolTipTitle, title, invalid } = _a, props = __rest(_a, ["className", "toolTipTitle", "title", "invalid"]);
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledField, Object.assign({}, props, { invalid: invalid, MenuProps: {
                classes: { paper: className },
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                },
                getContentAnchorEl: null
            } })),
        invalid && !props.costModal && React.createElement(ErrorText, null, toolTipTitle)));
}) `
  && {
    background-color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.background; }};
    color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.subtitle; }};
  }
`;
