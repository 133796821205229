import Keycloak from 'keycloak-js'
import { KEYCLOAK_URL, KEYCLOAK_REALM, KEYCLOAK_CLIENTID } from '../constants'

const configs = {
  clientId: KEYCLOAK_CLIENTID,
  realm: KEYCLOAK_REALM,
  url: KEYCLOAK_URL
}

export const setUser = (user: any, _kc): any => {
  const userkeyclock = {
    firstname: user.given_name,
    lastname: user.family_name,
    roles: _kc.realmAccess?.roles,
    username: user.preferred_username
  }
  localStorage.setItem('user', JSON.stringify(userkeyclock))
}

export const _kc = Keycloak(configs)

export const handleKeycloakInit = (event: any, error: any): void => {
  if (event === 'onAuthSuccess') {
    setUser(_kc.idTokenParsed, _kc)
    localStorage.setItem('token', getToken())
  } else if (event === 'onTokenExpired') {
    void doLogout()
  }
}

const doLogin = _kc.login

const doLogout = _kc.logout

export const getToken = (): any => {
  return _kc.token
}

const updateToken = (sucessCallback): any => {
  return _kc.updateToken(5).then(sucessCallback).catch(doLogin)
}

const getFirstName = (): any => {
  const user = localStorage.getItem('user')
  if (user != null) {
    const objUser = JSON.parse(user)
    return objUser.firstname
  }
}

const hasRole = (roles: any[]): any => {
  return roles.some((role) => _kc.hasRealmRole(role))
}

const UserService = {
  doLogin,
  doLogout,
  getFirstName,
  getToken,
  hasRole,
  updateToken
}

export default UserService
