import {
  Table,
  LabelCell,
  IconButton
} from "fiorde-fe-components"
import { EditIcon, TrashIcon } from "../icons"
import { t } from "i18next"
import { 
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERADDRESS_LABELCELL_ADDRESSTYPE,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERADDRESS_LABELCELL_NAME,
 } from "@/ids";

export function BusinessPartnerAddressColumns({
  addressTypeList,
  edit,
  remove
}){
  return [
    {
      label: t("components.business-partner-columns.address-type"),
      key: "addressType",
      render: ({ addressType }: any) =>
        <LabelCell id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERADDRESS_LABELCELL_ADDRESSTYPE}>
          {addressTypeList?.find(v => v.id === addressType.id)?.name}
        </LabelCell>,
      size: 4,
    },
    {
      label: t("components.business-partner-columns.address"),
      key: "name",
      render: ({ name }: any) => 
        <LabelCell id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERADDRESS_LABELCELL_NAME}>
          {name}
        </LabelCell>,
      size: 8,
    },
    {
      label: t("components.business-partner-columns.actions"),
      key: "menu",
      render: (address, index) => (
        <div style={{ display: "flex", gap: 8 }}>
          <IconButton
            data-testid="open-button"
            onClick={() => edit(address, index)}
            tooltip={t("components.business-partner-columns.edit-tooltip")}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            data-testid="open-button"
            onClick={() => remove(index)}
            tooltip={t("components.business-partner-columns.remove-tooltip")}
          >
            <TrashIcon />
          </IconButton>
        </div>
      ),
      size: 1,
    },
  ]
}