import React, { useState } from "react";
import {
  LoadFlowPegeable,
  LoadFlow,
} from "@/domain/usecase";
import {
  LabelCell,
  Pagination,
  Table,
  ProgressIndicator,
  Button,
} from "fiorde-fe-components";
import { useQuery } from "@tanstack/react-query";
import {
  PageHeader,
} from "@/presentation/components";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { PREFIX_REGISTER_FLOWLIST_BUTTON_ADDFLOW } from "@/ids";

type FlowListProps = {
  LoadFlowPegeable: LoadFlowPegeable;
  loadFlow: LoadFlow;
};

const columns = (
  loadFlow: LoadFlow,
) => [
  {
    label: t("page.flow-list.columns.flow-name"),
    key: "name",
    render: ({ name }: any) => <LabelCell>{name}</LabelCell>,
    size: 3.5,
  },
  {
    label: t("page.flow-list.columns.menu"),
    key: "menu",
    render: ({ id, isActive }) => (
      <div style={{ display: "flex", gap: 8 }}>
      </div>
    ),
    size: 0.5,
  },
];

export function Flow({
  LoadFlowPegeable,
  loadFlow,
}: FlowListProps) {
  const [filter, setFilter] = useState({
    page: 0,
    size: 10,
    sort: "id,desc",
  });
  const { isLoading, isRefetching, data } = useQuery({
    queryKey: ["flow-list", filter],
    queryFn: async () => LoadFlowPegeable.load(filter),
  });
  const navigate = useNavigate()
  return (
    <div>
      <ProgressIndicator
        visible={isLoading || isRefetching}
        content={t("components.progress-indicator.content")}
      />
      <PageHeader title={t("page.flow-list.header")}>
        <Button
          id={PREFIX_REGISTER_FLOWLIST_BUTTON_ADDFLOW}
          icon="add"
          data-testid="add-flow"
          onAction={() => navigate('/flow/add')}
          text={t("page.flow-list.add-flow")}
        />
      </PageHeader>
      <Table
        rows={data?.content || []}
        columns={columns(
          loadFlow,
        )}
      />
      <Pagination
        count={data?.totalElements || 0}
        labelDisplay="exibindo"
        labelDisplayedRows="de"
        labelRowsPerPage="Fluxos por página"
        onPageChange={(value) =>
          setFilter((filter: any) => ({ ...filter, page: value }))
        }
        onRowsPerPageChange={(value) =>
          setFilter((filter: any) => ({
            ...filter,
            size: value,
            page: 0,
          }))
        }
        tooltipBack="Anterior"
        tooltipFirst="Primeira"
        tooltipLast="Última"
        tooltipNext="Próxima"
        page={filter.page}
      />
    </div>
  );
}
