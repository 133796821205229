import React, { useContext, useEffect } from 'react';
import { ProposalContext } from '../../pages/NewProposal/context/ProposalContext';
import { CwLabel, TotalCargoContainer, TotalContainer, UpperContainer, LowerContainer } from './style';
import { I18n } from 'react-redux-i18n';
import FormatNumber from '../../../application/utils/formatNumber';
import { CostTypes } from '../../../application/enum/costEnum';
const TotalSurcharge = ({ value, currency, currencyPurchase, totalOtherFare, cw, cwSale, modal, data, totalCosts, setTotalCostArray, purchaseValue }) => {
    const { proposal, setProposal } = useContext(ProposalContext);
    const isAir = () => {
        return modal === 'AIR';
    };
    const changeTotalCosts = () => {
        if (data !== undefined) {
            const totalCostArray = [...proposal === null || proposal === void 0 ? void 0 : proposal.totalCosts.filter(e => e.costType === CostTypes.Origin || e.costType === CostTypes.Destiny)];
            if (totalOtherFare !== '0,00' && totalOtherFare !== '') {
                totalCostArray.push({
                    idTotalCost: null,
                    costType: CostTypes.Tariff,
                    idCurrency: currency,
                    valueTotalSale: FormatNumber.convertStringToNumber(totalOtherFare),
                    valueTotalPurchase: FormatNumber.convertStringToNumber(totalOtherFare)
                });
            }
            if ((value !== '0,00' && value !== '') && (purchaseValue !== '0,00' && purchaseValue !== '')) {
                if (currency === currencyPurchase) {
                    totalCostArray.push({
                        idTotalCost: null,
                        costType: CostTypes.Freight,
                        idCurrency: currency,
                        valueTotalSale: FormatNumber.convertStringToNumber(value),
                        valueTotalPurchase: FormatNumber.convertStringToNumber(purchaseValue)
                    });
                }
                else {
                    totalCostArray.push({
                        idTotalCost: null,
                        costType: CostTypes.Freight,
                        idCurrency: currencyPurchase,
                        valueTotalSale: null,
                        valueTotalPurchase: FormatNumber.convertStringToNumber(purchaseValue)
                    });
                    totalCostArray.push({
                        idTotalCost: null,
                        costType: CostTypes.Freight,
                        idCurrency: currency,
                        valueTotalSale: FormatNumber.convertStringToNumber(value),
                        valueTotalPurchase: null
                    });
                }
            }
            if (setTotalCostArray)
                setTotalCostArray(totalCostArray);
            setProposal(Object.assign(Object.assign({}, proposal), { totalCosts: totalCostArray }));
        }
    };
    useEffect(() => {
        changeTotalCosts();
    }, [data, totalCosts, currency, modal, totalOtherFare, value, purchaseValue, currencyPurchase]);
    return (React.createElement(TotalContainer, null,
        React.createElement(UpperContainer, null,
            isAir()
                ? React.createElement("div", { style: { display: 'flex', flexDirection: 'row' } },
                    React.createElement(CwLabel, null, I18n.t('pages.newProposal.step5.cwBuySell')),
                    React.createElement("div", null, cw === null || cw === void 0 ? void 0 :
                        cw.toFixed(2).replace('.', ','),
                        " / ", cwSale === null || cwSale === void 0 ? void 0 :
                        cwSale.toFixed(2).replace('.', ',')))
                : React.createElement("div", null),
            React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                React.createElement(TotalCargoContainer, null,
                    React.createElement("div", null, I18n.t('pages.newProposal.step5.totalFareSales')),
                    React.createElement("div", null, (value !== '0,00' && value !== '') ? `${currency} ${value}` : '-')),
                React.createElement(TotalCargoContainer, null,
                    React.createElement("div", null, I18n.t('pages.newProposal.step5.totalFarePurchase')),
                    React.createElement("div", null, (purchaseValue !== '0,00' && purchaseValue !== '') ? `${currencyPurchase} ${purchaseValue}` : '-')))),
        React.createElement(LowerContainer, null,
            React.createElement(TotalCargoContainer, null,
                React.createElement("div", null, I18n.t('pages.newProposal.step5.totalOtherFees')),
                React.createElement("div", null, (totalOtherFare !== '0,00' && totalOtherFare !== '') ? `${currency} ${totalOtherFare}` : '-')))));
};
export default TotalSurcharge;
