import React, { useState } from "react";
import {
  AddMaterialFamily as DomainAddMaterialFamily,
  LoadMaterialClassificationList,
  LoadMaterialFamilyPegeable,
  UpdateMaterialFamily as DomainUpdateMaterialFamily,
  LoadMaterialFamily
} from "@/domain/usecase";
import { LabelCell, Pagination, Table, ProgressIndicator } from "fiorde-fe-components";
import { useQuery } from "@tanstack/react-query";
import { AddMaterialFamily, DisableMaterialFamily, PageHeader } from "@/presentation/components";
import { t } from "i18next";
import { UpdateMaterialFamily } from "@/presentation/components/update-material-family/update-material-family";
import { mockLoadMaterialFamilyPegeableModel } from "@/domain/mocks";
import {
  PREFIX_REGISTER_LISTDOCK_LABELCELL_NAME,
  PREFIX_REGISTER_LISTMATERIALFAMILY_LABELCELL_MATERIALCLASSIFICATION,
} from "@/ids";

type MaterialFamilyListProps = {
  addMaterialFamily: DomainAddMaterialFamily
  LoadMaterialFamilyPegeable: LoadMaterialFamilyPegeable
  loadMaterialFamily: LoadMaterialFamily
  loadMaterialClassificationList: LoadMaterialClassificationList
  updateMaterialFamily: DomainUpdateMaterialFamily
}

const columns = (
  updateMaterialFamily: DomainUpdateMaterialFamily,
  loadMaterialFamily: LoadMaterialFamily,
  loadMaterialClassificationList: LoadMaterialClassificationList
) => ([
  {
    label: t("page.material-family-list.columns.material-family"),
    key: "name",
    render: ({ name }: any) => <LabelCell id={PREFIX_REGISTER_LISTDOCK_LABELCELL_NAME}>{name}</LabelCell>,
    size: 3.5
  },
  {
    label: t("page.material-family-list.columns.material-classification"),
    key: "materialClassification.name",
    render: ({ materialClassification }: any) => 
    <LabelCell id={PREFIX_REGISTER_LISTMATERIALFAMILY_LABELCELL_MATERIALCLASSIFICATION}>
      {materialClassification?.name}
    </LabelCell>,
    size: 1.5
  },
  {
    label: t("page.material-family-list.columns.menu"),
    key: "menu",
    render: ({ id, isActive }) => (
      <div style={{ display: "flex", gap: 8 }}>
        <UpdateMaterialFamily
          id={id}
          updateMaterialFamily={updateMaterialFamily}
          loadMaterialFamily={loadMaterialFamily}
          loadMaterialClassificationList={loadMaterialClassificationList}
        />
        <DisableMaterialFamily
          id={id}
          checked={isActive}
          loadMaterialFamily={loadMaterialFamily}
          updateMaterialFamily={updateMaterialFamily}
        />
      </div>
    ),
    size: 0.5
  },
])

export function MaterialFamily({
  LoadMaterialFamilyPegeable,
  addMaterialFamily,
  loadMaterialClassificationList,
  updateMaterialFamily,
  loadMaterialFamily
}: MaterialFamilyListProps) {
  const [filter, setFilter] = useState({
    page: 0,
    size: 10,
    sort: "id,desc"
  })
  const { isLoading, isRefetching, data } = useQuery({
    queryKey: ['material-family-list', filter],
    queryFn: async () => LoadMaterialFamilyPegeable.load(filter)
  })
  return (
    <div>
      <ProgressIndicator
        visible={isLoading || isRefetching}
        content={t("components.progress-indicator.content")}
      />
      <PageHeader title={t("page.material-family-list.header")}>
        <AddMaterialFamily
          addMaterialFamily={addMaterialFamily}
          loadMaterialClassificationList={loadMaterialClassificationList}
        />
      </PageHeader>
      <Table
        rows={data?.content || []}
        columns={columns(updateMaterialFamily, loadMaterialFamily, loadMaterialClassificationList)}
      />
      <Pagination
        count={data?.totalElements || 0}
        labelDisplay="exibindo"
        labelDisplayedRows="de"
        labelRowsPerPage="Tipo de Veículos por página"
        onPageChange={(value) =>
          setFilter((filter: any) => ({ ...filter, page: value }))
        }
        onRowsPerPageChange={(value) =>
          setFilter((filter: any) => ({
            ...filter,
            size: value,
            page: 0
          }))
        }
        tooltipBack="Anterior"
        tooltipFirst="Primeira"
        tooltipLast="Última"
        tooltipNext="Próxima"
        page={filter.page}
      />
    </div>
  );
}
