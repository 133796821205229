import { Paper } from '@material-ui/core';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
const OriginDestLabel = styled.span `
  font-weight: ${(props) => props.isLand ? 'bold' : 400};
`;
const ButtonWrapper = styled.div `
  margin-top: 15px;
`;
const HeightDiv = styled.div `
  min-height: 650px; // Para que o stepper marque o Step 6 corretamente
`;
const NumberInput = styled(NumberFormat) `
`;
const StyledPaper = styled(Paper) `
    border: 1px solid ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.proposal) === null || _d === void 0 ? void 0 : _d.dropdownBorderColor; }} !important;
    background-color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.proposal) === null || _d === void 0 ? void 0 : _d.dropdownBackgroundColor; }} !important;
    color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.proposal) === null || _d === void 0 ? void 0 : _d.dropdownFontColor; }} !important;
    box-sizing: border-box;
`;
const ErrorText = styled.span `
  color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.costTable) === null || _d === void 0 ? void 0 : _d.errorText; }};
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0.02em;
  line-height: 150%;
`;
const LineSeparator = styled.div `
  height: 20px;
`;
const UpperContainer = styled.div `
  display: flex;
  flex-direction: row;
  margin: 5px 1%;
  width: 98%;
  border-bottom: 1px solid;
  border-color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.totalSurchage) === null || _d === void 0 ? void 0 : _d.borderColor; }};
  padding-bottom: 25px;
`;
const LowerContainer = styled.div `
  margin-bottom: 20px;
  margin-left: 1%;
  margin-right: 1%;
  width: 98%;
`;
const TotalContainer = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: auto;
  border-radius: 8px;
  background: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.totalSurchage) === null || _d === void 0 ? void 0 : _d.backgroundColor; }};
  margin-top: 15px;
  padding: 0 6px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.totalSurchage) === null || _d === void 0 ? void 0 : _d.fontColor; }};
  letter-spacing: 0.02em;
  .line-bottom {
    border-top: 1px solid;
    border-color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.totalSurchage) === null || _d === void 0 ? void 0 : _d.borderColor; }};
  }
`;
const FreightContainer = styled.div `
  margin: 35px 0px;
  font-weight: 400 !important;
  width: 100%;
  text-align: center !important;
`;
const CargoContainer = styled.div `
  margin: 5px 1%;
  width: 98%;
  padding-bottom: 25px;
`;
export { OriginDestLabel, ButtonWrapper, NumberInput, HeightDiv, StyledPaper, ErrorText, LineSeparator, UpperContainer, LowerContainer, TotalContainer, FreightContainer, CargoContainer };
