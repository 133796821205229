import React from "react";
import { Step } from "@/presentation/pages/step/page";
import {
  makeRemoteLoadStepPegeable,
  makeRemoteLoadStep,
  makeRemoteAddStep,
  makeRemoteLoadStepTypeList,
  makeRemoteLoadConditionType,
  makeRemoteLoadConditionValue,
  makeRemoteLoadAdditionalFieldType,
  makeRemoteLoadSectionByCheckListId,
  makeRemoteUpdateStep
} from "@/main/factories/usecases";
import { makeRemoteLoadChecklistAll } from "../../usecases/remote-load-checklist-all-factory";

export const makeStepList: React.FC = (props) => {
  return (
    <Step
      {...props}
      LoadStepPegeable={makeRemoteLoadStepPegeable()}
      loadStep={makeRemoteLoadStep()}
      putStep={makeRemoteUpdateStep()}
      addStep={makeRemoteAddStep()}
      loadStepTypeList={makeRemoteLoadStepTypeList()}
      loadConditionType={makeRemoteLoadConditionType()}
      // @ts-expect-error
      loadConditionValue={makeRemoteLoadConditionValue}
      loadAdditionalFieldType={makeRemoteLoadAdditionalFieldType()}
      loadChecklistAll={makeRemoteLoadChecklistAll()}
      // @ts-expect-error
      loadSectionByCheckListId={makeRemoteLoadSectionByCheckListId()}
    />
  );
};
