import styled from 'styled-components';
import { Box, Table, TableRow, TableCell } from '@material-ui/core';
import { primary } from '../../../../../application/themes';
styled(Box) `
  position: absolute;
  top: 13px;
  left: 171px;
  background: white;
`;
export const ModalDiv = styled.div `
  background-color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.itemModal) === null || _d === void 0 ? void 0 : _d.backgroundColor; }};
  border-radius: 4px;
  width: 857px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
`;
export const MainDiv = styled.div `
  padding: 0 20px;

  .MuiGrid-spacing-xs-2 {
    margin: 0;
  }

  .MuiFormLabel-root {
    color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.subtitle; }};
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .MuiFormGroup-root {
    justify-content: center;
  }

  .MuiIconButton-root {
    padding: 0;
  }

  .MuiTypography-root {
    color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.subtitle; }};
    margin-left: 5px;
  }

  & .MuiRadio-root, .MuiCheckbox-root {
    color: #B5B8C2;
  }
  & .MuiRadio-colorSecondary.Mui-checked, .MuiCheckbox-colorSecondary.Mui-checked {
    color: ${primary};
  }

  .MuiSelect-icon {
    color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.font; }};
  }

  .MuiOutlinedInput-adornedEnd {
    padding-right: 6px !important;
  }  

  .MuiTypography-root{
  line-height: 21px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: 2% !important;
  }
`;
export const SubDiv = styled.div `
  width: auto;
  border-radius: 8px;
  background: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.tariffTable) === null || _d === void 0 ? void 0 : _d.backgroundColor; }};
  margin-top: 10px;
  padding: 5px 25px 25px 25px;

  .MuiTableCell-root {
    padding-left: 0 !important;
  }

  .MuiTableCell-body {
    border-bottom: 1px solid ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.tariffTable) === null || _d === void 0 ? void 0 : _d.border; }} !important;
  }

  .noBorder {
    border-bottom: none !important;
    padding: 8px 16px 8px 0 !important;
  }

  .noPadding {
    padding: 0 16px 0 0 !important;
  }
`;
export const CheckBoxArea = styled.div `
  display: flex;
  cursor: pointer;
`;
export const SelectSpan = styled.span `
  color: ${(props) => { var _a, _b, _c, _d; return (props === null || props === void 0 ? void 0 : props.placeholder) && ((_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.placeholder); }};
  margin: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis !important;
`;
export const StyledTable = styled(Table) `
  margin-top: 20px;
`;
export const TableHeadRow = styled(TableRow) `
  .MuiTableCell-root {
    border-bottom: none;
    font-family: DM Sans;
    color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.tariffTable) === null || _d === void 0 ? void 0 : _d.subtitle; }};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.02em;
    font-weight: normal;
  }
`;
export const TableBodyRow = styled(TableRow) `
  .MuiTableCell-root {
    border: none !important;
    font-family: DM Sans;
    color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.tariffTable) === null || _d === void 0 ? void 0 : _d.subtitle; }};
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.02em;
  }
`;
export const StyledTableCell = styled(TableCell) `
  width: ${(props) => props.width};
  vertical-align: top ! important;
`;
export const Input = styled.input `
  text-indent: 10px;
  border: 1px solid ${(props) => {
    var _a, _b, _c, _d;
    return props.invalid
        ? '#FF4D4D'
        : props.filled != null && props.filled.length > 0
            ? '#43BFB5'
            : (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.itemModal) === null || _d === void 0 ? void 0 : _d.border;
}};
  margin-top: 12px;
  box-sizing: border-box;
  border-radius: 4px;
  width: 110px;
  height: 32px;
  margin-right: 14px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: ${(props) => { var _a, _b, _c, _d, _e, _f, _g, _h; return props.disabled ? (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.placeholder : (_h = (_g = (_f = (_e = props.theme) === null || _e === void 0 ? void 0 : _e.commercial) === null || _f === void 0 ? void 0 : _f.components) === null || _g === void 0 ? void 0 : _g.itemModal) === null || _h === void 0 ? void 0 : _h.inputFontColor; }};
  background: ${(props) => { var _a, _b, _c, _d, _e, _f, _g, _h; return props.disabled ? (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.itemModal) === null || _d === void 0 ? void 0 : _d.disabledBackground : (_h = (_g = (_f = (_e = props.theme) === null || _e === void 0 ? void 0 : _e.commercial) === null || _f === void 0 ? void 0 : _f.components) === null || _g === void 0 ? void 0 : _g.itemModal) === null || _h === void 0 ? void 0 : _h.backgroundColor; }};

  :focus {
    outline: none;
    border-color: #43bfb5;
  }

  ::placeholder {
    color: #999dac;
    opacity: 1;
  }

  &:hover {
    border: 1px solid #43bfb5;
  }
`;
export const ColumnDiv = styled.div `
  display: flex;
  flex-direction: column;
`;
export const ButtonDiv = styled.div `
  margin-top: 30px;
  margin-bottom: 25px;
  display: flex;
  justify-content: right;
  width: 100%;
`;
