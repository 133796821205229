import React from 'react'
import { FormActions, FormContainer, FormContent, Title } from './step.style'
import { Button, FormControl, Select } from 'fiorde-fe-components'
import { makeSelectValues } from '@/presentation/utils'
import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { PREFIX_REGISTER_DISPLAYRULESFORM_FORMCONTROL_BUTTON_CANCEL, PREFIX_REGISTER_DISPLAYRULESFORM_FORMCONTROL_BUTTON_SUBMIT, PREFIX_REGISTER_DISPLAYRULESFORM_FORMCONTROL_SELECT_FEAT, PREFIX_REGISTER_DISPLAYRULESFORM_FORMCONTROL_SELECT_OPERATOR } from '@/ids';

export function DisplayRuleForm({ values, onChange, onClose, onSubmit, loadConditionType, loadConditionValue }) {
  const { data: conditionTypes } = useQuery({
    queryKey: ["condition-type"],
    queryFn: async () => loadConditionType.load(),
  });
  const { data: conditionValues } = useQuery({
    queryKey: [`condition-values-${values?.conditionType?.id}`, values?.conditionType?.id],
    queryFn: async () => loadConditionValue(makeConditionValuesEndpoint()).load(),
    enabled: !!values?.conditionType?.id
  });
  function makeConditionValuesEndpoint() {
    return conditionTypes.find(conditionType => conditionType.id === values?.conditionType?.id)?.endpoint
  }
  return (
    <FormContainer>
      <Title>
        {values.index
          ? t("components.step-display-rules.form.title-add")
          : t("components.step-display-rules.form.title-edit")
        }
      </Title>
      <FormContent>
        <FormControl
          label={t("components.step-display-rules.form.select-label-feat")}
          id={PREFIX_REGISTER_DISPLAYRULESFORM_FORMCONTROL_SELECT_FEAT}
        >
          <Select
            options={makeSelectValues({
              label: 'name',
              value: 'id',
              array: conditionTypes || []
            })}
            onChange={(value) => {
              onChange({...values, conditionType: { id: value }, value: null })}
            }
            placeholder={t("components.step-display-rules.form.select-placeholder-feat")}
            value={values?.conditionType?.id}
          />
        </FormControl>
        <FormControl
          label={t("components.step-display-rules.form.select-label-operator")}
          id={PREFIX_REGISTER_DISPLAYRULESFORM_FORMCONTROL_SELECT_OPERATOR}
        >
          <Select
            options={makeSelectValues({
              label: 'name',
              value: 'id',
              array: conditionValues
            })}
            onChange={(value) => onChange({...values, value: value })}
            placeholder={t("components.step-display-rules.form.select-placeholder-operator")}
            value={values?.value}
          />
        </FormControl>
      </FormContent>
      <FormActions>
        <Button
          text={t("components.step-display-rules.form.button-cancel")}
          backgroundGreen={false}
          onAction={onClose}
          id={PREFIX_REGISTER_DISPLAYRULESFORM_FORMCONTROL_BUTTON_CANCEL}
        />
        <Button
          text={values.index
            ? t("components.step-display-rules.form.title-edit")
            : t("components.step-display-rules.form.title-add")
          }
          backgroundGreen={true}
          onAction={onSubmit}
          id={PREFIX_REGISTER_DISPLAYRULESFORM_FORMCONTROL_BUTTON_SUBMIT}
        />
      </FormActions>
    </FormContainer>
  )
}