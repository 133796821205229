var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import styled from 'styled-components';
import { Select } from '@material-ui/core';
import ControlledToolTip from '../ControlledToolTip/ControlledToolTip';
import React from 'react';
export const StyledMenuSelect = styled((_a) => {
    var { className, invalid, toolTipTitle } = _a, props = __rest(_a, ["className", "invalid", "toolTipTitle"]);
    return (React.createElement(ControlledToolTip, { title: toolTipTitle, open: invalid },
        React.createElement(StyledSelect, Object.assign({}, props, { invalid: invalid === true ? 1 : 0, MenuProps: {
                classes: { paper: className },
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                },
                getContentAnchorEl: null
            } }))));
}) `
  && {
    background-color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.itemModal) === null || _d === void 0 ? void 0 : _d.backgroundColor; }};
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    max-height:300px;
    color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.itemModal) === null || _d === void 0 ? void 0 : _d.fontColor; }};
  }
`;
const StyledSelect = styled(Select) `
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 8px 5px 16px;
  background: ${(props) => { var _a, _b, _c, _d, _e, _f, _g, _h; return props.disabled ? (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.itemModal) === null || _d === void 0 ? void 0 : _d.disabledBackground : (_h = (_g = (_f = (_e = props.theme) === null || _e === void 0 ? void 0 : _e.commercial) === null || _f === void 0 ? void 0 : _f.components) === null || _g === void 0 ? void 0 : _g.itemModal) === null || _h === void 0 ? void 0 : _h.backgroundColor; }};
  border: 1px solid ${(props) => {
    var _a, _b, _c, _d;
    return props.invalid
        ? '#FF4D4D'
        : props.filled != null && props.filled.length > 0
            ? '#43BFB5'
            : (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.itemModal) === null || _d === void 0 ? void 0 : _d.border;
}};
  box-sizing: border-box;
  border-radius: 4px;
  width: ${(props) => props.width};
  height: 32px;
  margin-top: 12px;
  margin-right: ${(props) => props.large ? '23px' : '14px'};

  & .MuiSelect-root {
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.02em;
    color: ${(props) => { var _a, _b, _c, _d, _e, _f, _g, _h; return props.placeholder === '' || props.disabled ? (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.placeholder : (_h = (_g = (_f = (_e = props.theme) === null || _e === void 0 ? void 0 : _e.commercial) === null || _f === void 0 ? void 0 : _f.components) === null || _g === void 0 ? void 0 : _g.itemModal) === null || _h === void 0 ? void 0 : _h.inputFontColor; }};
  }

  & .MuiSvgIcon-root {
    color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.itemModal) === null || _d === void 0 ? void 0 : _d.iconColor; }};
  }

  &:hover {
    border: 1px solid #43bfb5;
  }
`;
export const CheckBox = styled.div `
  border: ${(props) => props.checked ? '0px' : '2px solid #B5B8C2'};
  box-sizing: border-box;
  border-radius: 2px;
  margin-right: 11px;
  margin-top: 28px;
  width: 18px;
  height: 18px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 1px;
  background: ${(props) => { var _a, _b, _c, _d; return props.checked ? '#43BFB5' : (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.itemModal) === null || _d === void 0 ? void 0 : _d.backgroundColor; }};

  path {
    fill: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.itemModal) === null || _d === void 0 ? void 0 : _d.backgroundColor; }};
    stroke: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.itemModal) === null || _d === void 0 ? void 0 : _d.backgroundColor; }};
  }
`;
export const CheckBoxLabel = styled.span `
  font-size: 12px;
  margin-top: 28px;
  color: ${(props) => {
    var _a, _b, _c, _d;
    return props.invalid
        ? '#FF4D4D'
        : (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.itemModal) === null || _d === void 0 ? void 0 : _d.fontColor;
}};
    
`;
export const Input = styled.input `
  text-indent: 10px;
  border: 1px solid ${(props) => {
    var _a, _b, _c, _d;
    return props.invalid
        ? '#FF4D4D'
        : props.filled != null && props.filled.length > 0
            ? '#43BFB5'
            : (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.itemModal) === null || _d === void 0 ? void 0 : _d.border;
}};
  margin-top: 12px;
  box-sizing: border-box;
  border-radius: 4px;
  width: 110px;
  height: 32px;
  margin-right: 14px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: ${(props) => { var _a, _b, _c, _d, _e, _f, _g, _h; return props.disabled ? (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.placeholder : (_h = (_g = (_f = (_e = props.theme) === null || _e === void 0 ? void 0 : _e.commercial) === null || _f === void 0 ? void 0 : _f.components) === null || _g === void 0 ? void 0 : _g.itemModal) === null || _h === void 0 ? void 0 : _h.inputFontColor; }};
  background: ${(props) => { var _a, _b, _c, _d, _e, _f, _g, _h; return props.disabled ? (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.itemModal) === null || _d === void 0 ? void 0 : _d.disabledBackground : (_h = (_g = (_f = (_e = props.theme) === null || _e === void 0 ? void 0 : _e.commercial) === null || _f === void 0 ? void 0 : _f.components) === null || _g === void 0 ? void 0 : _g.itemModal) === null || _h === void 0 ? void 0 : _h.backgroundColor; }};

  :focus {
    outline: none;
    border-color: #43bfb5;
  }

  ::placeholder {
    color: #999dac;
    opacity: 1;
  }

  &:hover {
    border: 1px solid #43bfb5;
  }
`;
export const ReplyDiv = styled.div `
  margin-top: 18px;
  font-size: 14px;
  color: ${(props) => { var _a, _b, _c, _d, _e, _f, _g, _h; return props.disabled ? '#999DAC' : props.disabled ? (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.itemModal) === null || _d === void 0 ? void 0 : _d.disabledBackground : (_h = (_g = (_f = (_e = props.theme) === null || _e === void 0 ? void 0 : _e.commercial) === null || _f === void 0 ? void 0 : _f.components) === null || _g === void 0 ? void 0 : _g.itemModal) === null || _h === void 0 ? void 0 : _h.replyIconColor; }};
  white-space: nowrap;
  text-align: center;
  display: flex;
  flex-direction: row;

  svg {
    margin-right: 5px;
  }

  path {
    fill: ${(props) => { var _a, _b, _c, _d, _e, _f, _g, _h; return props.disabled ? '#999DAC' : props.disabled ? (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.itemModal) === null || _d === void 0 ? void 0 : _d.disabledBackground : (_h = (_g = (_f = (_e = props.theme) === null || _e === void 0 ? void 0 : _e.commercial) === null || _f === void 0 ? void 0 : _f.components) === null || _g === void 0 ? void 0 : _g.itemModal) === null || _h === void 0 ? void 0 : _h.replyIconColor; }};
  }
`;
export const ReplyIconDiv = styled.div `
  margin-top: 2px;
`;
export const WarningPopUpMessage = styled.span `
  max-width:55%;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  margin-left:70px;
  margin-right: 20px;
`;
export const WarningPopUpButtonDiv = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1px;
  button {
    padding: 6px 24px;
    color:#222222;
    border-color:#222222;
  }
`;
export const WarningPopUp = styled.div `
  width: 100%;
  color: #222222;
  display: flex;
  flex-direction: row;
  background: #FF7373;
  padding-top:24px;
  padding-bottom:24px;
  margin-top: 32px;
`;
export const MainDiv = styled.div `
  .MuiSelect-icon {
    color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.font; }};
  }
`;
export const CostModalContainer = styled.div `
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
  svg {
    fill: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.itemModal) === null || _d === void 0 ? void 0 : _d.iconColor; }};
  }
`;
export const MenuItemContent = styled.span `
  color: ${(props) => { var _a, _b, _c, _d; return (props === null || props === void 0 ? void 0 : props.placeholder) && ((_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.placeholder); }};
  margin: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis !important;
`;
