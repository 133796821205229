import { RemoteLoadAddressTypeList } from "@/data/usecase";
import { LoadAddressTypeList } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteLoadAddressTypeList = (): LoadAddressTypeList =>
  new RemoteLoadAddressTypeList(
    makeApiUrl("/sirius-business-partner-api/v1/address/type"),
    makeAuthorizeHttpClientDecorator()
  );
