import React, { useEffect, useState } from "react";
import { LoadStep, AddStep as DomainAddStep } from "@/domain/usecase";
import {
  Button,
  Modal,
  Messages,
  IconButton,
  ProgressIndicator
} from "fiorde-fe-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { CloneIcon } from "../icons/CloneIcon";
import { PREFIX_REGISTER_CLONESTEP_BUTTON_CLOSE, PREFIX_REGISTER_CLONESTEP_BUTTON_OPEN, PREFIX_REGISTER_CLONESTEP_BUTTON_SUBMIT, PREFIX_REGISTER_CLONESTEP_MODAL } from "@/ids";

type CloneStepProps = {
  addStep: DomainAddStep;
  loadStep: LoadStep;
  id: number;
};

export function CloneStep({ addStep, loadStep, id }: CloneStepProps) {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: '',
    stepType: { id: null },
    description: '',
    permanence: '',
    conditions: [],
    stepAdditionalFields: []
  })
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: "",
  });
  const { data } = useQuery<any>({
    queryKey: [`step-${id}`],
    queryFn: async () => await loadStep.load(id),
    enabled: open,
    onSuccess: (data) => {
      setForm({
        name: data?.name,
        stepType: { id: data?.stepType?.id },
        description: data?.description,
        permanence: data?.permanence,
        conditions: data?.conditions.map(condition => ({
          conditionType: { id: condition?.conditionType?.id },
          operator: { id: 1 },
          value: condition?.value,
          isActive: true
        })),
        stepAdditionalFields: data?.stepAdditionalFields?.map(additionalField => ({
          name: additionalField?.name,
          isRequired: additionalField?.isRequired,
          stepAdditionalFieldType: { id: additionalField?.stepAdditionalFieldType?.id },
          checklistFields: additionalField?.checklistFields?.map(checklistFields => ({
            checklistId: checklistFields?.checklistId,
            sectionId: checklistFields?.sectionId
          })),
          isActive: true
        })),
        // @ts-expect-error
        isActive: data?.isActive
      })
    }
  });

  function handleSubmit(): void {
    setLoading(true);
    addStep
      .add(
        // @ts-expect-error
        form
      )
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["step-list"] });
        queryClient.invalidateQueries({ queryKey: [`step-${id}`] });
        handleClose();
        setResponseMessage({
          message: t("components.clone-step.response-success-message"),
          severity: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        setResponseMessage({
          message: err?.message,
          severity: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <ProgressIndicator
        visible={isLoading}
        content={t("components.progress-indicator.content")}
      />
      <IconButton
        data-testid="open-button"
        onClick={handleOpen}
        tooltip={t("components.clone-step.open-button-tooltip")}
        id={PREFIX_REGISTER_CLONESTEP_BUTTON_OPEN}
      >
        <CloneIcon />
      </IconButton>
      <Modal
        id={PREFIX_REGISTER_CLONESTEP_MODAL}
        width={600}
        title='Clonar'
        isOpen={open}
        onClose={handleClose}
        actions={[
          <Button
            id={PREFIX_REGISTER_CLONESTEP_BUTTON_CLOSE}
            data-testid="close-button"
            backgroundGreen={false}
            onAction={() => handleClose()}
            text={t("components.clone-step.close-button")}
          />,
          <Button
            id={PREFIX_REGISTER_CLONESTEP_BUTTON_SUBMIT}
            data-testid="submit-button"
            onAction={() => handleSubmit()}
            text={t("components.clone-step.submit-button")}
          />,
        ]}
      >
        <span>
          {t("components.clone-step.content")} {data?.name}?
        </span>
      </Modal>
      {responseMessage.message && (
        <Messages
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={() =>
            setResponseMessage({
              message: "",
              severity: "",
            })
          }
        />
      )}
    </>
  );
}
