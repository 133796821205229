import React, { useState } from "react";

import {
  Button,
  Modal,
  Messages,
  IconButton,
  ProgressIndicator,
  Switch,
} from "fiorde-fe-components";
import { t } from "i18next";
import { TrashIcon } from "../icons";
import { CHECKLIST_REMOVEMULTIPLECHOICE_BUTTON_REMOVE, CHECKLIST_REMOVEMULTIPLECHOICE_MODAL, CHECKLIST_REMOVEMULTIPLECHOICE_BUTTON_CLOSE, CHECKLIST_REMOVEMULTIPLECHOICE_BUTTON_SUBMIT, CHECKLIST_REMOVEMULTIPLECHOICE_SPAN_TEXT, CHECKLIST_REMOVEMULTIPLECHOICE_MESSAGES } from "@/ids";

type RemoveMultipleChoiceProps = {
  removeMultipleChoice,
  index
};

export function RemoveMultipleChoice({
  removeMultipleChoice,
  index
}: RemoveMultipleChoiceProps) {
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: "",
  });


  function handleSubmit(): void {
    removeMultipleChoice(index)
    setOpen(false)
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <ProgressIndicator
        visible={isLoading}
        content={t("components.progress-indicator.content")}
      />
    <IconButton
        id={CHECKLIST_REMOVEMULTIPLECHOICE_BUTTON_REMOVE}
        data-testid="open-button"
        onClick={handleOpen}
        tooltip={t(
          "components.add-checklist.condition-columns.remove-tooltip"
        )}
      >
        <TrashIcon />
      </IconButton>
      <Modal
        width={600}
        id={CHECKLIST_REMOVEMULTIPLECHOICE_MODAL}
        title={t("components.add-checklist.modal-remove-field-title") }
        isOpen={open}
        onClose={handleClose}
        actions={[
          <Button
            id={CHECKLIST_REMOVEMULTIPLECHOICE_BUTTON_CLOSE}
            data-testid="close-button"
            backgroundGreen={false}
            onAction={() => handleClose()}
            text={t("components.disable-checklist.close-button")}
          />,
          <Button
            id={CHECKLIST_REMOVEMULTIPLECHOICE_BUTTON_SUBMIT}
            data-testid="submit-button"
            onAction={() => handleSubmit()}
            text={t("components.disable-checklist.submit-button")}
          />,
        ]}
      >
        <span
          id={CHECKLIST_REMOVEMULTIPLECHOICE_SPAN_TEXT}
        >
          {t("components.add-checklist.modal-remove-field")}
        </span>
      </Modal>
      {responseMessage.message && (
        <Messages
          id={CHECKLIST_REMOVEMULTIPLECHOICE_MESSAGES}
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={() =>
            setResponseMessage({
              message: "",
              severity: "",
            })
          }
        />
      )}
    </>
  );
}
