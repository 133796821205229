import React, { useState } from "react";
import { AddVehicleType as DomainAddVehicleType, LoadVehicleTypeList, UpdateVehicleType as DomainUpdateVehicleType, LoadVehicleType } from "@/domain/usecase";
import { LabelCell, Pagination, Table, ProgressIndicator } from "fiorde-fe-components";
import { useQuery } from "@tanstack/react-query";
import { PageHeader, AddVehicleType, UpdateVehicleType, DisableVehicleType } from "@/presentation/components";
import { t } from "i18next";
import { 
  PREFIX_VEHICLETYPE_LISTVEHICLETYPE_LABELCELL_NAME
 } from "@/ids";

type VehicleTypeListProps = {
  addVehicleType: DomainAddVehicleType
  loadVehicleTypeList: LoadVehicleTypeList
  loadVehicleType: LoadVehicleType
  updateVehicleType: DomainUpdateVehicleType
}

const columns = (updateVehicleType: DomainUpdateVehicleType, loadVehicleType: LoadVehicleType ) => ([
  {
    label: t("page.vehicle-type-list.columns.vehicle-type"),
    key: "txVehicleType",
    render: ({ txVehicleType }: any) => <LabelCell id={PREFIX_VEHICLETYPE_LISTVEHICLETYPE_LABELCELL_NAME}>{txVehicleType}</LabelCell>,
    size: 6.5,
  },
  {
    label: t("page.vehicle-type-list.columns.menu"),
    key: "menu",
    render: ({ idVehicleType, isActive }) => (
      <div style={{ display: "flex", gap: 8 }}>
        <UpdateVehicleType
          id={idVehicleType}
          loadVehicleType={loadVehicleType}
          updateVehicleType={updateVehicleType}
        />
        <DisableVehicleType
          id={idVehicleType}
          checked={isActive}
          loadVehicleType={loadVehicleType}
          updateVehicleType={updateVehicleType}
        />
      </div>
    ),
    size: 0.5,
  },
]);

export function VehicleType({ addVehicleType, loadVehicleTypeList, updateVehicleType, loadVehicleType }: VehicleTypeListProps) {
  const [filter, setFilter] = useState({
    page: 0,
    size: 10,
    sort: "id,desc"
  })
  const { isLoading, isRefetching, data } = useQuery({
    queryKey: ['vehicle-type-list', filter],
    queryFn: async () => loadVehicleTypeList.load(filter)
  })
  return (
    <div>
      <ProgressIndicator
        visible={isLoading || isRefetching}
        content={t("components.progress-indicator.content")}
      />
      <PageHeader title={t("page.vehicle-type-list.header")}>
        <AddVehicleType addVehicleType={addVehicleType}/>
      </PageHeader>
      <Table
        rows={data?.content || []}
        columns={columns(updateVehicleType, loadVehicleType)}
      />
      <Pagination
        count={data?.totalElements || 0}
        labelDisplay="exibindo"
        labelDisplayedRows="de"
        labelRowsPerPage="Tipo de Veículos por página"
        onPageChange={(value) =>
          setFilter((filter: any) => ({ ...filter, page: value }))
        }
        onRowsPerPageChange={(value) =>
          setFilter((filter: any) => ({
            ...filter,
            size: value,
            page: 0,
          }))
        }
        tooltipBack="Anterior"
        tooltipFirst="Primeira"
        tooltipLast="Última"
        tooltipNext="Próxima"
        page={filter.page}
      />
    </div>
  );
}
