import { RemoteLoadSystemList } from "@/data/usecase";
import { LoadSystemList } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteLoadSystemList = (): LoadSystemList =>
  new RemoteLoadSystemList(
    makeApiUrl("/sirius-checklist-api/v1/system/all"),
    makeAuthorizeHttpClientDecorator()
  );
