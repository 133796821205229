import styled from 'styled-components';
const IconDisplay = styled.div `
  display: flex;

  .icon {
    margin-right: 5px;
  }
`;
const RedColorSpan = styled.span `
  color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.itemModal) === null || _d === void 0 ? void 0 : _d.redAsterisk; }};
`;
export { IconDisplay, RedColorSpan };
