import { useState } from "react";
import { AddBodywork as DomainAddBodywork, LoadBodyworkList, UpdateBodywork as DomainUpdateBodywork, LoadBodywork } from "@/domain/usecase";
import { LabelCell, Pagination, Table, ProgressIndicator } from "fiorde-fe-components";
import { useQuery } from "@tanstack/react-query";
import { PageHeader, AddBodywork, UpdateBodywork, DisableBodywork } from "@/presentation/components";
import { t } from "i18next";
import { 
  PREFIX_BODYWORK_LISTBODYWORK_LABELCELL_NAME
 } from "@/ids";

type BodyworkListProps = {
  addBodywork: DomainAddBodywork
  loadBodyworkList: LoadBodyworkList
  loadBodywork: LoadBodywork
  updateBodywork: DomainUpdateBodywork
}

const columns = (updateBodywork: DomainUpdateBodywork, loadBodywork: LoadBodywork ) => ([
  {
    label: t("page.bodywork-list.columns.bodywork"),
    key: "name",
    render: ({ name }: any) => <LabelCell id={PREFIX_BODYWORK_LISTBODYWORK_LABELCELL_NAME}>{name}</LabelCell>,
    size: 6.5
  },
  {
    label: t("page.bodywork-list.columns.menu"),
    key: "menu",
    render: ({ id, isActive }) => (
      <div style={{ display: "flex", gap: 8 }}>
        <UpdateBodywork id={id} loadBodywork={loadBodywork} updateBodywork={updateBodywork} />
        <DisableBodywork id={id} checked={isActive} loadBodywork={loadBodywork} updateBodywork={updateBodywork} />
      </div>
    ),
    size: 0.5
  },
])

export function Bodywork({ addBodywork, loadBodyworkList, updateBodywork, loadBodywork }: BodyworkListProps) {
  const [filter, setFilter] = useState({
    page: 0,
    size: 10,
    sort: "id,desc"
  })
  const { isLoading, isRefetching, data } = useQuery({
    queryKey: ['bodywork-list', filter],
    queryFn: async () => loadBodyworkList.load(filter)
  })
  return (
    <div>
      <ProgressIndicator
        visible={isLoading || isRefetching}
        content={t("components.progress-indicator.content")}
      />
      <PageHeader title={t("page.bodywork-list.header")}>
        <AddBodywork addBodywork={addBodywork}/>
      </PageHeader>
      <Table
        rows={data?.content || []}
        columns={columns(updateBodywork, loadBodywork)}
      />
      <Pagination
        count={data?.totalElements || 0}
        labelDisplay="exibindo"
        labelDisplayedRows="de"
        labelRowsPerPage="Carrocerias por página"
        onPageChange={(value) =>
          setFilter((filter: any) => ({ ...filter, page: value }))
        }
        onRowsPerPageChange={(value) =>
          setFilter((filter: any) => ({
            ...filter,
            size: value,
            page: 0
          }))
        }
        tooltipBack="Anterior"
        tooltipFirst="Primeira"
        tooltipLast="Última"
        tooltipNext="Próxima"
        page={filter.page}
      />
    </div>
  );
}
