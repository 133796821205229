import { RemoteLoadMaterialFamilyList } from "@/data/usecase";
import { LoadMaterialFamilyList } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteLoadMaterialFamilyList = (): LoadMaterialFamilyList =>
  new RemoteLoadMaterialFamilyList(
    makeApiUrl("/sirius-yard-control-api/v1/material/family/all"),
    makeAuthorizeHttpClientDecorator()
  );
