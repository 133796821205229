import React, { useEffect, useState } from "react";
import { UpdateOrganization as DomainUpdateOrganization, LoadOrganization } from "@/domain/usecase";
import {
  Button,
  Modal,
  FormControl,
  Messages,
  Input,
  IconButton,
} from "fiorde-fe-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EditIcon } from "../icons";
import { t } from "i18next";
import { 
  PREFIX_ORGANIZATION_UPDATEORGANIZATION_BUTTON_CLOSE,
  PREFIX_ORGANIZATION_UPDATEORGANIZATION_BUTTON_SUBMIT,
  PREFIX_ORGANIZATION_UPDATEORGANIZATION_INPUT_NAME,
  PREFIX_ORGANIZATION_UPDATEORGANIZATION_INPUT_NAME_LABEL
 } from "@/ids";

type UpdateOrganizationProps = {
  updateOrganization: DomainUpdateOrganization;
  loadOrganization: LoadOrganization
  id: number;
};

export function UpdateOrganization({
  updateOrganization,
  loadOrganization,
  id,
}: UpdateOrganizationProps) {
  const queryClient = useQueryClient();
  const [name, setname] = useState("");
  const [open, setOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: "",
  });

  const { data } = useQuery<any>({
    queryKey: [`organization-${id}`],
    queryFn: async () => await loadOrganization.load(id),
    enabled: open
  })

  function handleSubmit(): void {
    updateOrganization
      .update(id, {
        ...data,
        name: name
      })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["organization-list"] });
        handleClose();
        setResponseMessage({
          message: t("components.update-organization.response-success-message"),
          severity: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        setResponseMessage({
          message: err?.message,
          severity: "error",
        });
      });
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setResponseMessage({
      message: "",
      severity: "",
    });
    setOpen(false);
  }

  useEffect(() => {
    if (open) {
      queryClient.invalidateQueries({ queryKey: [`organization-${id}`]})
    }
  }, [open]);

  useEffect(() => {
    setname(data?.name)
  }, [data])

  return (
    <>
      <IconButton
        data-testid="open-button"
        onClick={handleOpen}
        tooltip={t("components.update-organization.open-button-tooltip")}
      >
        <EditIcon />
      </IconButton>
      <Modal
        width={600}
        title={t("components.update-organization.title")}
        isOpen={open}
        onClose={handleClose}
        actions={[
          <Button
            id={PREFIX_ORGANIZATION_UPDATEORGANIZATION_BUTTON_CLOSE}
            data-testid="close-button"
            backgroundGreen={false}
            onAction={() => handleClose()}
            text={t("components.update-organization.close-button")}
          />,
          <Button
            id={PREFIX_ORGANIZATION_UPDATEORGANIZATION_BUTTON_SUBMIT}
            data-testid="submit-button"
            onAction={() => handleSubmit()}
            text={t("components.update-organization.submit-button")}
          />,
        ]}
      >
        <FormControl
          label={t("components.update-organization.name-label")}
          id={PREFIX_ORGANIZATION_UPDATEORGANIZATION_INPUT_NAME_LABEL}
        >
          <Input
            inputProps={{ "data-testid": "name" }}
            id={PREFIX_ORGANIZATION_UPDATEORGANIZATION_INPUT_NAME}
            onChange={(value) => setname(value)}
            value={name}
            placeholder={t("components.update-organization.name-placeholder")}
          />
        </FormControl>
      </Modal>
      {responseMessage.message && (
        <Messages
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={() =>
            setResponseMessage({
              message: "",
              severity: "",
            })
          }
        />
      )}
    </>
  );
}
