import React from 'react'
import { Home } from '@/presentation/pages'

export const makeHome: React.FC = (props) => {
  return (
    <Home
      {...props}
    />
  )
}
