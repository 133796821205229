import { AccessDeniedError, UnexpectedError } from "@/domain/errors";
import { HttpClient, HttpStatusCode } from "@/domain/protocols/http";
import { LoadStepTypeList } from "@/domain/usecase";
import { RemoteLoadStepTypeDTO } from "../models";

export class RemoteLoadStepTypeList implements LoadStepTypeList {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<LoadStepTypeList.Model>
  ) {}

  async load(): Promise<LoadStepTypeList.Model> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: "get",
    });
    const LoadStepTypeList = httpResponse.body;
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return LoadStepTypeList;
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        //@ts-ignore
        const err = httpResponse.body?.response?.error;
        throw new UnexpectedError(err);
    }
  }
}

export namespace RemoteLoadStepTypeList {
  export type Model = RemoteLoadStepTypeDTO[];
}
