var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMutation } from '@tanstack/react-query';
import API from '../../../infrastructure/api';
const useUploadTariff = () => {
    const uploadTariff = (params) => __awaiter(void 0, void 0, void 0, function* () {
        const { type, modal, setProgress, formData, agent } = params;
        const data = yield API.uploadTariff(type, modal, setProgress, formData, agent);
        return data;
    });
    return useMutation((params) => __awaiter(void 0, void 0, void 0, function* () { return yield uploadTariff(params); }));
};
export default useUploadTariff;
