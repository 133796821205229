var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HttpStatusCode } from '../../data/protocols/http';
import { LocalStorageAdapter } from '../cache';
// import { refreshAuthenticate } from '../../dataSource/request'
// import { setUserOnStorage } from '@/utils/users'
import instance from '../../infrastructure/instance';
export class AxiosHttpClient {
    request(data) {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            let axiosResponse;
            const storage = new LocalStorageAdapter();
            try {
                axiosResponse = yield instance.request({
                    url: data.url,
                    method: data.method,
                    data: data.body,
                    headers: data.headers
                });
            }
            catch (error) {
                axiosResponse = error.response;
                const axiosStatus = (_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status;
                const axiosMessage = ((_c = (_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.message) || 'Erro';
                console.error({
                    message: `Error: ${axiosStatus === null || axiosStatus === void 0 ? void 0 : axiosStatus.toString()}`,
                    description: axiosMessage
                });
                if (axiosStatus === HttpStatusCode.unauthorized) {
                    const alwaysLogged = JSON.parse(storage.get('always_logged'));
                    if (alwaysLogged) {
                        // const response = await refreshAuthenticate()
                        //   setUserOnStorage(response)
                        // window.location.reload()
                    }
                    else {
                        // storage.clear()
                        // window.location.href = '/login'
                    }
                }
            }
            return {
                statusCode: axiosResponse.status,
                body: axiosResponse.data
            };
        });
    }
}
