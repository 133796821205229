import styled from "styled-components";

export const PageHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const PageHeaderTitle = styled.p`
  font-size: 18;
  font-weight: 600;
`;

export const PageHeaderChildren = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
`;
