import { RemoteLoadBodyworkList } from "@/data/usecase";
import { LoadBodyworkList } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteLoadBodyworkList = (): LoadBodyworkList =>
  new RemoteLoadBodyworkList(
    makeApiUrl("/sirius-master-data-api/v1/bodywork/all"),
    makeAuthorizeHttpClientDecorator()
  );
