import { SetStorage, GetStorage, GetToken } from '@/domain/protocols/cache'
import { PREFIX_LOCALSTORAGE } from '@/main/constants'

export class LocalStorageAdapter implements SetStorage, GetStorage, GetToken {
  set (key: string, value: object): void {
    if (value) {
      localStorage.setItem(`${PREFIX_LOCALSTORAGE}${key}`, JSON.stringify(value))
    } else {
      localStorage.removeItem(`${PREFIX_LOCALSTORAGE}${key}`)
    }
  }

  get (key: string): any {
    return localStorage.getItem(`${PREFIX_LOCALSTORAGE}${key}`)
  }

  getToken (): string {
    return localStorage.getItem('token')
  }

  clear (): void {
    Object.keys(localStorage)
      .filter(x => x.startsWith(PREFIX_LOCALSTORAGE))
      .forEach(x => localStorage.removeItem(x))
  }
}
