import styled from "styled-components";

export const RowStyled = styled.div<{
  gridTemplateColumns?: string;
  gap?: string;
  justifyItems?: string;
}>`
  display: grid;
  grid-template-columns: ${(props) => props?.gridTemplateColumns || "1fr"};
  gap: ${(props) => props?.gap || "24px"};
  justify-items: ${(props) => props?.justifyItems || "normal"};
`;
