import { RemoteLoadDocumentTypeList } from "@/data/usecase";
import { LoadDocumentTypeList } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteLoadDocumentTypeList = (): LoadDocumentTypeList =>
  new RemoteLoadDocumentTypeList(
    makeApiUrl("/sirius-business-partner-api/v1/document/type/all"),
    makeAuthorizeHttpClientDecorator()
  );
