import { RemoteLoadBusinessPartner } from "@/data/usecase";
import { LoadBusinessPartner } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteLoadBusinessPartner = (): LoadBusinessPartner =>
  new RemoteLoadBusinessPartner(
    makeApiUrl("/sirius-business-partner-api/v1/business/partner"),
    makeAuthorizeHttpClientDecorator()
  );
