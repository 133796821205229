import { PREFIX_LOCALSTORAGE } from '../../utils/constants';
export class LocalStorageAdapter {
    set(key, value) {
        if (value) {
            localStorage.setItem(`${process.env.REACT_APP_PREFIX_LOCALSTORAGE}${key}`, JSON.stringify(value));
        }
        else {
            localStorage.removeItem(`${process.env.REACT_APP_PREFIX_LOCALSTORAGE}${key}`);
        }
    }
    get(key) {
        return localStorage.getItem(`${process.env.REACT_APP_PREFIX_LOCALSTORAGE}${key}`);
    }
    clear() {
        Object.keys(localStorage)
            .filter(x => x.startsWith(PREFIX_LOCALSTORAGE))
            .forEach(x => localStorage.removeItem(x));
    }
}
