export * from "./remote-add-vehicle-type";
export * from "./remote-update-vehicle-type";
export * from "./remote-load-vehicle-type-list";
export * from "./remote-load-vehicle-type";
export * from "./remote-add-organization";
export * from "./remote-update-organization";
export * from "./remote-load-organization";
export * from "./remote-load-organization-list";
export * from "./remote-add-bodywork";
export * from "./remote-update-bodywork";
export * from "./remote-load-bodywork";
export * from "./remote-load-bodywork-list";
export * from "./remote-load-business-partner-list";
export * from "./remote-load-classification-list";
export * from "./remote-add-business-partner";
export * from "./remote-update-business-partner";
export * from "./remote-load-countries-list";
export * from "./remote-load-cities-list";
export * from "./remote-load-states-list";
export * from "./remote-load-address-type-list";
export * from "./remote-load-document-type-list";
export * from "./remote-load-business-partner";
