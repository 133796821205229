import React, { useState } from "react";
import {
  AddVehicleComposition as DomainAddVehicleComposition,
  LoadVehicleCompositionPegeable,
  UpdateVehicleComposition as DomainUpdateVehicleComposition,
  LoadVehicleComposition,
  LoadVehicleTypeList,
  LoadBodyworkList,
} from "@/domain/usecase";
import {
  LabelCell,
  Pagination,
  Table,
  ProgressIndicator,
} from "fiorde-fe-components";
import { useQuery } from "@tanstack/react-query";
import {
  AddVehicleComposition,
  // UpdateVehicleComposition,
  // DisableVehicleComposition,
  PageHeader,
} from "@/presentation/components";
import { t } from "i18next";
import { UpdateVehicleComposition } from "@/presentation/components/update-vehicle-composition/update-vehicle-composition";
import { DisableVehicleComposition } from "@/presentation/components/disable-vehicle-composition/disable-vehicle-composition";
import {
  PREFIX_REGISTER_LISTDOCK_LABELCELL_NAME
} from "@/ids";

type VehicleCompositionListProps = {
  addVehicleComposition: DomainAddVehicleComposition,
  updateVehicleComposition: DomainUpdateVehicleComposition,
  loadVehicleComposition: LoadVehicleComposition,
  LoadVehicleCompositionPegeable: LoadVehicleCompositionPegeable,
  loadVehicleTypeList: LoadVehicleTypeList,
  loadBodyworkList: LoadBodyworkList
};

const columns = ({
  updateVehicleComposition,
  loadVehicleComposition,
  loadVehicleTypeList,
  loadBodyworkList
}) => [
  {
    label: t("page.vehicle-composition-list.columns.name"),
    key: "name",
    render: ({ name }: any) => <LabelCell id={PREFIX_REGISTER_LISTDOCK_LABELCELL_NAME}>{name}</LabelCell>,
    size: 6.5,
  },
  {
    label: t("page.vehicle-composition-list.columns.menu"),
    key: "menu",
    render: ({ id, isActive }) => (
      <div style={{ display: "flex", gap: 8 }}>
        <UpdateVehicleComposition
          id={id}
          updateVehicleComposition={updateVehicleComposition}
          loadVehicleComposition={loadVehicleComposition}
          loadVehicleTypeList={loadVehicleTypeList}
          loadBodyworkList={loadBodyworkList}
        />
        <DisableVehicleComposition
          id={id}
          checked={isActive}
          loadVehicleComposition={loadVehicleComposition}
          updateVehicleComposition={updateVehicleComposition}
        />
      </div>
    ),
    size: 0.5,
  },
];

export function VehicleComposition({
  addVehicleComposition,
  updateVehicleComposition,
  loadVehicleComposition,
  LoadVehicleCompositionPegeable,
  loadVehicleTypeList,
  loadBodyworkList
}: VehicleCompositionListProps) {
  const [filter, setFilter] = useState({
    page: 0,
    size: 10,
    sort: "id,desc",
  });
  const { isLoading, isRefetching, data } = useQuery({
    queryKey: ["vehicle-composition-list", filter],
    queryFn: async () => LoadVehicleCompositionPegeable.load(filter),
  });
  return (
    <div>
      <ProgressIndicator
        visible={isLoading || isRefetching}
        content={t("components.progress-indicator.content")}
      />
      <PageHeader title={t("page.vehicle-composition-list.header")}>
        <AddVehicleComposition
          addVehicleComposition={addVehicleComposition}
          loadVehicleTypeList={loadVehicleTypeList}
          loadBodyworkList={loadBodyworkList}
        />
      </PageHeader>
      <Table
        rows={data?.content || []}
        columns={columns({
          updateVehicleComposition,
          loadVehicleComposition,
          loadVehicleTypeList,
          loadBodyworkList
        })}
      />
      <Pagination
        count={data?.totalElements || 0}
        labelDisplay="exibindo"
        labelDisplayedRows="de"
        labelRowsPerPage="Vagas por página"
        onPageChange={(value) =>
          setFilter((filter: any) => ({ ...filter, page: value }))
        }
        onRowsPerPageChange={(value) =>
          setFilter((filter: any) => ({
            ...filter,
            size: value,
            page: 0,
          }))
        }
        tooltipBack="Anterior"
        tooltipFirst="Primeira"
        tooltipLast="Última"
        tooltipNext="Próxima"
        page={filter.page}
      />
    </div>
  );
}
