import styled from 'styled-components';
import { TableRow } from '@material-ui/core';
export const IconsContainer = styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
`;
export const IndividualIconContainer = styled.div `
    margin: 0 12px;
    cursor: pointer;
`;
export const TableHeader = styled.span `
    color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.itemModal) === null || _d === void 0 ? void 0 : _d.fontColor; }};
    font-size: 12px;
`;
export const StyledTableRow = styled(TableRow) `
    .MuiTableCell-root {
        border-bottom: ${(props) => props.noBorder ? 0 : '1px solid'};
        border-color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.itemModal) === null || _d === void 0 ? void 0 : _d.border; }};
        color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.itemModal) === null || _d === void 0 ? void 0 : _d.inputFontColor; }};
    }
`;
export const BottomRow = styled(TableRow) `
    background-color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.costTable) === null || _d === void 0 ? void 0 : _d.backgroundColor; }};
    .MuiTableCell-root {
        color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.itemModal) === null || _d === void 0 ? void 0 : _d.inputFontColor; }};
        border-bottom: 0;
    }
`;
