import React from "react";
import { FlowDetail } from "@/presentation/pages/flow-detail/page";
import { makeRemoteLoadFlow, makeRemoteLoadMovementTypeList, makeRemoteLoadStep, makeRemoteLoadStepAll, makeRemoteUpdateFlow } from "../../usecases";
import { makeRemoteAddFlow } from "../../usecases/remote-add-flow-factory";

export const makeFlowDetail: React.FC = (props) => {
  return (
    <FlowDetail
      {...props}
      loadFlow={makeRemoteLoadFlow()}
      loadMovementTypeList={makeRemoteLoadMovementTypeList()}
      loadStepAll={makeRemoteLoadStepAll()}
      loadStep={makeRemoteLoadStep()}
      addFlow={makeRemoteAddFlow()}
      editFlow={makeRemoteUpdateFlow()}
    />
  );
};
