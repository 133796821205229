import moment from 'moment';
import { IncotermTypes, ProposalTypes, ModalTypes, IdProposalTypes } from '../../application/enum/enum';
const validateIncoterm = (data) => {
    if (!data.incoterm || data.incoterm.length === 0) {
        return false;
    }
    if (data.incoterm === IncotermTypes.Exw) {
        return data.collection !== '' && data.postalCode !== '';
    }
    if (data.incoterm === IncotermTypes.Dap) {
        return data.collectionDap !== '' && data.postalCodeDap !== '';
    }
    return data.incoterm !== IncotermTypes.Exw && data.incoterm !== IncotermTypes.Dap;
};
const validateClient = (proposalType, selectedAgents) => {
    return proposalType !== ProposalTypes.Client || selectedAgents[0].agent.length !== 0;
};
const validateOriginDestination = (modal, data) => {
    return modal === ModalTypes.Land || (modal !== ModalTypes.Land && data.origin !== data.destiny);
};
const validateShippingCompany = (value, index, selectedAgents) => {
    return selectedAgents.some((agent, currentIndex) => agent.shippingCompany === value &&
        currentIndex !== index &&
        value.length !== 0);
};
const validateAgent = (value, index, selectedAgents) => {
    return selectedAgents.some((agent, currentIndex) => agent.agent === value &&
        currentIndex !== index &&
        value.length > 0);
};
const validateCompleteShippingCompany = (selectedAgents) => {
    return selectedAgents.every(selectedAgent => selectedAgent.shippingCompany.length > 0);
};
const validateFilledStep2Import = (modal, data, setFilled, proposalType, selectedAgents) => {
    const step6 = modal === ModalTypes.Land;
    if (data.origin !== '' ||
        data.destiny !== '' ||
        data.oriCity !== '' ||
        data.oriState !== '' ||
        data.oriCountry !== '' ||
        data.destCity !== '' ||
        data.destState !== '' ||
        data.destCountry !== '' ||
        data.incoterm !== '' ||
        (proposalType === ProposalTypes.Client && selectedAgents[0].agent.length !== 0) ||
        proposalType !== ProposalTypes.Client ||
        (proposalType === ProposalTypes.Client && selectedAgents[0].profitPercentageAgent !== null) ||
        proposalType !== ProposalTypes.Client) {
        setFilled((currentState) => {
            return Object.assign(Object.assign({}, currentState), { step2: true, step6 });
        });
    }
    else {
        setFilled((currentState) => {
            return Object.assign(Object.assign({}, currentState), { step2: false, step6 });
        });
    }
};
const validateFilledStep1ImportExport = (data, setFilled) => {
    if (data.proposal !== 0 ||
        data.modal !== '') {
        setFilled((currentState) => {
            return Object.assign(Object.assign({}, currentState), { step1: true });
        });
    }
    else {
        setFilled((currentState) => {
            return Object.assign(Object.assign({}, currentState), { step1: false });
        });
    }
};
const validateFilledStep2Export = (data, setFilled, proposalType, selectedAgents) => {
    if (proposalType !== ProposalTypes.Client) {
        if (data.origin !== '' ||
            data.destiny !== '' ||
            data.oriCity !== '' ||
            data.oriState !== '' ||
            data.oriCountry !== '' ||
            data.destCity !== '' ||
            data.destState !== '' ||
            data.destCountry !== '' ||
            data.incoterm !== '' ||
            (proposalType === ProposalTypes.Client && selectedAgents[0].agent.length !== 0) ||
            proposalType !== ProposalTypes.Client ||
            (proposalType === ProposalTypes.Client && selectedAgents[0].profitPercentageAgent !== null) ||
            proposalType !== ProposalTypes.Client) {
            setFilled((currentState) => {
                return Object.assign(Object.assign({}, currentState), { step2: true });
            });
        }
        else {
            setFilled((currentState) => {
                return Object.assign(Object.assign({}, currentState), { step2: false });
            });
        }
    }
    else {
        setFilled((currentState) => {
            return Object.assign(Object.assign({}, currentState), { step2: true });
        });
    }
};
const validateFilledStep3ImportExport = (tableRows, data, setFilled) => {
    var _a;
    if (tableRows.length > 0 ||
        data.description.length > 0 ||
        data.specifications.length > 0 ||
        data.temperature !== '' ||
        data.imo.length > 0 ||
        ((_a = data.codUn) === null || _a === void 0 ? void 0 : _a.length) > 0 ||
        data.dangerous) {
        setFilled((currentState) => {
            return Object.assign(Object.assign({}, currentState), { step3: true });
        });
    }
    else {
        setFilled((currentState) => {
            return Object.assign(Object.assign({}, currentState), { step3: false });
        });
    }
};
const validateFilledStep4ImportExport = (data, setFilled) => {
    if (data.validity.length > 0 ||
        data.validityDate.length > 0 ||
        data.transitTime.length > 0 ||
        data.frequency !== '' ||
        data.route.length > 0 ||
        data.client.length > 0 ||
        data.generalObs.length > 0 ||
        data.internalObs.length > 0) {
        setFilled((currentState) => {
            return Object.assign(Object.assign({}, currentState), { step4: true });
        });
    }
    else {
        setFilled((currentState) => {
            return Object.assign(Object.assign({}, currentState), { step4: false });
        });
    }
};
const validadeFilledStep5ImportExport = (proposal, data, dataSales, dataContainer, setFilled) => {
    var _a;
    const FclCargoContractingType = 1 /* FCL */;
    const ContractingTypeWithoutFcl = [
        2 /* LCL */,
        3 /* BREAK_BULK */,
        4 /* RO_RO */
    ];
    if (proposal.idTransportMode === IdProposalTypes.Air || proposal.idTransportMode === IdProposalTypes.Land || (proposal.idTransportMode === IdProposalTypes.Sea && proposal.cargo[0].idCargoContractingType !== null && ContractingTypeWithoutFcl.includes(proposal.cargo[0].idCargoContractingType))) {
        if (data.every(d => d.currencyPurchase !== '') &&
            data.every(d => d.valuePurchase !== '') &&
            ((_a = dataSales.valueSale) === null || _a === void 0 ? void 0 : _a.length) !== 0 &&
            (dataSales.valueSale.every(value => value !== '' && value !== '0')) &&
            (dataSales.currencySale !== '' && dataSales.currencySale !== '0' && dataSales.currencySale !== null)) {
            setFilled((currentState) => {
                return Object.assign(Object.assign({}, currentState), { step5: true });
            });
        }
        else {
            setFilled((currentState) => {
                return Object.assign(Object.assign({}, currentState), { step5: false });
            });
        }
    }
    if (proposal.idTransportMode === IdProposalTypes.Sea && proposal.cargo[0].idCargoContractingType === FclCargoContractingType) {
        if (dataContainer.every(d => d.currencyPurchase !== '') && dataContainer.every(d => d.valuePurchase !== '')) {
            setFilled((currentState) => {
                return Object.assign(Object.assign({}, currentState), { step5: true });
            });
        }
        else {
            setFilled((currentState) => {
                return Object.assign(Object.assign({}, currentState), { step5: false });
            });
        }
    }
};
const validadeFilledStep6ImportExport = (dataOrigin, dataDestiny, setFilled) => {
    if (dataOrigin.length > 0 && dataDestiny.length > 0) {
        setFilled((currentState) => {
            return Object.assign(Object.assign({}, currentState), { step6: true });
        });
    }
    else {
        setFilled((currentState) => {
            return Object.assign(Object.assign({}, currentState), { step6: false });
        });
    }
};
const validateDate = (data) => {
    const validityDate = moment(data.validityDate, 'DD/MM/YYYY', true);
    const today = moment().startOf('day');
    return validityDate.isValid() && validityDate.isSameOrAfter(today);
};
const validateFormCompleteStep2ImportExport = (invalidAgent, validateCompleteShippingCompany, originDestinyFullfilled, validateClient, validateIncoterm, validateOriginDestination, validateProfitPercent, setCompleted) => {
    const isFormValid = !invalidAgent &&
        validateCompleteShippingCompany() &&
        originDestinyFullfilled() &&
        validateClient() &&
        validateIncoterm() &&
        validateOriginDestination() &&
        validateProfitPercent();
    setCompleted(currentState => (Object.assign(Object.assign({}, currentState), { step2: isFormValid })));
};
const validateFormCompleteStep3ImportExport = (modal, validateDangerous, validateEspecification, data, setCompleted) => {
    const isFormValid = validateDangerous() &&
        validateEspecification() &&
        data.description.length !== 0 &&
        data.temperature.length !== 0;
    const step6 = modal === ModalTypes.Land;
    setCompleted(currentState => (Object.assign(Object.assign({}, currentState), { step3: isFormValid, step6 })));
};
const validateFormCompleteStep4ImportExport = (modal, validateCompleteInputs, validateDate, setCompleted) => {
    const isFormValid = validateCompleteInputs() && validateDate();
    const step6 = modal === ModalTypes.Land;
    setCompleted(currentState => (Object.assign(Object.assign({}, currentState), { step4: isFormValid, step6 })));
};
const validateField = (field) => {
    return (field === null || field.length === 0);
};
const Validations = {
    validateIncoterm,
    validateClient,
    validateOriginDestination,
    validateShippingCompany,
    validateAgent,
    validateCompleteShippingCompany,
    validateFilledStep1ImportExport,
    validateFilledStep2Import,
    validateFilledStep2Export,
    validateFilledStep3ImportExport,
    validateFilledStep4ImportExport,
    validadeFilledStep5ImportExport,
    validadeFilledStep6ImportExport,
    validateDate,
    validateFormCompleteStep2ImportExport,
    validateFormCompleteStep3ImportExport,
    validateFormCompleteStep4ImportExport,
    validateField
};
export default Validations;
