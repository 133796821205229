import { AccessDeniedError, UnexpectedError } from "@/domain/errors";
import {
  HttpClient,
  HttpStatusCode,
} from "@/domain/protocols/http";
import { LoadCitiesList } from "@/domain/usecase";
import { RemoteLoadCitiesListDTO } from "../models";

export class RemoteLoadCitiesList implements LoadCitiesList {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<LoadCitiesList.Model>
  ) {}

  async load(stateId: number): Promise<LoadCitiesList.Model> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${stateId}/cities`,
      method: "get",
    });
    const LoadClassificationList = httpResponse.body;
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return LoadClassificationList;
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }
}

export namespace RemoteLoadCitiesList {
  export type Model = RemoteLoadCitiesListDTO;
}
