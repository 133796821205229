import axios from 'axios';
export const URL = process.env.BASE_URL_WSO2;
const ApiKey = process.env.WSO2_API_KEY;
const instance = axios.create({
    baseURL: URL,
    headers: {
        'Content-Type': 'application/json;'
    }
});
instance.interceptors.request.use((config) => {
    config.headers.ApiKey = ApiKey;
    return config;
});
export default instance;
