var CostTypes;
(function (CostTypes) {
    CostTypes["Tariff"] = "Tarifa";
    CostTypes["Freight"] = "Frete";
    CostTypes["Origin"] = "Origem";
    CostTypes["Destiny"] = "Destino";
})(CostTypes || (CostTypes = {}));
var FareItemsTypes;
(function (FareItemsTypes) {
    FareItemsTypes["Fixed"] = "FIXO";
    FareItemsTypes["Bl"] = "BL";
    FareItemsTypes["Cw"] = "CW";
    FareItemsTypes["Container"] = "CONTAINER";
    FareItemsTypes["Fdesp"] = "% F.+ DESP.O";
    FareItemsTypes["Ton"] = "TON";
    FareItemsTypes["Kilo"] = "KG";
})(FareItemsTypes || (FareItemsTypes = {}));
var CostNameTypes;
(function (CostNameTypes) {
    CostNameTypes["Fixed"] = "Fixo";
    CostNameTypes["Bl"] = "BL";
    CostNameTypes["Cw"] = "CW";
    CostNameTypes["Container"] = "Container";
    CostNameTypes["Fdesp"] = "%F. + Desp. O";
    CostNameTypes["Ton"] = "Ton\u00B3";
    CostNameTypes["Kilo"] = "KG";
})(CostNameTypes || (CostNameTypes = {}));
var TooltipTypes;
(function (TooltipTypes) {
    TooltipTypes["Fdesp"] = "% de frete + Despesa Origem";
})(TooltipTypes || (TooltipTypes = {}));
export { CostTypes, FareItemsTypes, CostNameTypes, TooltipTypes };
