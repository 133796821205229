import { Modal, Grid, FormLabel, RadioGroup, Checkbox, FormControlLabel, Box, InputAdornment } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CloseIcon from '../../../application/icons/CloseIcon';
import { ModalDiv, MainDiv } from './ItemModalStyles';
import { I18n } from 'react-redux-i18n';
import ControlledInput from '../ControlledInput';
import { HeaderDiv, RedColorSpan, RowReverseDiv, Title, CloseIconContainer, ButtonDiv } from '../StyledComponents/modalStyles';
import { Button } from 'fiorde-fe-components';
import { Autocomplete } from '@material-ui/lab';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { NumberInput, StyledPaper } from '../../pages/NewProposal/steps/StepsStyles';
import FormatNumber from '../../../application/utils/formatNumber';
import { PROPOSAL_ITEM_MODAL_INPUT_SIZE, PROPOSAL_ITEM_MODAL_INPUT_TYPE } from '../../../ids';
export const initialState = {
    amount: '',
    cubage: null,
    diameter: null,
    height: null,
    id: null,
    length: null,
    rawWeight: null,
    stack: false,
    type: null,
    width: null
};
const ItemModal = ({ containerTypeList, dataProp, handleAdd, modal, open, packagingList, setClose, specifications, title, vehicleTypes }) => {
    var _a, _b;
    const [data, setData] = useState(initialState);
    const [didMount, setDidMount] = useState(false);
    const [invalidInput, setInvalidInput] = useState(false);
    const rgxFloat = /^[0-9]*,?[0-9]*$/;
    const rgxInt = /^[0-9]*$/;
    useEffect(() => {
        if (dataProp !== initialState) {
            setData(dataProp);
        }
    }, [open]);
    const handleOnClose = () => {
        setData(initialState);
        setInvalidInput(false);
        setDidMount(false);
        setClose();
    };
    const marineFCL = () => {
        return modal === 'SEA' && specifications === 'fcl';
    };
    const validateFloatInput = (value) => {
        return value.match(rgxFloat);
    };
    const validateIntInput = (value) => {
        return value.match(rgxInt);
    };
    const validateData = () => {
        var _a, _b, _c, _d, _e, _f;
        if (marineFCL() || !isNotLand()) {
            return !(((_a = data.type) === null || _a === void 0 ? void 0 : _a.length) === 0 ||
                data.amount.length === 0);
        }
        else {
            return !((data.idCalculationType === null) ||
                data.amount.length === 0 ||
                (data.rawWeight === null || ((_b = data.rawWeight) === null || _b === void 0 ? void 0 : _b.length) === 0) ||
                (data.height === null || ((_c = data.height) === null || _c === void 0 ? void 0 : _c.length) === 0) ||
                (data.width === null || ((_d = data.width) === null || _d === void 0 ? void 0 : _d.length) === 0) ||
                (data.length === null || ((_e = data.length) === null || _e === void 0 ? void 0 : _e.length) === 0) ||
                (data.cubage === null || ((_f = data.cubage) === null || _f === void 0 ? void 0 : _f.length) === 0 || data.cubage === '0,00'));
        }
    };
    const isNotLand = () => {
        return modal !== 'LAND';
    };
    const handleOnAdd = () => {
        if (validateData()) {
            handleAdd(data);
            handleOnClose();
        }
        else {
            setInvalidInput(true);
        }
    };
    const getContainerTypeList = () => {
        const finalList = [];
        containerTypeList === null || containerTypeList === void 0 ? void 0 : containerTypeList.forEach((item) => {
            if (item.container !== null) {
                finalList.push(String(item.container));
            }
        });
        return finalList;
    };
    const updateCubage = () => {
        const newCubage = Number(data.length === null ? 0 : Number(data.length.replace(',', '.'))) *
            Number(data.width === null ? 0 : Number(data.width.replace(',', '.'))) *
            Number(data.height === null ? 0 : Number(data.height.replace(',', '.'))) *
            Number(data.amount === null ? 0 : Number(data.amount));
        setData(Object.assign(Object.assign({}, data), { cubage: newCubage.toFixed(2).replace('.', ',') }));
    };
    useEffect(() => {
        if (didMount) {
            updateCubage();
        }
        else if (!didMount && data !== initialState) {
            setDidMount(true);
        }
    }, [data.length, data.width, data.height, data.amount]);
    return (React.createElement(Modal, { open: open, onClose: handleOnClose },
        React.createElement(ModalDiv, null,
            React.createElement(HeaderDiv, null,
                React.createElement(Title, null, title),
                React.createElement(RowReverseDiv, null,
                    React.createElement(CloseIconContainer, null,
                        React.createElement(CloseIcon, { onClick: handleOnClose })))),
            React.createElement(MainDiv, null,
                React.createElement(Grid, { container: true, spacing: 2, style: { width: '100%' } },
                    React.createElement(Grid, { item: true, xs: 10 },
                        React.createElement(FormLabel, { component: "legend", error: invalidInput &&
                                (data.length === null || data.length.length === 0) },
                            !isNotLand() ? I18n.t('components.itemModal.vehicle') : marineFCL() ? I18n.t('components.itemModal.container') : I18n.t('components.itemModal.packaging'),
                            React.createElement(RedColorSpan, null, " *")),
                        React.createElement(Autocomplete, { value: data.type, onChange: (e, newValue) => setData(Object.assign(Object.assign({}, data), { type: newValue })), options: !isNotLand() ? vehicleTypes.map((item) => String(item.txVehicleType)) : marineFCL() ? getContainerTypeList() : packagingList.map((item) => String(item.packaging)), renderInput: (params) => (React.createElement("div", { ref: params.InputProps.ref },
                                React.createElement(ControlledInput, Object.assign({}, params, { id: "search-origin", toolTipTitle: I18n.t('components.itemModal.requiredField'), invalid: invalidInput &&
                                        (data.length === null || data.length.length === 0), variant: "outlined", size: "small", modal: true, placeholder: I18n.t('components.itemModal.choose'), InputProps: {
                                        endAdornment: (React.createElement(InputAdornment, { position: "end" },
                                            React.createElement(Box, Object.assign({ width: 'max-content' }, params.inputProps),
                                                React.createElement(ArrowDropDownIcon, null))))
                                    }, value: data.type })))), PaperComponent: (params) => React.createElement(StyledPaper, Object.assign({}, params)) })),
                    React.createElement(Grid, { item: true, xs: 2 },
                        React.createElement(FormLabel, { component: "legend", error: invalidInput &&
                                (data.amount === null || data.amount.length === 0) },
                            I18n.t('components.itemModal.amount'),
                            React.createElement(RedColorSpan, null, " *")),
                        React.createElement(ControlledInput, { toolTipTitle: I18n.t('components.itemModal.requiredField'), invalid: invalidInput &&
                                (data.amount === null || data.amount.length === 0), value: data.amount, onChange: e => { validateIntInput(e.target.value) !== null && (setData(Object.assign(Object.assign({}, data), { amount: e.target.value }))); }, variant: "outlined", size: "small", modal: true, inputProps: { maxLength: 4 } })),
                    isNotLand() && React.createElement(React.Fragment, null,
                        !marineFCL() && React.createElement(Grid, { item: true, xs: 4 },
                            React.createElement(FormLabel, { component: "legend", error: invalidInput && (!marineFCL()) &&
                                    (data.rawWeight === null || data.rawWeight.length === 0) },
                                I18n.t('components.itemModal.rawWeight'),
                                (modal === 'AIR' ||
                                    (modal === 'SEA' && specifications !== 'fcl') ||
                                    modal === 'LAND') && React.createElement(RedColorSpan, null, " *")),
                            React.createElement(NumberInput, { decimalSeparator: ',', thousandSeparator: '.', decimalScale: 2, format: (value) => FormatNumber.rightToLeftFormatter(value, 2), customInput: ControlledInput, onChange: e => { validateFloatInput(e.target.value) !== null && (setData(Object.assign(Object.assign({}, data), { rawWeight: e.target.value }))); }, toolTipTitle: I18n.t('components.itemModal.requiredField'), invalid: invalidInput && (!marineFCL()) &&
                                    (data.rawWeight === null || data.rawWeight.length === 0), value: data.rawWeight != null ? data.rawWeight : '', variant: "outlined", size: "small", modal: true })),
                        marineFCL() && React.createElement(Grid, { item: true, xs: 4 },
                            React.createElement(FormLabel, { component: "legend" }, I18n.t('components.itemModal.containerType')),
                            React.createElement(ControlledInput, { id: PROPOSAL_ITEM_MODAL_INPUT_TYPE, toolTipTitle: '', invalid: false, value: data.type ? (_a = containerTypeList.find((each) => each.container === data.type)) === null || _a === void 0 ? void 0 : _a.type : '', onChange: () => { }, variant: "outlined", size: "small", modal: true, disabled: true })),
                        marineFCL() && React.createElement(Grid, { item: true, xs: 2 },
                            React.createElement(FormLabel, { component: "legend" }, I18n.t('components.itemModal.size')),
                            React.createElement(ControlledInput, { id: PROPOSAL_ITEM_MODAL_INPUT_SIZE, toolTipTitle: '', invalid: false, value: data.type ? String((_b = containerTypeList.find((each) => each.container === data.type)) === null || _b === void 0 ? void 0 : _b.size) : '', onChange: () => { }, variant: "outlined", size: "small", modal: true, disabled: true })),
                        React.createElement(Grid, { item: true, xs: 6 },
                            React.createElement(RadioGroup, { style: { margin: '47px 10px 10px -15px' }, row: true, "aria-label": "services", name: "row-radio-buttons-group" },
                                React.createElement(FormControlLabel, { value: "stack", control: React.createElement(Checkbox, { checked: data.stack, onChange: () => setData(Object.assign(Object.assign({}, data), { stack: !data.stack })) }), label: I18n.t('components.itemModal.stack') }))),
                        marineFCL() && React.createElement(Box, { width: "100%" }),
                        !marineFCL() && React.createElement(Grid, { item: true, xs: 6 },
                            React.createElement(FormLabel, { component: "legend", error: invalidInput && !(marineFCL()) &&
                                    (data.length === null || data.length.length === 0) },
                                I18n.t('components.itemModal.hwl'),
                                (modal === 'AIR' ||
                                    (modal === 'SEA' && specifications !== 'fcl') ||
                                    modal === 'LAND') && React.createElement(RedColorSpan, null, " *")),
                            React.createElement("div", { style: { display: 'flex', justifyContent: 'space-between' } },
                                React.createElement(NumberInput, { decimalSeparator: ',', thousandSeparator: '.', decimalScale: 2, format: (value) => FormatNumber.rightToLeftFormatter(value, 2), customInput: ControlledInput, toolTipTitle: I18n.t('components.itemModal.requiredField'), invalid: invalidInput && !(marineFCL()) &&
                                        (data.length === null || data.length.length === 0), value: data.length != null ? data.length : '', onChange: e => { validateFloatInput(e.target.value) !== null && (setData(Object.assign(Object.assign({}, data), { length: e.target.value }))); }, variant: "outlined", size: "small", modal: true, style: { marginRight: '8px' }, hwl: true }),
                                React.createElement(NumberInput, { decimalSeparator: ',', thousandSeparator: '.', decimalScale: 2, format: (value) => FormatNumber.rightToLeftFormatter(value, 2), customInput: ControlledInput, toolTipTitle: I18n.t('components.itemModal.requiredField'), value: data.width != null ? data.width : '', onChange: e => { validateFloatInput(e.target.value) !== null && (setData(Object.assign(Object.assign({}, data), { width: e.target.value }))); }, invalid: invalidInput && !(marineFCL()) &&
                                        (data.width === null || data.width.length === 0), variant: "outlined", size: "small", modal: true, style: { marginRight: '8px' }, hwl: true }),
                                React.createElement(NumberInput, { decimalSeparator: ',', thousandSeparator: '.', decimalScale: 2, format: (value) => FormatNumber.rightToLeftFormatter(value, 2), customInput: ControlledInput, toolTipTitle: I18n.t('components.itemModal.requiredField'), value: data.height != null ? data.height : '', onChange: e => { validateFloatInput(e.target.value) !== null && (setData(Object.assign(Object.assign({}, data), { height: e.target.value }))); }, invalid: invalidInput && !(marineFCL()) &&
                                        (data.height === null || data.height.length === 0), variant: "outlined", size: "small", modal: true, hwl: true }))),
                        !(marineFCL()) && React.createElement(Grid, { item: true, xs: 3 },
                            React.createElement(FormLabel, { component: "legend", error: invalidInput && !(marineFCL()) &&
                                    (data.cubage === null || data.cubage.length === 0 || data.cubage === '0,00') },
                                I18n.t('components.itemModal.cubage'),
                                (modal === 'AIR' ||
                                    (modal === 'SEA' && specifications !== 'fcl') ||
                                    modal === 'LAND') && React.createElement(RedColorSpan, null, " *")),
                            React.createElement(NumberInput, { decimalSeparator: ',', thousandSeparator: '.', decimalScale: 2, format: (value) => FormatNumber.rightToLeftFormatter(value, 2), customInput: ControlledInput, toolTipTitle: I18n.t('components.itemModal.requiredField'), invalid: invalidInput && !(marineFCL()) &&
                                    (data.cubage === null || data.cubage.length === 0 || data.cubage === '0,00'), value: data.cubage != null ? data.cubage : '', onChange: e => { validateFloatInput(e.target.value) !== null && (setData(Object.assign(Object.assign({}, data), { cubage: e.target.value }))); }, variant: "outlined", size: "small", modal: true })),
                        React.createElement(Grid, { item: true, xs: 3 },
                            React.createElement(FormLabel, { component: "legend" }, I18n.t('components.itemModal.diameter')),
                            React.createElement(NumberInput, { decimalSeparator: ',', thousandSeparator: '.', decimalScale: 2, format: (value) => FormatNumber.rightToLeftFormatter(value, 2), customInput: ControlledInput, toolTipTitle: I18n.t('components.itemModal.requiredField'), invalid: false, value: data.diameter != null ? data.diameter : '', onChange: e => { validateFloatInput(e.target.value) !== null && (setData(Object.assign(Object.assign({}, data), { diameter: e.target.value }))); }, variant: "outlined", size: "small", modal: true }))),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(ButtonDiv, null,
                            React.createElement(Button, { disabled: false, text: I18n.t('components.itemModal.save'), tooltip: I18n.t('components.itemModal.save'), backgroundGreen: true, icon: "", onAction: handleOnAdd }))))))));
};
export default ItemModal;
