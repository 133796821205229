import { RemoteLoadOrganizationList } from "@/data/usecase";
import { LoadOrganizationList } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteLoadOrganizationList = (): LoadOrganizationList =>
  new RemoteLoadOrganizationList(
    makeApiUrl("/sirius-business-partner-api/v1/organization"),
    makeAuthorizeHttpClientDecorator()
  );
