import React, { useState } from "react";
import { UpdateOrganization as DomainUpdateOrganization, LoadOrganization } from "@/domain/usecase";
import { Button, Modal, Messages, IconButton, ProgressIndicator, Switch } from "fiorde-fe-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import {
  PREFIX_ORGANIZATION_DISABLEORGANIZATION_BUTTON_CLOSE,
  PREFIX_ORGANIZATION_DISABLEORGANIZATION_BUTTON_SUBMIT,
} from "@/ids";

type DisableOrganizationProps = {
  updateOrganization: DomainUpdateOrganization
  loadOrganization: LoadOrganization
  id: number
  checked: boolean
}

export function DisableOrganization({ updateOrganization, loadOrganization, id, checked }: DisableOrganizationProps) {
  const queryClient = useQueryClient()
  const [open, setOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: "",
  });

  const { data } = useQuery<any>({
    queryKey: [`organization-${id}`],
    queryFn: async () => await loadOrganization.load(id),
    enabled: open
  })

  function handleSubmit (): void {
    setLoading(true)
    updateOrganization.update(id, {
      ...data,
      isActive: !checked
    })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["organization-list"] });
        handleClose();
        setResponseMessage({
          message: checked
            ? t("components.disable-organization.response-success-message.active")
            : t("components.disable-organization.response-success-message.disabled"),
          severity: "success"
        })
      })
      .catch((err) => {
        console.log(err);
        setResponseMessage({
          message: err?.message,
          severity: "error"
        })
      })
      .finally((() => {
        setLoading(false)
      }))
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false)
    queryClient.invalidateQueries({ queryKey: [`organization-${id}`] })
  }

  return (
    <>
      <ProgressIndicator
        visible={isLoading}
        content={t("components.progress-indicator.content")}
      />
      <IconButton
        data-testid="open-button"
        onClick={handleOpen}
        tooltip={checked
          ? t("components.disable-organization.open-button-tooltip.active")
          : t("components.disable-organization.open-button-tooltip.disabled")
        }
      >
        <Switch checked={checked} />
      </IconButton>
      <Modal
        width={600}
        title={checked
          ? t("components.disable-organization.title.active")
          : t("components.disable-organization.title.disabled")
        }
        isOpen={open}
        onClose={handleClose}
        actions={[
          <Button
            id={PREFIX_ORGANIZATION_DISABLEORGANIZATION_BUTTON_CLOSE}
            data-testid="close-button"
            backgroundGreen={false}
            onAction={() => handleClose()}
            text={t("components.disable-organization.close-button")}
          />,
          <Button
            id={PREFIX_ORGANIZATION_DISABLEORGANIZATION_BUTTON_SUBMIT}
            data-testid='submit-button'
            onAction={() => handleSubmit()}
            text={t("components.disable-organization.submit-button")}
          />
        ]}
      >
        <span>
          {checked
            ? t("components.disable-organization.content.active")
            : t("components.disable-organization.content.disabled")
          } {data?.name}?
        </span>
      </Modal>
      {responseMessage.message && (
        <Messages
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={() =>
            setResponseMessage({
              message: "",
              severity: "",
            })
          }
        />
      )}
    </>
  );
}
