import { AccessDeniedError, UnexpectedError } from "@/domain/errors";
import { HttpClient, HttpStatusCode } from "@/domain/protocols/http";
import { LoadChecklist } from "@/domain/usecase";
import { RemoteLoadChecklistDTO } from "../models";

export class RemoteLoadChecklist implements LoadChecklist {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<LoadChecklist.Model>
  ) {}

  async load(id: number): Promise<LoadChecklist.Model> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${id}`,
      method: "get",
    });
    const LoadChecklist = httpResponse.body;
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return LoadChecklist;
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }
}

export namespace RemoteLoadChecklist {
  export type Model = RemoteLoadChecklistDTO;
}
