import { FormControl, Input, Select } from 'fiorde-fe-components'
import { t } from 'i18next'
import { FormContent, PermanenceFormControl, SubTitle, Title } from './step.style'
import { makeSelectValues } from '@/presentation/utils'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { PREFIX_REGISTER_GENERALFORM_FORMCONTROL_INPUT_DESCRIPTION, PREFIX_REGISTER_GENERALFORM_FORMCONTROL_INPUT_NAME, PREFIX_REGISTER_GENERALFORM_FORMCONTROL_SELECT_TYPE, PREFIX_REGISTER_GENERALFORM_INPUT_DESCRIPTION, PREFIX_REGISTER_GENERALFORM_INPUT_NAME, PREFIX_REGISTER_GENERALFORM_INPUT_PERMANENCE } from '@/ids'

export function GeneralForm ({ values, onChange, loadStepTypeList, addPermanenceType }) {
  const [permanenceType, setPermanenceType] = useState(null);
  const { data: stepTypeList } = useQuery<any>({
    queryKey: ["step-status"],
    queryFn: async () => await loadStepTypeList.load()
  });
  function makePermanenceState (value) {
    let cleaned = value.replace(/\D/g, '');

    const maxValues = {
      minutos: 59,
      horas: 23
    };

    if (cleaned.length > 2) {
      cleaned = cleaned.slice(1,3)
    }

    if (permanenceType in maxValues && cleaned) {
      const maxValue = maxValues[permanenceType];
      cleaned = Math.min(parseInt(cleaned, 10), maxValue).toString();
    }

    cleaned = cleaned.padStart(2, '0').slice(0, 2);

    return cleaned || '00';
  }

  return (
    <>
      <FormControl
        id={PREFIX_REGISTER_GENERALFORM_FORMCONTROL_INPUT_NAME}
        label={t("components.step-form.input-label-name")}>
        <Input
          id={PREFIX_REGISTER_GENERALFORM_INPUT_NAME}
          inputProps={{ "data-testid": "name" }}
          onChange={value => onChange({...values, name: value})}
          value={values?.name}
          placeholder={t("components.step-form.input-placeholder-name")}
        />
      </FormControl>
      <FormControl 
        label={t("components.step-form.input-label-description")}
        id={PREFIX_REGISTER_GENERALFORM_FORMCONTROL_INPUT_DESCRIPTION}>
        <Input
          id={PREFIX_REGISTER_GENERALFORM_INPUT_DESCRIPTION}
          inputProps={{ "data-testid": "description" }}
          onChange={value => onChange({...values, description: value})}
          value={values?.description}
          placeholder={t("components.step-form.input-placeholder-description")}
          multiline
          rows={3}
        />
      </FormControl>
      <FormControl 
        label={t("components.step-form.step-type-label")}
        id={PREFIX_REGISTER_GENERALFORM_FORMCONTROL_SELECT_TYPE}>
        <Select
          name="StepType"
          options={makeSelectValues({
            label: "name",
            value: "id",
            array: stepTypeList,
          })}
          onChange={(value) => onChange({...values, stepType: { id: value }})}
          value={values?.stepType?.id}
          placeholder={t("components.step-form.step-type-placeholder")}
        />
      </FormControl>
      <PermanenceFormControl>
        <Title>{t("components.step-form.permanence-title")}</Title>
        <SubTitle>{t("components.step-form.permanence-subtitle")}</SubTitle>
        <FormContent>
          <Input
            id={PREFIX_REGISTER_GENERALFORM_INPUT_PERMANENCE}
            inputProps={{ "data-testid": "permanence" }}
            onChange={value => onChange({...values, permanence: makePermanenceState(value)})}
            value={values?.permanence}
            placeholder={t("components.step-form.input-placeholder-permanence")}
          />
          <Select
            options={makeSelectValues({
              label: "name",
              value: "name",
              array: [{name: 'minutos'}, {name: 'horas'}],
            })}
            onChange={(value) => {
              setPermanenceType(value)
              addPermanenceType(value)
              onChange({...values, permanence: ''})
            }}
            value={permanenceType}
            placeholder={t("components.step-form.select-placeholder-permanence")}
          />
        </FormContent>
      </PermanenceFormControl>
    </>
  )
}