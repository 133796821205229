import { Form } from "fiorde-fe-components";
import styled from "styled-components";

export const RootContainer = styled.div`
  width: 100%;
  height: auto;
`

export const ChildrenContainer = styled.div<any>`
  padding: 1rem 1rem 1rem 1rem;
  width: 100%;
  min-height: calc(100vh - 64px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: padding-left 0.2s;
  transition-timing-function: linear;
  z-index: 0;
  background: #e5e5e5;
`;

export const PageContainer = styled.div`
  background: white;
  padding: 24px;
  gap: 24px;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px #0000000D;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const Header = styled.span`
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #222222;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const Title = styled.span`
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #222222;
`

export const Label = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #545454;
`

export const Row = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

export const Divider = styled.div`
  width: 100%;
  border-width: 1px;
  border: solid 1px #E3E5EB;
`
