import { PREFIX_REGISTER_ADDFLOWREMOVESTEP_BUTTON_CLOSE, PREFIX_REGISTER_ADDFLOWREMOVESTEP_BUTTON_SUBMIT, PREFIX_REGISTER_ADDFLOWREMOVESTEP_LABEL_MESSAGE12, PREFIX_REGISTER_ADDFLOWREMOVESTEP_LABEL_STEPNAME, PREFIX_REGISTER_ADDFLOWREMOVESTEP_TITLE } from '@/ids';
import { useQuery } from '@tanstack/react-query';
import { Button, Modal } from 'fiorde-fe-components'
import { t } from 'i18next';
import React, { useState } from 'react'

export function FlowDetailRemoveStep({ id, isVisible, onClose, onSubmit, loadStep }) {
  const { data: step } = useQuery({
    queryKey: [`step-${id}`],
    queryFn: async () => loadStep.load(id),
    enabled: isVisible
  });

  function handleClose() {
    onClose()
  }

  function handleSubmit() {
    onSubmit(step?.id)  
    onClose()
  }

  return (
    <Modal
      id={PREFIX_REGISTER_ADDFLOWREMOVESTEP_TITLE}
      width={600}
      title={t("page.flow-add.remove-step.title")}
      isOpen={isVisible}
      onClose={handleClose}
      actions={[
        <Button
          id={PREFIX_REGISTER_ADDFLOWREMOVESTEP_BUTTON_CLOSE}
          data-testid="close-button"
          backgroundGreen={false}
          onAction={handleClose}
          text={t("page.flow-add.remove-step.close-button")}
        />,
        <Button
          id={PREFIX_REGISTER_ADDFLOWREMOVESTEP_BUTTON_SUBMIT}
          data-testid="submit-button"
          onAction={handleSubmit}
          text={t("page.flow-add.remove-step.submit-button")}
        />,
      ]}
    >
      <span id={PREFIX_REGISTER_ADDFLOWREMOVESTEP_LABEL_MESSAGE12}>{t("page.flow-add.remove-step.message-1")} <strong id={PREFIX_REGISTER_ADDFLOWREMOVESTEP_LABEL_STEPNAME}>{step?.name}</strong>? {t("page.flow-add.remove-step.message-2")}</span>
    </Modal>
  )
}