import React, { useState } from "react";
import {
  AddVehicleComposition as DomainAddVehicleComposition,
  LoadVehicleTypeList,
  LoadBodyworkList
} from "@/domain/usecase";
import { Button, Modal, FormControl, Input, Messages, ProgressIndicator, Select, Table, LabelCell, IconButton } from "fiorde-fe-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { makeSelectValues } from "@/presentation/utils";
import { EditIcon, TrashIcon } from "../icons";
import { validate } from "webpack";
import {
  PREFIX_REGISTER_ADDVEHICLECOMPOSITION_LABELCELL_VEHICLETYPE,
  PREFIX_REGISTER_ADDVEHICLECOMPOSITION_LABELCELL_BODYWORK,
  PREFIX_REGISTER_ADDVEHICLECOMPOSITION_ICONBUTTON_EDITTOOLTIP,
  PREFIX_REGISTER_ADDVEHICLECOMPOSITION_ICONBUTTON_REMOVETOOLTIP,
  PREFIX_REGISTER_ADDVEHICLECOMPOSITION_BUTTON_OPEN,
  PREFIX_REGISTER_ADDVEHICLECOMPOSITION_MODAL,
  PREFIX_REGISTER_ADDVEHICLECOMPOSITION_BUTTON_CLOSE,
  PREFIX_REGISTER_ADDVEHICLECOMPOSITION_BUTTON_SUBMIT,
  PREFIX_REGISTER_ADDVEHICLECOMPOSITION_FORMCONTROL_INPUTNAME,
  PREFIX_REGISTER_ADDVEHICLECOMPOSITION_INPUT_NAME,
  PREFIX_REGISTER_ADDVEHICLECOMPOSITION_FORMCONTROL_SELECTVEHICLETYPE,
  PREFIX_REGISTER_ADDVEHICLECOMPOSITION_FORMCONTROL_SELECTBODYWORK,
  PREFIX_REGISTER_ADDVEHICLECOMPOSITION_FORMBUTTON_CLOSE,
  PREFIX_REGISTER_ADDVEHICLECOMPOSITION_FORMBUTTON_SUBMITUPDATE,
  PREFIX_REGISTER_ADDVEHICLECOMPOSITION_BUTTON_ADDCOMPOSITION,
} from "@/ids";

type AddVehicleCompositionProps = {
  addVehicleComposition: DomainAddVehicleComposition
  loadVehicleTypeList: LoadVehicleTypeList
  loadBodyworkList: LoadBodyworkList
}

const columns = (
  edit,
  remove,
  vehicleTypeList,
  bodyworkList
) => [
  {
    label: t("components.add-vehicle-composition.columns.vehicle-type"),
    key: "vehicleTypeId",
    render: ({ vehicleTypeId }: any) =>
      <LabelCell id={PREFIX_REGISTER_ADDVEHICLECOMPOSITION_LABELCELL_VEHICLETYPE}>
        {vehicleTypeList.find(v => v.idVehicleType === vehicleTypeId).txVehicleType}
      </LabelCell>,
    size: 4,
  },
  {
    label: t("components.add-vehicle-composition.columns.bodywork"),
    key: "bodyworkId",
    render: ({ bodyworkId }: any) => 
      <LabelCell id={PREFIX_REGISTER_ADDVEHICLECOMPOSITION_LABELCELL_BODYWORK}>
        {bodyworkList.find(b => b.id === bodyworkId).name}
      </LabelCell>,
    size: 4,
  },
  {
    label: t("components.add-vehicle-composition.columns.menu"),
    key: "menu",
    render: ({ vehicleTypeId, bodyworkId }, index) => (
      <div style={{ display: "flex", gap: 8 }}>
        <IconButton
          id={PREFIX_REGISTER_ADDVEHICLECOMPOSITION_ICONBUTTON_EDITTOOLTIP}
          data-testid="open-button"
          onClick={() => edit(vehicleTypeId, bodyworkId, index)}
          tooltip={t("components.add-vehicle-composition.columns.edit-tooltip")}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          id={PREFIX_REGISTER_ADDVEHICLECOMPOSITION_ICONBUTTON_REMOVETOOLTIP}
          data-testid="open-button"
          onClick={() => remove(index)}
          tooltip={t("components.add-vehicle-composition.columns.remove-tooltip")}
        >
          <TrashIcon />
        </IconButton>
      </div>
    ),
    size: 1,
  },
];

export function AddVehicleComposition({
  addVehicleComposition,
  loadVehicleTypeList,
  loadBodyworkList
}: AddVehicleCompositionProps) {
  const queryClient = useQueryClient()
  const [isLoading, setLoading] = useState(false)
  const [visibleForm, setVisibleForm] = useState(false)
  const [values, setValues] = useState({
    name: '',
    vehicleTypeBodyworks: []
  })
  const [compositionForm, setCompositionForm] = useState({
    index: null,
    vehicleTypeId: null,
    bodyworkId: null,
  })
  const [open, setOpen] = useState(false)
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: ""
  })
  const { data: vehicleTypeList } = useQuery<any>({
    queryKey: ['vehicle-type'],
    queryFn: async () => await loadVehicleTypeList.load(),
    enabled: open
  })
  const { data: bodyworkList } = useQuery<any>({
    queryKey: ['bodywork'],
    queryFn: async () => await loadBodyworkList.load(),
    enabled: open
  })

  function handleSubmit (): void {
    setLoading(true)
    addVehicleComposition.add({
      name: values.name,
      vehicleTypeBodyworks: values.vehicleTypeBodyworks,
      isActive: true
    })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["vehicle-composition-list"] })
        setResponseMessage({
          message: t("components.add-vehicle-composition.response-success-message"),
          severity: "success"
        })
        handleClose()
      })
      .catch(err => {
        setResponseMessage({
          message: err?.message,
          severity: "error"
        })
      })
      .finally((() => {
        setLoading(false)
      }))
  }

  function handleOpen() {
    setOpen(true)
  }

  function handleClose() {
    setValues({
      name: '',
      vehicleTypeBodyworks: []
    })
    setCompositionForm({
      index: null,
      vehicleTypeId: null,
      bodyworkId: null,
    })
    setOpen(false)
  }

  function handleOpenForm() {
    setVisibleForm(true)
  }

  function handleCloseForm() {
    setVisibleForm(false)
    setCompositionForm({
      index: null,
      vehicleTypeId: null,
      bodyworkId: null,
    })
  }

  function handleSubmitForm() {
    if (!compositionForm.vehicleTypeId) return
    if (!compositionForm.bodyworkId) return
    if (compositionForm.index !== null) {
      const getVehicleTypeBodyworks = values.vehicleTypeBodyworks
      getVehicleTypeBodyworks[compositionForm.index].vehicleTypeId = compositionForm.vehicleTypeId
      getVehicleTypeBodyworks[compositionForm.index].bodyworkId = compositionForm.bodyworkId
      setValues({
        ...values,
        vehicleTypeBodyworks: getVehicleTypeBodyworks
      })
    } else {
      const newVehicleTypeBodyworks = [
        ...values.vehicleTypeBodyworks,
        {
          vehicleTypeId: compositionForm.vehicleTypeId,
          bodyworkId: compositionForm.bodyworkId
        }
      ]
      setValues({
        ...values,
        vehicleTypeBodyworks: newVehicleTypeBodyworks
      })
    }
    handleCloseForm()
  }

  function editVehicleTypeBodywork(vehicleTypeId, bodyworkId,index) {
    setVisibleForm(true)
    setCompositionForm({index, vehicleTypeId, bodyworkId})
  }

  function removeVehicleTypeBodywork(index) {
    const filteredValues = values.vehicleTypeBodyworks.filter((_, i) => i !== index)
    setValues({ ...values, vehicleTypeBodyworks: filteredValues })
  }

  
  function disableSubmit() {
    if (visibleForm) return true
    if (values?.vehicleTypeBodyworks.length === 0) return true
    if (!values?.name) return true
  }

  function disableAddCondition() {
    if (visibleForm) return true
    if (values?.vehicleTypeBodyworks.length === 0) return true
  }

  return (
    <>
      <ProgressIndicator
        visible={isLoading}
        content={t("components.progress-indicator.content")}
      />
      <Button
        id={PREFIX_REGISTER_ADDVEHICLECOMPOSITION_BUTTON_OPEN}
        icon="add"
        data-testid="open-button"
        onAction={() => handleOpen()}
        text={t("components.add-vehicle-composition.open-button")}
      />
      <Modal
        id={PREFIX_REGISTER_ADDVEHICLECOMPOSITION_MODAL}
        width={600}
        title={t("components.add-vehicle-composition.title")}
        isOpen={open}
        onClose={handleClose}
        actions={[
          <Button
            id={PREFIX_REGISTER_ADDVEHICLECOMPOSITION_BUTTON_CLOSE}
            data-testid="close-button"
            backgroundGreen={false}
            onAction={() => handleClose()}
            text={t("components.add-vehicle-composition.close-button")}
          />,
          <Button
            id={PREFIX_REGISTER_ADDVEHICLECOMPOSITION_BUTTON_SUBMIT}
            data-testid="submit-button"
            onAction={() => handleSubmit()}
            text={t("components.add-vehicle-composition.submit-button")}
            disabled={disableSubmit()}
          />
        ]}
      >
        <FormControl 
          id={PREFIX_REGISTER_ADDVEHICLECOMPOSITION_FORMCONTROL_INPUTNAME}
          label={t("components.add-vehicle-composition.name-label")}>
          <Input
            inputProps={{ "data-testid": "name" }}
            id={PREFIX_REGISTER_ADDVEHICLECOMPOSITION_INPUT_NAME}
            onChange={value => setValues({...values, name: value})}
            value={values.name}
            placeholder={t("components.add-vehicle-composition.name-placeholder")}
          />
        </FormControl>
        <strong>Composições</strong>
        <div style={{margin: "24px 0px 24px 0px"}}>
        {(visibleForm || values.vehicleTypeBodyworks.length === 0)
          ? <>
              <FormControl 
                id={PREFIX_REGISTER_ADDVEHICLECOMPOSITION_FORMCONTROL_SELECTVEHICLETYPE}
                label={t("components.add-vehicle-composition.form.vehicle-type-label")}>
                <Select
                  name='vehicleType'
                  options={makeSelectValues({
                    label: 'txVehicleType',
                    value: 'idVehicleType',
                    array: vehicleTypeList || []
                  })}
                  onChange={(value) => setCompositionForm({...compositionForm, vehicleTypeId: value})}
                  value={compositionForm.vehicleTypeId}
                  placeholder={t("components.add-vehicle-composition.form.vehicle-type-placeholder")}
                />
              </FormControl>
              <FormControl 
                id={PREFIX_REGISTER_ADDVEHICLECOMPOSITION_FORMCONTROL_SELECTBODYWORK}
                label={t("components.add-vehicle-composition.form.bodywork-label")}>
                <Select
                  name='bodywork'
                  options={makeSelectValues({
                    label: 'name',
                    value: 'id',
                    array: bodyworkList || []
                  })}
                  onChange={(value) => setCompositionForm({...compositionForm, bodyworkId: value})}
                  value={compositionForm.bodyworkId}
                  placeholder={t("components.add-vehicle-composition.form.bodywork-placeholder")}
                />
              </FormControl>
              <div style={{ display: "flex", justifyContent: 'end', gap: 8}}>
                {values.vehicleTypeBodyworks.length > 0 && (
                  <Button
                    id={PREFIX_REGISTER_ADDVEHICLECOMPOSITION_FORMBUTTON_CLOSE}
                    data-testid="open-button"
                    onAction={handleCloseForm}
                    text={t("components.add-vehicle-composition.form.close-button")}
                    backgroundGreen={false}
                  />
                )}
                <Button
                  id={PREFIX_REGISTER_ADDVEHICLECOMPOSITION_FORMBUTTON_SUBMITUPDATE}
                  data-testid="open-button"
                  onAction={handleSubmitForm}
                  text={compositionForm.index !== null
                    ? t("components.add-vehicle-composition.form.update-button")
                    : t("components.add-vehicle-composition.form.submit-button")
                  }
                />
              </div>
            </>
          : <Table
              rows={values.vehicleTypeBodyworks || []}
              columns={columns(editVehicleTypeBodywork, removeVehicleTypeBodywork, vehicleTypeList, bodyworkList)}
              size='small'
            />
        }
        </div>
        <Button
          id={PREFIX_REGISTER_ADDVEHICLECOMPOSITION_BUTTON_ADDCOMPOSITION}
          data-testid="open-button"
          onAction={handleOpenForm}
          backgroundGreen={false}
          text={t("components.add-vehicle-composition.add-composition")}
          disabled={disableAddCondition()}
        />
      </Modal>
      {responseMessage.message && (
        <Messages
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={()=> setResponseMessage({
            message: "",
            severity: ""
          })}
        />
      )}
    </>
  );
}
