import { RemoteAddChecklist } from "@/data/usecase";
import { AddChecklist } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteAddChecklist = (): AddChecklist =>
  new RemoteAddChecklist(
    makeApiUrl("/sirius-checklist-api/v1/checklist"),
    makeAuthorizeHttpClientDecorator()
  );
