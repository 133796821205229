import { RemoteAddBusinessPartner, RemoteAddOrganization } from "@/data/usecase";
import { AddBusinessPartner } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteAddBusinessPartner = (): AddBusinessPartner =>
  new RemoteAddBusinessPartner(
    makeApiUrl("/sirius-business-partner-api/v1/business/partner/v2"),
    makeAuthorizeHttpClientDecorator()
  );
