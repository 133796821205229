import { useState } from "react";
import { AddChecklist as DomainAddChecklist } from "@/domain/usecase";
import {
  Button,
  Modal,
  FormControl,
  Input,
  Messages,
  Switch,
  Table,
  QuestionTree,
} from "fiorde-fe-components";
import { ChecklistConditionsColumns } from "./checklist-conditions-columns";
import { ChecklistSectionColumns } from "./checklist-section-columns";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { t } from "i18next";
import {
  createPayload,
} from "./checklist-helpers";
import { ChecklistConditionForm } from "./checklist-condition-form";
import { ChecklistSectionForm } from "./checklist-section-form";
import { ChecklistQuestionForm } from "./checklist-question-form";
import {
  LoadConditionList,
  LoadTypeFieldList,
  LoadSystemList
} from "@/domain/usecase";
import { OPERATOR } from "@/main/enums/checklist"
import { ChecklistGeneralForm } from "./checklist-general-form";
import { ChecklistConditionsContainer } from "./checklist-conditions-container";
import { ChecklistSectionsQuestionsContainer } from "./checklist-sections-questions-container";
import { CHECKLIST_ADDCHECKLIST_BUTTON_CANCEL, CHECKLIST_ADDCHECKLIST_BUTTON_OPEN, CHECKLIST_ADDCHECKLIST_BUTTON_SUBMIT, CHECKLIST_ADDCHECKLIST_LABEL_MESSAGE, CHECKLIST_ADDCHECKLIST_MODAL_TITLE } from "@/ids";

type AddChecklistProps = {
  addChecklist: DomainAddChecklist;
  loadOperatorList: any;
  loadConditionList: LoadConditionList;
  loadTypeFieldList: LoadTypeFieldList;
  loadSystemList: LoadSystemList;
};

export const initialQuestionForm = {
  index: null,
  name: "",
  questionType: null,
  description: "",
  isRequired: false,
  isConditional: false,
  answers: [],
  section: 0
};

export function AddChecklist({
  addChecklist,
  loadOperatorList,
  loadConditionList,
  loadTypeFieldList,
  loadSystemList
}: AddChecklistProps) {
  const queryClient = useQueryClient();
  const [questionForm, setQuestionForm] = useState(initialQuestionForm);
  const [sectionForm, setSectionForm] = useState({
    index: null,
    name: "",
  });
  const [generalForm, setGeneralForm] = useState({
    name: '',
    systems: []
  })
  const [enableConditions, setEnableConditions] = useState(false);
  const [sections, setSections] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [visibleForm, setVisibleForm] = useState(false);
  const [visibleSectionForm, setVisibleSectionForm] = useState(false);
  const [visibleQuestionForm, setVisibleQuestionForm] = useState(false);
  const [sectionEditable, setSectionEditable] = useState();
  const [open, setOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: "",
  });
  const [conditionForm, setConditionForm] = useState({
    index: null,
    conditionType: { id: null },
    operator: '',
  });

  const { data: conditionsList = [] } = useQuery({
    queryKey: ["condition-list"],
    queryFn: async () => await loadConditionList.load(),
    enabled: open,
  });

  const { data: systemList = [] } = useQuery({
    queryKey: ["system-list"],
    queryFn: async () => await loadSystemList.load(),
    enabled: open,
  });

  const { data: typeFieldList = [] } = useQuery({
    queryKey: ["type-field-list"],
    queryFn: async () => await loadTypeFieldList.load(),
    enabled: open,
  });

  const { data: operatorList = [] } = useQuery({
    queryKey: ["operator-list", conditionForm],
    queryFn: async () => await loadOperatorList(conditionsList.find((condition) =>  condition.id === conditionForm?.conditionType?.id)?.endpoint).load(),
    enabled: open && conditionsList.length > 0 && conditionForm.conditionType.id !== null,
  });

  function handleSubmit(): void {
    addChecklist
      .add(createPayload(questions, conditions, sections, generalForm))
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["checklist-list"] });
        handleClose();
        setResponseMessage({
          message: t("components.add-checklist.response-success-message"),
          severity: "success",
        });
      })
      .catch((err) => {
        setResponseMessage({
          message: err?.message,
          severity: "error",
        });
      });
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setGeneralForm({
      name: '',
      systems: []
    });
    setResponseMessage({
      message: "",
      severity: "",
    });
    setQuestionForm(initialQuestionForm)
    setSectionForm({
      index: null,
      name: "",
    })
    setEnableConditions(false)
    setSections([])
    setQuestions([])
    setConditions([])
    setVisibleForm(false)
    setVisibleSectionForm(false)
    setVisibleQuestionForm(false)
    setSectionEditable(undefined)
    setConditionForm({
      index: null,
      conditionType: { id: null },
      operator: '',
    })
    setOpen(false);
  }

  return (
    <>
      <Button
        id={CHECKLIST_ADDCHECKLIST_BUTTON_OPEN}
        icon="add"
        data-testid="open-button"
        onAction={() => handleOpen()}
        text={t("components.add-checklist.open-button")}
      />
      <Modal
        id={CHECKLIST_ADDCHECKLIST_MODAL_TITLE}
        data-testid="modal-add-checklist"
        width={600}
        title={t("components.add-checklist.title")}
        isOpen={open}
        onClose={handleClose}
        actions={[
          <Button
            id={CHECKLIST_ADDCHECKLIST_BUTTON_CANCEL}
            data-testid="close-button"
            backgroundGreen={false}
            onAction={() => handleClose()}
            text={t("components.add-checklist.close-button")}
          />,
          <Button
            id={CHECKLIST_ADDCHECKLIST_BUTTON_SUBMIT}
            data-testid="submit-button"
            onAction={() => handleSubmit()}
            text={t("components.add-checklist.submit-button")}
          />,
        ]}
      >
        <ChecklistGeneralForm
          values={generalForm}
          onChange={(value) => setGeneralForm(value)}
          systemList={systemList}
        />
        <ChecklistConditionsContainer
          enableConditions={enableConditions}
          setEnableConditions={setEnableConditions}
          visibleForm={visibleForm}
          conditions={conditions}
          setConditions={setConditions}
          conditionForm={conditionForm}
          setVisibleForm={setVisibleForm}
          setConditionForm={setConditionForm}
          conditionsList={conditionsList}
          operatorList={operatorList}
        />
        <ChecklistSectionsQuestionsContainer
          visibleSectionForm={visibleSectionForm}
          sectionForm={sectionForm}
          setSectionForm={setSectionForm}
          sections={sections}
          setSections={setSections}
          setVisibleSectionForm={setVisibleSectionForm}
          visibleQuestionForm={visibleQuestionForm}
          sectionEditable={sectionEditable}
          typeFieldList={typeFieldList}
          questionForm={questionForm}
          setQuestionForm={setQuestionForm}
          questions={questions}
          setQuestions={setQuestions}
          setVisibleQuestionForm={setVisibleQuestionForm}
          setSectionEditable={setSectionEditable}
        />
      </Modal>
      {responseMessage.message && (
        <Messages
          id={CHECKLIST_ADDCHECKLIST_LABEL_MESSAGE}
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={() =>
            setResponseMessage({
              message: "",
              severity: "",
            })
          }
        />
      )}
    </>
  );
}
