import { HttpClient, HttpStatusCode } from '@/domain/protocols/http'
import { AddBodywork } from '@/domain/usecase'
import { RemoteAddBodyworkDTO } from '@/data/models'
import { AccessDeniedError, UnexpectedError } from '@/domain/errors'

export class RemoteAddBodywork implements AddBodywork {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<AddBodywork.Model>
  ) {}

  async add (params: AddBodywork.Model): Promise<void> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.created: break;
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}

export namespace RemoteAddBodywork {
  export type Model = RemoteAddBodyworkDTO
}
