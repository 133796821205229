import { RemoteLoadDockStatusList } from "@/data/usecase";
import { LoadDockStatusList } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteLoadDockStatusList = (): LoadDockStatusList =>
  new RemoteLoadDockStatusList(
    makeApiUrl("/sirius-yard-control-api/v1/dock/status/all"),
    makeAuthorizeHttpClientDecorator()
  );
