import { RemoteLoadVehicleCompositionPegeable } from "@/data/usecase";
import { LoadVehicleCompositionPegeable } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteLoadVehicleCompositionPegeable = (): LoadVehicleCompositionPegeable =>
  new RemoteLoadVehicleCompositionPegeable(
    makeApiUrl("/sirius-yard-control-api/v1/vehicle/composition"),
    makeAuthorizeHttpClientDecorator()
  );
