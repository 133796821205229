import { FieldValidation } from '@/validation/protocols'
import { RequiredFieldError } from '@/validation/errors'

export class RequiredFieldValidation implements FieldValidation {
  constructor (readonly field: string) {}

  validate (input: object): Error {
    if (Array.isArray(input[this.field])) {
      return input[this.field].length > 0 ? null : new RequiredFieldError()
    }
    return input[this.field] ? null : new RequiredFieldError()
  }
}
