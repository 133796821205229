import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import { RouteWrapper } from "../route-wrapper/route-wrapper";
import { makeHome, makeChecklistList } from "@/main/factories/pages";

export function Router() {
  return (
    <HashRouter basename="/checklist">
      <Routes>
        <Route
          path="/"
          element={<RouteWrapper makeComponent={makeChecklistList} />}
        />
      </Routes>
    </HashRouter>
  );
}
