import instance from '../instance'

const getEmail = async (email: string): Promise<any> => {
  try {
    const res = await instance.get(`/sirius-connector-api/public/user/email/${String(email)}`)
    return res.data
  } catch (error) {
    console.error(error)
  }
}

const postUser = async (params: any): Promise<any> => {
  try {
    const res = await instance.post('/sirius-connector-api/public/user', params)
    return res.data
  } catch (error) {
    console.error(error)
  }
}

const API = {
  getEmail,
  postUser
}

export default API
