import { AccessDeniedError, UnexpectedError } from '@/domain/errors'
import { HttpClient, HttpStatusCode } from '@/domain/protocols/http'
import { LoadMovementType } from '@/domain/usecase'
import { RemoteLoadMovementTypeDTO } from '../models'

export class RemoteLoadMovementType implements LoadMovementType {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<LoadMovementType.Model>
  ) {}

  async load (id: number): Promise<LoadMovementType.Model> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${id}`,
      method: 'get'
    })
    const LoadMovementType = httpResponse.body
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return LoadMovementType
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      default:
        //@ts-ignore
        const err = httpResponse.body?.response?.error;
        throw new UnexpectedError(err);
    }
  }
}

export namespace RemoteLoadMovementType {
  export type Model = RemoteLoadMovementTypeDTO
}
