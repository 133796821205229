import styled from 'styled-components';
import { Table, TableCell, TableRow } from '@material-ui/core';
const FormatValue = styled.p `
    width: 200px;
`;
const StyledTable = styled(Table) `
  margin-top: 20px;
`;
const StyledTableCell = styled(TableCell) `
    width: ${(props) => props.width};
    color: ${(props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    return props.color
        ? `${String((_e = (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.steps) === null || _e === void 0 ? void 0 : _e.stepFare)}`
        : `${String((_j = (_h = (_g = (_f = props.theme) === null || _f === void 0 ? void 0 : _f.commercial) === null || _g === void 0 ? void 0 : _g.pages) === null || _h === void 0 ? void 0 : _h.newProposal) === null || _j === void 0 ? void 0 : _j.font)}`;
}};
`;
const DeleteIconDiv = styled.div `
  cursor: pointer;
  height: 36px;
  align-items: center;
  display: flex;
`;
const EditIconDiv = styled.div `
  margin-right: 20px;
  cursor: pointer;
  height: 36px;
  padding-left: 16px;
  align-items: center;
  display: flex;
`;
const StyledTableRow = styled(TableRow) `
    .MuiTableCell-root {
        border-bottom: ${(props) => props.noBorder ? 0 : '1px solid'};
        border-color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.itemModal) === null || _d === void 0 ? void 0 : _d.border; }};
        color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.itemModal) === null || _d === void 0 ? void 0 : _d.inputFontColor; }};
    }
`;
const TableHeader = styled.span `
    color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.itemModal) === null || _d === void 0 ? void 0 : _d.fontColor; }};
    font-size: 12px;
`;
const StyledRow = styled(TableRow) `
  justify-content: space-between !important;
  margin: 0 20px !important;
`;
const RowReverseDiv = styled.div `
  display: flex;
  flex-direction: row-reverse;
  justify-self: flex-end;
  width: 100%;
  margin-right: 35px;
`;
export { FormatValue, StyledRow, TableHeader, StyledTableRow, EditIconDiv, DeleteIconDiv, StyledTableCell, StyledTable, RowReverseDiv };
