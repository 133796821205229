import { useState } from "react";
import { Row } from "../row/row";
import {
  Button,
  FormControl,
  Input,
  Switch,
  Select,
  IconButton,
} from "fiorde-fe-components";
import { t } from "i18next";
import { makeSelectValues } from "@/presentation/utils";
import { initialQuestionForm } from "./add-checklist";
import { CHECKLIST_TYPE } from "@/main/enums/checklist"
import { TrashIcon } from "../icons";
import { RemoveMultipleChoice } from "../remove-checklist-multiple-choice/remove-checklist-multiple-choice";
import { CHECKLIST_QUESTIONFORM_BUTTON_ADDCHOICE, CHECKLIST_QUESTIONFORM_BUTTON_CLOSE, CHECKLIST_QUESTIONFORM_BUTTON_SUBMIT, CHECKLIST_QUESTIONFORM_INPUT_CHOICE, CHECKLIST_QUESTIONFORM_INPUT_CHOICE_LABEL, CHECKLIST_QUESTIONFORM_INPUT_DESCRIPTION, CHECKLIST_QUESTIONFORM_INPUT_DESCRIPTION_LABEL, CHECKLIST_QUESTIONFORM_INPUT_NAME, CHECKLIST_QUESTIONFORM_INPUT_NAME_LABEL, CHECKLIST_QUESTIONFORM_LINK_ADDCHOICE, CHECKLIST_QUESTIONFORM_LINK_NO, CHECKLIST_QUESTIONFORM_LINK_YES, CHECKLIST_QUESTIONFORM_SELECT_TYPE, CHECKLIST_QUESTIONFORM_SELECT_TYPE_LABEL, CHECKLIST_QUESTIONFORM_SWITCH_CONDITIONAL, CHECKLIST_QUESTIONFORM_SWITCH_CONDITIONAL_LABEL, CHECKLIST_QUESTIONFORM_SWITCH_MANDATORY, CHECKLIST_QUESTIONFORM_SWITCH_MANDATORY_LABEL } from "@/ids";

export function ChecklistQuestionForm({
  sectionEditable,
  values,
  typeFieldList,
  onChange,
  onSubmit,
  onClose,
  disabledCloseButton,
}) {
  const [answers, setAnswers] = useState((values.index !== null && values.answers) ? [...values.answers] : []);
  const [isConditional, setIsconditional] = useState(values.isConditional);
  const [number, setNumber] = useState('1')
  const [data, setData] = useState(values.index !== null ? { ...values, section: sectionEditable } : {...initialQuestionForm, section: sectionEditable})

  const multipleChoiceAnswer = [
    {
      answer: "",
      isThereSubQuestion: isConditional,
    },
    {
      answer: "",
      isThereSubQuestion: isConditional,
    },
  ];

  const yesNoAnswer = [
    {
      answer: t("components.add-checklist.yes-label"),
      isThereSubQuestion: isConditional,
    },
    {
      answer: t("components.add-checklist.no-label"),
      isThereSubQuestion: isConditional,
    },
  ];

  function changeType(type: number): void {
    if (type === CHECKLIST_TYPE.MULTIPLE_CHOICE) {
      setAnswers([...multipleChoiceAnswer]);
      setData({
        ...data,
        answers: [...multipleChoiceAnswer],
        questionType: { id: type },
      })
      // onChange({
      //   ...values,
      //   answers: [...multipleChoiceAnswer],
      //   questionType: { id: type },
      // });
    }
    if (type === CHECKLIST_TYPE.YES_NO) {
      setAnswers([...yesNoAnswer]);
      setData({
        ...data,
        answers: [...yesNoAnswer],
        questionType: { id: type },
      })
      // onChange({
      //   ...values,
      //   answers: [...yesNoAnswer],
      //   questionType: { id: type },
      // });
    } else {
      setData({
        ...data,
        questionType: { id: type },
      })
    }
  }

  const removeMultipleChoice = (index: number) => {
    const newAnswers = answers.filter((_answer, i) =>  i !== index)
    setAnswers([...newAnswers])
    setData({...data, answers: newAnswers})
  }

  const saveSubquestion = () => {
    const alternativesForm = values.answers;
    const subQuestionNumber = number.split('.')[1]
    alternativesForm[Number(subQuestionNumber)-1].subquestion = {...data};
    alternativesForm[Number(subQuestionNumber)-1].isThereSubQuestion = true;
    onChange({...values, answers: alternativesForm });
    setData(values);
    setNumber('1');
  }

  return (
    <div
      style={{ margin: "15px", backgroundColor: "#F8F8F8", padding: "10px" }}
    >
      <p>{t("components.add-checklist.question")} {number}</p>
      <Row>
        <FormControl
          label={t("components.add-checklist.question-text-label")}
          id={CHECKLIST_QUESTIONFORM_INPUT_NAME_LABEL}
        >
          <Input
            inputProps={{ "data-testid": "question-text" }}
            id={CHECKLIST_QUESTIONFORM_INPUT_NAME}
            onChange={(event: any) =>
              // onChange({ ...values, name: event.target.value })
              setData({...data, name: event.target.value })
            }
            value={data.name}
            placeholder={t(
              "components.add-checklist.question-text-placeholder"
            )}
          />
        </FormControl>
      </Row>
      <Row>
        <FormControl
          label={t("components.add-checklist.mandatory")}
          id={CHECKLIST_QUESTIONFORM_SWITCH_MANDATORY_LABEL}
        >
          <Switch
            id={CHECKLIST_QUESTIONFORM_SWITCH_MANDATORY}
            label={
              data.isRequired
                ? t("components.add-checklist.mandatory-answer")
                : t("components.add-checklist.non-mandatory-answer")
            }
            checked={data.isRequired}
            onChange={() =>
              // onChange({ ...values, isRequired: !values.isRequired })
              setData({...data, isRequired: !data.isRequired })
            }
          />
        </FormControl>
      </Row>
      <Row>
        <FormControl
          label={t("components.add-checklist.conditional-question-label")}
          id={CHECKLIST_QUESTIONFORM_SWITCH_CONDITIONAL_LABEL}
        >
          <Switch
            id={CHECKLIST_QUESTIONFORM_SWITCH_CONDITIONAL}
            label={
              data.isConditional
                ? t("components.add-checklist.next-question")
                : t("components.add-checklist.non-next-question")
            }
            checked={data.isConditional}
            onChange={() => {
              // onChange({ ...values, isConditional: !values.isConditional });
              setData({...data, isConditional: !data.isConditional })
              setIsconditional((prev: boolean) => !prev);
            }}
          />
        </FormControl>
      </Row>
      <Row gridTemplateColumns="1fr 2fr">
        <FormControl
          label={t("components.add-checklist.type-field-label")}
          id={CHECKLIST_QUESTIONFORM_SELECT_TYPE_LABEL}
          >
          <Select
            id={CHECKLIST_QUESTIONFORM_SELECT_TYPE}
            name="type"
            options={makeSelectValues({
              label: "name",
              value: "id",
              array: typeFieldList,
            })}
            onChange={(value) => {
              changeType(value);
            }}
            placeholder={t("components.add-checklist.select")}
            value={data?.questionType?.id}
          />
        </FormControl>
      </Row>
      <Row>
        <FormControl
          label={t("components.add-checklist.description-label")}
          id={CHECKLIST_QUESTIONFORM_INPUT_DESCRIPTION_LABEL}
        >
          <Input
            inputProps={{ "data-testid": "description" }}
            id={CHECKLIST_QUESTIONFORM_INPUT_DESCRIPTION}
            onChange={(event: any) =>
              // onChange({ ...values, description: event.target.value })
              setData({...data, description: event.target.value })
            }
            value={data.description}
            placeholder={t("components.add-checklist.description-placeholder")}
          />
        </FormControl>
      </Row>
      {data?.questionType?.id === CHECKLIST_TYPE.MULTIPLE_CHOICE && (
        <>
          {t("components.add-checklist.multiple-choice")}
          {answers.map((choice, index) => (
            <Row style={{ marginTop: "10px" }} key={index}>
              <FormControl
                label={`${t("components.add-checklist.multiple-choice")} ${
                  index + 1
                }`}
                id={CHECKLIST_QUESTIONFORM_INPUT_CHOICE_LABEL}
              >
                <>
                <div style={{display: 'grid', gridTemplateColumns: '1fr auto', gap: '8px'}}>
                  <Input
                    inputProps={{ "data-testid": "multiple-choice" }}
                    id={CHECKLIST_QUESTIONFORM_INPUT_CHOICE}
                    onChange={(event: any) => {
                      const choices = [...answers];
                      choices[index].answer = event.target.value;
                      // onChange({ ...values, answers: [...choices] });
                      setData({...data, answers: [...choices] })
                    }}
                    value={choice.answer}
                    placeholder=""
                  />
                  <RemoveMultipleChoice
                    removeMultipleChoice={removeMultipleChoice}
                    index={index}
                  />
                </div>
                {data.isConditional && (
                  <a
                    id={CHECKLIST_QUESTIONFORM_LINK_ADDCHOICE}
                    style={{
                      cursor: "pointer",
                      textAlign: "right",
                      color: "#43BFB5",
                    }}
                    onClick={() => {
                      const choices = [...answers];
                      choices[index].subquestion = initialQuestionForm;
                      onChange({ ...data, answers: [...choices] });
                      setData(initialQuestionForm)
                      setNumber(1 + '.' + (index + 1))
                    }}
                  >
                    {t("components.add-checklist.add-conditional-question")}
                  </a>
                )}
                </>
              </FormControl>
            </Row>
          ))}
          <Button
            backgroundGreen={false}
            id={CHECKLIST_QUESTIONFORM_BUTTON_ADDCHOICE}
            onAction={() => {
              const choices = [...data?.answers];
              choices.push("");
              setAnswers((prev) => [
                ...prev,
                {
                  answer: "",
                  isThereSubQuestion: false,
                },
              ]);
              onChange({ ...values, answers: [...choices] });
            }}
            disabled={false}
            text={t("components.add-checklist.add-multiple-choice")}
          />
        </>
      )}
      {data?.questionType?.id === CHECKLIST_TYPE.YES_NO && data.isConditional && (
        <>
          {t("components.add-checklist.yes-or-no")}
          <Row style={{ marginTop: "10px" }}>
            {t("components.add-checklist.yes-label")}
            <a
              id={CHECKLIST_QUESTIONFORM_LINK_YES}
              style={{
                cursor: "pointer",
                textAlign: "right",
                color: "#43BFB5",
              }}
              onClick={() => {
                const choices = [...answers];
                choices[0].subquestion = initialQuestionForm;
                onChange({ ...data, answers: [...choices] });
                setData(initialQuestionForm)
                setNumber(1 + '.' + 1)
              }}
            >
              {t("components.add-checklist.add-conditional-question")}
            </a>
          </Row>
          <Row style={{ marginTop: "10px", marginBottom: "10px" }}>
            {t("components.add-checklist.no-label")}

            <a
              id={CHECKLIST_QUESTIONFORM_LINK_NO}
              style={{
                cursor: "pointer",
                textAlign: "right",
                color: "#43BFB5",
              }}
              onClick={() => {
                const choices = [...answers];
                choices[1].subquestion = initialQuestionForm;
                onChange({ ...data, answers: [...choices] });
                setData(initialQuestionForm)
                setNumber(1 + '.' + 2)
              }}
            >
              {t("components.add-checklist.add-conditional-question")}
            </a>
          </Row>
        </>
      )}
      <Row gridTemplateColumns="1fr auto" justifyItems="end">
        <Button
          id={CHECKLIST_QUESTIONFORM_BUTTON_CLOSE}
          backgroundGreen={false}
          onAction={onClose}
          text="Fechar"
          disabled={disabledCloseButton}
        />
        <Button
          id={CHECKLIST_QUESTIONFORM_BUTTON_SUBMIT}
          onAction={() => number === '1' ? onSubmit(data) : saveSubquestion()}
          disabled={false}
          text={data.index !== null ? t("components.add-checklist.edit-question") : t("components.add-checklist.add-question") }
        />
      </Row>
    </div>
  );
}
