import React, { useState } from "react";
import { UpdateVehicleComposition as DomainUpdateVehicleComposition, LoadVehicleComposition } from "@/domain/usecase";
import {
  Button,
  Modal,
  Messages,
  IconButton,
  ProgressIndicator,
  Switch
} from "fiorde-fe-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import {
  PREFIX_REGISTER_DISABLEVEHICLECOMPOSITION_ICONBUTTON_TOOLTIPACTIVEDISABLED,
  PREFIX_REGISTER_DISABLEVEHICLECOMPOSITION_SWITCH,
  PREFIX_REGISTER_DISABLEVEHICLECOMPOSITION_MODAL,
  PREFIX_REGISTER_DISABLEVEHICLECOMPOSITION_BUTTON_CLOSE,
  PREFIX_REGISTER_DISABLEVEHICLECOMPOSITION_BUTTON_SUBMIT,
} from "@/ids";

type DisableVehicleCompositionProps = {
  updateVehicleComposition: DomainUpdateVehicleComposition;
  loadVehicleComposition: LoadVehicleComposition;
  id: number;
  checked: boolean
};

export function DisableVehicleComposition({ updateVehicleComposition, loadVehicleComposition, id, checked }: DisableVehicleCompositionProps) {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: "",
  });
  const { data } = useQuery<any>({
    queryKey: [`vehicle-composition-${id}`],
    queryFn: async () => await loadVehicleComposition.load(id),
    enabled: open,
  });

  function handleSubmit(): void {
    setLoading(true);
    updateVehicleComposition
      .update(id, {
        ...data,
        isActive: !checked,
      })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["vehicle-composition-list"] });
        handleClose();
        setResponseMessage({
          message: checked
            ? t("components.disable-vehicle-composition.response-success-message.active")
            : t("components.disable-vehicle-composition.response-success-message.disabled"),
          severity: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        setResponseMessage({
          message: err?.message,
          severity: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <ProgressIndicator
        visible={isLoading}
        content={t("components.progress-indicator.content")}
      />
      <IconButton
        id={PREFIX_REGISTER_DISABLEVEHICLECOMPOSITION_ICONBUTTON_TOOLTIPACTIVEDISABLED}
        data-testid="open-button"
        onClick={handleOpen}
        tooltip={checked
          ? t("components.disable-vehicle-composition.open-button-tooltip.active")
          : t("components.disable-vehicle-composition.open-button-tooltip.disabled")
        }
      >
        <Switch id={PREFIX_REGISTER_DISABLEVEHICLECOMPOSITION_SWITCH} checked={checked} />
      </IconButton>
      <Modal
        id={PREFIX_REGISTER_DISABLEVEHICLECOMPOSITION_MODAL}
        width={600}
        title={checked
          ? t("components.disable-vehicle-composition.title.active")
          : t("components.disable-vehicle-composition.title.disabled")
        }
        isOpen={open}
        onClose={handleClose}
        actions={[
          <Button
            id={PREFIX_REGISTER_DISABLEVEHICLECOMPOSITION_BUTTON_CLOSE}
            data-testid="close-button"
            backgroundGreen={false}
            onAction={() => handleClose()}
            text={t("components.disable-vehicle-composition.close-button")}
          />,
          <Button
            id={PREFIX_REGISTER_DISABLEVEHICLECOMPOSITION_BUTTON_SUBMIT}
            data-testid="submit-button"
            onAction={() => handleSubmit()}
            text={t("components.disable-vehicle-composition.submit-button")}
          />,
        ]}
      >
        <span>
          {checked
            ? t("components.disable-vehicle-composition.content.active")
            : t("components.disable-vehicle-composition.content.disabled")
          } {data?.name}?
        </span>
      </Modal>
      {responseMessage.message && (
        <Messages
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={() =>
            setResponseMessage({
              message: "",
              severity: "",
            })
          }
        />
      )}
    </>
  );
}
