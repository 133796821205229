import React, { CSSProperties } from "react";
import { RowStyled } from "./row.style";

type RowProps = {
  children?: React.ReactNode
  gridTemplateColumns?: string
  gap?: string
  style?: CSSProperties
  justifyItems?: string
}

export function Row ({ children, ...props }: RowProps) {
  return (
    <RowStyled {...props}>{children}</RowStyled>
  )
}