import { Paper, FormLabel } from '@material-ui/core';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
const OriginDestLabel = styled.span `
  font-weight: ${(props) => props.isLand ? 'bold' : 400};
`;
const ButtonWrapper = styled.div `
  margin-top: 15px;
`;
const HeightDiv = styled.div `
  min-height: 650px; // Para que o stepper marque o Step 6 corretamente
`;
const NumberInput = styled(NumberFormat) `
`;
const StyledPaper = styled(Paper) `
    border: 1px solid ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.proposal) === null || _d === void 0 ? void 0 : _d.dropdownBorderColor; }} !important;
    background-color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.proposal) === null || _d === void 0 ? void 0 : _d.dropdownBackgroundColor; }} !important;
    color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.proposal) === null || _d === void 0 ? void 0 : _d.dropdownFontColor; }} !important;
    box-sizing: border-box;
`;
const ErrorText = styled.span `
  color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.costTable) === null || _d === void 0 ? void 0 : _d.errorText; }};
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0.02em;
  line-height: 150%;
`;
const LineSeparator = styled.div `
  height: 20px;
`;
const Title = styled.div `
  color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.title; }};
  font-size: 16px;
  font-weight: bold;
  line-height: 150%;
  letter-spacing: 0.02em;
  border-bottom: 1px solid ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.border; }};
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Subtitle = styled.div `
  color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.subtitle; }};
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.02em;
  font-weight: normal;
`;
const Separator = styled.div `
  margin-bottom: 100px;
  .form-size {
    width: 100%;
  }
  .form-size-half {
    width: 50%;
  }
  .radio-spacement {
    margin-left: 30px;
  }
  .checkbox-div-spacement {
    margin-top: 54px;
  }

  .MuiChip-root {
        background-color: ${(props) => { var _a, _b, _c, _d, _e, _f; return (_f = (_e = (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.steps) === null || _e === void 0 ? void 0 : _e.chips) === null || _f === void 0 ? void 0 : _f.background; }};
        color: ${(props) => { var _a, _b, _c, _d, _e, _f; return (_f = (_e = (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.steps) === null || _e === void 0 ? void 0 : _e.chips) === null || _f === void 0 ? void 0 : _f.color; }};
        svg path {
            fill: ${(props) => { var _a, _b, _c, _d, _e, _f; return (_f = (_e = (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.steps) === null || _e === void 0 ? void 0 : _e.chips) === null || _f === void 0 ? void 0 : _f.deleteBackground; }};
        }
    }

  .disabledDay {
    background: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.weekComponent; }};
    color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.subtitle; }};
  }

  .activeDay {
    background: #50E5D9;
    color: #545454;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button {
    opacity: 1;
  }

  .dropdown {
    margin-top: 12px;
    position: absolute;
    top: 4px;
    z-index: 10;
    left: 78px;
    background: green;
  }

  .MuiInputBase-root.Mui-disabled {
    background: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.disabledBackground; }};
    color: #999DAC;
  }

  .MuiTextField-root {
    border-radius: 4px;
  }
`;
const SelectSpan = styled.span `
  color: ${(props) => { var _a, _b, _c, _d; return (props === null || props === void 0 ? void 0 : props.placeholder) && ((_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.placeholder); }};
  margin: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis !important;
`;
const TextCellHead = styled.p `
font-family: DM Sans;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 150%;
letter-spacing: 0.28px;
`;
const TextCell = styled.p `
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`;
const TextInnerGreyCell = styled.p `
  color: ${(props) => { var _a, _b, _c, _d; return (props === null || props === void 0 ? void 0 : props.invalid) && ((_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.costTable) === null || _d === void 0 ? void 0 : _d.errorText); }};
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  text-align: right;
  margin: 10px 50px 0px 0px;  
`;
const TextInnerCell = styled.p `
  color: ${(props) => { var _a, _b, _c, _d; return (props === null || props === void 0 ? void 0 : props.invalid) && ((_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.costTable) === null || _d === void 0 ? void 0 : _d.errorText); }};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: right;
  margin-right: 50px;
`;
const InputContainer = styled.div `
  display: flex;
  align-items: center;
`;
const DateRange = styled.div `
  & .MuiInputBase-root {
    border-style: ${(props) => (props === null || props === void 0 ? void 0 : props.invalid) && 'solid'};
    border-color: ${(props) => { var _a, _b, _c, _d; return (props === null || props === void 0 ? void 0 : props.invalid) && ((_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.costTable) === null || _d === void 0 ? void 0 : _d.errorText); }};
    border-width: ${(props) => (props === null || props === void 0 ? void 0 : props.invalid) && '1px'};
    margin-top: -8px;
  }
`;
const FormLabelHeader = styled(FormLabel) `
  color: #545454!important;
  font-weight: 400!important;
  letter: 2%!important;
  font-size: 14px!important;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  
`;
const StepMainDiv = styled.div `
  margin-top: 25px;
`;
export { OriginDestLabel, ButtonWrapper, NumberInput, HeightDiv, StyledPaper, ErrorText, LineSeparator, Title, Subtitle, Separator, SelectSpan, TextCellHead, TextCell, InputContainer, TextInnerCell, TextInnerGreyCell, DateRange, FormLabelHeader, StepMainDiv };
