import { RemoteLoadBusinessPartnerList } from "@/data/usecase";
import { LoadBusinessPartnerList } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteLoadBusinessPartnerList = (): LoadBusinessPartnerList =>
  new RemoteLoadBusinessPartnerList(
    makeApiUrl("/sirius-business-partner-api/v1/business/partner/page-filter"),
    makeAuthorizeHttpClientDecorator()
  );
