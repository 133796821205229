import React from "react";
import {
  PageHeaderContainer,
  PageHeaderTitle,
  PageHeaderChildren,
} from "./page-header.styles";

type PageHeaderProps = {
  title?: string;
  children?: any;
};

export function PageHeader({ title, children }: PageHeaderProps): JSX.Element {
  return (
    <PageHeaderContainer>
      <PageHeaderTitle>{title}</PageHeaderTitle>
      <PageHeaderChildren>{children}</PageHeaderChildren>
    </PageHeaderContainer>
  );
}
