import { AccessDeniedError, UnexpectedError } from '@/domain/errors'
import { HttpClient, HttpStatusCode } from '@/domain/protocols/http'
import { Load } from '@/domain/usecase'

export class RemoteLoad<T> implements Load<T> {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<T>
  ) {}

  async load (): Promise<T> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'get'
    })
    const Load = httpResponse.body
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return Load
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      default:
        //@ts-ignore
        const err = httpResponse.body?.response?.error;
        throw new UnexpectedError(err);
    }
  }
}
