import { RemoteLoadTypeFieldList } from "@/data/usecase";
import { LoadTypeFieldList } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteLoadTypeFieldList = (): LoadTypeFieldList =>
  new RemoteLoadTypeFieldList(
    makeApiUrl("/sirius-checklist-api/v1/question/type/all"),
    makeAuthorizeHttpClientDecorator()
  );
