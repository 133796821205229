import React from 'react'
import { Router } from '@/presentation/components/router/router'
import i18next from 'i18next';
import en from './translations/en.json'
import pt from './translations/pt.json'
import './index.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { GlobalFont, light, StyledThemeProvider } from 'fiorde-fe-components';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false
    }
  }
})

i18next.init({
  lng: 'pt',
  resources: {
    en: {
      translation: en
    },
    pt: {
      translation: pt
    }
  }
});

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <StyledThemeProvider theme={light}>
        <GlobalFont />
        <Router />
      </StyledThemeProvider>
    </QueryClientProvider>
  )
}
