import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { LogoIcon } from 'fiorde-fe-components'
import { TextField } from '@material-ui/core'
import { I18n } from 'react-redux-i18n'
import { MainContainer, Container, InputContainer, ButtonContainer } from './style'
import ArrowButton from '../../components/ArrowButton'
import CustomButton from '../../components/CustomButton'
import API from '../../../infrastructure/api'

const SignUp = (): JSX.Element => {
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [showError, setShowError] = useState({ show: false, msg: '' })
  const search = useLocation().search
  const languageParam = new URLSearchParams(search).get('language')

  const validateEmail = (): boolean => {
    const regex = /\S+@\S+\.\S+/
    return regex.test(email)
  }

  const handleChange = (value): void => {
    setEmail(value)
    setShowError({ show: false, msg: '' })
  }

  const handleClick = (): void => {
    window.open('/', '_self')
  }

  const isEmailRegistered = async (): Promise<boolean> => {
    const userData = await API.getEmail(email)
    return userData.roles.length > 0 && (userData.roles !== 'SIRIUS_COMEX' || userData.roles !== 'SIRIUS_LN')
  }

  const handleVerify = async (): Promise<void> => {
    if (email !== '') {
      if (validateEmail()) {
        await isEmailRegistered() ? history.push({ pathname: '/register', state: { email: email } }) : history.push('/fail')
      } else {
        setShowError({ show: true, msg: I18n.t('pages.signup.errors.invalidEmail') })
      }
    } else {
      setShowError({ show: true, msg: I18n.t('pages.signup.errors.requiredEmail') })
    }
  }

  useEffect(() => {
    const lang = languageParam?.toLowerCase()
    if (lang !== localStorage.getItem('locale')) {
      localStorage.setItem('locale', lang ?? 'pt')
      location.reload()
    }
  }, [])

  return (
    <MainContainer id="mainContainer">
      <LogoIcon id="logoIcon" />
      <Container id="container">
        <p id="labelMessage">{I18n.t('pages.signup.enterEmail')}</p>
        <InputContainer>
          <TextField
            error={showError.show}
            helperText={showError.show && showError.msg}
            id="user-email"
            name="user"
            value={email}
            label="E-mail"
            margin="normal"
            onChange={(e) => handleChange(e.target.value)}
            size="small"
          />
        </InputContainer>
        <ButtonContainer>
          <ArrowButton id="cancelButton" onPress={handleClick} position='left'>
            {I18n.t('pages.signup.cancel')}
          </ArrowButton>
          <CustomButton id="verifyButton" onPress={handleVerify}>
            {I18n.t('pages.signup.verify')}
          </CustomButton>
        </ButtonContainer>
      </Container>
    </MainContainer>
  )
}

export default SignUp
