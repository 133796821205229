import React from "react";

export function CloseIcon() {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5625 3.57734L9.92266 2.9375L6.90625 5.95391L3.88984 2.9375L3.25 3.57734L6.26641 6.59375L3.25 9.61016L3.88984 10.25L6.90625 7.23359L9.92266 10.25L10.5625 9.61016L7.54609 6.59375L10.5625 3.57734Z"
        fill="#222222"
      />
    </svg>
  );
}
