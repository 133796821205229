var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useReducer, useState, useEffect, useContext } from 'react';
import { Modal, Box, Container, FormLabel } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '../../../application/icons/CloseIcon';
import { Button } from 'fiorde-fe-components';
import { CheckBox, CheckBoxLabel, Input, ReplyDiv, ReplyIconDiv, WarningPopUp, WarningPopUpMessage, WarningPopUpButtonDiv, MainDiv, CostModalContainer } from './CostModalStyles';
import { I18n } from 'react-redux-i18n';
import CheckIcon from '../../../application/icons/CheckItem';
import ReplyIcon from '../../../application/icons/ReplyIcon';
import ControlledToolTip from '../ControlledToolTip/ControlledToolTip';
import { ButtonDiv, CloseIconContainer, Form, HeaderDiv, ModalContainer, PlaceholderDiv, PlaceholderSpan, RedColorSpan, RowDiv, RowReverseDiv, Title } from '../StyledComponents/modalStyles';
import API from '../../../infrastructure/api';
import { CheckBoxArea } from '../ItemModal/ItemModalStyles';
import { StyledPaper, NumberInput } from '../../pages/NewProposal/steps/StepsStyles';
import FormatNumber from '../../../application/utils/formatNumber';
import { ProposalContext } from '../../pages/NewProposal/context/ProposalContext';
import { TARIFF_COST_MODAL_BUTTON_GOTITVALUE, TARIFF_COST_MODAL_BUTTON_GOTITWITHOUTVALUE, TARIFF_COST_MODAL_BUTTON_REPLY, TARIFF_COST_MODAL_BUTTON_SAVE, TARIFF_COST_MODAL_CHECKBOX_BUY, TARIFF_COST_MODAL_CHECKBOX_SALE, TARIFF_COST_MODAL_INPUT_BUY, TARIFF_COST_MODAL_INPUT_BUYMINIMUM, TARIFF_COST_MODAL_INPUT_SALE, TARIFF_COST_MODAL_INPUT_SALEMINIMUM, TARIFF_COST_MODAL_SELECT_AGENT, TARIFF_COST_MODAL_SELECT_BUYCURRENCY, TARIFF_COST_MODAL_SELECT_SALECURRENCY, TARIFF_COST_MODAL_SELECT_SELECTEDCONTAINER, TARIFF_COST_MODAL_SELECT_TYPE, TARIFF_COST_MODAL_SPAN_TITLE } from '../../../ids';
import { CostNameTypes, TooltipTypes, FareItemsTypes, CostTypes } from '../../../application/enum/costEnum';
import { ModalTypes, SpecificationsType, FreightTypes } from '../../../application/enum/enum';
import { useCalculationTypes } from '../../hooks/index';
import GetNamesByID from '../../../application/utils/getNamesByID';
import ControlledInput from '../ControlledInput';
export const initialState = {
    type: '',
    description: null,
    agent: {
        idBusinessPartnerAgent: undefined,
        idBusinessPartnerTransportCompany: undefined
    },
    buyCurrency: 'BRL',
    buyValue: null,
    buyMin: null,
    saleCurrency: 'BRL',
    saleValue: null,
    selectedContainer: null,
    saleMin: null,
    id: null,
    buyValueCalculated: null,
    saleValueCalculated: null,
    idCalculationType: null
};
const CostModal = ({ idSufix, agentList, dataProp, handleAdd, open, setClose, title, modal, specifications, containerItems, serviceList, calculationData, dataTotalCostOrigin, totalCostArray }) => {
    var _a, _b;
    const getAgentByName = (name) => {
        const agent = agentList.find(agent => agent.agent === name);
        return { idBusinessPartnerAgent: agent === null || agent === void 0 ? void 0 : agent.idBusinessPartnerAgent, idBusinessPartnerTransportCompany: agent === null || agent === void 0 ? void 0 : agent.idBusinessPartnerTransportCompany };
    };
    const reducer = (state, action) => {
        switch (action.type) {
            case 'type':
                return Object.assign(Object.assign({}, state), { type: action.value });
            case 'description':
                return Object.assign(Object.assign({}, state), { description: action.value });
            case 'agent':
                return Object.assign(Object.assign({}, state), { agent: getAgentByName(action.value) });
            case 'buyValue':
                return Object.assign(Object.assign({}, state), { buyValue: action.value });
            case 'buyCurrency':
                return Object.assign(Object.assign({}, state), { buyCurrency: action.value });
            case 'buyMin':
                return Object.assign(Object.assign({}, state), { buyMin: action.value });
            case 'saleValue':
                return Object.assign(Object.assign({}, state), { saleValue: action.value });
            case 'selectedContainer':
                return Object.assign(Object.assign({}, state), { selectedContainer: action.value });
            case 'saleCurrency':
                return Object.assign(Object.assign({}, state), { saleCurrency: action.value });
            case 'saleMin':
                return Object.assign(Object.assign({}, state), { saleMin: action.value });
            case 'buyValueCalculated':
                return Object.assign(Object.assign({}, state), { buyValueCalculated: action.value });
            case 'saleValueCalculated':
                return Object.assign(Object.assign({}, state), { saleValueCalculated: action.value });
            case 'replyForSale':
                return Object.assign(Object.assign({}, state), { saleMin: state.buyMin, saleValue: state.buyValue, saleCurrency: state.buyCurrency });
            case 'reset':
                return initialState;
            case 'dataProp':
                return dataProp !== null && dataProp !== undefined ? dataProp : initialState;
            case 'idCalculationType':
                return Object.assign(Object.assign({}, state), { idCalculationType: action.value });
            default:
                return state;
        }
    };
    const rgxFloat = /^[0-9]*,?[0-9]*$/;
    const [state, dispatch] = useReducer(reducer, dataProp !== null && dataProp !== undefined ? dataProp : initialState);
    const [typeList, setTypeList] = useState([]);
    const [buyCheckbox, setBuyCheckBox] = useState(state.buyValue != null);
    const [saleCheckbox, setSaleCheckBox] = useState(state.saleValue != null);
    const [invalidInput, setInvalidInput] = useState(false);
    const [invalidValueInput, setInvalidValueInput] = useState(false);
    const [noValueInput, setNoValueInput] = useState(false);
    const [currencyList, setCurrencyList] = useState([]);
    const [flag, setFlag] = useState(false);
    const { proposal } = useContext(ProposalContext);
    const { data: calculationTypes = [] } = useCalculationTypes();
    const verifyContainerItems = () => {
        if (containerItems.length === 1) {
            dispatch({ type: 'selectedContainer', value: containerItems[0].type });
        }
    };
    useEffect(() => {
        dispatch({ type: 'dataProp' });
        verifyContainerItems();
        setBuyCheckBox((dataProp === null || dataProp === void 0 ? void 0 : dataProp.buyValue) !== null && (dataProp === null || dataProp === void 0 ? void 0 : dataProp.buyValue.length) !== 0);
        setSaleCheckBox((dataProp === null || dataProp === void 0 ? void 0 : dataProp.saleValue) !== null && (dataProp === null || dataProp === void 0 ? void 0 : dataProp.saleValue.length) !== 0);
    }, [open]);
    useEffect(() => {
        let newTypeList = [];
        switch (true) {
            case modal === ModalTypes.Sea && specifications === SpecificationsType.Fcl && (proposal === null || proposal === void 0 ? void 0 : proposal.operationType) === FreightTypes.Import && title === I18n.t('pages.newProposal.step6.destinationCost'):
                newTypeList = calculationTypes.filter(item => item.txCalculationType === FareItemsTypes.Container || item.txCalculationType === FareItemsTypes.Bl || item.txCalculationType === FareItemsTypes.Fdesp)
                    .map(item => ({
                    name: item.txCalculationType,
                    value: item.idCalculationType,
                    tooltip: item.txCalculationType === FareItemsTypes.Fdesp ? TooltipTypes.Fdesp : undefined // Conditionally add tooltip
                }));
                break;
            case ((modal === ModalTypes.Sea && specifications === SpecificationsType.Lcl) || (modal === ModalTypes.Sea && specifications === SpecificationsType.BreakBulk) || (modal === ModalTypes.Sea && specifications === SpecificationsType.Roro)) && (proposal === null || proposal === void 0 ? void 0 : proposal.operationType) === FreightTypes.Import && title === I18n.t('pages.newProposal.step6.destinationCost'):
                newTypeList = calculationTypes.filter(item => item.txCalculationType === FareItemsTypes.Fixed || item.txCalculationType === FareItemsTypes.Ton || item.txCalculationType === FareItemsTypes.Fdesp)
                    .map(item => ({
                    name: item.txCalculationType,
                    value: item.idCalculationType,
                    tooltip: item.txCalculationType === FareItemsTypes.Fdesp ? TooltipTypes.Fdesp : undefined // Conditionally add tooltip
                }));
                break;
            case modal === ModalTypes.Air && (proposal === null || proposal === void 0 ? void 0 : proposal.operationType) === FreightTypes.Import && title === I18n.t('pages.newProposal.step6.destinationCost'):
                newTypeList = calculationTypes
                    .filter(item => item.txCalculationType === FareItemsTypes.Kilo || item.txCalculationType === FareItemsTypes.Fixed || item.txCalculationType === FareItemsTypes.Cw || item.txCalculationType === FareItemsTypes.Fdesp)
                    .map(item => ({
                    name: item.txCalculationType,
                    value: item.idCalculationType,
                    tooltip: item.txCalculationType === FareItemsTypes.Fdesp ? TooltipTypes.Fdesp : undefined // Conditionally add tooltip
                }));
                break;
            case modal === ModalTypes.Land && (proposal === null || proposal === void 0 ? void 0 : proposal.operationType) === FreightTypes.Import && title === I18n.t('pages.newProposal.step6.destinationCost'):
                newTypeList = calculationTypes
                    .filter(item => item.txCalculationType === FareItemsTypes.Fixed)
                    .map(item => ({
                    name: item.txCalculationType,
                    value: item.idCalculationType,
                    tooltip: item.txCalculationType === FareItemsTypes.Fdesp ? TooltipTypes.Fdesp : undefined // Conditionally add tooltip
                }));
                break;
            case modal === ModalTypes.Sea && specifications === SpecificationsType.Fcl:
                newTypeList = calculationTypes.filter(item => item.txCalculationType === FareItemsTypes.Container || item.txCalculationType === FareItemsTypes.Bl)
                    .map(item => ({ name: item.txCalculationType, value: item.idCalculationType }));
                break;
            case (modal === ModalTypes.Sea && specifications === SpecificationsType.Lcl) || (modal === ModalTypes.Sea && specifications === SpecificationsType.BreakBulk) || (modal === ModalTypes.Sea && specifications === SpecificationsType.Roro):
                newTypeList = calculationTypes.filter(item => item.txCalculationType === FareItemsTypes.Fixed || item.txCalculationType === FareItemsTypes.Ton)
                    .map(item => ({ name: item.txCalculationType, value: item.idCalculationType }));
                break;
            case modal === ModalTypes.Air:
                newTypeList = calculationTypes
                    .filter(item => item.txCalculationType === FareItemsTypes.Kilo || item.txCalculationType === FareItemsTypes.Fixed || item.txCalculationType === FareItemsTypes.Cw)
                    .map(item => ({ name: item.txCalculationType, value: item.idCalculationType }));
                break;
            case modal === ModalTypes.Land:
                newTypeList = calculationTypes
                    .filter(item => item.txCalculationType === FareItemsTypes.Fixed)
                    .map(item => ({ name: item.txCalculationType, value: item.idCalculationType }));
                break;
            default:
                newTypeList = [];
        }
        setTypeList(newTypeList);
    }, [modal, specifications, calculationTypes]);
    useEffect(() => {
        void (function () {
            return __awaiter(this, void 0, void 0, function* () {
                yield API.getCurrencies()
                    .then((response) => setCurrencyList(response))
                    .catch((err) => console.log(err));
            });
        })();
    }, []);
    const handleOnClose = () => {
        setFlag(false);
        dispatch({ type: 'reset' });
        setInvalidInput(false);
        setInvalidValueInput(false);
        setBuyCheckBox(false);
        setSaleCheckBox(false);
        setClose();
    };
    const handleClickWarningButton = () => {
        if (invalidValueInput)
            setInvalidValueInput(false);
        if (noValueInput)
            setNoValueInput(false);
    };
    const validateFloatInput = (value) => {
        return value.match(rgxFloat);
    };
    const buyCheckboxHandler = () => {
        setBuyCheckBox((buyCheckbox) => !buyCheckbox);
    };
    const saleCheckboxHandler = () => {
        setSaleCheckBox((saleCheckbox) => !saleCheckbox);
    };
    const replyForSaleHandler = () => {
        if (buyCheckbox) {
            setSaleCheckBox(true);
            dispatch({ type: 'replyForSale' });
        }
    };
    const buyValueHandler = (e) => {
        const validatedInput = validateFloatInput(e.target.value);
        if (validatedInput !== null) {
            dispatch({ type: 'buyValue', value: validatedInput[0] });
        }
    };
    const buyMinHandler = (e) => {
        const validatedInput = validateFloatInput(e.target.value);
        if (validatedInput !== null) {
            dispatch({ type: 'buyMin', value: validatedInput[0] });
        }
    };
    const saleValueHandler = (e) => {
        const validatedInput = validateFloatInput(e.target.value);
        if (validatedInput !== null) {
            dispatch({ type: 'saleValue', value: validatedInput[0] });
        }
    };
    const buyValueHandlerPercentage = (e) => {
        const valuePercentage = e.target.value;
        const value = valuePercentage.replace('%', '');
        const validatedInput = validateFloatInput(value);
        if (validatedInput !== null) {
            dispatch({ type: 'buyValue', value: validatedInput[0] });
        }
    };
    const saleValueHandlerPercentage = (e) => {
        const valuePercentage = e.target.value;
        const value = valuePercentage.replace('%', '');
        const validatedInput = validateFloatInput(value);
        if (validatedInput !== null) {
            dispatch({ type: 'saleValue', value: validatedInput[0] });
        }
    };
    const saleMinHandler = (e) => {
        const validatedInput = validateFloatInput(e.target.value);
        if (validatedInput !== null) {
            dispatch({ type: 'saleMin', value: validatedInput[0] });
        }
    };
    const addHandler = () => {
        var _a, _b;
        let invalid = false;
        let item = state;
        if (isOriginCost && !item.agent.idBusinessPartnerAgent) {
            invalid = true;
        }
        if (buyCheckbox) {
            if (item.buyValue === null || item.buyValue.length === 0) {
                invalid = true;
                setInvalidValueInput(true);
            }
        }
        else {
            item = Object.assign(Object.assign({}, item), { buyMin: '', buyCurrency: '', buyValue: '' });
            dispatch({ type: 'buyValue', value: '' });
            dispatch({ type: 'buyMin', value: '' });
            dispatch({ type: 'buyCurrency', value: '' });
        }
        if (saleCheckbox) {
            if (item.saleValue === null || item.saleValue.length === 0) {
                invalid = true;
                setInvalidValueInput(true);
            }
        }
        else {
            item = Object.assign(Object.assign({}, item), { saleMin: '', saleCurrency: '', saleValue: '' });
            dispatch({ type: 'saleValue', value: '' });
            dispatch({ type: 'saleMin', value: '' });
            dispatch({ type: 'saleCurrency', value: '' });
        }
        if ((item.idCalculationType === null || item.idCalculationType === undefined) ||
            (item.description === null || ((_a = item.description) === null || _a === void 0 ? void 0 : _a.length) === 0)) {
            invalid = true;
        }
        if (item.saleMin !== null) {
            dispatch({ type: 'saleMin', value: item.saleMin.replace(',', '.') });
            item.saleMin = item.saleMin.replace(',', '.');
        }
        if (item.saleValue !== null) {
            dispatch({ type: 'saleValue', value: item.saleValue.replace(',', '.') });
            item.saleValue = item.saleValue.replace(',', '.');
        }
        if (item.buyMin !== null) {
            dispatch({ type: 'buyMin', value: item.buyMin.replace(',', '.') });
            item.buyMin = item.buyMin.replace(',', '.');
        }
        if (item.buyValue !== null) {
            dispatch({ type: 'buyValue', value: item.buyValue.replace(',', '.') });
            item.buyValue = item.buyValue.replace(',', '.');
        }
        if (!buyCheckbox && !saleCheckbox) {
            setNoValueInput(true);
            invalid = true;
            item = Object.assign(Object.assign({}, item), { buyCurrency: 'BRL', saleCurrency: 'BRL' });
            dispatch({ type: 'buyCurrency', value: 'BRL' });
            dispatch({ type: 'saleCurrency', value: 'BRL' });
        }
        if (item.selectedContainer === null) {
            invalid = true;
        }
        if (!invalid) {
            const indexContainer = containerItems.findIndex(container => state.selectedContainer === container.type);
            const data = {
                costType: GetNamesByID.getTxCalculationTypeById(calculationTypes, item.idCalculationType),
                quantityContainer: specifications === 'fcl' ? Number((_b = containerItems[indexContainer]) === null || _b === void 0 ? void 0 : _b.amount) : 0,
                valueGrossWeight: isNaN(Number(calculationData === null || calculationData === void 0 ? void 0 : calculationData.weight)) ? 0 : calculationData === null || calculationData === void 0 ? void 0 : calculationData.weight,
                valueCubage: isNaN(Number(calculationData === null || calculationData === void 0 ? void 0 : calculationData.cubage)) ? 0 : calculationData === null || calculationData === void 0 ? void 0 : calculationData.cubage,
                valueWeightCubed: isNaN(Number(calculationData === null || calculationData === void 0 ? void 0 : calculationData.cubageWeight)) ? 0 : calculationData === null || calculationData === void 0 ? void 0 : calculationData.cubageWeight,
                valuePurchase: Number(item.buyValue),
                valueSale: Number(item.saleValue),
                idCurrencyPurchase: item.buyCurrency,
                idCurrencySale: item.saleCurrency
            };
            void (function () {
                var _a, _b;
                return __awaiter(this, void 0, void 0, function* () {
                    const totalFreight = totalCostArray === null || totalCostArray === void 0 ? void 0 : totalCostArray.find((total) => total.costType === CostTypes.Freight);
                    const totalTariff = totalCostArray === null || totalCostArray === void 0 ? void 0 : totalCostArray.find((total) => total.costType === CostTypes.Tariff);
                    const totalCalculationData = data.costType === CostNameTypes.Cw
                        ? Object.assign(Object.assign({}, data), { valuePurchaseCW: proposal.cargo[0].vlCwPurchase, valueSaleCW: proposal.cargo[0].vlCwSale }) : data.costType === FareItemsTypes.Fdesp
                        ? Object.assign(Object.assign({}, data), { valueTotalOriginPurchase: dataTotalCostOrigin.length > 0 ? (_a = dataTotalCostOrigin[0].value) === null || _a === void 0 ? void 0 : _a.buy : 0, valueTotalOriginSale: dataTotalCostOrigin.length > 0 ? (_b = dataTotalCostOrigin[0].value) === null || _b === void 0 ? void 0 : _b.sale : 0, valueTotalFreight: totalFreight ? totalFreight.valueTotalSale : 0, valueTotalTariff: totalTariff ? totalTariff.valueTotalSale : 0 }) : Object.assign(Object.assign({}, data), { valuePurchaseCW: null, valueSaleCW: null });
                    yield API.postTotalCalculation(totalCalculationData)
                        .then((response) => {
                        dispatch({ type: 'buyValueCalculated', value: response.valuePurchase });
                        dispatch({ type: 'saleValueCalculated', value: response.valueSale });
                    })
                        .catch((err) => console.log(err));
                });
            })();
        }
        else {
            setInvalidInput(true);
        }
    };
    useEffect(() => {
        if (state.buyValueCalculated !== null && state.saleValueCalculated !== null) {
            setFlag(true);
        }
    }, [state.buyValueCalculated, state.saleValueCalculated]);
    useEffect(() => {
        if (flag) {
            handleAdd(state);
            handleOnClose();
        }
    }, [flag]);
    const isOriginCost = title === I18n.t('pages.newProposal.step6.originCost');
    useEffect(() => {
        if (open) {
            state.agent.idBusinessPartnerAgent === undefined && agentList.length === 1 && dispatch({ type: 'agent', value: agentList[0].agent });
        }
    }, [open]);
    const getAgentName = (id) => {
        var _a;
        const agent = agentList.find(agents => agents.idBusinessPartnerAgent === id);
        return String((_a = agent === null || agent === void 0 ? void 0 : agent.agent) !== null && _a !== void 0 ? _a : '');
    };
    return (React.createElement(Modal, { open: open, onClose: handleOnClose },
        React.createElement(ModalContainer, null,
            React.createElement(HeaderDiv, null,
                React.createElement(Title, { id: `${TARIFF_COST_MODAL_SPAN_TITLE}${idSufix}` }, title),
                React.createElement(RowReverseDiv, null,
                    React.createElement(CloseIconContainer, null,
                        React.createElement(CloseIcon, { onClick: handleOnClose })))),
            React.createElement(MainDiv, null,
                React.createElement(Form, null,
                    React.createElement(RowDiv, null,
                        React.createElement(FormLabel, { component: "legend", style: { width: '27%', fontSize: '0.8rem', zIndex: 1 }, error: invalidInput && (state.idCalculationType === undefined) },
                            I18n.t('components.costModal.type'),
                            React.createElement(RedColorSpan, null, " *")),
                        React.createElement(FormLabel, { component: "legend", style: { width: '73%', fontSize: '0.8rem', zIndex: 1 }, error: invalidInput &&
                                (state.description === null ||
                                    state.description.length === 0) },
                            I18n.t('components.costModal.description'),
                            React.createElement(RedColorSpan, null, " *"))),
                    React.createElement(RowDiv, { margin: true },
                        React.createElement(CostModalContainer, null,
                            React.createElement(Autocomplete, { id: `${TARIFF_COST_MODAL_SELECT_TYPE}${idSufix}`, onChange: (_e, newValue) => { var _a; return dispatch({ type: 'idCalculationType', value: (_a = typeList.find((type) => type.name === newValue)) === null || _a === void 0 ? void 0 : _a.value }); }, options: typeList.map((option) => option.name), value: (_a = typeList.find((type) => type.value === state.idCalculationType)) === null || _a === void 0 ? void 0 : _a.name, renderInput: (params) => {
                                    var _a;
                                    return (React.createElement("div", { ref: params.InputProps.ref },
                                        React.createElement(ControlledInput, Object.assign({}, params.inputProps, { toolTipTitle: I18n.t('components.itemModal.requiredField'), invalid: invalidInput && (state.idCalculationType === undefined), filled: (_a = typeList.find((type) => type.value === state.idCalculationType)) === null || _a === void 0 ? void 0 : _a.name, placeholder: I18n.t('components.fareModal.choose'), style: { width: '119px' }, variant: "outlined", size: "small" })),
                                        React.createElement(Box, Object.assign({ style: { left: '112px', zIndex: 1, top: '118px' } }, params.inputProps, { className: 'dropdownIconAutoComplete' }),
                                            React.createElement(ArrowDropDownIcon, null))));
                                }, PaperComponent: (params) => React.createElement(StyledPaper, Object.assign({}, params)) })),
                        React.createElement(Container, { style: { position: 'relative', marginRight: '368px' } },
                            React.createElement(ControlledToolTip, { title: I18n.t('components.itemModal.requiredField'), open: invalidInput &&
                                    (state.description === null ||
                                        state.description.length === 0) },
                                React.createElement(Autocomplete, { style: { width: '358px' }, value: state.description, onChange: (event, newValue) => {
                                        dispatch({ type: 'description', value: newValue });
                                    }, options: serviceList.map((option) => option.service), renderInput: (params) => (React.createElement("div", { ref: params.InputProps.ref },
                                        React.createElement(ControlledInput, Object.assign({}, params.inputProps, { toolTipTitle: I18n.t('components.itemModal.requiredField'), invalid: invalidInput &&
                                                (state.description === null ||
                                                    state.description.length === 0), filled: state.description, placeholder: I18n.t('components.fareModal.choose'), style: { width: '358px' }, variant: "outlined", size: "small" })),
                                        React.createElement(Box, Object.assign({ style: { left: '350px', zIndex: 1 } }, params.inputProps, { className: 'dropdownIconAutoComplete' }),
                                            React.createElement(ArrowDropDownIcon, null)))), PaperComponent: (params) => (React.createElement(StyledPaper, Object.assign({}, params))) })))),
                    isOriginCost &&
                        React.createElement(RowDiv, null,
                            React.createElement(FormLabel, { component: "legend", style: { fontSize: '0.8rem' }, error: invalidInput &&
                                    (state.agent === null || state.agent.idBusinessPartnerAgent === undefined) },
                                I18n.t('components.costModal.agent'),
                                React.createElement(RedColorSpan, null, " *"))),
                    isOriginCost &&
                        React.createElement(RowDiv, { margin: specifications === 'fcl' && true, style: { position: 'relative' } },
                            React.createElement(ControlledToolTip, { title: I18n.t('components.itemModal.requiredField'), open: invalidInput && (state.agent === null || state.agent.idBusinessPartnerAgent === undefined) },
                                React.createElement(Autocomplete, { id: `${TARIFF_COST_MODAL_SELECT_AGENT}${idSufix}`, disabled: agentList.length === 0, options: agentList.map((agent) => agent.agent), value: getAgentName(Number(state.agent.idBusinessPartnerAgent)), onChange: (event, newValue) => {
                                        dispatch({ type: 'agent', value: newValue });
                                    }, renderInput: (params) => (React.createElement("div", { ref: params.InputProps.ref },
                                        React.createElement(ControlledInput, Object.assign({}, params.inputProps, { toolTipTitle: I18n.t('components.itemModal.requiredField'), invalid: invalidInput && (state.agent === null || state.agent.idBusinessPartnerAgent === undefined), filled: state.agent.idBusinessPartnerAgent, placeholder: I18n.t('components.fareModal.choose'), style: { width: '501px' }, variant: "outlined", size: "small" })),
                                        React.createElement(Box, Object.assign({ style: { right: '45px' } }, params.inputProps, { className: "dropdownContainer" }),
                                            React.createElement(ArrowDropDownIcon, null)))), PaperComponent: (params) => React.createElement(StyledPaper, Object.assign({}, params)) }))),
                    specifications === 'fcl' && (React.createElement(React.Fragment, null,
                        React.createElement(RowDiv, null,
                            React.createElement(FormLabel, { component: "legend", style: { fontSize: '0.8rem' }, error: invalidInput && state.selectedContainer === null },
                                I18n.t('components.costModal.container'),
                                React.createElement(RedColorSpan, null, " *"))),
                        React.createElement(RowDiv, { style: { position: 'relative' } },
                            React.createElement(Autocomplete, { id: `${TARIFF_COST_MODAL_SELECT_SELECTEDCONTAINER}${idSufix}`, options: containerItems.map((item) => item.type), value: state.selectedContainer, onChange: (event, newValue) => {
                                    dispatch({ type: 'selectedContainer', value: newValue });
                                }, renderInput: (params) => (React.createElement("div", { ref: params.InputProps.ref },
                                    React.createElement(ControlledInput, Object.assign({}, params.inputProps, { toolTipTitle: I18n.t('components.itemModal.requiredField'), filled: state.selectedContainer, invalid: invalidInput && state.selectedContainer === null, placeholder: I18n.t('components.fareModal.choose'), style: { width: '501px' }, variant: "outlined", size: "small" })),
                                    React.createElement(Box, Object.assign({ style: { right: '45px' } }, params.inputProps, { className: "dropdownContainer" }),
                                        React.createElement(ArrowDropDownIcon, null)))), PaperComponent: (params) => React.createElement(StyledPaper, Object.assign({}, params)) })))),
                    React.createElement(RowDiv, null,
                        React.createElement(CheckBoxArea, { onClick: buyCheckboxHandler },
                            React.createElement(CheckBox, { id: `${TARIFF_COST_MODAL_CHECKBOX_BUY}${idSufix}`, "aria-label": "checkbox", checked: buyCheckbox }, buyCheckbox && React.createElement(CheckIcon, null)),
                            React.createElement(CheckBoxLabel, { invalid: buyCheckbox &&
                                    invalidInput &&
                                    (state.buyValue === null || state.buyValue.length === 0) },
                                I18n.t('components.costModal.buyValue'),
                                ":"))),
                    React.createElement(RowDiv, { style: { position: 'relative' } },
                        React.createElement(Autocomplete, { id: `${TARIFF_COST_MODAL_SELECT_BUYCURRENCY}${idSufix}`, options: currencyList.map((option) => option.id), value: state.buyCurrency, onChange: (event, newValue) => {
                                dispatch({ type: 'buyCurrency', value: newValue });
                            }, renderInput: (params) => (React.createElement("div", { ref: params.InputProps.ref },
                                React.createElement(Input, Object.assign({}, params.inputProps, { width: "84px", disabled: !buyCheckbox, filled: buyCheckbox ? state.buyCurrency : null, toolTipTitle: I18n.t('components.itemModal.requiredField'), invalid: buyCheckbox &&
                                        invalidInput &&
                                        (state.buyCurrency === null || state.buyCurrency.length === 0) })),
                                React.createElement(Box, Object.assign({}, params.inputProps, { className: "dropdown" }),
                                    React.createElement(ArrowDropDownIcon, null)))), PaperComponent: (params) => React.createElement(StyledPaper, Object.assign({}, params)) }),
                        React.createElement(ControlledToolTip, { title: I18n.t('components.itemModal.requiredField'), open: buyCheckbox &&
                                invalidInput &&
                                (state.buyValue === null || state.buyValue.length === 0) },
                            React.createElement(PlaceholderDiv, null,
                                React.createElement("label", null,
                                    (state.buyValue === null || state.buyValue.length === 0) && (React.createElement(PlaceholderSpan, null,
                                        GetNamesByID.getTxCalculationTypeById(calculationTypes, state.idCalculationType) === FareItemsTypes.Fdesp ? I18n.t('components.costModal.percentage') : I18n.t('components.costModal.value'),
                                        buyCheckbox && React.createElement(RedColorSpan, null, " *"))),
                                    GetNamesByID.getTxCalculationTypeById(calculationTypes, state.idCalculationType) === FareItemsTypes.Fdesp
                                        ? React.createElement(NumberInput, { id: `${TARIFF_COST_MODAL_INPUT_BUY}${idSufix}`, decimalSeparator: ',', thousandSeparator: '.', decimalScale: 2, customInput: Input, format: (value) => FormatNumber.rightToLeftFormatterPercentage(value, 2), onChange: buyValueHandlerPercentage, value: state.buyValue != null ? state.buyValue : '', disabled: !buyCheckbox, filled: buyCheckbox ? state.buyValue : null, invalid: buyCheckbox &&
                                                invalidInput &&
                                                (state.buyValue === null || state.buyValue.length === 0) })
                                        : React.createElement(NumberInput, { id: `${TARIFF_COST_MODAL_INPUT_BUY}${idSufix}`, decimalSeparator: ',', thousandSeparator: '.', decimalScale: 2, customInput: Input, format: (value) => FormatNumber.rightToLeftFormatter(value, 2), onChange: buyValueHandler, value: state.buyValue != null ? state.buyValue : '', disabled: !buyCheckbox, filled: buyCheckbox ? state.buyValue : null, invalid: buyCheckbox &&
                                                invalidInput &&
                                                (state.buyValue === null || state.buyValue.length === 0) })))),
                        React.createElement(NumberInput, { id: `${TARIFF_COST_MODAL_INPUT_BUYMINIMUM}${idSufix}`, placeholder: I18n.t('components.costModal.minimum'), decimalSeparator: ',', thousandSeparator: '.', decimalScale: 2, customInput: Input, format: (value) => FormatNumber.rightToLeftFormatter(value, 2), onChange: buyMinHandler, value: state.buyMin != null ? state.buyMin : '', disabled: !buyCheckbox, filled: buyCheckbox ? state.buyMin : null }),
                        React.createElement(ReplyDiv, { id: `${TARIFF_COST_MODAL_BUTTON_REPLY}${idSufix}`, disabled: !buyCheckbox, onClick: replyForSaleHandler },
                            React.createElement(ReplyIconDiv, null,
                                React.createElement(ReplyIcon, null)),
                            I18n.t('components.costModal.replyForSale'))),
                    React.createElement(RowDiv, null,
                        React.createElement(CheckBoxArea, { onClick: saleCheckboxHandler },
                            React.createElement(CheckBox, { checked: saleCheckbox, id: `${TARIFF_COST_MODAL_CHECKBOX_SALE}${idSufix}` }, saleCheckbox && React.createElement(CheckIcon, null)),
                            React.createElement(CheckBoxLabel, { checked: true, invalid: saleCheckbox &&
                                    invalidInput &&
                                    (state.saleValue === null || state.saleValue.length === 0) },
                                I18n.t('components.costModal.saleValue'),
                                ":"))),
                    React.createElement(RowDiv, { style: { position: 'relative' } },
                        React.createElement(Autocomplete, { id: `${TARIFF_COST_MODAL_SELECT_SALECURRENCY}${idSufix}`, options: currencyList.map((option) => option.id), value: state.saleCurrency, onChange: (event, newValue) => {
                                dispatch({ type: 'saleCurrency', value: newValue });
                            }, renderInput: (params) => (React.createElement("div", { ref: params.InputProps.ref },
                                React.createElement(Input, Object.assign({}, params.inputProps, { width: "84px", disabled: !saleCheckbox, filled: saleCheckbox ? state.saleCurrency : null, toolTipTitle: I18n.t('components.itemModal.requiredField'), invalid: saleCheckbox &&
                                        invalidInput &&
                                        (state.saleCurrency === null || state.saleCurrency.length === 0) })),
                                React.createElement(Box, Object.assign({}, params.inputProps, { className: "dropdown" }),
                                    React.createElement(ArrowDropDownIcon, null)))), PaperComponent: (params) => React.createElement(StyledPaper, Object.assign({}, params)) }),
                        React.createElement(ControlledToolTip, { title: I18n.t('components.itemModal.requiredField'), open: saleCheckbox &&
                                invalidInput &&
                                (state.saleValue === null || state.saleValue.length === 0) },
                            React.createElement(PlaceholderDiv, null,
                                React.createElement("label", null,
                                    (state.saleValue === null ||
                                        state.saleValue.length === 0) && (React.createElement(PlaceholderSpan, null,
                                        state.type === FareItemsTypes.Fdesp ? I18n.t('components.costModal.percentage') : I18n.t('components.costModal.value'),
                                        saleCheckbox && React.createElement(RedColorSpan, null, " *"))),
                                    state.type === FareItemsTypes.Fdesp
                                        ? React.createElement(NumberInput, { id: `${TARIFF_COST_MODAL_INPUT_SALE}${idSufix}`, decimalSeparator: ',', thousandSeparator: '.', decimalScale: 2, customInput: Input, format: (value) => FormatNumber.rightToLeftFormatterPercentage(value, 2), onChange: saleValueHandlerPercentage, value: state.saleValue != null ? state.saleValue : '', disabled: !saleCheckbox, filled: saleCheckbox ? state.saleValue : null, invalid: saleCheckbox &&
                                                invalidInput &&
                                                (state.saleValue === null || state.saleValue.length === 0) })
                                        : React.createElement(NumberInput, { id: `${TARIFF_COST_MODAL_INPUT_SALE}${idSufix}`, decimalSeparator: ',', thousandSeparator: '.', decimalScale: 2, customInput: Input, format: (value) => FormatNumber.rightToLeftFormatter(value, 2), onChange: saleValueHandler, value: state.saleValue != null ? state.saleValue : '', disabled: !saleCheckbox, filled: saleCheckbox ? state.saleValue : null, invalid: saleCheckbox &&
                                                invalidInput &&
                                                (state.saleValue === null || state.saleValue.length === 0) })))),
                        React.createElement(NumberInput, { id: `${TARIFF_COST_MODAL_INPUT_SALEMINIMUM}${idSufix}`, placeholder: I18n.t('components.costModal.minimum'), decimalSeparator: ',', thousandSeparator: '.', decimalScale: 2, customInput: Input, format: (value) => FormatNumber.rightToLeftFormatter(value, 2), onChange: saleMinHandler, value: state.saleMin != null ? state.saleMin : '', disabled: !saleCheckbox, filled: saleCheckbox ? state.saleMin : null })),
                    (!invalidValueInput && !noValueInput) && (React.createElement(ButtonDiv, null,
                        React.createElement(Button, { id: `${TARIFF_COST_MODAL_BUTTON_SAVE}${idSufix}`, disabled: false, backgroundGreen: true, icon: '', onAction: addHandler, text: I18n.t('components.costModal.save'), tooltip: I18n.t('components.costModal.save') }))))),
            invalidValueInput && (React.createElement(WarningPopUp, null,
                React.createElement(WarningPopUpMessage, null,
                    I18n.t('components.costModal.popUpMessageStart'),
                    "\u201C",
                    buyCheckbox &&
                        (((_b = state.buyValue) === null || _b === void 0 ? void 0 : _b.length) === 0 || state.buyValue === null)
                        ? I18n.t('components.costModal.buyValue')
                        : I18n.t('components.costModal.saleValue'),
                    "\u201D",
                    I18n.t('components.costModal.popUpMessageEnd')),
                React.createElement(WarningPopUpButtonDiv, null,
                    React.createElement(Button, { id: `${TARIFF_COST_MODAL_BUTTON_GOTITVALUE}${idSufix}`, disabled: false, backgroundGreen: false, icon: '', onAction: handleClickWarningButton, text: I18n.t('components.costModal.gotIt'), tooltip: I18n.t('components.costModal.gotIt') })))),
            noValueInput && (React.createElement(WarningPopUp, null,
                React.createElement(WarningPopUpMessage, null, I18n.t('components.costModal.noValueError')),
                React.createElement(WarningPopUpButtonDiv, null,
                    React.createElement(Button, { id: `${TARIFF_COST_MODAL_BUTTON_GOTITWITHOUTVALUE}${idSufix}`, disabled: false, backgroundGreen: false, icon: '', onAction: handleClickWarningButton, text: I18n.t('components.costModal.gotIt'), tooltip: I18n.t('components.costModal.gotIt') })))))));
};
export default CostModal;
