import { Button, FormControl, IconButton, LabelCell, Select, Table } from "fiorde-fe-components";
import React, { useState } from "react";
import { ContentContainer, DisplayRuleContainer, SubTitle, Title } from './step.style'
import { makeSelectValues } from "@/presentation/utils";
import { DisplayRuleForm } from "./display-rule-form";
import { Load } from "@/domain/usecase";
import { EditIcon, TrashIcon } from "../icons";
import { useQuery } from "@tanstack/react-query";
import { t } from "i18next";
import { PREFIX_REGISTER_DISPLAYRULES_BUTTON_ADD } from "@/ids";

type DisplayRulesProps = {
  data?: any
  conditions?: any
  onChange?: (values: any) => void
  loadConditionType?: Load<any>
  loadConditionValue?: Load<any>
}

function ConditionTypeCell({ id, loadConditionType}) {
  const { data: conditionTypes } = useQuery({
    queryKey: ["condition-type"],
    queryFn: async () => loadConditionType.load(),
  });

  return <LabelCell>{conditionTypes?.find(f => f.id === id)?.name}</LabelCell>
}

function ConditionValueCell({ conditionTypeId, value, loadConditionValue}) {
  const { data: conditionTypes } = useQuery<any>({
    queryKey: ["condition-type"]
  });

  const { data: conditionValues } = useQuery({
    queryKey: [`condition-values-${conditionTypeId}`, conditionTypeId],
    queryFn: async () => loadConditionValue(makeConditionValuesEndpoint()).load(),
    enabled: !!conditionTypeId && !!conditionTypes
  });


  function makeConditionValuesEndpoint() {
    return conditionTypes?.find(conditionType => conditionType?.id === conditionTypeId)?.endpoint
  }

  return <LabelCell>{conditionValues?.find(f => f.id === value)?.name}</LabelCell>
}

const columns = (
  removeCondition,
  editCondition,
  loadConditionType,
  loadConditionValue
) => [
  {
    label: t("components.step-display-rules.columns.feat"),
    key: "conditionType.id",
    render: ({ conditionType }: any) => <ConditionTypeCell id={conditionType.id} loadConditionType={loadConditionType}/>,
    size: 3.5,
  },
  {
    label: t("components.step-display-rules.columns.operator"),
    key: "value",
    render: ({ conditionType, value }: any) => <ConditionValueCell conditionTypeId={conditionType.id} value={value} loadConditionValue={loadConditionValue}/>,
    size: 3.5,
  },
  {
    label: t("components.step-display-rules.columns.actions"),
    key: "menu",
    render: (_, index) => (
      <div>
        <IconButton onClick={() => editCondition(index)}><EditIcon/></IconButton>
        <IconButton onClick={() => removeCondition(index)}><TrashIcon/></IconButton>
      </div>),
    size: 0.5,
  },
];

export function DisplayRules({ conditions, onChange, loadConditionType, loadConditionValue }: DisplayRulesProps) {
  const [visibleForm, setVisibleForm] = useState(false)
  const [form, setForm] = useState({
    index: null,
    conditionType: { id: null },
    operator: { id: 1 },
    value: null,
    isActive: true
  })

  function handleOpenForm() {
    setVisibleForm(true)
  }
  function handleCloseForm() {
    resetForm()
    setVisibleForm(false)
  }
  function handleSubmit() {
    if (form.index !== null) {
      const getConditions = conditions
      getConditions[form.index] = form
      onChange(getConditions)
    } else {
      const getConditions = conditions
      getConditions.push(form)
      onChange(getConditions)
    }
    handleCloseForm()
  }
  function resetForm() {
    setForm({
      index: null,
      conditionType: { id: null },
      operator: { id: 1 },
      value: null,
      isActive: true
    })
  }
  function removeCondition(index) {
    onChange(conditions.filter((_, fIndex) => index !== fIndex))
  }
  function editCondition(index) {
    const condition = {...conditions.find((_, fIndex) => fIndex === index), index}
    setForm(condition)
    handleOpenForm()
  }
  return (
    <DisplayRuleContainer>
      <Title>{t("components.step-display-rules.title")}</Title>
      <SubTitle>{t("components.step-display-rules.subtitle")}</SubTitle>
      <ContentContainer>
        <Table
          rows={conditions || []}
          columns={columns(removeCondition, editCondition, loadConditionType, loadConditionValue)}
        />
        {visibleForm && (
          <DisplayRuleForm
            values={form}
            onChange={(values) => setForm(values)}
            onClose={handleCloseForm}
            onSubmit={handleSubmit}
            loadConditionType={loadConditionType}
            loadConditionValue={loadConditionValue}
          />
        )}
        <Button
          text={t("components.step-display-rules.button-add")}
          icon="add"
          backgroundGreen={false}
          onAction={handleOpenForm}
          id={PREFIX_REGISTER_DISPLAYRULES_BUTTON_ADD}
        />
      </ContentContainer>
    </DisplayRuleContainer>
  );
}
