import { Button, IconButton, LabelCell, Table } from "fiorde-fe-components";
import React, { useState } from "react";
import { ContentContainer, FormContainer, SubTitle, Title } from './step.style'
import { Load } from "@/domain/usecase";
import { EditIcon, TrashIcon } from "../icons";
import { useQuery } from "@tanstack/react-query";
import { AdditionalFieldsForm } from "./additional-fields-form";
import { t } from "i18next";
import { PREFIX_REGISTER_ADDITIONALFIELDS_BUTTON_ADD } from "@/ids";

type AdditionalFieldsProps = {
  data?: any
  additionaleFields?: any
  onChange?: (values: any) => void
  loadAdditionalFieldType?: Load<any>
  loadChecklistAll?: Load<any>
  loadSectionByCheckListId?: Load<any>
}

function AdditionalFieldTypeCell({ id, loadAdditionalFieldType}) {
  const { data: additionalFieldTypes } = useQuery({
    queryKey: ["additional-field-type"],
    queryFn: async () => loadAdditionalFieldType.load(),
  });

  return <LabelCell>{additionalFieldTypes?.find(f => f.id === id)?.name}</LabelCell>
}

function CheckListAllCell({ id, loadChecklistAll}) {
  const { data: checklistAll } = useQuery({
    queryKey: ["checklist-all"],
    queryFn: async () => loadChecklistAll.load(),
  });

  return <LabelCell>{checklistAll?.find(f => f.id === id)?.name}</LabelCell>
}

const columns = (
  removeAdditionalField,
  editAdditionalField,
  loadAdditionalFieldType,
  loadChecklistAll
) => [
  {
    label: t("components.step-additional-fields.columns.name"),
    key: "name",
    render: ({ name, checklistFields }: any) =>
      checklistFields.length > 0
        ? <CheckListAllCell id={checklistFields[0]?.checklistId} loadChecklistAll={loadChecklistAll} />
        : <LabelCell>{name}</LabelCell>,
    size: 2.5,
  },
  {
    label: t("components.step-additional-fields.columns.type"),
    key: "value",
    render: ({ stepAdditionalFieldType }: any) =>
      <AdditionalFieldTypeCell
        id={stepAdditionalFieldType.id}
        loadAdditionalFieldType={loadAdditionalFieldType}
      />,
    size: 2.5,
  },
  {
    label: t("components.step-additional-fields.columns.required"),
    key: "isRequired",
    render: ({ isRequired }: any) => <LabelCell>{isRequired ? "Sim" : "Não"}</LabelCell>,
    size: 2.5,
  },
  {
    label: t("components.step-additional-fields.columns.actions"),
    key: "menu",
    render: (_, index) => (
      <div>
        <IconButton onClick={() => editAdditionalField(index)}><EditIcon/></IconButton>
        <IconButton onClick={() => removeAdditionalField(index)}><TrashIcon/></IconButton>
      </div>),
    size: 0.5,
  },
];

export function AdditionalFields({
  additionaleFields,
  onChange,
  loadAdditionalFieldType,
  loadChecklistAll,
  loadSectionByCheckListId
}: AdditionalFieldsProps) {
  const [visibleForm, setVisibleForm] = useState(false)
  const [form, setForm] = useState({
    index: null,
    name: '',
    isRequired: false,
    stepAdditionalFieldType: { id: null },
    checklistFields: [],
    isActive: true
  })

  function handleOpenForm() {
    setVisibleForm(true)
  }
  function handleCloseForm() {
    resetForm()
    setVisibleForm(false)
  }
  function handleSubmit() {
    if (isValidSubmit()) {
      if (form.index !== null) {
        const getFields = additionaleFields
        getFields[form.index] = form
        onChange(getFields)
      } else {
        const getFields = additionaleFields
        getFields.push(form)
        onChange(getFields)
      }
      handleCloseForm()
    }
  }
  function isValidSubmit() {
    if (form.checklistFields.length > 0 && form.checklistFields[0]?.checklistId == null) return false
    if (form.checklistFields.length > 0 && form.checklistFields[0]?.sectionId == null) return false
    if (form.checklistFields.length === 0 && form.name === '') return false
    if (form.stepAdditionalFieldType.id === null) return false
    return true
  }
  function resetForm() {
    setForm({
      index: null,
      name: '',
      isRequired: false,
      stepAdditionalFieldType: { id: null },
      checklistFields: [],
      isActive: true
    })
  }
  function removeAdditionalField(index) {
    onChange(additionaleFields.filter((_, fIndex) => index !== fIndex))
  }
  function editAdditionalField(index) {
    const additionalField = {...additionaleFields.find((_, fIndex) => fIndex === index), index}
    setForm(additionalField)
    handleOpenForm()
  }
  return (
    <FormContainer>
      <Title>{t("components.step-additional-fields.title")}</Title>
      <SubTitle>{t("components.step-additional-fields.subtitle")}</SubTitle>
      <ContentContainer>
        <Table
          rows={additionaleFields || []}
          columns={columns(removeAdditionalField, editAdditionalField, loadAdditionalFieldType, loadChecklistAll)}
        />
        {visibleForm && (
          <AdditionalFieldsForm
            values={form}
            onChange={(values) => setForm(values)}
            onClose={handleCloseForm}
            onSubmit={handleSubmit}
            loadAdditionalFieldType={loadAdditionalFieldType}
            loadChecklistAll={loadChecklistAll}
            loadSectionByCheckListId={loadSectionByCheckListId}
          />
        )}
        <Button
          text={t("components.step-additional-fields.button-add")}
          icon="add"
          backgroundGreen={false}
          onAction={handleOpenForm}
          id={PREFIX_REGISTER_ADDITIONALFIELDS_BUTTON_ADD}
        />
      </ContentContainer>
    </FormContainer>
  );
}
