import { RemoteUpdateVehicleType } from "@/data/usecase";
import { UpdateVehicleType } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteUpdateVehicleType = (): UpdateVehicleType =>
  new RemoteUpdateVehicleType(
    makeApiUrl("/sirius-master-data-api/v1/vehicleType"),
    makeAuthorizeHttpClientDecorator()
  );
