import React, { useState } from "react";
import {
  AddSpot as DomainAddSpot,
  LoadSpotPegeable,
  LoadSpotStatusList,
  UpdateSpot as DomainUpdateSpot,
  LoadSpot,
  LoadVehicleCompositionList,
} from "@/domain/usecase";
import {
  LabelCell,
  Pagination,
  Table,
  ProgressIndicator,
} from "fiorde-fe-components";
import { useQuery } from "@tanstack/react-query";
import {
  AddSpot,
  UpdateSpot,
  DisableSpot,
  PageHeader,
} from "@/presentation/components";
import { t } from "i18next";
import { UpdateSpotModel } from "@/domain/models";
import { mockSpotPegeableModel } from "@/domain/mocks";
import {
  PREFIX_REGISTER_LISTDOCK_LABELCELL_NAME,
  PREFIX_REGISTER_LISTSPOT_LABELCELL_SPOTSTATUS,
} from "@/ids";

type SpotListProps = {
  LoadSpotPegeable: LoadSpotPegeable;
  addSpot: DomainAddSpot;
  loadSpotStatusList: LoadSpotStatusList;
  updateSpot: DomainUpdateSpot;
  loadSpot: LoadSpot;
  loadVehicleCompositionList: LoadVehicleCompositionList
};

const columns = (
  updateSpot: DomainUpdateSpot,
  loadSpotStatusList: LoadSpotStatusList,
  loadSpot: LoadSpot,
  loadVehicleCompositionList: LoadVehicleCompositionList
) => [
  {
    label: t("page.spot-list.columns.spot"),
    key: "name",
    render: ({ name }: any) => <LabelCell id={PREFIX_REGISTER_LISTDOCK_LABELCELL_NAME}>{name}</LabelCell>,
    size: 3.5,
  },
  {
    label: t("page.spot-list.columns.spot-status"),
    key: "spotStatus",
    render: ({ spotStatus }: any) => (
      <LabelCell id={PREFIX_REGISTER_LISTSPOT_LABELCELL_SPOTSTATUS}>{spotStatus?.name}</LabelCell>
    ),
    size: 1.5,
  },
  {
    label: t("page.spot-list.columns.menu"),
    key: "menu",
    render: ({ id, isActive }) => (
      <div style={{ display: "flex", gap: 8 }}>
        <UpdateSpot
          id={id}
          updateSpot={updateSpot}
          loadSpot={loadSpot}
          loadSpotStatusList={loadSpotStatusList}
          loadVehicleCompositionList={loadVehicleCompositionList}
        />
        <DisableSpot
          id={id}
          checked={isActive}
          loadSpot={loadSpot}
          updateSpot={updateSpot}
        />
      </div>
    ),
    size: 0.5,
  },
];

export function Spot({
  LoadSpotPegeable,
  addSpot,
  loadSpotStatusList,
  updateSpot,
  loadSpot,
  loadVehicleCompositionList
}: SpotListProps) {
  const [filter, setFilter] = useState({
    page: 0,
    size: 10,
    sort: "id,desc",
  });
  const { isLoading, isRefetching, data } = useQuery({
    queryKey: ["spot-list", filter],
    queryFn: async () => LoadSpotPegeable.load(filter),
  });
  return (
    <div>
      <ProgressIndicator
        visible={isLoading || isRefetching}
        content={t("components.progress-indicator.content")}
      />
      <PageHeader title={t("page.spot-list.header")}>
        <AddSpot
          addSpot={addSpot}
          loadSpotStatusList={loadSpotStatusList}
          loadVehicleCompositionList={loadVehicleCompositionList}
        />
      </PageHeader>
      <Table
        rows={data?.content || []}
        columns={columns(updateSpot, loadSpotStatusList, loadSpot, loadVehicleCompositionList)}
      />
      <Pagination
        count={data?.totalElements || 0}
        labelDisplay="exibindo"
        labelDisplayedRows="de"
        labelRowsPerPage="Vagas por página"
        onPageChange={(value) =>
          setFilter((filter: any) => ({ ...filter, page: value }))
        }
        onRowsPerPageChange={(value) =>
          setFilter((filter: any) => ({
            ...filter,
            size: value,
            page: 0,
          }))
        }
        tooltipBack="Anterior"
        tooltipFirst="Primeira"
        tooltipLast="Última"
        tooltipNext="Próxima"
        page={filter.page}
      />
    </div>
  );
}
