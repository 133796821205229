import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${String(pdfjs.version)}/pdf.worker.js`;
export function Pdf({ url }) {
    const [numPages, setNumPages] = useState(null);
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };
    return (React.createElement("div", null,
        React.createElement(Document, { file: url, onLoadSuccess: onDocumentLoadSuccess, loading: "" }, numPages !== null && Array.from({ length: numPages }, (_, i) => i + 1).map((num) => React.createElement(Page, { key: num, pageNumber: num, loading: "", renderAnnotationLayer: false, renderTextLayer: false, scale: 1.7 })))));
}
