import { RemoteLoadOperatorList } from "@/data/usecase";
import { LoadOperatorList } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteLoadOperatorList = (value: string): LoadOperatorList =>
  new RemoteLoadOperatorList(
    makeApiUrl(value),
    makeAuthorizeHttpClientDecorator()
  );
