

import { AccessDeniedError, UnexpectedError } from '@/domain/errors'
import { HttpClient, HttpStatusCode } from '@/domain/protocols/http'
import { LoadVehicleType } from '@/domain/usecase'
import { RemoteLoadVehicleTypeDTO } from '../models'

export class RemoteLoadVehicleType implements LoadVehicleType {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<LoadVehicleType.Model>
  ) {}

  async load (id: number): Promise<LoadVehicleType.Model> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${id}`,
      method: 'get'
    })
    const LoadVehicleType = httpResponse.body
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return LoadVehicleType
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}

export namespace RemoteLoadVehicleType {
  export type Model = RemoteLoadVehicleTypeDTO
}
