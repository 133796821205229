import { RemoteLoadChecklist } from "@/data/usecase";
import { LoadChecklist } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteLoadChecklist = (): LoadChecklist =>
  new RemoteLoadChecklist(
    makeApiUrl("/sirius-checklist-api/v1/checklist"),
    makeAuthorizeHttpClientDecorator()
  );
