import { AccessDeniedError, UnexpectedError } from "@/domain/errors";
import { HttpClient, HttpStatusCode } from "@/domain/protocols/http";
import { LoadStepPegeable } from "@/domain/usecase";
import { RemoteLoadStepPegeableDTO } from "@/data/models";

export class RemoteLoadStepPegeable implements LoadStepPegeable {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<RemoteLoadStepPegeable.Model>
  ) {}

  async load(filters: Object): Promise<LoadStepPegeable.Model> {
    const queryString = Object.entries(filters)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    const httpResponse = await this.httpClient.request({
      url: `${this.url}?${queryString}`,
      method: "get",
    });
    const loadStepPegeable = httpResponse.body;
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return loadStepPegeable;
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        //@ts-ignore
        const err = httpResponse.body?.response?.error;
        throw new UnexpectedError(err);
    }
  }
}

export namespace RemoteLoadStepPegeable {
  export type Model = RemoteLoadStepPegeableDTO;
}
