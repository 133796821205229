import React from "react";
import { Dock } from "@/presentation/pages/dock/page";
import {
  makeRemoteLoadDockPegeable,
  makeRemoteLoadDockStatusList,
  makeRemoteLoadDock,
  makeRemoteAddDock,
  makeRemoteUpdateDock,
  makeRemoteLoadMaterialFamilyList,
  makeRemoteLoadVehicleCompositionList,
  makeRemoteLoadMovementTypeList,
} from "../../usecases";

export const makeDockList: React.FC = (props) => {
  return (
    <Dock
      {...props}
      LoadDockPegeable={makeRemoteLoadDockPegeable()}
      loadDockStatusList={makeRemoteLoadDockStatusList()}
      loadDock={makeRemoteLoadDock()}
      addDock={makeRemoteAddDock()}
      updateDock={makeRemoteUpdateDock()}
      loadMaterialFamilyList={makeRemoteLoadMaterialFamilyList()}
      loadVehicleCompositionList={makeRemoteLoadVehicleCompositionList()}
      loadMovementTypeList={makeRemoteLoadMovementTypeList()}
    />
  );
};
