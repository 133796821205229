import { Grid, Modal } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import CloseIcon from '../../../application/icons/CloseIcon';
import FormatNumber from '../../../application/utils/formatNumber';
import { NumberInput } from '../../pages/NewProposal/steps/StepsStyles';
import ControlledInput from '../ControlledInput';
import { CloseIconContainer, Form, HeaderDiv, ModalContainer, RedColorSpan, RowReverseDiv, ReverseDiv, Title } from '../StyledComponents/modalStyles';
import { ButtonDiv, CwSaleLabel, CwValue, Label, ValueLabel, WarningBoldMessage, WarningDiv, WarningMessage } from './CwModalStyles';
import { I18n } from 'react-redux-i18n';
import { Button } from 'fiorde-fe-components';
import InfoIcon from '../../../application/icons/InfoIcon';
import { PROPOSAL_CW_MODAL_SAVE_BUTTON, PROPOSAL_CW_MODAL_CANCEL_BUTTON } from '../../../ids';
const CwModal = ({ dataProp, action, open, setClose, editValue }) => {
    var _a, _b, _c;
    const [data, setData] = useState('');
    useEffect(() => {
        const value = editValue === null || editValue === void 0 ? void 0 : editValue.toFixed(2);
        setData(String(value));
    }, [open]);
    const handleOnClose = () => {
        setClose();
    };
    const handleOnAction = () => {
        if (data !== undefined && data.length > 0) {
            action(data);
            setClose();
        }
    };
    return (React.createElement(Modal, { open: open, onClose: handleOnClose },
        React.createElement(ModalContainer, null,
            React.createElement(HeaderDiv, null,
                React.createElement(Title, null, I18n.t('components.cwModal.title')),
                React.createElement(RowReverseDiv, null,
                    React.createElement(CloseIconContainer, null,
                        React.createElement(CloseIcon, { onClick: handleOnClose })))),
            React.createElement(Form, null,
                React.createElement(Grid, { container: true, xs: 12 },
                    React.createElement(Grid, { item: true, xs: 4 },
                        React.createElement(Label, null, I18n.t('components.cwModal.grossWeight'))),
                    React.createElement(Grid, { item: true, xs: 8 },
                        React.createElement(ValueLabel, null, (_a = dataProp === null || dataProp === void 0 ? void 0 : dataProp.weight) === null || _a === void 0 ? void 0 : _a.toFixed(2).replace('.', ','))),
                    React.createElement(Grid, { item: true, xs: 4 },
                        React.createElement(Label, null, I18n.t('components.cwModal.cubage'))),
                    React.createElement(Grid, { item: true, xs: 8 },
                        React.createElement(ValueLabel, null, (_b = dataProp === null || dataProp === void 0 ? void 0 : dataProp.cubage) === null || _b === void 0 ? void 0 : _b.toFixed(2).replace('.', ','))),
                    React.createElement(Grid, { item: true, xs: 4 },
                        React.createElement(Label, null, I18n.t('components.cwModal.cubageWeight'))),
                    React.createElement(Grid, { item: true, xs: 8 },
                        React.createElement(ValueLabel, null, (_c = dataProp === null || dataProp === void 0 ? void 0 : dataProp.cubageWeight) === null || _c === void 0 ? void 0 : _c.toFixed(2).replace('.', ','))),
                    React.createElement(Grid, { item: true, xs: 4 },
                        React.createElement(CwSaleLabel, null,
                            I18n.t('components.cwModal.cwSales'),
                            React.createElement(RedColorSpan, null, " *"))),
                    React.createElement(Grid, { item: true, xs: 8 },
                        React.createElement(CwValue, null,
                            React.createElement(NumberInput, { decimalSeparator: ',', thousandSeparator: '.', decimalScale: 2, format: (value) => FormatNumber.rightToLeftFormatter(value, 2), customInput: ControlledInput, onChange: (e) => setData(e.target.value), toolTipTitle: I18n.t('components.itemModal.requiredField'), invalid: data.length === 0, value: data, variant: "outlined", size: "small", modal: true }))),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement(ReverseDiv, null,
                            React.createElement(ButtonDiv, null,
                                React.createElement(Button, { id: PROPOSAL_CW_MODAL_SAVE_BUTTON, backgroundGreen: true, text: I18n.t('components.cwModal.save'), disabled: false, icon: "", onAction: handleOnAction, tooltip: "" })),
                            React.createElement(ButtonDiv, { green: true },
                                React.createElement(Button, { id: PROPOSAL_CW_MODAL_CANCEL_BUTTON, backgroundGreen: false, text: I18n.t('components.cwModal.cancel'), disabled: false, icon: "", onAction: handleOnClose, tooltip: "" })))))),
            React.createElement(WarningDiv, null,
                React.createElement(InfoIcon, null),
                React.createElement(WarningMessage, null,
                    React.createElement(WarningBoldMessage, null, I18n.t('components.cwModal.attention')),
                    I18n.t('components.cwModal.infoText'))))));
};
export default CwModal;
