export function allowOnlyNumbers(input: string) {
  return input.replace(/\D/g, "");
}

export function formatPhoneNumber(input) {
  const digits = input.replace(/\D/g, "");

  if (digits.length === 11 && digits[2] === '9') {
      return digits.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");
  } else  {
      return digits.replace(/^(\d{2})(\d{4})(\d{4})$/, "($1) $2-$3");
  }
}