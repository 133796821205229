import { RemoteAddOrganization } from "@/data/usecase";
import { AddOrganization } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteAddOrganization = (): AddOrganization =>
  new RemoteAddOrganization(
    makeApiUrl("/sirius-business-partner-api/v1/organization"),
    makeAuthorizeHttpClientDecorator()
  );
