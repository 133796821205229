import { HttpClient } from "@/domain/protocols/http";
import { makeLocalStorageAdapter } from "@/infrastructure/cache/local-storage-adapter-factory";
import { makeAxiosHttpClient } from "@/infrastructure/http/axios-http-client-factory";
import { AuthorizeHttpClientDecorator } from "@/main/decorators/authorize-http-client-decorator";

export const makeAuthorizeHttpClientDecorator = (): HttpClient =>
  new AuthorizeHttpClientDecorator(
    makeLocalStorageAdapter(),
    makeAxiosHttpClient()
  );
