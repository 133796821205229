import styled from "styled-components";

export const DisplayRuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const Title = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #222222;
`
export const SubTitle = styled.span`
  font-size: 14px;
  color: #545454;
`

export const ContentContainer = styled.div`
  display: flex;
  padding: 8px;
  border-radius: 4px;
  flex-direction: column;
  background:#F8F8F8;
  gap: 20px;
`

export const PermanenceFormControl = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const FormContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`

export const FormActions = styled.div`
  display: flex;
  gap: 20px;
  justify-content: end;
`