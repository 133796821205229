import { RemoteLoadCountriesList } from "@/data/usecase";
import { LoadCountriesList } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteLoadCountriesList = (): LoadCountriesList =>
  new RemoteLoadCountriesList(
    makeApiUrl("/sirius-master-data-api/v1/countries"),
    makeAuthorizeHttpClientDecorator()
  );
