import React from 'react'
import { VehicleType } from '@/presentation/pages'
import {
  makeRemoteAddVehicleType,
  makeRemoteLoadVehicleType,
  makeRemoteLoadVehicleTypeList,
  makeRemoteUpdateVehicleType
} from '../../usecases'

export const makeVehicleTypeList: React.FC = (props) => {
  return (
    <VehicleType
      {...props}
      addVehicleType={makeRemoteAddVehicleType()}
      loadVehicleTypeList={makeRemoteLoadVehicleTypeList()}
      updateVehicleType={makeRemoteUpdateVehicleType()}
      loadVehicleType={makeRemoteLoadVehicleType()}
    />
  )
}
