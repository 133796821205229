import React from 'react'
import { FormActions, FormContainer, FormContent, Title } from './step.style'
import { Button, FormControl, Input, Select, Switch } from 'fiorde-fe-components'
import { makeSelectValues } from '@/presentation/utils'
import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { PREFIX_REGISTER_ADDITIONALFIELDSFORM_BUTTON_CANCEL, PREFIX_REGISTER_ADDITIONALFIELDSFORM_BUTTON_SUBMIT, PREFIX_REGISTER_ADDITIONALFIELDSFORM_FORMCONTROL_INPUT_NAME, PREFIX_REGISTER_ADDITIONALFIELDSFORM_FORMCONTROL_SELECT_CHECKLIST, PREFIX_REGISTER_ADDITIONALFIELDSFORM_FORMCONTROL_SELECT_FIELDTYPE, PREFIX_REGISTER_ADDITIONALFIELDSFORM_FORMCONTROL_SELECT_SECTION, PREFIX_REGISTER_ADDITIONALFIELDSFORM_FORMCONTROL_SWITCH_REQUIRED, PREFIX_REGISTER_ADDITIONALFIELDSFORM_INPUT_NAME, PREFIX_REGISTER_ADDITIONALFIELDSFORM_SWITCH_REQUIRED } from '@/ids';
import { additionalFieldTypeId } from '@/main/factories/enums';

export function AdditionalFieldsForm({ values, onChange, onClose, onSubmit, loadAdditionalFieldType, loadChecklistAll, loadSectionByCheckListId}) {
  const { data: additionalFieldTypes } = useQuery({
    queryKey: ["additional-field-type"],
    queryFn: async () => loadAdditionalFieldType.load(),
  });
  const { data: checklist } = useQuery({
    queryKey: ["checklist-all"],
    queryFn: async () => loadChecklistAll.load(),
  });

  function makeSections () {
    if (values?.checklistFields[0]?.checklistId) {
      const getChecklist = checklist.find(c => c?.id === values?.checklistFields[0]?.checklistId)
      return getChecklist?.sections
    }
    return []
  }

  function makeChecklistName (checklistId) {
    return checklist.find(c => c.id === checklistId)?.name
  }

  return (
    <FormContainer>
      <Title>
        {
          values.index
            ? t("components.step-additional-fields.form.title-edit")
            : t("components.step-additional-fields.form.title-add")
        }
      </Title>
      {values.stepAdditionalFieldType.id === additionalFieldTypeId.CHECKLIST
        ? (
        <FormContent>
          <FormControl
            label={t("components.step-additional-fields.form.select-label-checklist")}
            id={PREFIX_REGISTER_ADDITIONALFIELDSFORM_FORMCONTROL_SELECT_CHECKLIST}
          >
            <Select
              options={makeSelectValues({
                label: 'name',
                value: 'id',
                array: checklist || []
              })}
              onChange={(value) => onChange({...values,
                checklistFields: [ { checklistId: value, sectionId: null, isActive: true } ],
                name: makeChecklistName(value)
              })}
              placeholder={t("components.step-additional-fields.form.select-placeholder-checklist")}
              value={values?.checklistFields[0]?.checklistId}
            />
          </FormControl>
          <FormControl
            label={t("components.step-additional-fields.form.select-label-section")}
            id={PREFIX_REGISTER_ADDITIONALFIELDSFORM_FORMCONTROL_SELECT_SECTION}
          >
            <Select
              options={makeSelectValues({
                label: 'name',
                value: 'id',
                array: makeSections() || []
              })}
              onChange={(value) => onChange({...values, checklistFields: [ 
                { checklistId: values.checklistFields[0].checklistId, sectionId: value, isActive: true }
              ] })}
              placeholder={t("components.step-additional-fields.form.select-placeholder-section")}
              value={values?.checklistFields[0]?.sectionId}
            />
          </FormControl>
        </FormContent>
          )
        : (
          <FormControl
            label={t("components.step-additional-fields.form.input-label-name")}
            id={PREFIX_REGISTER_ADDITIONALFIELDSFORM_FORMCONTROL_INPUT_NAME}
          >
            <Input
              id={PREFIX_REGISTER_ADDITIONALFIELDSFORM_INPUT_NAME}
              onChange={value => onChange({...values, name: value})}
              value={values.name}
              placeholder={t("components.step-additional-fields.form.input-placeholder-name")}
            />
          </FormControl>
          )
      }
      <FormContent>
        <FormControl
          label={t("components.step-additional-fields.form.select-label-step-type")}
          id={PREFIX_REGISTER_ADDITIONALFIELDSFORM_FORMCONTROL_SELECT_FIELDTYPE}
        >
          <Select
            options={makeSelectValues({
              label: 'name',
              value: 'id',
              array: additionalFieldTypes || []
            })}
            onChange={(value) =>
              onChange({
                ...values,
                stepAdditionalFieldType: { id: value },
                checklistFields: [],
                name: ''
              })}
            placeholder={t("components.step-additional-fields.form.select-placeholder-step-type")}
            value={values?.stepAdditionalFieldType?.id}
          />
        </FormControl>
        <FormControl
          label={t("components.step-additional-fields.form.switch-label-required")}
          id={PREFIX_REGISTER_ADDITIONALFIELDSFORM_FORMCONTROL_SWITCH_REQUIRED}
        >
          <Switch
            id={PREFIX_REGISTER_ADDITIONALFIELDSFORM_SWITCH_REQUIRED}
            onChange={() => onChange({...values, isRequired: !values.isRequired })}
            checked={values.isRequired}
          />
        </FormControl>
      </FormContent>
      <FormActions>
        <Button
          text="Cancelar"
          backgroundGreen={false}
          onAction={onClose}
          id={PREFIX_REGISTER_ADDITIONALFIELDSFORM_BUTTON_CANCEL}
        />
        <Button
          id={PREFIX_REGISTER_ADDITIONALFIELDSFORM_BUTTON_SUBMIT}
          text={values.index
            ? t("components.step-additional-fields.form.title-edit")
            : t("components.step-additional-fields.form.title-add")
          }
          backgroundGreen={true}
          onAction={onSubmit}
        />
      </FormActions>
    </FormContainer>
  )
}