import React from "react";
import { Checklist } from "@/presentation/pages";
import {
  makeRemoteAddChecklist,
  makeRemoteLoadChecklist,
  makeRemoteLoadChecklistList,
  makeRemoteUpdateChecklist,
  makeRemoteLoadConditionList,
  makeRemoteLoadOperatorList,
  makeRemoteLoadTypeFieldList,
  makeRemoteLoadSystemList
} from "../../usecases";

export const makeChecklistList: React.FC = (props) => {
  return (
    <Checklist
      {...props}
      addChecklist={makeRemoteAddChecklist()}
      loadChecklist={makeRemoteLoadChecklist()}
      loadChecklistList={makeRemoteLoadChecklistList()}
      updateChecklist={makeRemoteUpdateChecklist()}
      loadOperatorList={makeRemoteLoadOperatorList}
      loadConditionList={makeRemoteLoadConditionList()}
      loadTypeFieldList={makeRemoteLoadTypeFieldList()}
      loadSystemList={makeRemoteLoadSystemList()}
    />
  );
};
