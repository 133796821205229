import React, { useState } from "react";
import {
  AddDock as DomainAddDock,
  LoadDockPegeable,
  LoadDockStatusList,
  UpdateDock as DomainUpdateDock,
  LoadDock,
  LoadVehicleCompositionList,
  LoadMaterialFamilyList,
  LoadMovementTypeList,
} from "@/domain/usecase";
import {
  LabelCell,
  Pagination,
  Table,
  ProgressIndicator,
} from "fiorde-fe-components";
import { useQuery } from "@tanstack/react-query";
import {
  AddDock,
  UpdateDock,
  DisableDock,
  PageHeader,
} from "@/presentation/components";
import { t } from "i18next";
import {
  PREFIX_REGISTER_LISTDOCK_LABELCELL_NAME,
  PREFIX_REGISTER_LISTDOCK_LABELCELL_STATUS,
} from "@/ids";

type DockListProps = {
  LoadDockPegeable: LoadDockPegeable;
  addDock: DomainAddDock;
  loadDockStatusList: LoadDockStatusList;
  updateDock: DomainUpdateDock;
  loadDock: LoadDock;
  loadMaterialFamilyList: LoadMaterialFamilyList;
  loadVehicleCompositionList: LoadVehicleCompositionList;
  loadMovementTypeList: LoadMovementTypeList
};

const columns = (
  updateDock: DomainUpdateDock,
  loadDockStatusList: LoadDockStatusList,
  loadDock: LoadDock,
  loadMaterialFamilyList: LoadMaterialFamilyList,
  loadVehicleCompositionList: LoadVehicleCompositionList,
  loadMovementTypeList: LoadMovementTypeList
) => [
  {
    label: t("page.dock-list.columns.dock-name"),
    key: "name",
    render: ({ name }: any) => <LabelCell id={PREFIX_REGISTER_LISTDOCK_LABELCELL_NAME}>{name}</LabelCell>,
    size: 3.5,
  },
  {
    label: t("page.dock-list.columns.dock-status"),
    key: "dockStatus",
    render: ({ dockStatus }: any) => (
      <LabelCell id={PREFIX_REGISTER_LISTDOCK_LABELCELL_STATUS}>{dockStatus?.name}</LabelCell>
    ),
    size: 1.5,
  },
  {
    label: t("page.dock-list.columns.dock-menu"),
    key: "menu",
    render: ({ id, isActive }) => (
      <div style={{ display: "flex", gap: 8 }}>
        <UpdateDock
          id={id}
          updateDock={updateDock}
          loadDock={loadDock}
          loadDockStatusList={loadDockStatusList}
          loadMaterialFamilyList={loadMaterialFamilyList}
          loadVehicleCompositionList={loadVehicleCompositionList}
          loadMovementTypeList={loadMovementTypeList}
        />
        <DisableDock
          id={id}
          checked={isActive}
          loadDock={loadDock}
          updateDock={updateDock}
        />
      </div>
    ),
    size: 0.5,
  },
];

export function Dock({
  LoadDockPegeable,
  addDock,
  loadDockStatusList,
  updateDock,
  loadDock,
  loadMaterialFamilyList,
  loadVehicleCompositionList,
  loadMovementTypeList,
}: DockListProps) {
  const [filter, setFilter] = useState({
    page: 0,
    size: 10,
    sort: "id,desc",
  });
  const { isLoading, isRefetching, data } = useQuery({
    queryKey: ["dock-list", filter],
    queryFn: async () => LoadDockPegeable.load(filter),
  });
  return (
    <div>
      <ProgressIndicator
        visible={isLoading || isRefetching}
        content={t("components.progress-indicator.content")}
      />
      <PageHeader title={t("page.dock-list.header")}>
        <AddDock
          addDock={addDock}
          loadDockStatusList={loadDockStatusList}
          loadMaterialFamilyList={loadMaterialFamilyList}
          loadVehicleCompositionList={loadVehicleCompositionList}
          loadMovementTypeList={loadMovementTypeList}
        />
      </PageHeader>
      <Table
        rows={data?.content || []}
        columns={columns(
          updateDock,
          loadDockStatusList,
          loadDock,
          loadMaterialFamilyList,
          loadVehicleCompositionList,
          loadMovementTypeList
        )}
      />
      <Pagination
        count={data?.totalElements || 0}
        labelDisplay="exibindo"
        labelDisplayedRows="de"
        labelRowsPerPage="Docas por página"
        onPageChange={(value) =>
          setFilter((filter: any) => ({ ...filter, page: value }))
        }
        onRowsPerPageChange={(value) =>
          setFilter((filter: any) => ({
            ...filter,
            size: value,
            page: 0,
          }))
        }
        tooltipBack="Anterior"
        tooltipFirst="Primeira"
        tooltipLast="Última"
        tooltipNext="Próxima"
        page={filter.page}
      />
    </div>
  );
}
