import { RemoteLoadSpotPegeable } from "@/data/usecase";
import { LoadSpotPegeable } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteLoadSpotPegeable = (): LoadSpotPegeable =>
  new RemoteLoadSpotPegeable(
    makeApiUrl("/sirius-yard-control-api/v1/spot"),
    makeAuthorizeHttpClientDecorator()
  );
