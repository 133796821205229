import React, { Fragment, useState } from 'react';
import { ExitDialog } from 'fiorde-fe-components';
import { I18n } from 'react-redux-i18n';
import RemoveIcon from '../../../application/icons/RemoveIcon';
const FreeTimeDemurrageDeleteModal = ({ handleConfirm }) => {
    const [visible, setVisible] = useState(false);
    return (React.createElement(Fragment, null,
        React.createElement("div", { style: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '60px',
                marginLeft: '5px'
            } },
            React.createElement(RemoveIcon, { onClick: () => setVisible(true) })),
        visible && (React.createElement(ExitDialog, { title: I18n.t('components.freeTimeDeleteModal.title'), message: I18n.t('components.freeTimeDeleteModal.message'), cancelButtonText: I18n.t('components.freeTimeDeleteModal.cancelMessage'), confirmButtonText: I18n.t('components.freeTimeDeleteModal.confirmMessage'), onPressCancel: () => setVisible(false), onPressConfirm: () => {
                handleConfirm();
                setVisible(false);
            } }))));
};
export default FreeTimeDemurrageDeleteModal;
