import React, { useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead } from '@material-ui/core/';
import EditIcon from '../../../application/icons/EditIcon';
import RemoveIcon from '../../../application/icons/RemoveIcon';
import { IconsContainer, IndividualIconContainer, TableHeader, StyledTableRow, BottomRow } from './style';
import { I18n } from 'react-redux-i18n';
import FormatNumber from '../../../application/utils/formatNumber';
const ChargeTable = ({ charges, onEdit, onDelete, specification, modal, setCalculation }) => {
    let amount = 0;
    let weight = 0;
    let cubage = 0;
    let cubageWeight = 0;
    useEffect(() => {
        setCalculation({ weight, cubage, cubageWeight });
    }, [charges]);
    const isLand = () => {
        return modal === 'LAND';
    };
    const isFCL = () => {
        return specification === 'fcl' && modal === 'SEA';
    };
    const isAir = () => {
        return modal === 'AIR';
    };
    return (React.createElement(TableContainer, null,
        React.createElement(Table, null,
            React.createElement(TableHead, null,
                React.createElement(StyledTableRow, { noBorder: true },
                    React.createElement(TableCell, null,
                        React.createElement(TableHeader, null, "Item #")),
                    React.createElement(TableCell, null,
                        React.createElement(TableHeader, null, String(I18n.t('components.itemModal.qnt')))),
                    React.createElement(TableCell, null,
                        React.createElement(TableHeader, null, String(I18n.t('components.itemModal.type')))),
                    !isLand() && React.createElement(React.Fragment, null,
                        !isFCL() && React.createElement(TableCell, null,
                            React.createElement(TableHeader, null, String(I18n.t('components.itemModal.rawWeight')))),
                        !isFCL() && React.createElement(TableCell, null,
                            React.createElement(TableHeader, null, String(I18n.t('components.itemModal.cubage')))),
                        !isFCL() && React.createElement(TableCell, null,
                            React.createElement(TableHeader, null, String(I18n.t('components.itemModal.hwl')))),
                        (!isFCL()) && React.createElement(TableCell, null,
                            React.createElement(TableHeader, null, String(I18n.t('components.itemModal.diameter'))))))),
            React.createElement(TableBody, null,
                charges.map((row, i) => {
                    var _a, _b, _c;
                    amount += Number((_a = row.amount) === null || _a === void 0 ? void 0 : _a.replace(',', '.'));
                    cubage += Number((_b = row.cubage) === null || _b === void 0 ? void 0 : _b.replace(',', '.'));
                    weight += Number((_c = row.rawWeight) === null || _c === void 0 ? void 0 : _c.replace(',', '.'));
                    cubageWeight = FormatNumber.convertNumberToDecimal(Number(cubage) / 0.006);
                    return (React.createElement(StyledTableRow, { key: i, noBorder: i + 1 === charges.length },
                        React.createElement(TableCell, { component: "th", scope: "row" }, i + 1),
                        React.createElement(TableCell, null, row.amount),
                        React.createElement(TableCell, null, String(row.type).toUpperCase()),
                        !isLand() && React.createElement(React.Fragment, null,
                            !isFCL() ? React.createElement(TableCell, null, row.rawWeight !== null ? Number(row.rawWeight.replace(',', '.')).toFixed(2).replace('.', ',') : '-') : React.createElement(TableCell, null),
                            !isFCL() ? React.createElement(TableCell, null, row.cubage !== null ? Number(row.cubage.replace(',', '.')).toFixed(2).replace('.', ',') : '-') : React.createElement(TableCell, null),
                            !isFCL() ? React.createElement(TableCell, null, row.length !== null && row.width !== null && row.height !== null ? `${Number(row.length.replace(',', '.')).toFixed(2).replace('.', ',')} x ${Number(row.width.replace(',', '.')).toFixed(2).replace('.', ',')} x ${Number(row.height.replace(',', '.')).toFixed(2).replace('.', ',')}` : '-') : React.createElement(TableCell, null),
                            (!isFCL()) ? React.createElement(TableCell, null, row.diameter !== null ? row.diameter : '-') : React.createElement(TableCell, null)),
                        React.createElement(TableCell, { align: "right" },
                            React.createElement(IconsContainer, null,
                                React.createElement(IndividualIconContainer, null,
                                    React.createElement(EditIcon, { onClick: () => onEdit(row) })),
                                React.createElement(IndividualIconContainer, null,
                                    React.createElement(RemoveIcon, { onClick: () => onDelete(i) }))))));
                }),
                React.createElement(BottomRow, null,
                    React.createElement(TableCell, null,
                        React.createElement("b", null, "Total:")),
                    React.createElement(TableCell, null,
                        amount,
                        " ",
                        isLand() ? 'veículo(s)' : 'volume(s)'),
                    React.createElement(TableCell, null),
                    !isLand() && React.createElement(React.Fragment, null,
                        !isFCL() ? React.createElement(TableCell, null,
                            React.createElement("b", null, I18n.t('components.itemModal.rawWeight')),
                            Number(weight).toFixed(2).replace('.', ','),
                            " kg") : React.createElement(TableCell, null),
                        !isFCL() ? React.createElement(TableCell, null,
                            React.createElement("b", null, I18n.t('pages.newProposal.step3.cubage')),
                            Number(cubage).toFixed(2).replace('.', ',')) : React.createElement(TableCell, null),
                        isAir() ? React.createElement(TableCell, null,
                            React.createElement("b", null, I18n.t('components.itemModal.cubageWeight')),
                            Number(cubageWeight).toFixed(2).replace('.', ',')) : React.createElement(TableCell, null)),
                    React.createElement(TableCell, null),
                    React.createElement(TableCell, null))))));
};
export default ChargeTable;
