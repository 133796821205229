import React, { useState } from "react";
import {
  LoadStepPegeable,
  LoadStep,
  AddStep as DomainAddStep,
  LoadStepTypeList,
  Load,
  Put,
  Post,
} from "@/domain/usecase";
import {
  LabelCell,
  Pagination,
  Table,
  ProgressIndicator,
} from "fiorde-fe-components";
import { useQuery } from "@tanstack/react-query";
import {
  PageHeader,
  AddStep,
} from "@/presentation/components";
import { t } from "i18next";
import { UpdateStep } from "@/presentation/components/step/update-step";
import { DisableStep } from "@/presentation/components/step/disable-step";
import { CloneStep } from "@/presentation/components/step/clone-step";
import { useParams } from "react-router-dom";

type StepListProps = {
  LoadStepPegeable: LoadStepPegeable;
  loadStep: LoadStep;
  addStep: DomainAddStep;
  loadStepTypeList: LoadStepTypeList;
  loadConditionType: Load<any>
  loadConditionValue: Load<any>
  loadAdditionalFieldType: Load<any>
  loadChecklistAll: Load<any>
  loadSectionByCheckListId: Load<any>
  putStep: Put<any>
};

const columns = (
  addStep,
  putStep,
  loadStep,
  loadStepTypeList,
  loadConditionType,
  loadConditionValue,
  loadSectionByCheckListId,
  loadAdditionalFieldType,
  loadChecklistAll,
  receivedId
) => [
  {
    label: t("page.step-list.columns.step-name"),
    key: "name",
    render: ({ name }: any) => <LabelCell>{name}</LabelCell>,
    size: 3.5,
  },
  {
    label: t("page.step-list.columns.menu"),
    key: "menu",
    render: ({ id, isActive }) => {

      return (
        <div style={{ display: "flex", gap: 8 }}>
          <UpdateStep
            id={id}
            putStep={putStep}
            loadStep={loadStep}
            loadStepTypeList={loadStepTypeList}
            loadConditionType={loadConditionType}
            loadConditionValue={loadConditionValue}
            loadSectionByCheckListId={loadSectionByCheckListId}
            loadAdditionalFieldType={loadAdditionalFieldType}
            loadChecklistAll={loadChecklistAll}
          />
          <CloneStep
            addStep={addStep}
            loadStep={loadStep}
            id={id}
          />
          <DisableStep
            putStep={putStep}
            loadStep={loadStep}
            id={id}
            checked={isActive}
          />
        </div>
      )
    },
    size: 0.5,
  },
];

export function Step({
  LoadStepPegeable,
  loadStep,
  addStep,
  loadStepTypeList,
  loadConditionType,
  loadConditionValue,
  loadAdditionalFieldType,
  loadChecklistAll,
  loadSectionByCheckListId,
  putStep,
}: StepListProps) {
  const [filter, setFilter] = useState({
    page: 0,
    size: 10,
    sort: "id,desc",
  });
  const { isLoading, isRefetching, data } = useQuery({
    queryKey: ["step-list", filter],
    queryFn: async () => LoadStepPegeable.load(filter),
  });
  const { id } = useParams()
  return (
    <div>
      <ProgressIndicator
        visible={isLoading || isRefetching}
        content={t("components.progress-indicator.content")}
      />
      <PageHeader title={t("page.step-list.header")}>
      {id && (
        <UpdateStep
          id={Number(id)}
          putStep={putStep}
          loadStep={loadStep}
          loadStepTypeList={loadStepTypeList}
          loadConditionType={loadConditionType}
          loadConditionValue={loadConditionValue}
          loadSectionByCheckListId={loadSectionByCheckListId}
          loadAdditionalFieldType={loadAdditionalFieldType}
          loadChecklistAll={loadChecklistAll}
          hiddenButton={true}
        />
      )}
      <AddStep
        addStep={addStep}
        loadStepTypeList={loadStepTypeList}
        loadConditionType={loadConditionType}
        loadConditionValue={loadConditionValue}
        loadAdditionalFieldType={loadAdditionalFieldType}
        loadChecklistAll={loadChecklistAll}
        loadSectionByCheckListId={loadSectionByCheckListId}
      />
      </PageHeader>
      <Table
        rows={data?.content || []}
        columns={columns(
          addStep,
          putStep,
          loadStep,
          loadStepTypeList,
          loadConditionType,
          loadConditionValue,
          loadSectionByCheckListId,
          loadAdditionalFieldType,
          loadChecklistAll,
          id
        )}
      />
      <Pagination
        count={data?.totalElements || 0}
        labelDisplay="exibindo"
        labelDisplayedRows="de"
        labelRowsPerPage="Etapas por página"
        onPageChange={(value) =>
          setFilter((filter: any) => ({ ...filter, page: value }))
        }
        onRowsPerPageChange={(value) =>
          setFilter((filter: any) => ({
            ...filter,
            size: value,
            page: 0,
          }))
        }
        tooltipBack="Anterior"
        tooltipFirst="Primeira"
        tooltipLast="Última"
        tooltipNext="Próxima"
        page={filter.page}
      />
    </div>
  );
}
