import { RemoteLoadConditionList } from "@/data/usecase";
import { LoadConditionList } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteLoadConditionList = (): LoadConditionList =>
  new RemoteLoadConditionList(
    makeApiUrl("/sirius-checklist-api/v1/condition/type/all"),
    makeAuthorizeHttpClientDecorator()
  );
