import { RemoteLoadFlowPegeable } from "@/data/usecase";
import { LoadFlowPegeable } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteLoadFlowPegeable = (): LoadFlowPegeable =>
  new RemoteLoadFlowPegeable(
    makeApiUrl("/sirius-yard-control-api/v1/flow"),
    makeAuthorizeHttpClientDecorator()
  );
