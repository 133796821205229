import React from "react";
import { Flow } from "@/presentation/pages/flow/page";
import {
  makeRemoteLoadFlowPegeable,
  makeRemoteLoadFlow,
} from "@/main/factories/usecases";

export const makeFlowList: React.FC = (props) => {
  return (
    <Flow
      {...props}
      LoadFlowPegeable={makeRemoteLoadFlowPegeable()}
      loadFlow={makeRemoteLoadFlow()}
    />
  );
};
