import { RemoteLoadChecklistList } from "@/data/usecase";
import { LoadChecklistList } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteLoadChecklistList = (): LoadChecklistList =>
  new RemoteLoadChecklistList(
    makeApiUrl("/sirius-checklist-api/v1/checklist"),
    makeAuthorizeHttpClientDecorator()
  );
