

import { AccessDeniedError, UnexpectedError } from '@/domain/errors'
import { BackendPegeableResponse, HttpClient, HttpStatusCode } from '@/domain/protocols/http'
import { LoadMaterialClassificationList } from '@/domain/usecase'
import { RemoteLoadMaterialClassificationDTO } from '../models'

export class RemoteLoadMaterialClassificationList implements LoadMaterialClassificationList {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<LoadMaterialClassificationList.Model>
  ) {}

  async load (): Promise<LoadMaterialClassificationList.Model> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'get'
    })
    const LoadMaterialClassificationList = httpResponse.body
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return LoadMaterialClassificationList
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      default:
        //@ts-ignore
        const err = httpResponse.body?.response?.error;
        throw new UnexpectedError(err);
    }
  }
}

export namespace RemoteLoadMaterialClassificationList {
  export type Model = RemoteLoadMaterialClassificationDTO[]
}
