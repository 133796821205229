import axios from 'axios'

export const URL = process.env.BASE_URL_GATEWAY

const instance = axios.create({
  baseURL: URL,
  headers: {
    'Content-Type': 'application/json;'
  }
})

export default instance
