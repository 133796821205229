import { AccessDeniedError, UnexpectedError } from "@/domain/errors";
import { HttpClient, HttpStatusCode } from "@/domain/protocols/http";
import { LoadTypeFieldList } from "@/domain/usecase";
import { RemoteLoadTypeFieldListDTO } from "../models";

export class RemoteLoadTypeFieldList implements LoadTypeFieldList {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<RemoteLoadTypeFieldList.Model>
  ) {}

  async load(): Promise<LoadTypeFieldList.Model> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: "get",
    });
    const LoadClassificationList = httpResponse.body;
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return LoadClassificationList;
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }
}

export namespace RemoteLoadTypeFieldList {
  export type Model = RemoteLoadTypeFieldListDTO;
}
