import React, { useState } from "react";
import {
  UpdateChecklist as DomainUpdateChecklist,
  LoadChecklist,
} from "@/domain/usecase";
import {
  Button,
  Modal,
  Messages,
  IconButton,
  ProgressIndicator,
  Switch,
} from "fiorde-fe-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";

type DisableChecklistProps = {
  updateChecklist: DomainUpdateChecklist;
  loadChecklist: LoadChecklist;
  id: number;
  checked: boolean;
};

export function DisableChecklist({
  updateChecklist,
  loadChecklist,
  id,
  checked,
}: DisableChecklistProps) {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: "",
  });

  const { data } = useQuery<any>({
    queryKey: [`checklist-${id}`],
    queryFn: async () => await loadChecklist.load(id),
    enabled: open,
  });

  function handleSubmit(): void {
    setLoading(true);
    updateChecklist
      .update(id, {
        ...data,
        isActive: !checked,
      })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["checklist-list"] });
        handleClose();
        setResponseMessage({
          message: checked
            ? t("components.disable-checklist.response-success-message.active")
            : t(
                "components.disable-checklist.response-success-message.disabled"
              ),
          severity: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        setResponseMessage({
          message: err?.message,
          severity: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    queryClient.invalidateQueries({ queryKey: [`checklist-${id}`] });
  }

  return (
    <>
      <ProgressIndicator
        visible={isLoading}
        content={t("components.progress-indicator.content")}
      />
      <IconButton
        id="SIRIUS-CHECKLIST-FE_COMPONENT_ADD-CHECKLIST_BUTTON_OPEN-DISABLE"
        data-testid="open-button"
        onClick={handleOpen}
        tooltip={
          checked
            ? t("components.disable-checklist.open-button-tooltip.active")
            : t("components.disable-checklist.open-button-tooltip.disabled")
        }
      >
        <Switch checked={checked} />
      </IconButton>
      <Modal
        width={600}
        title={
          checked
            ? t("components.disable-checklist.title.active")
            : t("components.disable-checklist.title.disabled")
        }
        isOpen={open}
        onClose={handleClose}
        actions={[
          <Button
            id="SIRIUS-CHECKLIST-FE_COMPONENT_ADD-CHECKLIST_BUTTON_CLOSE-DISABLE"
            data-testid="close-button"
            backgroundGreen={false}
            onAction={() => handleClose()}
            text={t("components.disable-checklist.close-button")}
          />,
          <Button
            id="SIRIUS-CHECKLIST-FE_COMPONENT_ADD-CHECKLIST_BUTTON_SUBMIT-DISABLE"
            data-testid="submit-button"
            onAction={() => handleSubmit()}
            text={t("components.disable-checklist.submit-button")}
          />,
        ]}
      >
        <span>
          {checked
            ? t("components.disable-checklist.content.active")
            : t("components.disable-checklist.content.disabled")}{" "}
          {data?.name}?
        </span>
      </Modal>
      {responseMessage.message && (
        <Messages
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={() =>
            setResponseMessage({
              message: "",
              severity: "",
            })
          }
        />
      )}
    </>
  );
}
