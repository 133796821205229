import React, { useState } from "react";
import {
  AddOrganization as DomainOrganization,
  LoadOrganizationList,
  UpdateOrganization as DomainUpdateOrganization,
  LoadOrganization,
} from "@/domain/usecase";
import { LabelCell, ProgressIndicator, Pagination, Table } from "fiorde-fe-components";
import { useQuery } from "@tanstack/react-query";
import {
  PageHeader,
  AddOrganization,
  UpdateOrganization,
} from "@/presentation/components";
import { t } from "i18next";
import { DisableOrganization } from "@/presentation/components/disable-organization/disable-organization";
import { 
  PREFIX_ORGANIZATION_LISTORGANIZATION_LABELCELL_NAME
 } from "@/ids";

type OrganizationListProps = {
  addOrganization: DomainOrganization;
  loadOrganization: LoadOrganization;
  loadOrganizationList: LoadOrganizationList;
  updateOrganization: DomainUpdateOrganization;
};

const columns = (updateOrganization: DomainUpdateOrganization, loadOrganization: LoadOrganization) => [
  {
    label: "Organização",
    key: "name",
    render: ({ name }: any) => (
      <LabelCell id={PREFIX_ORGANIZATION_LISTORGANIZATION_LABELCELL_NAME}>{name}</LabelCell>
    ),
    size: 6.5,
  },
  {
    label: "Menu",
    key: "menu",
    render: ({ id, isActive }) => (
      <div style={{ display: "flex", gap: 8 }}>
        <UpdateOrganization
          id={id}
          loadOrganization={loadOrganization}
          updateOrganization={updateOrganization}
        />
        <DisableOrganization
          id={id}
          checked={isActive}
          loadOrganization={loadOrganization}
          updateOrganization={updateOrganization}
        />
      </div>
    ),
    size: 0.5,
  },
];

export function Organization({
  addOrganization,
  loadOrganization,
  loadOrganizationList,
  updateOrganization,
}: OrganizationListProps) {
  const [filter, setFilter] = useState({
    page: 0,
    size: 10,
    sort: "id,desc",
  });
  const { isLoading, isRefetching, data } = useQuery({
    queryKey: ["organization-list", filter],
    queryFn: async () => loadOrganizationList.load(filter),
  });
  return (
    <div>
      <ProgressIndicator
        visible={isLoading || isRefetching}
        content={t("components.progress-indicator.content")}
      />
      <PageHeader title="Organização">
        <AddOrganization addOrganization={addOrganization} />
      </PageHeader>
      <Table
        rows={data?.content || []}
        columns={columns(updateOrganization, loadOrganization)}
      />
      <Pagination
        count={data?.totalElements || 0}
        labelDisplay="exibindo"
        labelDisplayedRows="de"
        labelRowsPerPage="Organização por página"
        onPageChange={(value) =>
          setFilter((filter: any) => ({ ...filter, page: value }))
        }
        onRowsPerPageChange={(value) =>
          setFilter((filter: any) => ({
            ...filter,
            size: value,
            page: 0,
          }))
        }
        tooltipBack="Anterior"
        tooltipFirst="Primeira"
        tooltipLast="Última"
        tooltipNext="Próxima"
        page={filter.page}
      />
    </div>
  );
}
