type makeSelectValueProps = {
  label: string;
  value: string;
  array: Array<any>;
  subLabel?: string;
};

export function makeSelectValues({
  label,
  value,
  array,
  subLabel
}: makeSelectValueProps) {
  if (subLabel) {
    return array?.map((arrayItem) => ({
      label: arrayItem[subLabel][label],
      value: arrayItem[subLabel][value],
    }));
  } else {
    return array?.map((arrayItem) => ({
      label: arrayItem[label],
      value: arrayItem[value],
    }));
  }
}
