import { RemoteLoadMovementTypeList } from "@/data/usecase";
import { LoadMovementTypeList } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteLoadMovementTypeList = (): LoadMovementTypeList =>
  new RemoteLoadMovementTypeList(
    makeApiUrl("/sirius-yard-control-api/v1/movement/type/all"),
    makeAuthorizeHttpClientDecorator()
  );
