import React, { useState } from 'react'
import { LogoIcon, Alert } from 'fiorde-fe-components'
import PasswordContainer from '../../components/PasswordContainer'
import { MainContainer, Container, Error, InputContainer, ButtonContainer } from './style'
import { I18n } from 'react-redux-i18n'
import ArrowButton from '../../components/ArrowButton'
import CustomButton from '../../components/CustomButton'
import API from '../../../infrastructure/api'
import { TextField } from '@material-ui/core'
import { useLocation, useHistory } from 'react-router-dom'

const Register = (): JSX.Element => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [passwords, setPasswords] = useState({ new: '', repeat: '' })
  const [showPasswords, setShowPasswords] = useState({ new: false, repeat: false })
  const [showErrorFirstName, setShowErrorFirstName] = useState({ show: false, msg: '' })
  const [showErrorLastName, setShowErrorLastName] = useState({ show: false, msg: '' })
  const [showError, setShowError] = useState({ new: false, repeat: false })
  const [alert, setAlert] = useState({ show: false, message: '' })

  const history = useHistory()
  const location = useLocation()

  const checkPasswords = (newp, repeatp): string => {
    if (newp.length < 8 || repeatp.length < 8) {
      return (I18n.t('pages.register.errors.eightChars'))
    }
    if (!(/^(?=.*\d)/).test(newp) || !(/^(?=.*\d)/).test(repeatp)) {
      return (I18n.t('pages.register.errors.oneDigit'))
    }
    if (!(/^(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/).test(newp) || !(/^(?=.*[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/).test(repeatp)) {
      return (I18n.t('pages.register.errors.specialChar'))
    }
    if (!(/^(?=.*[a-z])(?=.*[A-Z])/).test(newp) || !(/^(?=.*[a-z])(?=.*[A-Z])/).test(repeatp)) {
      return (I18n.t('pages.register.errors.upperLower'))
    }

    return ''
  }

  const validNames = (): boolean => {
    if ((firstName === '' || firstName === null) && (lastName === '' || lastName === null)) {
      setShowErrorFirstName({ show: true, msg: I18n.t('pages.register.errors.requiredFirstName') })
      setShowErrorLastName({ show: true, msg: I18n.t('pages.register.errors.requiredLastName') })
      validPasswords()
      return false
    } else if (firstName === '' || firstName === null) {
      setShowErrorFirstName({ show: true, msg: I18n.t('pages.register.errors.requiredFirstName') })
      validPasswords()
      return false
    } else if (lastName === '' || lastName === null) {
      setShowErrorLastName({ show: true, msg: I18n.t('pages.register.errors.requiredLastName') })
      validPasswords()
      return false
    }
    return true
  }

  const validPasswords = (): boolean => {
    const newp = passwords.new
    const repeatp = passwords.repeat

    if (newp !== '' && repeatp !== '') {
      if (newp === repeatp) {
        const msg = checkPasswords(newp, repeatp)
        if (msg !== '') {
          setAlert({
            show: true,
            message: msg
          })
          return false
        }
      } else {
        setAlert({ show: true, message: I18n.t('pages.register.errors.differentPwds') })
        return false
      }
    } else {
      setShowError({
        ...showError,
        new: newp === '',
        repeat: repeatp === ''
      })

      return false
    }

    return true
  }

  const handleRegister = (): void => {
    setAlert({ show: false, message: '' })

    if (validNames() && validPasswords()) {
      const params = {
        email: location.state.email,
        password: passwords.new,
        firstName: firstName,
        lastName: lastName
      }

      void API.postUser(params).then(
        history.push('/login')
      )
    }
  }

  const handleClick = (name: any, value: boolean): void => {
    setShowPasswords({ ...showPasswords, [name]: !value })
  }

  const handleChange = (evt): void => {
    const value = evt.target.value
    setPasswords({
      ...passwords,
      [evt.target.name]: value
    })
    setShowError({
      ...showError,
      [evt.target.name]: false
    })
  }

  const handleChangeFirstName = (value): void => {
    setFirstName(value)
    setShowErrorFirstName({ show: false, msg: '' })
  }

  const handleChangeLastName = (value): void => {
    setLastName(value)
    setShowErrorLastName({ show: false, msg: '' })
  }

  return (
    <MainContainer id="mainContainer">
      <LogoIcon id="logoIcon" />
      <Container id="container">
        <p id="labelMessage">{I18n.t('pages.register.registerPwd')}</p>
        <Error>
          {alert.show && (
            <Alert backgroundColor="#B85151" fontColor="#FFFFFF">
              <span>{alert.message}</span>
            </Alert>
          )}
        </Error>
        <InputContainer>
          <TextField
            error={showErrorFirstName.show}
            helperText={showErrorFirstName.show && showErrorFirstName.msg}
            id="firstname"
            name="firstname"
            value={firstName}
            label={I18n.t('pages.register.firstName')}
            margin="normal"
            onChange={(e) => handleChangeFirstName(e.target.value)}
            size="small"
          />
          <TextField
            error={showErrorLastName.show}
            helperText={showErrorLastName.show && showErrorLastName.msg}
            id="lastname"
            name="lastname"
            value={lastName}
            label={I18n.t('pages.register.lastName')}
            margin="normal"
            onChange={(e) => handleChangeLastName(e.target.value)}
            size="small"
          />
          <PasswordContainer
            id="password"
            name="new"
            label={I18n.t('pages.register.newPassword')}
            value={passwords.new}
            showError={showError.new}
            errMsg={I18n.t('pages.register.errors.requiredPwd')}
            showPasswords={showPasswords.new}
            handleChange={handleChange}
            handleClick={() => handleClick('new', showPasswords.new)}
          />
          <PasswordContainer
            id="repeat-password"
            name="repeat"
            label={I18n.t('pages.register.repeatPassword')}
            value={passwords.repeat}
            showError={showError.repeat}
            errMsg={I18n.t('pages.register.errors.requiredRepeatPwd')}
            showPasswords={showPasswords.repeat}
            handleChange={handleChange}
            handleClick={() => handleClick('repeat', showPasswords.repeat)}
          />
        </InputContainer>
        <ButtonContainer>
          <ArrowButton id="cancelButton" onPress={() => window.open('/', '_self')} position='left'>
            {I18n.t('pages.register.cancel')}
          </ArrowButton>
          <CustomButton id="registerButton" onPress={handleRegister}>
            {I18n.t('pages.register.registerAccount')}
          </CustomButton>
        </ButtonContainer>
      </Container>
    </MainContainer>
  )
}

export default Register
