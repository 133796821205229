type makeSelectValueProps = {
  label: string,
  value: string,
  array: Array<any> 
}

export function makeSelectValues ({ label, value, array }: makeSelectValueProps) {
  return array?.map(arrayItem => ({
    label: arrayItem[label],
    value: arrayItem[value],
  }))
}

