import React, { useState } from "react";
import {
  AddChecklist as DomainChecklist,
  LoadChecklistList,
  UpdateChecklist as DomainUpdateChecklist,
  LoadChecklist,
  LoadConditionList,
  LoadOperatorList,
  LoadTypeFieldList,
  LoadSystemList
} from "@/domain/usecase";
import {
  LabelCell,
  ProgressIndicator,
  Pagination,
  Table,
} from "fiorde-fe-components";
import { useQuery } from "@tanstack/react-query";
import {
  PageHeader,
  AddChecklist,
  UpdateChecklist,
  DisableChecklist,
} from "@/presentation/components";
import { t } from "i18next";

type ChecklistListProps = {
  addChecklist: DomainChecklist;
  loadChecklist: LoadChecklist;
  loadChecklistList: LoadChecklistList;
  updateChecklist: DomainUpdateChecklist;
  loadConditionList: LoadConditionList;
  loadOperatorList: any;
  loadTypeFieldList: LoadTypeFieldList;
  loadSystemList: LoadSystemList;
};

const columns = (
  updateChecklist: DomainUpdateChecklist,
  loadChecklist: LoadChecklist,
  loadOperatorList: any,
  loadConditionList: LoadConditionList,
  loadTypeFieldList: LoadTypeFieldList,
  loadSystemList: LoadSystemList,
) => [
  {
    label: "Checklist",
    key: "name",
    render: ({ name }: any) => <LabelCell>{name}</LabelCell>,
    size: 12,
  },
  {
    label: "Menu",
    key: "menu",
    render: ({ id, isActive }) => (
      <div style={{ display: "flex", gap: 8 }}>
        <UpdateChecklist
          id={id}
          loadChecklist={loadChecklist}
          updateChecklist={updateChecklist}
          loadOperatorList={loadOperatorList}
          loadConditionList={loadConditionList}
          loadTypeFieldList={loadTypeFieldList}
          loadSystemList={loadSystemList}
        />
        <DisableChecklist
          id={id}
          checked={isActive}
          loadChecklist={loadChecklist}
          updateChecklist={updateChecklist}
        />
      </div>
    ),
    size: 0,
  },
];

export function Checklist({
  addChecklist,
  loadChecklist,
  loadChecklistList,
  updateChecklist,
  loadOperatorList,
  loadConditionList,
  loadTypeFieldList,
  loadSystemList
}: ChecklistListProps) {
  const [filter, setFilter] = useState({
    page: 0,
    size: 10,
    sort: "id,desc",
  });
  const { isLoading, isRefetching, data } = useQuery({
    queryKey: ["checklist-list", filter],
    queryFn: async () => loadChecklistList.load(filter),
  });
  return (
    <div>
      <ProgressIndicator
        visible={isLoading || isRefetching}
        content={t("components.progress-indicator.content")}
      />
      <PageHeader title="Checklist">
        <AddChecklist
          addChecklist={addChecklist}
          loadOperatorList={loadOperatorList}
          loadConditionList={loadConditionList}
          loadTypeFieldList={loadTypeFieldList}
          loadSystemList={loadSystemList}
        />
      </PageHeader>
      <Table
        rows={data?.content || []}
        columns={columns(
          updateChecklist,
          loadChecklist,
          loadOperatorList,
          loadConditionList,
          loadTypeFieldList,
          loadSystemList
        )}
      />
      <Pagination
        count={data?.totalElements || 0}
        labelDisplay="exibindo"
        labelDisplayedRows="de"
        labelRowsPerPage="Checklist por página"
        onPageChange={(value) =>
          setFilter((filter: any) => ({ ...filter, page: value }))
        }
        onRowsPerPageChange={(value) =>
          setFilter((filter: any) => ({
            ...filter,
            size: value,
            page: 0,
          }))
        }
        tooltipBack="Anterior"
        tooltipFirst="Primeira"
        tooltipLast="Última"
        tooltipNext="Próxima"
        page={filter.page}
      />
    </div>
  );
}
