import { Button, FormControl, Switch, Table } from 'fiorde-fe-components'
import { t } from 'i18next'
import React from 'react'
import { ChecklistConditionForm } from './checklist-condition-form'
import { handleAddForm, handleCloseForm, handleEditCondition, handleRemoveCondition } from './checklist-helpers'
import { ChecklistConditionsColumns } from './checklist-conditions-columns'
import { CHECKLIST_CONDITIONCONTAINER_BUTTON_OPEN, CHECKLIST_CONDITIONCONTAINER_SWITCH_CONDITION } from '@/ids'

export function ChecklistConditionsContainer({
  enableConditions,
  setEnableConditions,
  visibleForm,
  conditions,
  setConditions,
  conditionForm,
  setVisibleForm,
  setConditionForm,
  conditionsList,
  operatorList
}) {
  return (
    <>
      <FormControl label={t("components.add-checklist.conditions-label")}>
        <Switch
          id={CHECKLIST_CONDITIONCONTAINER_SWITCH_CONDITION}
          label={
            enableConditions
              ? t("components.add-checklist.conditions-enabled-label")
              : t("components.add-checklist.conditions-disabled-label")
          }
          checked={enableConditions}
          onChange={() => setEnableConditions(!enableConditions)}
        />
      </FormControl>

      {enableConditions && (
        <>
          {t("components.add-checklist.condition-message")}
          <div style={{ margin: "20px 0px 0px 0px" }}>
            {visibleForm || !conditions.length ? (
              <ChecklistConditionForm
                values={conditionForm}
                onChange={(value) => setConditionForm(value)}
                conditionsList={conditionsList}
                compareList={operatorList}
                onSubmit={() =>
                  handleAddForm(
                    conditionForm,
                    conditions,
                    setConditions,
                    () => handleCloseForm(setConditionForm, setVisibleForm)
                  )
                }
                onClose={() =>
                  handleCloseForm(setConditionForm, setVisibleForm)
                }
                disabledCloseButton={!conditions.length}
                conditions={conditions}
              />
            ) : (
              <>
                <Table
                  rows={conditions || []}
                  columns={ChecklistConditionsColumns({
                    conditionList: conditionsList,
                    operatorList: operatorList,
                    edit: (condition, index) =>
                      handleEditCondition(
                        condition,
                        index,
                        setConditionForm,
                        setVisibleForm
                      ),
                    remove: (index) =>
                      handleRemoveCondition(conditions, index, setConditions),
                  })}
                  size="small"
                />
              </>
            )}
            <Button
              id={CHECKLIST_CONDITIONCONTAINER_BUTTON_OPEN}
              data-testid="new-condition-button"
              onAction={() => setVisibleForm(true)}
              text={t("components.add-checklist.new-condition-button")}
              backgroundGreen={false}
            />
          </div>
        </>
      )}
    </>
  )
}