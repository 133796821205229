import React, { useState } from 'react';
import { StyledTooltip } from './ControlledToolTipStyles';
const ControlledToolTip = (props) => {
    const [mouseOn, setMouseOn] = useState(false);
    const mouseEnterHandler = () => {
        setMouseOn(true);
    };
    const mouseLeaveHandler = () => {
        setMouseOn(false);
    };
    return (React.createElement("div", { onClick: mouseLeaveHandler, onMouseEnter: mouseEnterHandler, onMouseLeave: mouseLeaveHandler },
        React.createElement(StyledTooltip, { "aria-label": "toolTip", title: props.title, open: mouseOn && props.open && props.title !== '' }, props.children)));
};
export default ControlledToolTip;
