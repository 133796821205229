import { RemoteLoadCitiesList } from "@/data/usecase";
import { LoadCitiesList } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteLoadCitiesList = (): LoadCitiesList =>
  new RemoteLoadCitiesList(
    makeApiUrl("/sirius-master-data-api/v1/city/states"),
    makeAuthorizeHttpClientDecorator()
  );
