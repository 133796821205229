import { RemoteLoadDockPegeable } from "@/data/usecase";
import { LoadDockPegeable } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteLoadDockPegeable = (): LoadDockPegeable =>
  new RemoteLoadDockPegeable(
    makeApiUrl("/sirius-yard-control-api/v1/dock"),
    makeAuthorizeHttpClientDecorator()
  );
