import { HashRouter, Routes, Route } from 'react-router-dom'
import {
  makeHome,
} from '@/main/factories/pages'

export function Router() {
  return (
    <HashRouter basename='/pu-catp'>
      <Routes>
        <Route path="/" element={makeHome()}/>
      </Routes>
    </HashRouter>
  );
}
