import React, { useEffect, useState } from "react";
import {
  UpdateChecklist as DomainUpdateChecklist,
  LoadChecklist,
  LoadConditionList,
  LoadTypeFieldList,
  LoadSystemList,
} from "@/domain/usecase";
import {
  Button,
  Modal,
  FormControl,
  Messages,
  Input,
  IconButton,
  Switch,
  Table,
  QuestionTree,
} from "fiorde-fe-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EditIcon } from "../icons";
import { t } from "i18next";
import { OPERATOR } from "@/main/enums/checklist";
import { ChecklistConditionForm } from "./checklist-condition-form";
import { createPayload, handleAddForm, handleAddQuestionForm, handleAddSectionForm, handleCloseForm, handleCloseQuestionForm, handleCloseSectionForm, handleEdit, handleEditCondition, handleEditSection, handleRemove, handleRemoveCondition, handleRemoveSection, parsePayload } from "./checklist-helpers";
import { ChecklistConditionsColumns } from "./checklist-conditions-columns";
import { ChecklistSectionForm } from "./checklist-section-form";
import { ChecklistSectionColumns } from "./checklist-section-columns";
import { ChecklistQuestionForm } from "./checklist-question-form";
import { ChecklistGeneralForm } from "./checklist-general-form";
import { ChecklistConditionsContainer } from "./checklist-conditions-container";
import { ChecklistSectionsQuestionsContainer } from "./checklist-sections-questions-container";
import { CHECKLIST_UPDATECHECKLIST_BUTTON_CANCEL, CHECKLIST_UPDATECHECKLIST_BUTTON_OPEN, CHECKLIST_UPDATECHECKLIST_BUTTON_SUBMIT, CHECKLIST_UPDATECHECKLIST_LABEL_MESSAGE, CHECKLIST_UPDATECHECKLIST_MODAL_TITLE } from "@/ids";

type UpdateChecklistProps = {
  updateChecklist: DomainUpdateChecklist;
  loadChecklist: LoadChecklist;
  id: number;
  loadOperatorList: any;
  loadConditionList: LoadConditionList;
  loadTypeFieldList: LoadTypeFieldList;
  loadSystemList: LoadSystemList;
};

const initialQuestionForm = {
  index: null,
  name: "",
  questionType: null,
  description: "",
  isRequired: false,
  isConditional: false,
  answers: [],
  section: 0
};

export function UpdateChecklist({
  updateChecklist,
  loadChecklist,
  id,
  loadOperatorList,
  loadConditionList,
  loadTypeFieldList,
  loadSystemList
}: UpdateChecklistProps) {
  const queryClient = useQueryClient();
  const [questionForm, setQuestionForm] = useState(initialQuestionForm);
  const [sectionForm, setSectionForm] = useState({
    index: null,
    name: "",
  });
  const [generalForm, setGeneralForm] = useState({
    name: '',
    systems: []
  })
  const [enableConditions, setEnableConditions] = useState(false);
  const [sections, setSections] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [visibleForm, setVisibleForm] = useState(false);
  const [visibleSectionForm, setVisibleSectionForm] = useState(false);
  const [visibleQuestionForm, setVisibleQuestionForm] = useState(false);
  const [sectionEditable, setSectionEditable] = useState();
  const [open, setOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: "",
  });
  const [conditionForm, setConditionForm] = useState({
    index: null,
    conditionType: { id: null },
    operator: '',
  });
  const { data } = useQuery({
    queryKey: [`checklist-${id}`],
    queryFn: async () => await loadChecklist.load(id),
    enabled: open,
    onSuccess: (data) => {
      const { conditions, questions, sections, name, systems } = parsePayload(data)
      setConditions(conditions)
      setGeneralForm({
        name: name,
        systems
      })
      setQuestions(questions)
      setSections(sections)
    }
  });

  const { data: conditionsList = [] } = useQuery({
    queryKey: ["condition-list"],
    queryFn: async () => await loadConditionList.load(),
    enabled: open,
  });
  
  const { data: typeFieldList = [] } = useQuery({
    queryKey: ["type-field-list"],
    queryFn: async () => await loadTypeFieldList.load(),
    enabled: open,
  });

  const { data: systemList = [] } = useQuery({
    queryKey: ["system-list"],
    queryFn: async () => await loadSystemList.load(),
    enabled: open,
  });

  const { data: operatorList = [] } = useQuery({
    queryKey: ["operator-list", conditionForm],
    queryFn: async () => await loadOperatorList(conditionsList.find((condition) =>  condition.id === conditionForm?.conditionType?.id)?.endpoint).load(),
    enabled: open && conditionsList.length > 0 && conditionForm.conditionType.id !== null,
  });

  function handleSubmit(): void {
    updateChecklist
      .update(id, createPayload(questions, conditions, sections, generalForm))
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["checklist-list"] });
        handleClose();
        setResponseMessage({
          message: t("components.update-checklist.response-success-message"),
          severity: "success",
        });
      })
      .catch((err) => {
        setResponseMessage({
          message: err?.message,
          severity: "error",
        });
      });
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <IconButton
        data-testid="open-button"
        onClick={handleOpen}
        tooltip={t("components.update-checklist.open-button-tooltip")}
        id={CHECKLIST_UPDATECHECKLIST_BUTTON_OPEN}
      >
        <EditIcon />
      </IconButton>
      <Modal
        id={CHECKLIST_UPDATECHECKLIST_MODAL_TITLE}
        data-testid="modal-update-checklist"
        width={600}
        title={t("components.update-checklist.title")}
        isOpen={open}
        onClose={handleClose}
        actions={[
          <Button
            id={CHECKLIST_UPDATECHECKLIST_BUTTON_CANCEL}
            data-testid="close-button"
            backgroundGreen={false}
            onAction={() => handleClose()}
            text={t("components.update-checklist.close-button")}
          />,
          <Button
            id={CHECKLIST_UPDATECHECKLIST_BUTTON_SUBMIT}
            data-testid="submit-button"
            onAction={() => handleSubmit()}
            text={t("components.update-checklist.submit-button")}
          />,
        ]}
      >
        <ChecklistGeneralForm
          values={generalForm}
          onChange={(value) => setGeneralForm(value)}
          systemList={systemList}
        />
        <ChecklistConditionsContainer
          enableConditions={enableConditions}
          setEnableConditions={setEnableConditions}
          visibleForm={visibleForm}
          conditions={conditions}
          setConditions={setConditions}
          conditionForm={conditionForm}
          setVisibleForm={setVisibleForm}
          setConditionForm={setConditionForm}
          conditionsList={conditionsList}
          operatorList={operatorList}
        />
        <ChecklistSectionsQuestionsContainer
          visibleSectionForm={visibleSectionForm}
          sectionForm={sectionForm}
          setSectionForm={setSectionForm}
          sections={sections}
          setSections={setSections}
          setVisibleSectionForm={setVisibleSectionForm}
          visibleQuestionForm={visibleQuestionForm}
          sectionEditable={sectionEditable}
          typeFieldList={typeFieldList}
          questionForm={questionForm}
          setQuestionForm={setQuestionForm}
          questions={questions}
          setQuestions={setQuestions}
          setVisibleQuestionForm={setVisibleQuestionForm}
          setSectionEditable={setSectionEditable}
        />
      </Modal>
      {responseMessage.message && (
        <Messages
          id={CHECKLIST_UPDATECHECKLIST_LABEL_MESSAGE}
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={() =>
            setResponseMessage({
              message: "",
              severity: "",
            })
          }
        />
      )}
    </>
  );
}
