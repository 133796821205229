import React from "react";
import { Spot } from "@/presentation/pages";
import {
  makeRemoteLoadSpotPegeable,
  makeRemoteAddSpot,
  makeRemoteLoadSpotStatusList,
  makeRemoteUpdateSpot,
  makeRemoteLoadSpot,
  makeRemoteLoadVehicleCompositionList,
} from "../../usecases";

export const makeSpotList: React.FC = (props) => {
  return (
    <Spot
      {...props}
      LoadSpotPegeable={makeRemoteLoadSpotPegeable()}
      addSpot={makeRemoteAddSpot()}
      loadSpotStatusList={makeRemoteLoadSpotStatusList()}
      updateSpot={makeRemoteUpdateSpot()}
      loadSpot={makeRemoteLoadSpot()}
      loadVehicleCompositionList={makeRemoteLoadVehicleCompositionList()}
    />
  );
};
