import styled from 'styled-components'

const Root = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: ${(props: any) => props.theme?.platform?.pages?.home?.mainColor};
  color: ${(props: any) => props.theme?.platform?.fontColor};
`

const Title = styled.div`
  padding: 30px 1px 1px 13%;
  display: flex;
  flex-direction: column;
  & span {
    font-family: DMSans-Regular;
  }
`

const WelcomeUserName = styled.span`
  font-size: 32px;
  font-weight: bold;
`

const ItemContainer = styled.span`
  font-size: 16px;
  padding-top: 40px;
  font-weight: 400;
`

const Container = styled.div`
  padding: 0px 1px 1px 13%;
  display: grid;
  grid-template-columns: auto;
  & > div {
    width: 800px;
  }
`

const Rows = styled.div`
  display: flex;  
  width: 100%;
  flex-direction: row;
  & > div {
    width: 800px;
  }
`

export { Rows, Container, ItemContainer, Root, Title, WelcomeUserName }
