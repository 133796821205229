import { AccessDeniedError, UnexpectedError } from "@/domain/errors";
import { HttpClient, HttpStatusCode } from "@/domain/protocols/http";
import { LoadChecklistList } from "@/domain/usecase";
import { RemoteLoadChecklistListDTO } from "../models";

export class RemoteLoadChecklistList implements LoadChecklistList {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<LoadChecklistList.Model>
  ) {}

  async load(filters: Object): Promise<LoadChecklistList.Model> {
    const queryString = Object.entries(filters)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    const httpResponse = await this.httpClient.request({
      url: `${this.url}?${queryString}`,
      method: "get",
    });
    const LoadChecklistList = httpResponse.body;
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return LoadChecklistList;
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }
}

export namespace RemoteLoadChecklistList {
  export type Model = RemoteLoadChecklistListDTO;
}
