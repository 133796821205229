import { RemoteLoadSpotStatusList } from "@/data/usecase";
import { LoadSpotStatusList } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteLoadSpotStatusList = (): LoadSpotStatusList =>
  new RemoteLoadSpotStatusList(
    makeApiUrl("/sirius-yard-control-api/v1/spot/status"),
    makeAuthorizeHttpClientDecorator()
  );
