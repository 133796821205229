import { RemoteLoadVehicleTypeList } from "@/data/usecase";
import { AddVehicleType, LoadVehicleTypeList } from "@/domain/usecase";
import { makeApiUrl } from "@/infrastructure/http/api-url-factory";
import { makeAuthorizeHttpClientDecorator } from "../decorators/authorize-http-client-decorator-factory";

export const makeRemoteLoadVehicleTypeList = (): LoadVehicleTypeList =>
  new RemoteLoadVehicleTypeList(
    makeApiUrl("/sirius-master-data-api/v1/vehicleType/page"),
    makeAuthorizeHttpClientDecorator()
  );
