import { HttpClient, HttpStatusCode } from "@/domain/protocols/http";
import { UpdateVehicleType } from "@/domain/usecase";
import { RemoteUpdateVehicleTypeDTO } from "../models";
import { AccessDeniedError, UnexpectedError } from "@/domain/errors";

export class RemoteUpdateVehicleType implements UpdateVehicleType {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<RemoteUpdateVehicleType.Model>
  ) {}

  async update (id: number, params: UpdateVehicleType.Params): Promise<void> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${String(id)}`,
      method: 'put',
      body: params
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent: break
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}

export namespace RemoteUpdateVehicleType {
  export type Model = RemoteUpdateVehicleTypeDTO;
}
