import { AccessDeniedError, UnexpectedError } from "@/domain/errors";
import {
  HttpClient,
  HttpStatusCode,
} from "@/domain/protocols/http";
import { LoadAddressTypeList } from "@/domain/usecase";
import { RemoteLoadAddressTypeListDTO } from "../models";

export class RemoteLoadAddressTypeList implements LoadAddressTypeList {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<RemoteLoadAddressTypeList.Model>
  ) {}

  async load(): Promise<LoadAddressTypeList.Model> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: "get",
    });
    const LoadClassificationList = httpResponse.body;
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return LoadClassificationList;
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }
}

export namespace RemoteLoadAddressTypeList {
  export type Model = RemoteLoadAddressTypeListDTO;
}
