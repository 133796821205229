import styled from 'styled-components';
import { TextField } from '@material-ui/core/';
import { primary } from '../../../application/themes';
export const StyledInput = styled(TextField) `
  background: ${(props) => { var _a, _b, _c, _d, _e, _f, _g, _h; return props.disabled ? (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.itemModal) === null || _d === void 0 ? void 0 : _d.disabledBackground : (_h = (_g = (_f = (_e = props.theme) === null || _e === void 0 ? void 0 : _e.commercial) === null || _f === void 0 ? void 0 : _f.components) === null || _g === void 0 ? void 0 : _g.itemModal) === null || _h === void 0 ? void 0 : _h.backgroundColor; }};
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: ${(props) => {
    var _a, _b, _c, _d;
    return props.invalid
        ? '#FF4D4D'
        : props.value != null && props.value.length > 0
            ? primary
            : (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.itemModal) === null || _d === void 0 ? void 0 : _d.border;
}} !important;
    }
    &:hover fieldset {
      border-color: ${primary};
    }
    &.Mui-focused fieldset {
      border: 1px solid ${primary};
    }
    input {
      height: ${(props) => (props.modal && '12px')};
      color: ${(props) => { var _a, _b, _c, _d, _e, _f, _g, _h; return props.disabled ? (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.placeholder : (_h = (_g = (_f = (_e = props.theme) === null || _e === void 0 ? void 0 : _e.commercial) === null || _f === void 0 ? void 0 : _f.components) === null || _g === void 0 ? void 0 : _g.itemModal) === null || _h === void 0 ? void 0 : _h.inputFontColor; }};
    }
  }
  & .MuiInputBase-input {
    height: ${(props) => (props.modal && '0')};
  }

  svg path {
    fill: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.newProposal) === null || _d === void 0 ? void 0 : _d.subtitle; }}
  }
`;
export const MainContainer = styled.div `
  display: flex;
  width: 100%; 
  align-items: center;
`;
export const InnerConteiner = styled.div `
  width: 100%;
`;
export const SpaceContainer = styled.div `
  width: 16px; 
  height: 16px; 
  margin: 0 16px; 
`;
export const SpaceIconContainer = styled.div `
  margin: 0 16px; 
`;
export const ErrorText = styled.span `
  color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.components) === null || _c === void 0 ? void 0 : _c.costTable) === null || _d === void 0 ? void 0 : _d.errorText; }};
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0.02em;
  line-height: 150%;
`;
