import styled from 'styled-components';
const OrderByContainer = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ListTextSpan = styled.span `
  margin-right: 10px;
  color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.proposal) === null || _d === void 0 ? void 0 : _d.listTextSpan; }};
`;
const DropdownMenuContainer = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.proposal) === null || _d === void 0 ? void 0 : _d.dropdownBackgroundColor; }};
  min-width: 150px;
  height: 32px;
  border: 1px solid ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.proposal) === null || _d === void 0 ? void 0 : _d.dropdownBorderColor; }};
  box-sizing: border-box;
  border-radius: 4px;

  .select-style {
    .MuiSelect-select:focus {
      background-color: transparent;
    }

    .MuiSelect-icon {
      color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.proposal) === null || _d === void 0 ? void 0 : _d.dropdownIconColor; }}
    }
    padding-left: 16px;
    width: 100%;
  }

  span {
    color: ${(props) => { var _a, _b, _c, _d; return (_d = (_c = (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.commercial) === null || _b === void 0 ? void 0 : _b.pages) === null || _c === void 0 ? void 0 : _c.proposal) === null || _d === void 0 ? void 0 : _d.dropdownFontColor; }};
  }
`;
const ArrowIconContainer = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  width: 20px;
  height: 20px;
  cursor: pointer;

  svg {
    transform: ${(props) => !props.rotate && 'rotate(180deg)'};
  }
`;
export { OrderByContainer, ListTextSpan, DropdownMenuContainer, ArrowIconContainer };
