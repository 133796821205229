var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState, useContext, Fragment } from 'react';
import { MenuItem, FormLabel, Grid, Popover } from '@material-ui/core/';
import { I18n } from 'react-redux-i18n';
import { SelectSpan, TextCell, TextInnerGreyCell, TextInnerCell } from '../steps/StepsStyles';
import { withTheme } from 'styled-components';
import ControlledSelect from '../../../components/ControlledSelect';
import ControlledInput from '../../../components/ControlledInput';
import API from '../../../../infrastructure/api';
import { MenuIconCell, FloatingMenu } from 'fiorde-fe-components';
import RemoveIcon from '../../../../application/icons/RemoveIcon';
import { STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_LABEL_AGENT, STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_LABEL_CIAAREA, STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_LABEL_COIN, STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_LABEL_VMINIMUM, STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_LABEL_UNTIL45KG, STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_LABEL_UNTIL100KG, STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_LABEL_UNTIL300KG, STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_LABEL_UNTIL500KG, STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_LABEL_UNTIL1000KG, STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_INPUT_UNTIL45KG, STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_INPUT_UNTIL100KG, STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_INPUT_UNTIL300KG, STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_INPUT_UNTIL500KG, STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_INPUT_UNTIL1000KG, STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_BUTTON_DELETE, STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_INPUT_FREQUENCY, STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_SELECT_VLFREQUENCY, STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_BUTTON_REMOVEDUPLICATE } from '../../../../ids';
import { NumberInput, FormLabelInner, ButtonInner, RedText } from './styles';
import FormatNumber from '../../../../application/utils/formatNumber';
import { usePartnerList, useBusinessPartnerByType } from '../../../hooks';
import { StaggeredProposalContext } from '../../StaggeredProposal/context/StaggeredProposalContext';
import { CurrencytemsTypes } from '../../../../application/enum/currencyEnum';
import { LocaleTypes } from '../../../../application/enum/enum';
const InputRow = ({ invalidInput, setCompleted, setFilled, item, chave }) => {
    var _a;
    const { staggeredproposal, setStaggeredProposal } = useContext(StaggeredProposalContext);
    const { partnerList: agentsList } = usePartnerList();
    const { airPartners } = useBusinessPartnerByType();
    const initialData = {
        until45kg: null,
        until100kg: null,
        until300kg: null,
        until500kg: null,
        until1000kg: null,
        frequency: null,
        vlFrequency: null
    };
    const [data, setData] = useState(initialData);
    const floatingButtonMenuItems = [
        {
            label: 'Excluir tarifa',
            onClick: (e) => {
                handleRemove();
            }
        }
    ];
    const MaxLenth = {
        maxLength: 13
    };
    const [state, setState] = useState({ anchorEl: null, currentKey: null });
    const handleClick = (event, key) => {
        setState({ anchorEl: event.currentTarget, currentKey: key });
    };
    const handleClose = () => {
        setState({ anchorEl: null, currentKey: null });
    };
    const { anchorEl } = state;
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const valuesFrequency = (e) => {
        let value = parseInt(e.target.value, 10);
        if (value > 99)
            value = 99;
        if (value < 1)
            value = 1;
        setData(Object.assign(Object.assign({}, data), { frequency: value }));
    };
    const [frequencyList, setFrequencyList] = useState([]);
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        void (function () {
            return __awaiter(this, void 0, void 0, function* () {
                yield API.getFrequency()
                    .then((response) => setFrequencyList(response))
                    .catch((err) => console.log(err));
            });
        })();
        if (item.freightValues[1] !== undefined) {
            setData({
                until45kg: { value: FormatNumber.convertNumberToString(((_a = item.freightValues[1]) === null || _a === void 0 ? void 0 : _a.until45kg) !== null ? (_b = item.freightValues[1]) === null || _b === void 0 ? void 0 : _b.until45kg : null) },
                until100kg: { value: FormatNumber.convertNumberToString(((_c = item.freightValues[1]) === null || _c === void 0 ? void 0 : _c.until100kg) !== null ? (_d = item.freightValues[1]) === null || _d === void 0 ? void 0 : _d.until100kg : null) },
                until300kg: { value: FormatNumber.convertNumberToString(((_e = item.freightValues[1]) === null || _e === void 0 ? void 0 : _e.until300kg) !== null ? (_f = item.freightValues[1]) === null || _f === void 0 ? void 0 : _f.until300kg : null) },
                until500kg: { value: FormatNumber.convertNumberToString(((_g = item.freightValues[1]) === null || _g === void 0 ? void 0 : _g.until500kg) !== null ? (_h = item.freightValues[1]) === null || _h === void 0 ? void 0 : _h.until500kg : null) },
                until1000kg: { value: FormatNumber.convertNumberToString(((_j = item.freightValues[1]) === null || _j === void 0 ? void 0 : _j.until1000kg) !== null ? (_k = item.freightValues[1]) === null || _k === void 0 ? void 0 : _k.until1000kg : null) },
                frequency: item.frequency || null,
                vlFrequency: item.vlFrequency || null
            });
        }
        return () => {
            setData(initialData);
        };
    }, []);
    useEffect(() => {
        if ((data.until45kg !== null && String(data.until45kg) !== 'NaN') &&
            (data.until100kg !== null && String(data.until100kg) !== 'NaN') &&
            (data.until300kg !== null && String(data.until300kg) !== 'NaN') &&
            (data.until500kg !== null && String(data.until500kg) !== 'NaN') &&
            (data.until1000kg !== null && String(data.until1000kg) !== 'NaN') &&
            (data.frequency !== null && String(data.frequency) !== 'NaN') &&
            data.vlFrequency !== null && !item.duplicate) {
            setCompleted((currentState) => (Object.assign(Object.assign({}, currentState), { step2: true })));
        }
        else {
            setCompleted((currentState) => (Object.assign(Object.assign({}, currentState), { step2: false })));
        }
        if (data.until45kg !== null || data.until100kg !== null || data.until300kg !== null || data.until500kg !== null || data.until1000kg || data.frequency !== null || data.vlFrequency !== null) {
            setFilled((currentState) => {
                return Object.assign(Object.assign({}, currentState), { step2: true });
            });
        }
        else {
            setFilled((currentState) => {
                return Object.assign(Object.assign({}, currentState), { step2: false });
            });
        }
    }, [data]);
    const rgxFloat = /^[0-9]*,?[0-9]*$/;
    const validateFloatInput = (value) => {
        return value.match(rgxFloat);
    };
    const handleValues = (e, key) => {
        setData(Object.assign(Object.assign({}, data), { [key]: e.target.value }));
    };
    const handleRemove = () => {
        const staggered = staggeredproposal === null || staggeredproposal === void 0 ? void 0 : staggeredproposal.proposalTariff;
        const newArr = staggered.filter((item, index) => index !== state.currentKey);
        setStaggeredProposal(Object.assign(Object.assign({}, staggeredproposal), { proposalTariff: newArr }));
        setData(initialData);
    };
    const handleRemoveDuplicated = (chave) => {
        const staggered = staggeredproposal === null || staggeredproposal === void 0 ? void 0 : staggeredproposal.proposalTariff;
        const newArr = staggered.filter((item, index) => index !== chave);
        setStaggeredProposal(Object.assign(Object.assign({}, staggeredproposal), { proposalTariff: newArr }));
        setData(initialData);
    };
    useEffect(() => {
        const originalData = staggeredproposal === null || staggeredproposal === void 0 ? void 0 : staggeredproposal.proposalTariff;
        if (data.until45kg !== null || data.until100kg !== null || data.until300kg !== null || data.until500kg !== null || data.until1000kg !== null) {
            const updatedData = originalData.map((obj, index) => {
                var _a, _b, _c, _d, _e;
                if (index === chave) {
                    return Object.assign(Object.assign({}, obj), { frequency: data.frequency, vlFrequency: data.vlFrequency, freightValues: [
                            obj === null || obj === void 0 ? void 0 : obj.freightValues[0],
                            Object.assign(Object.assign({}, obj === null || obj === void 0 ? void 0 : obj.freightValues[1]), { vlMinimum: obj === null || obj === void 0 ? void 0 : obj.freightValues[0].vlMinimum, until45kg: ((_a = data.until45kg) === null || _a === void 0 ? void 0 : _a.value) !== undefined ? data.until45kg.value : String(data.until45kg), until100kg: ((_b = data.until100kg) === null || _b === void 0 ? void 0 : _b.value) !== undefined ? data.until100kg.value : String(data.until100kg), until300kg: ((_c = data.until300kg) === null || _c === void 0 ? void 0 : _c.value) !== undefined ? data.until300kg.value : String(data.until300kg), until500kg: ((_d = data.until500kg) === null || _d === void 0 ? void 0 : _d.value) !== undefined ? data.until500kg.value : String(data.until500kg), until1000kg: ((_e = data.until1000kg) === null || _e === void 0 ? void 0 : _e.value) !== undefined ? data.until1000kg.value : String(data.until1000kg), buyOrSell: 'SELL' })
                        ] });
                }
                else {
                    return obj;
                }
            });
            setStaggeredProposal(Object.assign(Object.assign({}, staggeredproposal), { proposalTariff: updatedData }));
        }
    }, [data]);
    const getAgentName = (id) => {
        const { simpleName } = agentsList.find((item) => item.id === id);
        return simpleName;
    };
    const getBusinessPartnerName = (id) => {
        var _a;
        const getObj = airPartners === null || airPartners === void 0 ? void 0 : airPartners.find((item) => item.businessPartner.id === id);
        const simpleName = (_a = getObj === null || getObj === void 0 ? void 0 : getObj.businessPartner) === null || _a === void 0 ? void 0 : _a.simpleName;
        return simpleName || '';
    };
    const checkNumber = (value) => {
        if (typeof value === 'number' && !isNaN(value)) {
            return FormatNumber.convertNumberWithInterCoin(LocaleTypes.PT_BR, CurrencytemsTypes.BRL, Number(value));
        }
        else {
            return value;
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { container: true, spacing: 1 },
            React.createElement(Grid, { item: true, xs: 2 },
                React.createElement(FormLabelInner, { component: "legend", id: STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_LABEL_AGENT },
                    React.createElement(TextCell, null, getAgentName(item === null || item === void 0 ? void 0 : item.idAgent)))),
            React.createElement(Grid, { item: true, xs: 2 },
                React.createElement(FormLabelInner, { component: "legend", id: STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_LABEL_CIAAREA }, getBusinessPartnerName(item === null || item === void 0 ? void 0 : item.idBusinessPartnerTransporter))),
            React.createElement(Grid, { item: true, xs: 1 },
                React.createElement(FormLabelInner, { id: STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_LABEL_COIN, component: "legend" }, item === null || item === void 0 ? void 0 : item.currency)), (_a = item === null || item === void 0 ? void 0 : item.freightValues) === null || _a === void 0 ? void 0 :
            _a.map((line, index) => {
                if ((line === null || line === void 0 ? void 0 : line.buyOrSell) !== 'SELL') {
                    return (React.createElement(Fragment, { key: index },
                        React.createElement(Grid, { key: index, item: true, xs: 1 },
                            React.createElement(FormLabelInner, { component: "legend", id: STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_LABEL_VMINIMUM }, checkNumber(line.vlMinimum))),
                        React.createElement(Grid, { item: true, xs: 1 },
                            React.createElement(FormLabelInner, { component: "legend", center: true, id: STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_LABEL_UNTIL45KG }, checkNumber(line.until45kg))),
                        React.createElement(Grid, { item: true, xs: 1 },
                            React.createElement(FormLabelInner, { component: "legend", center: true, id: STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_LABEL_UNTIL100KG }, checkNumber(line.until100kg))),
                        React.createElement(Grid, { item: true, xs: 1 },
                            React.createElement(FormLabelInner, { component: "legend", center: true, id: STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_LABEL_UNTIL300KG }, checkNumber(line.until300kg))),
                        React.createElement(Grid, { item: true, xs: 1 },
                            React.createElement(FormLabelInner, { component: "legend", center: true, id: STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_LABEL_UNTIL500KG }, checkNumber(line.until500kg))),
                        React.createElement(Grid, { item: true, xs: 1 },
                            React.createElement(FormLabelInner, { component: "legend", center: true, id: STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_LABEL_UNTIL1000KG }, checkNumber(line.until1000kg))),
                        React.createElement(Grid, { item: true, xs: 1 }, item.duplicate
                            ? (React.createElement(FormLabelInner, { component: "legend", center: true, style: { cursor: 'pointer' } },
                                React.createElement(RemoveIcon, { id: STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_BUTTON_REMOVEDUPLICATE, onClick: () => handleRemoveDuplicated(chave) })))
                            : (React.createElement(React.Fragment, null,
                                React.createElement(ButtonInner, { id: STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_BUTTON_DELETE, "aria-describedby": id, onClick: (e) => handleClick(e, chave) },
                                    React.createElement(MenuIconCell, null)),
                                React.createElement(Popover, { id: id, open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'center'
                                    }, transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'center'
                                    } },
                                    React.createElement(FloatingMenu, { menuItems: floatingButtonMenuItems })))))));
                }
                return React.createElement(Fragment, { key: index });
            })),
        React.createElement(Grid, { container: true, spacing: 1, style: {
                padding: '10px 0px 0px 0px'
            } },
            React.createElement(Grid, { item: true, xs: 2 }),
            React.createElement(Grid, { item: true, xs: 2 }),
            React.createElement(Grid, { item: true, xs: 2, style: {
                    background: '#F0F1F5'
                } },
                React.createElement(TextInnerGreyCell, { invalid: invalidInput && (data.until45kg === null || data.until100kg === null || data.until300kg === null || data.until500kg === null || data.until1000kg === null) }, "Tarifas para venda:")),
            React.createElement(Grid, { item: true, xs: 1, style: {
                    background: '#F0F1F5'
                } },
                React.createElement(NumberInput, { id: STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_INPUT_UNTIL45KG, decimalSeparator: ',', thousandSeparator: '.', decimalScale: 2, format: (value) => FormatNumber.rightToLeftFormatter(value, 2), customInput: ControlledInput, toolTipTitle: "", invalid: invalidInput && (data.until45kg === null || String(data.until45kg) === 'NaN'), value: data.until45kg != null ? data.until45kg.value : '', onChange: e => { validateFloatInput(e.target.value) !== null && handleValues(e, 'until45kg'); }, variant: "outlined", size: "small", modal: true, style: { marginRight: '3px' }, inputProps: MaxLenth })),
            React.createElement(Grid, { item: true, xs: 1, style: {
                    background: '#F0F1F5'
                } },
                React.createElement(NumberInput, { id: STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_INPUT_UNTIL100KG, decimalSeparator: ',', thousandSeparator: '.', decimalScale: 2, format: (value) => FormatNumber.rightToLeftFormatter(value, 2), customInput: ControlledInput, toolTipTitle: "", invalid: invalidInput && (data.until100kg === null || String(data.until100kg) === 'NaN'), value: data.until100kg != null ? data.until100kg.value : '', onChange: e => { validateFloatInput(e.target.value) !== null && handleValues(e, 'until100kg'); }, variant: "outlined", size: "small", modal: true, style: { marginRight: '3px' }, inputProps: MaxLenth })),
            React.createElement(Grid, { item: true, xs: 1, style: {
                    background: '#F0F1F5'
                } },
                React.createElement(NumberInput, { id: STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_INPUT_UNTIL300KG, decimalSeparator: ',', thousandSeparator: '.', decimalScale: 2, format: (value) => FormatNumber.rightToLeftFormatter(value, 2), customInput: ControlledInput, toolTipTitle: "", invalid: invalidInput && (data.until300kg === null || String(data.until300kg) === 'NaN'), value: data.until300kg != null ? data.until300kg.value : '', onChange: e => { validateFloatInput(e.target.value) !== null && handleValues(e, 'until300kg'); }, variant: "outlined", size: "small", modal: true, style: { marginRight: '3px' }, inputProps: MaxLenth })),
            React.createElement(Grid, { item: true, xs: 1, style: {
                    background: '#F0F1F5'
                } },
                React.createElement(NumberInput, { id: STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_INPUT_UNTIL500KG, decimalSeparator: ',', thousandSeparator: '.', decimalScale: 2, format: (value) => FormatNumber.rightToLeftFormatter(value, 2), customInput: ControlledInput, toolTipTitle: "", invalid: invalidInput && (data.until500kg === null || String(data.until500kg) === 'NaN'), value: data.until500kg != null ? data.until500kg.value : '', onChange: e => { validateFloatInput(e.target.value) !== null && handleValues(e, 'until500kg'); }, variant: "outlined", size: "small", modal: true, style: { marginRight: '3px' }, inputProps: MaxLenth })),
            React.createElement(Grid, { item: true, xs: 1, style: {
                    background: '#F0F1F5'
                } },
                React.createElement(NumberInput, { id: STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_INPUT_UNTIL1000KG, decimalSeparator: ',', thousandSeparator: '.', decimalScale: 2, format: (value) => FormatNumber.rightToLeftFormatter(value, 2), customInput: ControlledInput, toolTipTitle: "", invalid: invalidInput && (data.until1000kg === null || String(data.until1000kg) === 'NaN'), value: data.until1000kg != null ? data.until1000kg.value : '', onChange: e => { validateFloatInput(e.target.value) !== null && handleValues(e, 'until1000kg'); }, variant: "outlined", size: "small", modal: true, style: { marginRight: '3px' }, inputProps: MaxLenth })),
            React.createElement(Grid, { item: true, xs: 1 })),
        React.createElement(Grid, { container: true, spacing: 1, style: {
                padding: '10px 0px 10px 0px'
            } },
            React.createElement(Grid, { item: true, xs: 2 }),
            React.createElement(Grid, { item: true, xs: 2 }),
            React.createElement(Grid, { item: true, xs: 2 },
                React.createElement(TextInnerCell, { invalid: invalidInput && (data.frequency === null || data.vlFrequency === null) }, "Frequ\u00EAncia*:")),
            React.createElement(Grid, { item: true, xs: 1 },
                React.createElement(ControlledInput, { id: STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_INPUT_FREQUENCY, toolTipTitle: I18n.t('components.itemModal.requiredField'), invalid: invalidInput && data.frequency === null, variant: "outlined", value: data.frequency || '', size: "small", InputProps: {
                        inputProps: {
                            max: 99,
                            min: 1
                        }
                    }, type: "number", 
                    // onChange={(e) => setData({ ...data, frequency: e.target.value })}
                    onChange: (e) => {
                        valuesFrequency(e);
                    } })),
            React.createElement(Grid, { item: true, xs: 1, style: { marginTop: '15px' } },
                React.createElement(FormLabel, { component: "span", style: { margin: '0 0 0 10px' } }, I18n.t('pages.newProposal.step4.times'))),
            React.createElement(Grid, { item: true, xs: 3 },
                React.createElement(ControlledSelect, { labelId: "frequency-label", id: STAGGEREDPROPOSAL_NEWSTAGGEREDPROPOSAL_STEP2_SELECT_VLFREQUENCY, value: data.vlFrequency || '', onChange: (e) => setData(Object.assign(Object.assign({}, data), { vlFrequency: e.target.value })), displayEmpty: true, disableUnderline: true, invalid: invalidInput && data.vlFrequency === null, toolTipTitle: I18n.t('components.itemModal.requiredField') },
                    React.createElement(MenuItem, { disabled: true, value: data.vlFrequency },
                        React.createElement(SelectSpan, { placeholder: 1 }, I18n.t('pages.newProposal.step4.choose'))),
                    frequencyList.map((item) => (React.createElement(MenuItem, { key: item.id, value: item.id },
                        React.createElement(SelectSpan, null, item.description)))))),
            React.createElement(Grid, { item: true, xs: 1 })),
        item.duplicate && (React.createElement(Grid, { container: true, spacing: 1, style: {
                padding: '10px 0px 10px 0px'
            } },
            React.createElement(Grid, { item: true, xs: 6 },
                React.createElement(RedText, null, I18n.t('pages.staggeredProposal.newStaggeredProposal.step2.duplicateTariffText')))))));
};
export default withTheme(InputRow);
