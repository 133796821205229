import React from 'react';
import { I18n } from 'react-redux-i18n';
import EditIcon from '../../../application/icons/EditIcon';
import RemoveIcon from '../../../application/icons/RemoveIcon';
import { TableHead, TableBody, TableCell } from '@material-ui/core';
import { DeleteIconDiv, EditIconDiv, FormatValue, RowReverseDiv, StyledRow, StyledTable, StyledTableCell, StyledTableRow, TableHeader } from './style';
import GetNamesByID from '../../../application/utils/getNamesByID';
const SurchargeTable = ({ data, dataFields, remove, edit, agentList, calculationTypes }) => {
    const verifyType = (item) => {
        if (item.type === 'FIXO' || item.type === 'BL') {
            return (React.createElement(FormatValue, null,
                dataFields.currencySale,
                " ",
                item.saleValue));
        }
        else {
            return (React.createElement(FormatValue, null,
                dataFields.currencySale,
                " ",
                item.totalItem));
        }
    };
    function getAgentNameByidBusinessPartnerAgent(idBusinessPartnerAgent) {
        var _a, _b;
        return (_b = (_a = agentList.find((agent) => agent.businessPartner.id === idBusinessPartnerAgent)) === null || _a === void 0 ? void 0 : _a.businessPartner) === null || _b === void 0 ? void 0 : _b.simpleName;
    }
    if (data != null && data.length > 0) {
        return (React.createElement(StyledTable, null,
            React.createElement(TableHead, null,
                React.createElement(StyledTableRow, { noBorder: true },
                    React.createElement(TableCell, null,
                        React.createElement(TableHeader, null, I18n.t('pages.newProposal.step5.expense'))),
                    React.createElement(TableCell, null,
                        React.createElement(TableHeader, null, I18n.t('pages.newProposal.step5.agent'))),
                    React.createElement(TableCell, null,
                        React.createElement(TableHeader, null, I18n.t('pages.newProposal.step5.type'))),
                    React.createElement(TableCell, null,
                        React.createElement(TableHeader, null, I18n.t('pages.newProposal.step5.minValue'))),
                    React.createElement(TableCell, null,
                        React.createElement(TableHeader, null, I18n.t('pages.newProposal.step5.colValue'))),
                    React.createElement(TableCell, null,
                        React.createElement(TableHeader, null, I18n.t('pages.newProposal.step5.totalItem'))),
                    React.createElement(TableCell, null,
                        React.createElement(TableHeader, null)),
                    React.createElement(TableCell, null,
                        React.createElement(TableHeader, null)))),
            React.createElement(TableBody, null, data === null || data === void 0 ? void 0 : data.map((item, key) => {
                var _a;
                return (React.createElement(StyledRow, { id: item.id, key: key },
                    React.createElement(StyledTableCell, { width: "30%", color: 1, component: "th", scope: "row" }, item.expense),
                    React.createElement(StyledTableCell, { width: "30%", align: "left" }, getAgentNameByidBusinessPartnerAgent((_a = item === null || item === void 0 ? void 0 : item.agent) === null || _a === void 0 ? void 0 : _a.idBusinessPartnerAgent)),
                    React.createElement(StyledTableCell, { width: "30%", align: "left" }, GetNamesByID.getTxCalculationTypeById(calculationTypes, item.idCalculationType)),
                    React.createElement(StyledTableCell, { width: "100%", align: "left" },
                        React.createElement(FormatValue, null, item.minimumValue !== '' ? `${String(dataFields.currencySale)} ${item.minimumValue}` : '-')),
                    React.createElement(StyledTableCell, { width: "100%", align: "left" },
                        React.createElement(FormatValue, null,
                            dataFields.currencySale,
                            " ",
                            item.saleValue)),
                    React.createElement(StyledTableCell, { width: "100%", align: "left" }, verifyType(item)),
                    React.createElement(StyledTableCell, { width: "100%" },
                        React.createElement(RowReverseDiv, null,
                            React.createElement(DeleteIconDiv, null,
                                React.createElement(RemoveIcon, { onClick: () => {
                                        if (remove != null)
                                            remove(item.id);
                                    } })),
                            React.createElement(EditIconDiv, null,
                                React.createElement(EditIcon, { onClick: () => {
                                        if (edit != null)
                                            edit(item);
                                    } }))))));
            }))));
    }
    return React.createElement("div", null);
};
export default SurchargeTable;
