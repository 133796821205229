import React, { useEffect, useState } from 'react'

import { I18n } from 'react-redux-i18n'
import { useHistory } from 'react-router-dom'
import { getUserName } from '../../../application/utils/authStorage'
import { MainMenuPL } from 'fiorde-fe-components'
import NotFound from './notFound'
import { Root, Title, WelcomeUserName, ItemContainer, Container, Rows } from './style'
import { Roles } from '../../../application/utils/enums/rolesEnum'
import { Modules } from '../../../application/utils/enums/modulesEnum'

const Home = (): JSX.Element => {
  const [noHasModule, setNoHasModule] = useState(false)
  const history = useHistory()
  const [username, setUserName] = useState<string>('User')

  getUserName()
    .then((result: string) => setUserName(result))
    .catch((error) => console.warn(error))

  const handleClick = (card: any): void => {
    history.push(card.url)
  }

  useEffect(() => {
    const user = localStorage.getItem('user')
    const objUser = user != null ? JSON.parse(user) : ''
    if (!('roles' in objUser)) {
      setNoHasModule(true)
    }
  }, [])

  const getRoles = (typeModule): boolean => {
    const user = localStorage.getItem('user')
    if (user != null) {
      const objUser = JSON.parse(user)
      if ('roles' in objUser) {
        for (const role of objUser.roles) {
          switch (role) {
            case Roles.MODULE_COMMERCIAL:
              if (typeModule === Modules.COMMERCIAL) {
                return true
              }
          }
        }
      }
    }
    return false
  }

  const menuItens: any[] = [
    {
      icon: 'exportation',
      url: '/',
      isActive: getRoles(Modules.EXPORTATION),
      key: 'exportation',
      label: I18n.t('pages.home.exportation'),
      onclick: (item) => handleClick(item)
    },
    {
      icon: 'importation',
      isActive: getRoles(Modules.IMPORTATION),
      url: '/',
      key: 'importation',
      label: I18n.t('pages.home.importation'),
      onclick: (item) => handleClick(item)
    },
    {
      icon: 'freight-forwarder',
      isActive: getRoles(Modules.FREIGHT_FORWARDER),
      key: 'freight-forwarder',
      url: '/',
      label: I18n.t('pages.home.freightForwarder'),
      onclick: (item) => handleClick(item)
    },
    {
      icon: 'commercial',
      url: '/comercial',
      isActive: getRoles(Modules.COMMERCIAL),
      key: 'commercial',
      label: I18n.t('pages.home.commercial'),
      onclick: (item) => handleClick(item)
    },
    {
      icon: 'registrations',
      url: '/registrations',
      isActive: true,
      key: 'registrations',
      label: 'Cadastros',
      onclick: (item) => handleClick(item)
    },
    {
      icon: 'yard-control',
      url: '/yard',
      isActive: true,
      key: 'yard',
      label: 'Controle de Pátio',
      onclick: (item) => handleClick(item)
    },
    {
      icon: 'checklist',
      url: '/checklist',
      isActive: true,
      key: 'checklist',
      label: 'Checklist',
      onclick: (item) => handleClick(item)
    },
    {
      icon: 'pu-catp',
      url: '/pu-catp',
      isActive: true,
      key: 'pu-catp',
      label: 'Catálogo de Produtos',
      onclick: (item) => handleClick(item)
    },
    {
      icon: 'billing',
      isActive: getRoles(Modules.BILLING),
      key: 'billing',
      url: '/',
      label: I18n.t('pages.home.billing'),
      onclick: (item) => handleClick(item)
    },
    {
      icon: 'national-logistic',
      isActive: getRoles(Modules.NATIONAL_LOGISTIC),
      key: 'national-logistic',
      url: '/',
      label: I18n.t('pages.home.nationalLogistic'),
      onclick: (item) => handleClick(item)
    }
  ]

  return (
    <Root>
      {noHasModule
        ? (
        <NotFound />
          )
        : (
        <>
          <Title>
            <WelcomeUserName>{I18n.t('pages.home.welcome', { name: username })}</WelcomeUserName>
          </Title>
          <Container>
            <Rows><ItemContainer>{I18n.t('pages.home.sirius_modules')}</ItemContainer></Rows>
            <Rows><MainMenuPL menuPlItems={menuItens} /></Rows>
          </Container>
        </>
          )}
    </Root>
  )
}
export default Home
