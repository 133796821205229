import styled from 'styled-components'
interface WrapperProps {
  isOpen: boolean
}
const Container = styled.div`
  width: 100%;
  height: auto;
  background-color: ${(props: any) =>
    props.theme?.commercial?.pages?.home?.mainColor};
  color: ${(props: any) => props.theme?.commercial?.fontColor};
  #extension_root_container {
    div {
      div.MuiDrawer-paper {
        left: 88px;
        top: 64px;
      }
    }
  }
`
const ExtensionMenuContainer = styled.div`
  position: fixed;
  z-index: 1;
`

const ChildrenContainer = styled.div<WrapperProps>`
  padding: 1rem 1rem 1rem ${props => props.isOpen ? 'calc(200px + 1rem)' : 'calc(58px + 1rem)'};
  width: 100%;
  min-height: calc(100vh - 64px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  transition: padding-left 0.2s;
  transition-timing-function: linear;
  z-index: 0;
  background: #e5e5e5;
`

export { Container, ChildrenContainer, ExtensionMenuContainer }