import { LabelCell, IconButton } from "fiorde-fe-components";
import { EditIcon, TrashIcon } from "../icons";
import { t } from "i18next";
import { CHECKLIST_CONDITIONCOLUMNS_BUTTON_EDIT, CHECKLIST_CONDITIONCOLUMNS_BUTTON_REMOVE } from "@/ids";

export function ChecklistConditionsColumns({
  operatorList,
  conditionList,
  edit,
  remove,
}) {
  return [
    {
      label: t("components.add-checklist.condition-columns.condition"),
      key: "condition",
      render: ({ conditionType }: any) => (
        <LabelCell>
          {conditionList?.find((v) => v.id === conditionType.id)?.name}
        </LabelCell>
      ),
      size: 2,
    },
    {
      label: t("components.add-checklist.condition-columns.operator"),
      key: "name",
      render: ({ operator }: any) => (
        <LabelCell>
          {operator}
        </LabelCell>
      ),
      size: 4,
    },
    {
      label: t("components.add-checklist.condition-columns.actions"),
      key: "actions",
      render: (contact, index) => (
        <div style={{ display: "flex", gap: 8 }}>
          <IconButton
            id={CHECKLIST_CONDITIONCOLUMNS_BUTTON_EDIT}
            data-testid="open-button"
            onClick={() => edit(contact, index)}
            tooltip={t(
              "components.add-checklist.condition-columns.edit-tooltip"
            )}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            id={CHECKLIST_CONDITIONCOLUMNS_BUTTON_REMOVE}
            data-testid="open-button"
            onClick={() => remove(index)}
            tooltip={t(
              "components.add-checklist.condition-columns.remove-tooltip"
            )}
          >
            <TrashIcon />
          </IconButton>
        </div>
      ),
      size: 1,
    },
  ];
}
