var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useContext, useEffect, useState } from 'react';
import { TableBody, TableHead, TableRow, Grid, FormLabel } from '@material-ui/core';
import { ButtonContainer, Default, DeleteIconDiv, Description, EditIconDiv, ErrorText, Footer, Header, MainDiv, RedColorSpan, RowReverseDiv, SecondaryValue, StyledTable, StyledTableCell, TableHeadRow, Title, TotalCostLabel, Type } from './CostTableStyles';
import EditIcon from '../../../application/icons/EditIcon';
import RemoveIcon from '../../../application/icons/RemoveIcon';
import { I18n } from 'react-redux-i18n';
import { Button, Messages } from 'fiorde-fe-components';
import CostModal, { initialState } from '../CostModal/CostModal';
import { MessageContainer } from '../../pages/NewProposal/style';
import API from '../../../infrastructure/api';
import { ProposalContext } from '../../pages/NewProposal/context/ProposalContext';
import { FareItemsTypes, CostTypes } from '../../../application/enum/costEnum';
import { ModalTypes } from '../../../application/enum/enum';
import { TARIFF_COST_TABLE_SPAN_AGENT } from '../../../ids';
import GetNamesByID from '../../../application/utils/getNamesByID';
import { useCalculationTypes } from '../../hooks/index';
const CostTable = ({ idSufix, agentList, modalTitle, title, costData, modal, specifications, containerItems, setUndoMessage, undoMessage, tableData, setTableData, setTotalCostData, serviceList, calculationData, errorMessage, dataTotalCostOrigin, totalCostArray }) => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [copyTable, setCopyTable] = useState([]);
    const [chargeData, setChargeData] = useState(initialState);
    const currencyList = new Map();
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { proposal } = useContext(ProposalContext);
    const { data: calculationTypes = [] } = useCalculationTypes();
    const editClickHandler = (tableData) => {
        setChargeData(Object.assign(Object.assign({}, tableData), { buyValueCalculated: null, saleValueCalculated: null }));
        handleOpen();
    };
    const removeClickHandler = (id) => {
        setCopyTable(data);
        const newTableData = [...data];
        setData(newTableData.filter((data) => data.id !== id));
        if (title === I18n.t('pages.newProposal.step6.origin')) {
            setUndoMessage({ step3: false, step6origin: true, step6destiny: false, step5: false });
        }
        else {
            setUndoMessage({ step3: false, step6origin: false, step6destiny: true, step5: false });
        }
    };
    const addClickHandler = () => {
        setChargeData(initialState);
        setUndoMessage({ step3: false, step6origin: false, step6destiny: false, step5: false });
        handleOpen();
    };
    const handleAdd = (item) => {
        if (item.id !== null) {
            const editData = [...data];
            const index = editData.findIndex((i) => i.id === item.id);
            editData[index] = item;
            setData(editData);
        }
        else {
            const MaxValue = data.length;
            const newItem = Object.assign(Object.assign({}, item), { id: MaxValue + 1 });
            setData([...data, newItem]);
        }
    };
    useEffect(() => {
        setTableData(data);
        setTotalCostData(Array.from(currencyList, ([name, value]) => ({ name, value })));
    }, [data]);
    useEffect(() => {
        const copyData = containerItems.length > 0
            ? data.filter(item => (containerItems.findIndex(container => item.selectedContainer === container.type) !== -1) || item.selectedContainer === null).map((item) => {
                var _a;
                const indexContainer = containerItems.findIndex(container => item.selectedContainer === container.type);
                const newData = {
                    costType: GetNamesByID.getTxCalculationTypeById(calculationTypes, item.idCalculationType),
                    quantityContainer: specifications === 'fcl' ? Number((_a = containerItems[indexContainer]) === null || _a === void 0 ? void 0 : _a.amount) : 0,
                    valueGrossWeight: isNaN(Number(calculationData === null || calculationData === void 0 ? void 0 : calculationData.weight)) ? 0 : calculationData === null || calculationData === void 0 ? void 0 : calculationData.weight,
                    valueCubage: isNaN(Number(calculationData === null || calculationData === void 0 ? void 0 : calculationData.cubage)) ? 0 : calculationData === null || calculationData === void 0 ? void 0 : calculationData.cubage,
                    valueWeightCubed: isNaN(Number(calculationData === null || calculationData === void 0 ? void 0 : calculationData.cubageWeight)) ? 0 : calculationData === null || calculationData === void 0 ? void 0 : calculationData.cubageWeight,
                    valuePurchase: Number(item.buyValue),
                    valueSale: Number(item.saleValue),
                    idCurrencyPurchase: item.buyCurrency,
                    idCurrencySale: item.saleCurrency,
                    valuePurchaseCW: item.type === 'CW' ? proposal.cargo[0].vlCwPurchase : null,
                    valueSaleCW: item.type === 'CW' ? proposal.cargo[0].vlCwSale : null
                };
                void (function () {
                    return __awaiter(this, void 0, void 0, function* () {
                        yield API.postTotalCalculation(newData)
                            .then((response) => {
                            item.buyValueCalculated = response.valuePurchase;
                            item.saleValueCalculated = response.valueSale;
                            item.buyCurrency = response.idCurrencyPurchase;
                            item.saleCurrency = response.idCurrencySale;
                        })
                            .catch((err) => console.log(err));
                    });
                })();
                calculateTotalCost(item.buyCurrency, item.saleCurrency, item.buyValueCalculated, item.saleValueCalculated, item.buyMin, item.saleMin);
                return item;
            })
            : [];
        setData(copyData);
    }, [calculationData, containerItems, proposal === null || proposal === void 0 ? void 0 : proposal.cargo[0]]);
    useEffect(() => {
        setCopyTable([]);
        setChargeData(initialState);
        setData([]);
        setUndoMessage({ step3: false, step6origin: false, step6destiny: false, step5: false });
    }, [modal]);
    const waitLoadAllData = () => __awaiter(void 0, void 0, void 0, function* () {
        const totalFreight = totalCostArray === null || totalCostArray === void 0 ? void 0 : totalCostArray.find((total) => total.costType === CostTypes.Freight);
        const totalTariff = totalCostArray === null || totalCostArray === void 0 ? void 0 : totalCostArray.find((total) => total.costType === CostTypes.Tariff);
        const allData = tableData.map((item) => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b, _c;
            const indexContainer = containerItems.findIndex(container => item.selectedContainer === container.type);
            const data = {
                costType: GetNamesByID.getTxCalculationTypeById(calculationTypes, item.idCalculationType),
                quantityContainer: specifications === 'fcl' ? Number((_a = containerItems[indexContainer]) === null || _a === void 0 ? void 0 : _a.amount) : 0,
                valueGrossWeight: isNaN(Number(calculationData === null || calculationData === void 0 ? void 0 : calculationData.weight)) ? 0 : calculationData === null || calculationData === void 0 ? void 0 : calculationData.weight,
                valueCubage: isNaN(Number(calculationData === null || calculationData === void 0 ? void 0 : calculationData.cubage)) ? 0 : calculationData === null || calculationData === void 0 ? void 0 : calculationData.cubage,
                valueWeightCubed: isNaN(Number(calculationData === null || calculationData === void 0 ? void 0 : calculationData.cubageWeight)) ? 0 : calculationData === null || calculationData === void 0 ? void 0 : calculationData.cubageWeight,
                valuePurchase: Number(item.buyValue),
                valueSale: Number(item.saleValue),
                idCurrencyPurchase: item.buyCurrency,
                idCurrencySale: item.saleCurrency
            };
            const totalCalculationData = data.costType === FareItemsTypes.Cw
                ? Object.assign(Object.assign({}, data), { valuePurchaseCW: proposal.cargo[0].vlCwPurchase, valueSaleCW: proposal.cargo[0].vlCwSale }) : data.costType === FareItemsTypes.Fdesp
                ? Object.assign(Object.assign({}, data), { valueTotalOriginPurchase: dataTotalCostOrigin.length > 0 ? (_b = dataTotalCostOrigin[0].value) === null || _b === void 0 ? void 0 : _b.buy : 0, valueTotalOriginSale: dataTotalCostOrigin.length > 0 ? (_c = dataTotalCostOrigin[0].value) === null || _c === void 0 ? void 0 : _c.sale : 0, valueTotalFreight: totalFreight ? totalFreight.valueTotalSale : 0, valueTotalTariff: totalTariff ? totalTariff.valueTotalSale : 0 }) : Object.assign(Object.assign({}, data), { valuePurchaseCW: null, valueSaleCW: null });
            return yield API.postTotalCalculation(totalCalculationData)
                .then((response) => {
                return Object.assign(Object.assign({}, item), { buyValueCalculated: response.valuePurchase, saleValueCalculated: response.valueSale });
            })
                .catch(() => {
                return Object.assign({}, item);
            });
        }));
        const newTableData = yield Promise.all(allData);
        setData([...newTableData]);
    });
    useEffect(() => {
        if (tableData.length > 0) {
            void waitLoadAllData();
        }
    }, []);
    useEffect(() => {
        if (tableData.length > 0 && modalTitle === I18n.t('pages.newProposal.step6.destinationCost')) {
            void waitLoadAllData();
        }
    }, [dataTotalCostOrigin]);
    const calculateTotalCost = (buyCurrency, saleCurrency, buyValue, saleValue, buyMin, saleMin) => {
        const buySum = Number(buyValue) > Number(buyMin) ? buyValue : buyMin;
        const saleSum = Number(saleValue) > Number(saleMin) ? saleValue : saleMin;
        if ((buyCurrency !== null && saleCurrency !== '') && buyCurrency === saleCurrency) {
            currencyList.has(String(buyCurrency))
                ? currencyList.set(String(buyCurrency), {
                    buy: Number(currencyList.get(String(buyCurrency)).buy) + Number(buySum),
                    sale: Number(currencyList.get(String(saleCurrency)).sale) + Number(saleSum)
                })
                : currencyList.set(String(buyCurrency), { buy: Number(buySum), sale: Number(saleSum) });
        }
        else {
            if (buyCurrency !== null && buyCurrency !== '') {
                currencyList.has(String(buyCurrency))
                    ? currencyList.set(String(buyCurrency), {
                        buy: Number(currencyList.get(String(buyCurrency)).buy) + Number(buySum),
                        sale: Number(currencyList.get(String(buyCurrency)).sale)
                    })
                    : currencyList.set(String(buyCurrency), { buy: Number(buySum), sale: 0 });
            }
            if (saleCurrency !== null && saleCurrency !== '') {
                currencyList.has(String(saleCurrency))
                    ? currencyList.set(String(saleCurrency), {
                        buy: Number(currencyList.get(String(saleCurrency)).buy),
                        sale: Number(currencyList.get(String(saleCurrency)).sale) + Number(saleSum)
                    })
                    : currencyList.set(String(saleCurrency), { buy: 0, sale: Number(saleSum) });
            }
        }
    };
    const type = (item) => {
        return item.type !== 'FIXO' && item.type !== 'BL';
    };
    const getAgentName = (idBusinessPartnerAgent) => {
        let name = '';
        agentList === null || agentList === void 0 ? void 0 : agentList.forEach((item) => {
            if (Number(item === null || item === void 0 ? void 0 : item.idBusinessPartnerAgent) === Number(idBusinessPartnerAgent)) {
                name = item.agent;
            }
        });
        return name;
    };
    const costValidation = () => {
        return costData === 0 || agentList.length < 1 || agentList[0].agent === '' || (proposal === null || proposal === void 0 ? void 0 : proposal.cargo[0].cargoVolumes.length) < 1;
    };
    const groupByAgent = () => {
        const result = data.reduce(function (r, a) {
            var _a, _b, _c;
            r[(_a = a.agent) === null || _a === void 0 ? void 0 : _a.idBusinessPartnerAgent] = r[(_b = a.agent) === null || _b === void 0 ? void 0 : _b.idBusinessPartnerAgent] || [];
            r[(_c = a.agent) === null || _c === void 0 ? void 0 : _c.idBusinessPartnerAgent].push(a);
            return r;
        }, {});
        return Object.values(result);
    };
    return (React.createElement(MainDiv, null,
        React.createElement(CostModal, { idSufix: idSufix, agentList: agentList, dataProp: chargeData, handleAdd: handleAdd, open: open, setClose: handleClose, title: modalTitle, modal: modal, specifications: specifications, containerItems: containerItems, serviceList: serviceList, calculationData: calculationData, dataTotalCostOrigin: dataTotalCostOrigin, totalCostArray: totalCostArray }),
        React.createElement(Header, null,
            React.createElement(Title, null,
                title,
                modal === ModalTypes.Land || React.createElement(RedColorSpan, null, " *"))),
        data.length > 0 && groupByAgent().map((dataByAgent, index) => {
            var _a;
            return (React.createElement("div", { key: index },
                title !== I18n.t('pages.newProposal.step6.destiny') &&
                    React.createElement(Grid, { container: true, spacing: 0 },
                        React.createElement(Grid, { item: true, xs: 1 },
                            React.createElement(FormLabel, { component: 'legend' },
                                I18n.t('pages.newProposal.step5.agent'),
                                ":")),
                        React.createElement(Grid, { item: true, xs: 11 },
                            React.createElement(FormLabel, { component: 'legend' },
                                React.createElement("strong", { id: TARIFF_COST_TABLE_SPAN_AGENT }, getAgentName((_a = dataByAgent[0].agent) === null || _a === void 0 ? void 0 : _a.idBusinessPartnerAgent))))),
                React.createElement(StyledTable, null,
                    React.createElement(TableHead, null,
                        React.createElement(TableHeadRow, null,
                            React.createElement(StyledTableCell, { width: "14%" }, I18n.t('components.costTable.description')),
                            React.createElement(StyledTableCell, { width: "11%", align: "left" }, I18n.t('components.costTable.type')),
                            React.createElement(StyledTableCell, { width: "12%", align: "left" }, I18n.t('components.costTable.minBuy')),
                            React.createElement(StyledTableCell, { width: "11%", align: "left" }, I18n.t('components.costTable.buy')),
                            React.createElement(StyledTableCell, { width: "11%", align: "left" }, I18n.t('components.costTable.minSale')),
                            React.createElement(StyledTableCell, { width: "11%", align: "left" }, I18n.t('components.costTable.sale')))),
                    React.createElement(TableBody, null, dataByAgent.map((dataMap) => {
                        calculateTotalCost(dataMap.buyCurrency, dataMap.saleCurrency, dataMap.buyValueCalculated, dataMap.saleValueCalculated, dataMap.buyMin, dataMap.saleMin);
                        return (React.createElement(TableRow, { key: dataMap.id },
                            React.createElement(StyledTableCell, { width: "14%", component: "th", scope: "row" },
                                React.createElement(Description, null, dataMap.description)),
                            React.createElement(StyledTableCell, { width: "11%", align: "left" },
                                React.createElement(Type, null, GetNamesByID.getTxCalculationTypeById(calculationTypes, dataMap.idCalculationType))),
                            React.createElement(StyledTableCell, { width: "13%", align: "left" }, dataMap.buyMin !== null && dataMap.buyMin !== ''
                                ? React.createElement(Default, null,
                                    dataMap.buyCurrency,
                                    " ",
                                    Number(dataMap.buyMin).toFixed(2).replace('.', ','))
                                : React.createElement(Default, null, "-")),
                            React.createElement(StyledTableCell, { width: "13%", align: "left" },
                                dataMap.buyValueCalculated !== null && dataMap.buyValueCalculated !== '' && Number(dataMap.buyValueCalculated) !== 0.00
                                    ? React.createElement(Default, null,
                                        dataMap.buyCurrency,
                                        " ",
                                        Number(dataMap.buyValueCalculated).toFixed(2).replace('.', ','))
                                    : React.createElement(Default, null, "-"),
                                type(dataMap) && dataMap.buyValue !== null && dataMap.buyValue !== ''
                                    ? React.createElement(SecondaryValue, null,
                                        "(",
                                        dataMap.buyCurrency,
                                        " ",
                                        Number(dataMap.buyValue).toFixed(2).replace('.', ','),
                                        ")")
                                    : null),
                            React.createElement(StyledTableCell, { width: "13%", align: "left" }, dataMap.saleMin !== null && dataMap.saleMin !== ''
                                ? React.createElement(Default, null,
                                    dataMap.saleCurrency,
                                    " ",
                                    Number(dataMap.saleMin).toFixed(2).replace('.', ','))
                                : React.createElement(Default, null, "-")),
                            React.createElement(StyledTableCell, { width: "13%", align: "left" },
                                dataMap.saleValueCalculated !== null && dataMap.saleValueCalculated !== '' && Number(dataMap.saleValueCalculated) !== 0.00
                                    ? React.createElement(Default, null,
                                        dataMap.saleCurrency,
                                        " ",
                                        Number(dataMap.saleValueCalculated).toFixed(2).replace('.', ','))
                                    : React.createElement(Default, null, "-"),
                                type(dataMap) && dataMap.saleValue !== null && dataMap.saleValue !== ''
                                    ? React.createElement(SecondaryValue, null,
                                        "(",
                                        dataMap.saleCurrency,
                                        " ",
                                        Number(dataMap.saleValue).toFixed(2).replace('.', ','),
                                        ")")
                                    : null),
                            React.createElement(StyledTableCell, { width: "11%" },
                                React.createElement(RowReverseDiv, null,
                                    React.createElement(DeleteIconDiv, null,
                                        React.createElement(RemoveIcon, { onClick: () => { removeClickHandler(dataMap.id); } })),
                                    React.createElement(EditIconDiv, null,
                                        React.createElement(EditIcon, { onClick: () => { editClickHandler(dataMap); } }))))));
                    })))));
        }),
        React.createElement(Footer, { style: (data === null || data === void 0 ? void 0 : data.length) > 0 ? { borderTop: '1px solid #999DAC' } : { border: 'none' } }, (data === null || data === void 0 ? void 0 : data.length) === 0
            ? React.createElement(ButtonContainer, null,
                React.createElement(Button, { text: I18n.t('components.costTable.addCost'), backgroundGreen: false, icon: 'add', onAction: addClickHandler, disabled: costValidation(), tooltip: costValidation()
                        ? I18n.t('components.costTable.addCostTooltip')
                        : I18n.t('components.costTable.addCost') }),
                React.createElement(ErrorText, null, errorMessage))
            : React.createElement(StyledTable, null,
                React.createElement(TableBody, null, Array.from(currencyList, ([name, value]) => ({ name, value })).map((currency, index) => {
                    return (React.createElement(TableRow, { key: index },
                        React.createElement(StyledTableCell, { width: "25%", align: "left", className: "noBorder noPadding" }, index === 0
                            ? React.createElement(Button, { text: I18n.t('components.costTable.addCost'), backgroundGreen: false, icon: 'add', onAction: addClickHandler, disabled: agentList.length < 1 || agentList[0].agent === '' || (proposal === null || proposal === void 0 ? void 0 : proposal.cargo[0].cargoVolumes.length) < 1, tooltip: costValidation()
                                    ? I18n.t('components.costTable.addCostTooltip')
                                    : I18n.t('components.costTable.addCost') })
                            : null),
                        React.createElement(StyledTableCell, { width: "12%", align: "left", className: "noBorder" }),
                        React.createElement(StyledTableCell, { width: "13%", align: "right", className: "noBorder" },
                            React.createElement(TotalCostLabel, null,
                                "Total ",
                                currency.name,
                                ":")),
                        React.createElement(StyledTableCell, { width: "13%", align: "left", className: "noBorder" }, currency.value.buy !== null && currency.value.buy !== '' && currency.value.buy !== 0
                            ? React.createElement(TotalCostLabel, null, Number(currency.value.buy).toFixed(2).replace('.', ','))
                            : React.createElement(TotalCostLabel, null, "-")),
                        React.createElement(StyledTableCell, { width: "13%", align: "left", className: "noBorder" }),
                        React.createElement(StyledTableCell, { width: "13%", align: "left", className: "noBorder" }, currency.value.sale !== null && currency.value.sale !== '' && currency.value.sale !== 0
                            ? React.createElement(TotalCostLabel, null, Number(currency.value.sale).toFixed(2).replace('.', ','))
                            : React.createElement(TotalCostLabel, null, "-")),
                        React.createElement(StyledTableCell, { width: "11%", className: "noBorder" })));
                })))),
        (((title === I18n.t('pages.newProposal.step6.origin')) && undoMessage.step6origin) ||
            ((title === I18n.t('pages.newProposal.step6.destiny')) && undoMessage.step6destiny)) &&
            React.createElement(MessageContainer, null,
                React.createElement(Messages, { closable: true, severity: 'success', buttonText: I18n.t('pages.newProposal.step3.messageUndoDelete'), closeAlert: () => { setUndoMessage({ step3: false, step6origin: false, step6destiny: false, step5: false }); }, closeMessage: '', goBack: () => { setData(copyTable); setUndoMessage({ step3: false, step6origin: false, step6destiny: false, step5: false }); }, message: I18n.t('pages.newProposal.step3.messageDeleteItem') }))));
};
export default CostTable;
