import { AccessDeniedError, UnexpectedError } from "@/domain/errors";
import { HttpClient, HttpStatusCode } from "@/domain/protocols/http";
import { LoadSpotPegeable } from "@/domain/usecase";
import { RemoteLoadSpotPegeableDTO } from "../models";

export class RemoteLoadSpotPegeable implements LoadSpotPegeable {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<RemoteLoadSpotPegeable.Model>
  ) {}

  async load(filters: Object): Promise<LoadSpotPegeable.Model> {
    const queryString = Object.entries(filters)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    const httpResponse = await this.httpClient.request({
      url: `${this.url}?${queryString}`,
      method: "get",
    });
    const LoadSpotPegeable = httpResponse.body;
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return LoadSpotPegeable;
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        //@ts-ignore
        const err = httpResponse.body?.response?.error;
        throw new UnexpectedError(err);
    }
  }
}

export namespace RemoteLoadSpotPegeable {
  export type Model = RemoteLoadSpotPegeableDTO;
}
