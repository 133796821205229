var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import API from '../../../infrastructure/api';
import { QueryKeys } from '../../../application/enum/queryKeys';
const useTariffsByCountry = () => {
    const [params, setParams] = useState();
    const getTariffsByCountry = () => __awaiter(void 0, void 0, void 0, function* () {
        if (params !== undefined) {
            const data = yield API.getTariffsByCountry(params);
            return data;
        }
    });
    const { data, refetch } = useQuery([QueryKeys.tariffsByCountry, params], getTariffsByCountry, {
        enabled: params !== undefined
    });
    if (data !== undefined && params !== undefined)
        return { content: data.content, totalElements: data.totalElements, setParams, refetch, params };
    return { content: [], totalElements: 0, setParams, refetch, params };
};
export default useTariffsByCountry;
